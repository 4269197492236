import React from 'react';
import {User} from '../../interface'
import './createuser.css';
import constants from '../../config/constants';
import authStore from '../../store/authStore'
import userStore from '../../store/userStore';
import userService from '../../services/userService';
import Utility from '../../helpers/Utility';
import toast from '../toast';
import Select from '../../components/select';
import validate from '../../helpers/validation';
import LoadingWheel from '../loadingwheel';
import UserDeleteModal from './deleteusermodal';
import PhoneInput from 'react-phone-number-input'
import {isValidPhoneNumber} from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import CountryOptions from '../../config/country.json'
import  Permissions from '../../config/permssions'

const {
    super_admin_access,
    org_admin_access ,
    create_or_update_sensorboxes_assets,
    create_edit_users,
    delete_users,
  } = Permissions

export interface userFormProps{
    user?                   :   any;
    updateUserList?         :   any;
    roleList?               :   any;              
    shouldPageChange?       :   any;
    clearSelectedUser?      :   any;
    checkSensor?            :   any;
    updateCustomerList?     :   any;
    setVisibleClose?        :   any;
    // note : temp solution for the org admin access to all org
    organisationId?         :   any;
}

export interface userFormState {
    user?           :   User; 
    error?          :   {
        name?:string,
        address?:string,
        email?:string,
        phonenumber?:string,
        officenumber?:string,
        country?:any,
        state?:any,
        zip_code? : any,
        street? : any,
        city?   : any,
        roles?:Array<object>,
        assigned_to?:string,
        new_contractor?: string,
        location?:string
    };
    isEdit          :   boolean;  
    assigneeList    :   Array<object>
    responseError   :   string;
    dataList        :   Array<object>
    isLoading       :   boolean
    checkSensor     :   boolean
    contractorList  :   Array<object>;
}

class CreateUser extends React.Component<userFormProps, userFormState>{

    
    error:any={};
    constructor(props : userFormProps){
        super(props)
        this.state = {
            user            : undefined,
            isEdit          : false,
            assigneeList    : [],
            responseError   : '',
            dataList        : [],
            error           : {
                name:'',
                address:'',
                email:'',
                phonenumber:'',
                officenumber:'',
                roles:[],
                assigned_to:'',
                new_contractor: '',
                location:'',
                country:'',
                state:'',
                zip_code :'',
                street : '',
                city : ''
            },
            isLoading       : false,
            checkSensor     : false,
            contractorList  : []
        }
    }
 
    async getAssigneeList() {

        if(this.state.user && this.state.user.roles){
            try{
                let assigneeList = await userService.getAssigneeList(this.state.user.roles, this.props?.organisationId)
                assigneeList = assigneeList?.filter((assignee: any) => assignee._id !== this.state.user?._id)
                if(authStore?.currentUser?.active_role?.role_type === 2){
                    assigneeList = assigneeList?.filter((assignee: any) => assignee._id === authStore?.currentUser.uid)
                }

                if(authStore?.currentUser?.active_role?.role_type === 3){
                    assigneeList = assigneeList?.filter((assignee: any) => assignee._id === userStore?.profileDetials?.assigned_to?._id)
                }

                this.setState({assigneeList});           

            }catch(error){
                toast.error(error.response.data.message)
            }
        }
    }

    // Iterate through the object properties and trim the space from string 
    formatUserDetails(){
        if(this.state.user){
            let formattedUserDetails = {} as any
            let assigneeObj = {}
            if(this.state.user.assigned_to) {
                let namearray = this.state.user.assigned_to.name.split('(');
                assigneeObj = {
                    _id  : this.state.user.assigned_to._id,
                    name : namearray[0]
                }
                Object.assign(this.state.user,{assigned_to : assigneeObj})
            }
            Object.entries(this.state.user).forEach(item=>{
                if(typeof item[1] === "string"){
                    let value:string = item[1];
                    formattedUserDetails[item[0]] = value.trim()
                }else{               
                    formattedUserDetails[item[0]] = item[1]
                }
            })
            return formattedUserDetails
        }
    }

    addressSave = async() => {
        let error = {} as any
        if(this.state.user?.street && this.state.user?.city && this.state.user?.state && this.state.user?.country && this.state.user?.zip_code) {                       
            let addressSaved = await this.coordinates();
            if(addressSaved){
                return true;
            }else{
                error.address = constants.message.address_valid
                this.setState({error});    
                return false;
            }
        }else{
            error.address = constants.message.address_mandatory
            this.setState({error});    
            return false;
        }
    }

    async saveUser(){
        let isAddOperation = false;    
        let currentPage = 0; 
        try{ 
            let addressCheck = false;
            this.setState({isLoading : true})
            if(this.state.user?.street && this.state.user?.city && this.state.user?.state && this.state.user?.country && this.state.user?.zip_code) {
                addressCheck = await this.addressSave();
            }else{
                addressCheck  = true;
            }

            if(addressCheck) {            
                let formattedUserDetails = this.formatUserDetails() // remove spaces from start and end 
                let isValidUserDetails   = await this.isValidUserDetails()   

                if(isValidUserDetails){
                   
                    if(formattedUserDetails && formattedUserDetails._id) {
                        await userService.updateUser(formattedUserDetails, this.props?.organisationId)
                    }else {
                        await userService.createUser(formattedUserDetails, this.props?.organisationId);
                        isAddOperation = true;
                    }
                    this.setState({isLoading : false},()=>{
                        this.closeForm()
                        if(this.props.updateUserList){
                            if(isAddOperation) {
                                currentPage = this.props.shouldPageChange(true)
                            } 
                            this.props.updateUserList(currentPage)
                        }
                        if(this.props.updateCustomerList){
                            this.props.updateCustomerList()
                        }
                    })
                } else{
                    this.setState({isLoading : false})
                }
            }
            else {
                this.setState({isLoading : false})
            }
            
        }catch(error){
            this.setState({isLoading : false})
            toast.error(error.response.data.message)
        }
    }

    validateUserName() {
        let user :any    =   this.state.user||{};
        let errorName    =   '';
        let isValid  =   true
        if(validate.isEmpty(user.name)){
            errorName =  constants.message.name_mandatory
            isValid    =  false
        }else{
            if(!validate.isName(user.name)){
                errorName = constants.message.name_valid
                isValid    =  false
            }
        }
        //this.setState({error:{...this.state.error,name:errorName}})
        this.error.name=errorName;
        this.setState({error:this.error});
        return isValid
    }

    async validateEmail() {
     
        let user :any    =   this.state.user||{};
        let errorEmail    =   '';
        let isValid  =   true
        if(validate.isEmpty(user.email)){
            errorEmail = constants.message.email_mandatory
            isValid     =  false
        }else{
            if(!validate.isEmail(user.email)){
                errorEmail = constants.message.email_valid
                isValid     =  false
            }else{
                let emailcheck = await this.isEmailExist()
                if(emailcheck) {
                    errorEmail = constants.message.email_exist
                    isValid     =  false
                }
            }
        }
        this.error.email=errorEmail;
        //this.setState({error:{...this.state.error,email:errorEmail}})
        return isValid
    }

    async validateState() {
     
        let user :any    =   this.state.user||{};
        let errorState    =   '';
        let isValid  =   true
        if(!(user.state)){
            errorState = constants.message.state_mandatory
            isValid     =  false
        }
        this.error.state=errorState;
        return isValid
    }

    async validateStreet() {
     
        let user :any    =   this.state.user||{};
        let errorStreet    =   '';
        let isValid  =   true
        if(!(user.street)){
            errorStreet = constants.message.street_mandatory
            isValid     =  false
        }
        this.error.street=errorStreet;
        return isValid
    }

    async validateCity() {
     
        let user :any    =   this.state.user||{};
        let errorCity    =   '';
        let isValid  =   true
        if(!(user.city)){
            errorCity = constants.message.city_mandatory
            isValid     =  false
        }else{
            if(!(validate?.isValidCity(user.city))){
                errorCity = constants.message.city_valid
                isValid     =  false
            }
        }
        this.error.city=errorCity;
        return isValid
    }
    
    async validateZipCode() {
     
        let user :any    =   this.state.user||{};
        let errorZip    =   '';
        let isValid  =   true
        if(!(user.zip_code)){
            errorZip = constants.message.zip_mandatory
            isValid     =  false
        }else{
            if(!(validate.isValidZipCode(user.zip_code))){
                errorZip = constants.message.zip_valid
                isValid     =  false
            }
        }
        this.error.zip_code=errorZip;
        return isValid
    }

    validatePhone() {
        let user :any    =   this.state.user||{};
        let errorPhone    =   '';
        let isValid  =   true
        if(validate.isEmpty(user.phonenumber)){
            errorPhone = constants.message.phoneNumber_mandatory
            isValid           =  false
        }else{
            if(!(isValidPhoneNumber(user.phonenumber))){
                errorPhone = constants.message.phoneNumber_valid
                isValid           =  false
            }
        }
        this.error.phonenumber=errorPhone;
        this.setState({error:this.error});
        return isValid
    }

    validateOfficePhone() {
        let user :any    =   this.state.user||{};
        let errorPhone    =   '';
        let isValid  =   true
        if(user?.officenumber){
            if(validate.isEmpty(user.officenumber)){
                errorPhone = constants.message.phoneNumber_mandatory
                isValid           =  false
            }else{
                if(!(isValidPhoneNumber(user.officenumber))){
                    errorPhone = constants.message.phoneNumber_valid
                    isValid           =  false
                }
            }
        }
        this.error.officenumber=errorPhone;
        this.setState({error:this.error});
        return isValid
    }

    validateRole = () => {
        let user :any    =   this.state.user||{};
        let errorRole    =   '';
        let isValid  =   true
        if(!user?.roles?.length){
            errorRole = constants.message.role_mandatory
            isValid    =  false
        }

        this.error.roles=errorRole;
        this.setState({error:this.error});
        return isValid
    }
    
    validateNewContractor = () => {
        const { user } = this.state;
        if(this.state.isEdit && this.isContratorRoleChanged() &&
            validate.isEmpty(user?.new_contractor)) {
            this.error.new_contractor = constants.message.new_contractor_mandatory
            return false;
        }else {
            this.error.new_contractor = ''
        }
        this.setState({ error: this.error });
        return true;
    }

    validateAssignedTo() {

        let user :any    =   this.state.user||{};
        let errorassignedTo    =   '';
        let isValid  =   true

        if(validate.isEmpty(user.assigned_to)){
            errorassignedTo = constants.message.assignee_mandatory
            isValid           =  false
        }
        this.error.assigned_to=errorassignedTo;
        this.setState({error:this.error});
        return isValid
    }

    validateCountry() {

        let user :any    =   this.state.user||{};
        let errorCountry    =   '';
        let isValid  =   true

        if(!(user.country)){
            errorCountry = constants.message.country_mandatory
            isValid           =  false
        }
        this.error.country=errorCountry;
        this.setState({error:this.error});
        return isValid
    }

    validateAddress() {
        let user :any    =   this.state.user||{};
        let errorAddress    =   '';
        let isValid  =   true

        if(!(user.street && user.city && user.state && user.country && user.zip_code)){
            isValid       =  false
        }

        this.error.address=errorAddress;
        this.setState({error:this.error});
        return isValid
    }

    isValidUserDetails = async() => {
        let isValid  =   true

        let isValidUserName =this.validateUserName() 
        let isValidEmail= await this.validateEmail();
        let isValidState = await this.validateState();
        let isValidStreet = await this.validateStreet();
        let isValidCity  = await this.validateCity();
        let isValidZip    = await this.validateZipCode();
        let isValidPhone=  this.validatePhone();
        let isisValidOfficePhone = this.validateOfficePhone();
        let isValidRole = this.validateRole();
        let isValidNewContractor = this.validateNewContractor();
        let isValidAssignedTo = this.validateAssignedTo();
        let isValidCountry = this.validateCountry();
        let isValidAddress = this.validateAddress();

        isValid = isValidUserName && isValidEmail && isValidState && isValidStreet && isValidCity && isValidZip && isValidPhone && isisValidOfficePhone && isValidRole && isValidAssignedTo && isValidCountry && isValidAddress && isValidNewContractor;        
        this.setState({error:this.error})
        return isValid
       
    }

    componentDidMount(){ 
        if(this.props.user){

            let userRoles=[...this.props.user?.roles]
            let roleArray=userRoles.map((item:any)=>({value:item._id,label:item.name}))  
            const roleValues=userRoles.map((item :any) =>  item._id)
            this.setState({user :{...JSON.parse(JSON.stringify(this.props.user)),roleObject : roleArray , roles:roleValues}},()=>{ 
                this.getAssigneeDetails()
            })
        }

        if(this.props?.checkSensor) {
            this.createConsumerOnSensor()
        }
    }

    createConsumerOnSensor() {
        // to set consumer as default role when creating user from sensor modal.
        const roleList = [...this.props.roleList]
        const consumer = roleList.filter((role :any) =>role.role_type === 4) //check for consumer
        const roleArray = consumer.map((item:any)=>({value:item._id,label:item.name}))  
        const optionValue = roleArray.map((item :any) => item.value)
        this.setState({
            user: { ...this.state.user, roles: optionValue ,roleObject : roleArray }
        },()=>{
            this.getAssigneeDetails()
        })
    }

    toggleEdit() {

        let userRoles=[...this.props.user?.roles]
        let roleArray=userRoles.map((item:any)=>({value:item._id,label:item.name}))
        const roleValues=userRoles.map((item :any) =>  item._id)
        this.setState({
            isEdit  : !this.state.isEdit,
            user :  {...JSON.parse(JSON.stringify(this.props.user)),roleObject : roleArray , roles: roleValues},
            error   : {} as any,  
        },()=>{
            this.getAssigneeDetails()
        })
    }
    
    updateTextFieldValue(e : any){  
        if(!this.state.user){
            Object.assign(this.state,{user : {}});
        }
        Object.assign(this.state.user,{[e.target.name] : e.target.value.trimLeft()})
        this.setState({user : this.state.user})
    }

    isEmailExist = async() => {
     
        let isValid             =   false
        if(this.state.user && this.state.user.email){
            try{
                let response = await userService.checkEmailExist(this.state.user?._id || '',this.state.user.email, this.props?.organisationId)
                if(response){
                    this.error.email   =  constants.message.email_exist
                    isValid       =  true
                    this.setState({error:this.error});
                    return isValid 
                }else{
                    this.error.email   = ''
                    this.setState({error:this.error});
                    return isValid 
                }                
            }catch(error){
                toast.error(error.response.data.message)
            }              
        }else{   
            return isValid 
        }
    }

    updatePhoneNumber=(phonenumber : any)=> {
        if(validate.validatePhoneNumber(phonenumber)) {

            if(!this.state.user){
                Object.assign(this.state, {user : {}})
            }
        
            Object.assign(this.state.user, { phonenumber})
            this.setState({user : this.state.user})
        }
    }

    updateOfficeNumber=(officenumber : any)=> {
            if(!this.state.user){
                Object.assign(this.state, {user : {}})
            }
        
            Object.assign(this.state.user, { officenumber})
            this.setState({user : this.state.user})
    }

    async getNewContractors() {
        try {
            const contractors = await userService.getContractorList(this.props?.organisationId);
            this.setState({
                contractorList: contractors?.filter((cont: any) => cont?._id !== this.props?.user?._id)
            })
        } catch (error) {
            
        }
    }

    handleRolesSelection(option: any) {

        if(!option.length){
            Object.assign(this.state.user, { assigned_to : '' }) // set assigned to state to null when role is empty
        }

       const optionValue= option.map((item :any) => item.value)
       const { user } = this.state;
       this.setState({
            user: { ...user, roles: optionValue ,roleObject : option }
        },()=>{
            if(this.state.isEdit && this.isContratorRoleChanged()) {
                this.getNewContractors()
            }
            this.getAssigneeDetails()
        })
    }

    isContratorRoleChanged() {
        const { user } = this.state;
        const { user: propUser } = this.props;
        let contractorRole = propUser?.roles?.find((r:any) => r.role_type === 2);
        if(!contractorRole) return false;
        let isChanged = user?.roleObject?.some(role => role.value === contractorRole?._id)
        return !isChanged;
    }
    
    updateNewContractor = (contractor: any) => {
        const { user } = this.state;
        this.setState({
            user: { ...user, new_contractor: contractor }
        })
    }

    roleData = () => {

        let result = this.props.roleList?.filter((role :any) =>role._id!== '')
        return result
    }

    updateAssignee=(assignee : any)=> {

        if(!this.state.user){
            Object.assign(this.state, {user : {}})
        }
    
        Object.assign(this.state.user, { assigned_to : assignee })
        this.setState({user : this.state.user})
    }

    updateCountry=(country : any)=> {

        if(!this.state.user){
            Object.assign(this.state, {user : {}})
        }
    
        Object.assign(this.state.user, { country : country?._id , state : '' })
        this.setState({user : this.state.user})
    }

    updateState=(state : any)=> {

        if(!this.state.user){
            Object.assign(this.state, {user : {}})
        }
    
        Object.assign(this.state.user, { state : state?._id })
        this.setState({user : this.state.user})
    }

    getAssigneeDetails = async () => {
        await this.getAssigneeList();

        if(this.state.user && this.state.user.assigned_to){
            let assignee_id = this.state.user.assigned_to._id
            this.state.assigneeList.forEach((assignee : any) => {
                if(assignee._id === assignee_id){
                    Object.assign(this.state.user, { assigned_to : assignee })
                    this.setState({user : this.state.user})
                }     
              });
        }
    }

    closeForm(){
        this.setState({user : undefined,isEdit : false, assigneeList : [],error : undefined},()=>{
            this.error = {}
            if(Utility.checkModalOpen('userdeletemodal')) {
                Utility.closeModal('userdeletemodal')
            }
            if(this.props.setVisibleClose){
                this.props.setVisibleClose()
            }          
            Utility.closeModal('createuser')
            if(typeof this.props.clearSelectedUser !== "undefined"){
                this.props.clearSelectedUser()
            }
            if(this.props.checkSensor){
                this.createConsumerOnSensor()
                Utility.showModal('sensorboxdetails')
            }
            
        })
    }

    deleteUser = async (new_contractor?: any)=> {
        try{
            if(this.state.user){
                this.setState({isLoading : true})
                await  userService.deleteUser(this.state.user?._id || '', new_contractor)
                this.setState({isLoading : false},()=>{
                    this.closeForm()
                    let currentPage = this.props.shouldPageChange(false)
                    this.props.updateUserList(currentPage)                        
                }) 
            }
        }catch(error){
            this.setState({isLoading : false})
            toast.error(error.response.data.message)
        }
    }

    closeConfirmModal = () =>{
        Utility.closeModal('userdeletemodal')
        Utility.showModal('createuser')
    }

    showDeleteConfirm() {
        const isContractor=this.props.user?.roles?.find((r:any) => r.role_type === 2)? true : false;
        if(isContractor){
        this.getNewContractors()
        }
        Utility.closeModal('createuser')
        Utility.showModal('userdeletemodal')
    }

    isSelected(){
        return this.state.user !== undefined && this.state.user._id !== undefined && !this.state.isEdit
    }

    isDisabled() {
        return (this.state.user !== undefined && this.state.user._id !== undefined && !this.state.isEdit) || this.state.isLoading
    }

    isUpdate(){
        return this.state.user !== undefined && this.state.user._id !== undefined 
    }


    updateLatitude(e:any) {
        if(!this.state.user){
            Object.assign(this.state, {user : {}})
        }    
        if(validate.isValidValue(e.target.value)){
            let error  = {} as any;
            let locationArray =  this.state.user &&  this.state.user.location &&  this.state.user.location.coordinates ? this.state.user.location.coordinates : [];
            locationArray[0]  =   e.target.value;   
            Object.assign(this.state.user,{ location : {coordinates : locationArray}})  
            this.setState({user : this.state.user,error})  
        } else{
            let error  = {} as any;
            Object.assign(this.state.user,{ location : {coordinates : {}}})  
            this.setState({user : this.state.user,error})
        }   
    }

    updateLongitude(e:any) {
        if(!this.state.user){
            Object.assign(this.state, {user : {}})
        }  
        if(validate.isValidValue(e.target.value)){
            let error  = {} as any;
            let locationArray =  this.state.user &&  this.state.user.location && this.state.user.location.coordinates ? this.state.user.location.coordinates : [];
            locationArray[1]  =   e.target.value;        
            Object.assign(this.state.user,{ location : {coordinates : locationArray}})
            this.setState({user : this.state.user,error})  
        } else{
            let error  = {} as any;
            Object.assign(this.state.user,{ location : {coordinates : {}}})  
            this.setState({user : this.state.user,error})  
        } 
            
    }

    getCoordinates = async(place :any) => {
        let coordinates=[];
        try{
            let errorAddress    =   '';
            coordinates         = await userService.getCoordinatesList(place)
            errorAddress           = coordinates.length?"":constants.message.address_valid
            Object.assign(this.state.user,{ location : {coordinates : coordinates}})
            this.error.address=errorAddress;
            this.setState({error:this.error});
            this.setState({user : this.state.user});
            return coordinates.length>0 
        }catch(error){
            toast.error(error.response.data.message)
            return coordinates.length>0 
        }       
    }


    coordinates = async() => {
        let isValid = this.validateAddress()
        if(!this.state.user){
            Object.assign(this.state, {user : {}})
        }
        if(isValid) {
            let appendedAddress = `${this.state.user?.street} , ${this.state.user?.state} , ${this.state.user?.country} , ${this.state.user?.zip_code}`
            isValid = await this.getCoordinates(appendedAddress)
        }
        else {
            Object.assign(this.state.user,{ location :{ coordinates : []}})  
            this.setState({user : this.state.user}) 
        }
        return isValid;       
    }

    isPhoneNumber = async() =>{
        let error               =   {} as any
        let isValid             =   false
        if(this.state.user && this.state.user.phonenumber){       
            if(!validate.isPhoneNumber(this.state.user.phonenumber)){
                error.phonenumber   =  constants.message.phoneNumber_valid
                isValid             =  true
                this.setState({error});
                return isValid 
            }else{
                error.phonenumber   = ''
                this.setState({error});
                return isValid 
            }                            
        }
    } 
        
    render(){
        const demoCheck = (authStore?.currentUser?.active_role?.role_type === -1);
        const countryValue = CountryOptions.find((value:any)=>(value._id === this.state.user?.country)) 
        const statesValue = CountryOptions.find((value:any)=>(value._id === this.state.user?.country)) 
        const states = statesValue ? statesValue?.states : [];
        const stateValue = states.find((value:any)=>(value._id === this.state.user?.state)) 
        return (
        <div>
            <div className="modal fade" id="createuser" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content create-user-modal">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle" >{this.isUpdate() ? !authStore?.currentUser?.access?.includes(create_or_update_sensorboxes_assets) || authStore?.currentUser?.access?.includes(super_admin_access) ? this.state.user?.name : "Edit User" : "Create User"}</h5>
                        <span aria-hidden="true" className="close" aria-label="Close" onClick={()=>this.closeForm()}>&times;</span>
                    </div>
                    <div className="modal-body">
                    <div className="row">
                            <div className="col-md-12">
                            {this.isUpdate() && !authStore?.currentUser?.access?.includes(super_admin_access) && 
                                <button type="button" 
                                    className="btn btn-edit" 
                                    onClick={()=>this.toggleEdit()}>
                                    {this.state.isEdit ? "Cancel Edit" : "Edit"}
                                </button>
                            } 
                            </div>
                    </div>
                    <div className="row">
                            <div className="col-md-6">
                                <div className="input-text-field">
                                    <div className="form-group">
                                            <label className="custom-label">Name <span className="mand-star">*</span></label>

                                            <input type="text" 
                                                className="form-control" 
                                                name="name"
                                                value={this.state.user && this.state.user.name ? this.state.user.name : ''}
                                                onChange={(e)=>this.updateTextFieldValue(e)}
                                                onBlur ={()=>{this.validateUserName()}}
                                                maxLength={50}
                                                disabled={this.isDisabled()}
                                                placeholder="Enter name" />
                                            <div className="error-msg">{this.state.error ? this.state.error.name : ''}</div>
                                    </div>  
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="input-text-field">
                                    <div className="form-group">
                                            <label className="custom-label">Email <span className="mand-star">*</span></label>
                                            <input type="text" 
                                                className="form-control" 
                                                id="" 
                                                aria-describedby="" 
                                                value={this.state.user && this.state.user.email  ? this.state.user.email : ''}
                                                name="email"
                                                onChange={(e)=>this.updateTextFieldValue(e)}
                                                onBlur ={()=>{this.validateEmail() && this.setState({error:this.error})}}
                                                maxLength={50}
                                                disabled={this.isDisabled()}
                                                placeholder="Enter email" />
                                             <div className="error-msg">{this.state.error ? this.state.error.email : ''}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                    <div className="form-group">
                                            <label className="custom-label">Role <span className="mand-star">*</span></label>
                                            <Select
                                                isMulti
                                                name="roles" 
                                                isDisabled= {this.isDisabled() || this.props.checkSensor}
                                                data={this.roleData()}
                                                value={this.state.user?.roleObject} 
                                                onChange={(e:any)=>this.handleRolesSelection(e)}
                                                onBlur={this.validateRole}
                                            />
                                            <div className="error-msg">{this.state.error ? this.state.error.roles : ''}</div>
                                    </div>
                            </div>

                            { authStore?.currentUser?.access?.includes(org_admin_access) &&
                                this.state.isEdit &&  this.isContratorRoleChanged()  &&
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="custom-label">Select new contractor <span className="mand-star">*</span></label>
                                        <Select  name="roles" 
                                            isDisabled= {this.isDisabled()}
                                            data={this.state.contractorList} 
                                            value={this.state.user?.new_contractor || ''}  
                                            onChange={this.updateNewContractor}
                                            onBlur={this.validateNewContractor}
                                        />
                                        { this.state.isEdit && !this.state.contractorList.length && <div className="warning-msg">Select a new contractor to replace this contractor. create one if there is no contractor. </div>}
                                        <div className="error-msg">{this.state.error ? this.state.error.new_contractor : ''}</div>
                                    </div>
                                </div>
                            }
                            
                            <div className="col-md-6">
                                <div className="input-text-field">
                                    <div className="form-group">
                                            <label className="custom-label">Mobile Number <span className="mand-star">*</span></label>
                                                <PhoneInput
                                                    name ="phonenumber"
                                                    disabled={this.isDisabled()}
                                                    maxLength = {15}
                                                    placeholder="Enter phone number"
                                                    value={this.state.user && this.state.user.phonenumber ? this.state.user.phonenumber : ''}
                                                    onChange={(number :any)=>{this.updatePhoneNumber(number)}}
                                                    onBlur ={()=>{this.validatePhone()}}
                                                />
                                             <div className="error-msg">{this.state.error ? this.state.error.phonenumber : ''}</div>
                                    </div>
                                </div>
                            </div>

                                                        
                            <div className="col-md-6">
                                <div className="input-text-field">
                                    <div className="form-group">
                                            <label className="custom-label">Office Number</label>
                                                <PhoneInput
                                                    name ="office"
                                                    disabled={this.isDisabled()}
                                                    maxLength = {15}
                                                    placeholder="Enter office number"
                                                    value={this.state.user && this.state.user?.officenumber ? this.state.user?.officenumber : ''}
                                                    onChange={(number :any)=>{this.updateOfficeNumber(number)}}
                                                    onBlur ={()=>{this.validateOfficePhone()}}
                                                />
                                            <div className="error-msg">{this.state.error ? this.state.error.officenumber : ''}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <form className="input-text-field">
                                    <div className="form-group">
                                        <label className="custom-label">Assigned to <span className="mand-star">*</span></label>
                                        
                                            <Select  name="assigned_to" 
                                                isDisabled = {this.isDisabled()}
                                                data       = {this.state.assigneeList} 
                                                value      = {this.state.user ? this.state.user.assigned_to : ''}  
                                                onChange   = {(value : any)=>this.updateAssignee(value)}
                                                onBlur ={()=>{this.validateAssignedTo()}}
                                                />

                                            <div className="error-msg">{this.state.error ? this.state.error.assigned_to : ''}</div>
                                    </div>
                                </form>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                        <label className="custom-label" >Street <span className="mand-star">*</span></label>
                                        <input type="text" 
                                                className="form-control" 
                                                id="" 
                                                aria-describedby="" 
                                                value={this.state.user && this.state.user.street  ? this.state.user.street : ''}
                                                name="street"
                                                onChange={(e)=>this.updateTextFieldValue(e)}
                                                onBlur ={()=>{this.coordinates() && this.validateStreet() && this.setState({error:this.error})}}
                                                maxLength={50}
                                                disabled={this.isDisabled()}
                                                placeholder="Enter street" />
                                        <div className="error-msg">{this.state.error ? this.state.error.street : ''}</div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                        <label className="custom-label" >City <span className="mand-star">*</span></label>
                                        <input type="text" 
                                                className="form-control" 
                                                id="" 
                                                aria-describedby="" 
                                                value={this.state.user && this.state.user.city  ? this.state.user.city : ''}
                                                name="city"
                                                onChange={(e)=>this.updateTextFieldValue(e)}
                                                onBlur ={()=>{this.coordinates() && this.validateCity() && this.setState({error:this.error})}}
                                                maxLength={50}
                                                disabled={this.isDisabled()}
                                                placeholder="Enter city" />
                                        <div className="error-msg">{this.state.error ? this.state.error.city : ''}</div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                        <label className="custom-label" >Country <span className="mand-star">*</span></label>
                                        <Select  name="country" 
                                                isDisabled = {this.isDisabled()}
                                                data       = {CountryOptions} 
                                                value      = {countryValue ? countryValue : ''}  
                                                onChange   = {(value : any)=>this.updateCountry(value)}
                                                onBlur ={()=>{this.coordinates() && this.validateCountry()}}
                                        />
                                        <div className="error-msg">{this.state.error ? this.state.error.country : ''}</div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                        <label className="custom-label" >State <span className="mand-star">*</span></label>
                                        <Select  name="state" 
                                                isDisabled = {this.isDisabled()}
                                                data       = {states} 
                                                value      = {stateValue ? stateValue : ''}  
                                                onChange   = {(value : any)=>this.updateState(value)}
                                                onBlur ={()=>{this.coordinates() && this.validateState()}}
                                                />
                                        <div className="error-msg">{this.state.error ? this.state.error.state : ''}</div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                        <label className="custom-label" >Zip Code <span className="mand-star">*</span></label>
                                        <input type="text" 
                                                className="form-control" 
                                                id="" 
                                                aria-describedby="" 
                                                value={this.state.user && this.state.user.zip_code  ? this.state.user.zip_code : ''}
                                                name="zip_code"
                                                onChange={(e)=>this.updateTextFieldValue(e)}
                                                onBlur ={()=>{this.coordinates() && this.validateZipCode() && this.setState({error:this.error})}}
                                                maxLength={50}
                                                disabled={this.isDisabled()}
                                                placeholder="Enter zip code" />
                                        <div className="error-msg">{this.state.error ? this.state.error.zip_code : ''}</div>
                                </div>
                            </div>

                            {this.state.error?.address &&
                            <div className="col-md-12">
                                <div className="form-group">
                                        <div className="error-msg">{this.state.error ? this.state.error.address : ''}</div>
                                </div>
                            </div>
                            }
                    </div>
                    </div>
                    <div style={{color : 'red'}}>{this.state.responseError}</div>
                    <div className="modal-footer">
                        {(this.state.user && this.state.user._id) && this.state.isEdit && authStore?.currentUser?.access?.includes(delete_users) &&
                            <button
                                type="button"
                                className="btn btn-inverse-info btn-rounded btn-icon delete-user-icon"
                                onClick={e => this.showDeleteConfirm()}
                                >
                                <i className="fa fa-trash-o fa-lg" />
                            </button>
                            }

                        <button type="button" disabled={this.isDisabled() || demoCheck || !authStore?.currentUser?.access?.includes(create_edit_users) } className="btn btn-primary default-btn" onClick={()=>this.saveUser()}>SAVE</button>
                    </div>
                    </div>
                </div>
                   <div>
                        {this.state.isLoading ?
                        <LoadingWheel/>
                        :   null}
                    </div>
                </div>
                {/* <ConfirmModal title={"Delete user"}  message = {" Do you want to delete the user ?"} closeConfirmModal={this.closeConfirmModal} delete={this.deleteUser} isLoading = {this.state.isLoading}/> */}
                <UserDeleteModal
                    title={"Delete user"}
                    message = {" Do you want to delete the user ?"} 
                    closeConfirmModal={this.closeConfirmModal} 
                    delete={this.deleteUser} 
                    isLoading = {this.state.isLoading}
                    user={this.props.user}
                    contractors={this.state.contractorList}
                />
        </div>

        );
    }
  }
 
export default CreateUser
