import React from 'react';
import { Modal } from 'react-bootstrap';

export interface confirmProps {
    show                : boolean;
    closePasswordModal  : any;
    message             : string;
    title               : string;
}

function MessageBoxmodal (props : confirmProps) {
    return (
        <div>
            <Modal
            show={props.show}
            onHide={props.closePasswordModal}
            >
            <Modal.Header closeButton>
            <div>{props.title}</div>
            </Modal.Header>
            <Modal.Body>
            <div className="modal-content confirm-modal">
                <div className="modal-body modal-text">
                    {props.message}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn default-btn" data-dismiss="modal" onClick={()=>props.closePasswordModal()}>OK</button>
                </div>
            </div>
            </Modal.Body>
            </Modal>    
        </div>
    );
}
     
export default MessageBoxmodal;    
    
 
