import React, { useState } from 'react';
import './confirmmodal.css';
import LoadingWheel from '../loadingwheel';
import constants from '../../config/constants';


export interface confirmProps {
    closeConfirmModal   : any;
    delete              : any;
    message             : string;
    title               : string;
    isLoading           : boolean;
    isSuperAdmin?       : boolean
}

function Confirmmodal (props : confirmProps) {

    const [hardDelete, setHardDelete] = useState(false)

    const onChangeCheckBox = (e : any)=> {
        setHardDelete(e.target.checked)
    };

    const onDelete = ()=> {
        props.delete(hardDelete)
        setHardDelete(false)
    };

    const onCloseModal = ()=> {
        props.closeConfirmModal()
        setHardDelete(false)
    }

    return (
       <div>
     
        <div className="modal fade blur-backdrop" id="confirmmodal" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content confirm-modal">
                <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{props.title}</h5>
                    <span aria-hidden="true" className="close" data-dismiss="modal" onClick={onCloseModal} aria-label="Close">&times;</span>
                </div>
                <div className="modal-body modal-text">
                   {props.message}
                    {props.isSuperAdmin &&
                    <div className="form-inline mt-4">
                            <input 
                                type="checkbox"
                                className="mr-3"
                                checked={hardDelete}
                                onChange={onChangeCheckBox}
                            />
                            <label className="custom-label" style={{ fontSize: '1rem' }}>
                                Hard Delete
                            </label>
                    </div>
                    }
                </div>
                <div className="modal-footer">
                    {props.message === constants.message.deleteGroupWarning || props.message === constants.message.deleteSensorboxWarning ? 
                    <button type="button" className="btn default-btn" onClick={onCloseModal}>OK</button>
                    :
                    <>
                    <button type="button" className="btn default-btn" data-dismiss="modal" onClick={onCloseModal}>CANCEL</button>
                    <button type="button" className="btn default-btn" onClick={onDelete}>DELETE</button>
                    </>
                    }
                </div>
                </div>
            </div>
        </div>
        <div>
            {props.isLoading ?
            <LoadingWheel/>
            :   null}
        </div>
       </div>

    );
  }
 
export default Confirmmodal;
