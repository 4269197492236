import React from "react";
import { Card, Button, Typography } from "antd";
import Icon from "../../components_new/icon";
const { Text } = Typography;
// eslint-disable-next-line react-hooks/rules-of-hooks
// const [ellipsis] = React.useState(true);
function Subsystem() {
  return (
    <>
      <Card className="custom-card card-padding h-100 danger">
        <div className="d-flex w-100 mb-1 align-items-center">
          <div className="flex-fill">
            <div className="d-flex align-items-center">
              <Icon className="icon-14 mr-2 online-icon" iconRef="#range" />
              <Text
                // style={ellipsis ? { maxWidth: 200 } : undefined}
                // ellipsis={ellipsis ? { tooltip: "I am ellipsis now!" } : true}
                className="mr-2 mb-0"
              >
                Contactor 1
              </Text>{" "}
              <div className="small-circle-alert"></div>
            </div>
          </div>
          <div className="flex-auto export-icon">
            <Icon className="icon-14 mr-2" iconRef="#last_updated" />
            <Icon className="icon-14" iconRef="#export" />
          </div>
        </div>
        <Text
          // style={ellipsis ? { maxWidth: 400 } : undefined}
          // ellipsis={ellipsis ? { tooltip: "I am ellipsis now!" } : true}
          className="sub-system-id"
        >
          Sub System ID: SYS0000276
        </Text>{" "}
        <div className="d-flex my-2">
          <div className="flex-fill mr-3 update-time">
            <Icon className="icon-14 mr-2" iconRef="#last_updated" />
            <span>3 hrs</span>
          </div>
          <div className="flex-auto">
            <Button type="link" className="small view-more p-0">
              View more
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
}
export default Subsystem;
