import React from 'react';
import { Modal } from 'react-bootstrap';
import { Table } from 'antd';
import './InvalidRangeAlertModal.css'
export interface Props {
    logData: any;
    sensorBoxName: string;
    isVisible: boolean;
    onClose: () => void;
}
const columns = [{
    title: 'Parameter',
    dataIndex: 'parameter',
    key: 'parameter'
}, {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    render:(text:any)=><span className="red">{text}</span>
}, {
    title: 'Valid Range',
    dataIndex: 'validRange',
    key: 'validRange',
    render:(text:any)=><span className="green">{text}</span>
}];
const InvalidRangeAlertModal: React.FC<Props> = ({ isVisible, sensorBoxName, logData, onClose }) => {

    const data = logData?.parameters?.map((x:any)=>({parameter: x.parameter,value: x.value,validRange: x.validRange}))??[]
    return (
        <Modal
            className="logs-modal-body"
            show={isVisible}
            onHide={onClose}
        >
            <Modal.Header closeButton>
                {sensorBoxName}
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="custom-label" >Alert Mail Sensor Values</label>
                                <Table columns={columns} dataSource={data}>

                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default InvalidRangeAlertModal
