import React from "react";
import { Doughnut } from "react-chartjs-2";

interface Props { 
  colorLabel? : any,
  colorValue? : any
}

const DoughnutComponent: React.FC<Props> = ({ colorLabel , colorValue }) => {

  const DoughnutData = {
    datasets: [
      {
        data: colorValue,
        backgroundColor: colorLabel,
        hoverOffset: 4,
        borderWidth: 0,
      },
    ],
  };

  const totalCount = colorValue?.reduce((partialSum : any, a : any) => partialSum + a, 0)

    return (
      <>
          <div className="doughnut">{totalCount}</div>
          <Doughnut
            data={DoughnutData}
            options={{
              responsive: true,
              aspectRatio: 1,
              borderRadius: 50,
              radius: "100%",
              legend: {
                display: true,
                position: "bottom",
                align: "center",
                reverse: true,
              },
              tooltips: { enabled: false },
              cutoutPercentage: 80,
            }}
            height={100}
            width={100}
          />
      </>
    );
}
export default DoughnutComponent;
