import Api from '../api';
class DashboardService {

    async getSensorBoxes(deviceSort? : String, groupFilter? : boolean, orgid? : any, searchFilter? : any, deployment_status? : any){
        let searchText = ''
        if(searchFilter) {
            searchText = encodeURIComponent(searchFilter)
        }
        
        let queryParams = `/sensorboxes-list-new?deviceSort=${deviceSort}&filter=${JSON.stringify({search : searchText})}`
        if(groupFilter){
            queryParams += `&group=1`
        }
        if(orgid){
            queryParams += `&orgid=${orgid}`
        }

        if(deployment_status){
            queryParams += `&deployment_status=${deployment_status}`
        }
        
        let response = await Api.get(queryParams)       
        return response.data
    }

}

export default new DashboardService()