export type SensorData = {
    sensor_name: string,
    value: string,
    created_on: number,
}

class SensorStore {
    sensorData: Array<SensorData>
    constructor() {
        this.sensorData =[]
    }

}

export default new SensorStore()