import React from 'react';
import { Modal } from 'react-bootstrap';
import {Button} from "antd";
import {CopyOutlined} from '@ant-design/icons'
import {toast} from "react-toastify";

export interface Props {
    logData: any;
    sensorBoxName: string;
    isVisible: boolean;
    onClose: () => void;
}

const LogDataModal: React.FC<Props> = ({ isVisible, sensorBoxName, logData, onClose }) => {
    const JsonData = JSON.stringify(logData, null, 2)
    const copyToClipboard = ()=>{
        navigator.clipboard.writeText(JsonData)
            .then(() => {
                toast.success("JSON Copied to Clipboard",{autoClose:1000})
            })
            .catch(err => console.error('Failed to copy text: ', err));
    }

    return (
        <Modal
            className="logs-modal-body"
            show={isVisible}
            onHide={onClose}
        >
            <Modal.Header closeButton>
                {sensorBoxName}
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <div >
                                    <label className="custom-label col-md-10" >Alert Mail Sensor Values</label>
                                    <Button className="" type="default" size="small" onClick={copyToClipboard}>
                                        <CopyOutlined  />
                                        Copy</Button>
                                </div>
                                <textarea
                                    className="form-control json-text"
                                    value={JsonData}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default LogDataModal
