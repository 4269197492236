import React, { Component } from "react";

export interface LoaderState {}
export interface LoaderProps {}

export default class LoadingIndicator extends Component < LoaderProps , LoaderState > {
    render() {
        return (
            <div style={{position:'fixed',top:0,right:0,bottom:0,left:0,backgroundColor:'rgba(0,0,0,.5)',zIndex:1999}}><div className="lds-dual-ring" /></div>
        )
    }
}
