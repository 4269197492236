import Api from '../api';
import qs from 'query-string'

class InspectionChecklistService {

    getInspectionsList = async (params : any) => {
        const query = qs.stringify(params);
        let url = query ? `/inspection-checklist?${query}` : `/inspection-checklist`
        return Api.get(url)
    }

    getInspectionsMasterData = async() => {
        let url = `/inspection-masterdata`
        return Api.get(url)
    }

    addInspection = async (data: any , org_id? : any) => {
        let url = `/inspection-checklist`
        if(org_id){
            url += `?orgid=${org_id}`
        }
        return Api.post(url, data)
    }

    updateInspection = async (data: any, org_id? : any) => {
        let url = `/inspection-checklist/${data._id}` 
        if(org_id){
            url += `?orgid=${org_id}`
        }
        return Api.put(url, data)
    }

    deleteInspection = async (id: String) => {
        return Api.delete(`/inspection-checklist/${id}`)
    }

    addInspectionChecklist = async (data: any , org_id : any) => {
        let url = `/reinspect-checklist`
        if(org_id){
            url += `?orgid=${org_id}`
        }
        return Api.post(url, data)
    }

    addUsers = async (data: any ,org_id ? : any) => {
        let url = `/users-inspection`
        if(org_id){
            url += `?orgid=${org_id}`
        }
        return Api.post(url, data)
    }

    editUser = async (data: any ,org_id ? : any) => {
        let url = `/users-inspection/${data?._id}`
        if(org_id){
            url += `?orgid=${org_id}`
        }
        return Api.put(url, data)
    }
    
    updateInspectionChecklist = async (data: any , org_id? : any) => {
        let url = `/reinspect-checklist/${data?.field_inspection_id}`
        if(org_id){
            url += `?orgid=${org_id}`
        }
        return Api.put(url, data)
    }   

    getInspectionDetailsFromId = async (id : any) => {
        return Api.get(`/inspection-checklists-details/${id}`)
    }
    
    isSystemExist = async (systemName: string, id?: string, customer?: string) => {
        return Api.get(`/system_exist/${systemName}?id=${id || ''}&customer=${customer || ''}`)
    }
    
}

export default new InspectionChecklistService();
