import React, { useState } from 'react';
import LoadingWheel from '../../loadingwheel';
import Select from '../../select';

export interface confirmProps {
    closeConfirmModal   : any;
    delete          : any;
    message             : string;
    title               : string;
    isLoading : boolean;
    user: any;
    contractors: Array<any>;
}

function UserDeleteModal (props : confirmProps) {
    const { user, contractors, closeConfirmModal } = props;
    const [newContractor, setNewContractor] = useState(null);


    const onClose = () => {
      setNewContractor(null);
      closeConfirmModal();
    }

    const isContractor=user?.roles?.find((r:any) => r.role_type === 2)? true : false;

    return (
       <div>
     
        <div className="modal fade" id="userdeletemodal" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content confirm-modal">
                <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{props.title}</h5>
                    <span aria-hidden="true" className="close" data-dismiss="modal" onClick={onClose} aria-label="Close">&times;</span>
                </div>
                <div className="modal-body modal-text">
                    {props.message}
                    {  isContractor &&
                      <div className="mt-3">
                        <label className="custom-label">Select new contractor <span className="mand-star">*</span></label>
                        <Select
                          name="new_contractor"
                          data={contractors} 
                          value={newContractor}  
                          onChange={(value: any) => setNewContractor(value)}
                        />
                         { !contractors.length && <div className="warning-msg">Select a new contractor to replace this contractor. create one if there is no contractor.</div>}
                      </div>
                    }
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn default-btn" data-dismiss="modal" onClick={onClose}>CANCEL</button>
                    <button 
                      type="button"
                      className="btn default-btn"
                      onClick={()=>props.delete(newContractor)}
                      disabled={isContractor && !newContractor}
                    >
                      DELETE
                    </button>
                </div>
                </div>
            </div>
        </div>
        <div>
            {props.isLoading ?
            <LoadingWheel/>
            :   null}
        </div>
       </div>

    );
  }
 
export default UserDeleteModal;
