import React, { useState } from 'react';
import { Input, Upload, Space, Form, Typography, message, Spin } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import constants from './../../config/constants';
import Utils from "../../helpers/Utility";

const { TextArea } = Input;
const { Dragger } = Upload;
const { Text } = Typography;

export default function CreateNote(props){
  var { isErrorVisible, errorMessage, createNoteForm, setMessage, setIsErrorVisible, setUploadLoading, uploadLoading } = props;
  // https://ant.design/components/upload
  const [fileList, setFileList] = React.useState([]);
  const [fileNames, setFileNames] = useState([]); 

  const noRequest = (options) => {
    const { onSuccess } = options;
    // Do nothing here and call onSuccess with an empty response to simulate a successful upload
    // since we're uploading as part of the form submission, we don't need to do it here
    onSuccess(null, new Response('{"status": "success"}'));
  }

  const uploadValidation = ((file) => {
    const isImage = file?.type?.startsWith('image/');
    if (!isImage) {
      message.destroy()
      message.error(constants.message.fileTypeError);
      return Upload.LIST_IGNORE;
    }
    if (!(file.type === 'image/png') && !(file.type === 'image/jpg') && !(file.type === 'image/jpeg')) {
        message.destroy()
        message.error(constants.message.unsupportedImageFormat)
        return Upload.LIST_IGNORE;
    }
    if ((fileList?.length + 1) > 5) {
        message.destroy()
        message.error(constants.message.imageMaxLimitReached);
        return Upload.LIST_IGNORE;
    }
    if (file.size > 10 * 1024 * 1024) {
        message.destroy()
        message.error(constants.message.imageSizeExceeded)
        return Upload.LIST_IGNORE
      }
      const index = fileList.findIndex(f => f.name === file.name);
      if (index !== -1 || fileNames.includes(file.name)) {
      message.destroy()
      message.error(constants.message.imageAlreadyExist)
      return Upload.LIST_IGNORE
      } 
      setFileList([...fileList, file]);
      setFileNames([...fileNames, file.name]);
      setIsErrorVisible(false)
      return false
  });

  const removeImage = (file) => {
    const index = fileList?.indexOf(file);
    const newFileList = fileList?.slice();
    newFileList?.splice(index, 1);
    setFileList(newFileList);
    setFileNames(fileNames.filter((name) => name !== file.name));
  };

  return (
    <Spin spinning={uploadLoading}>
    <Form
      style={{ paddingBottom: "24px"}}
      form={createNoteForm}
    >
      <Space direction="vertical" block style={{display: "flex"}}>
        <Form.Item name="text" required normalize={Utils.trimSpacesInValues}>
          <TextArea showCount maxLength={constants.attachmentNotesLength} placeholder='Add text to note.' onChange={() => setIsErrorVisible(false)}/>
        </Form.Item>
        <Form.Item
          name="attachments"
        >
          <Dragger
            {...props} 
            action=""
            accept=".jpg,.png,.jpeg,.webp"
            customRequest={noRequest}
            beforeUpload={uploadValidation}
            onRemove={(file) => removeImage(file)}
            listType="picture"
            onPreview={() => {}}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
              <p className="ant-upload-text alert-link">
                Click or drag file to this area to upload image(s)
              </p>
              <p className="ant-upload-hint">
                 Maximum of 5 images allowed for a description
              </p>
              <p className="ant-upload-hint settings-tr">
                Accepted formats - .jpeg .png .jpg .webp
              </p>
          </Dragger>
        </Form.Item>
        {isErrorVisible && <Text type="danger">{errorMessage}</Text>}
        {/* <Button type='primary' htmlType="submit" block onClick={onFinish}
        >Submit</Button> */}
      </Space>
    </Form>
    </Spin>
  );
};