import React, { useEffect, useState, ChangeEvent } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import _ from 'lodash';

import maintenaceService from '../../services/maintenaceService';
import DatePicker from '../datepicker';
import toast from '../../components/toast';

import './maintenance-checklist.css';
import authStore from '../../store/authStore';
import  Permissions from '../../config/permssions'

const {
    view_only_partial_asset_sensorbox_details
  } = Permissions


interface Props {
  sensorBoxId?: string,
  editMode?: boolean,
}

const MaintenanceCheckList: React.FC<Props> = ({ editMode, sensorBoxId }) => {

  const [checkLisItems, setCheckLisItems] = useState<any>({});
  const [maintenanceData, setMaintenanceData] = useState<any>({});
  const [savedData, setSavedData] = useState<any>({});
  const [saving, setSaving] = useState(false);

  const getMaintenaceChecklistItems = async () => {
    try {
      const resp = await maintenaceService.getMaintenaceChecklistItems();
      const { checklists } = resp.data;
      const checkListGroup = _.groupBy(checklists, 'check_list_category');
      setCheckLisItems(checkListGroup);
    } catch (error) {
    }
  }

  const getSensorBoxMaintenanceList = async () => {
    try {
      if(!sensorBoxId) {
        return
      }
      const response = await maintenaceService.getSensorboxMaintenanceList(sensorBoxId);
      const { checklists } = response.data;
      let data: any = {}
      checklists?.forEach((checklist: any) => {
        data[checklist?.check_list_id?._id] = {
          last_service_done_date: checklist?.last_service_done_date ? new Date(checklist?.last_service_done_date) : null,
          comment: checklist?.comment,
          next_maintenance_date: new Date(checklist?.next_maintenance_date),
          saved: true,
        }
      });
      setSavedData(data);
      setSaving(false);
      setMaintenanceData({ ...maintenanceData, ...data });
    } catch (error) {
      setSaving(false);
      toast.error('Something went wrong!')
    }
  }

  useEffect(() => {
    getMaintenaceChecklistItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=> {
    getSensorBoxMaintenanceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sensorBoxId])

  useEffect(() => {
    if(editMode === false) setMaintenanceData(savedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editMode]);
  

  const lastServiceDoneDateChangeHandler = (item: any, date: Date) => {
    let data = maintenanceData[item?._id]
    let updated = {
      ...maintenanceData,
      [item?._id]: {
        ...data,
        last_service_done_date: date,
        comment: data?.commentUpdated ? data.comment : '',
        next_maintenance_date: null,
        isChange: true,
      }
    }
    setMaintenanceData(updated);
  }

  const nextMaintenanceDateChangeHandler = (item: any, date: Date) => {
    let data = maintenanceData[item?._id]
    let updated = {
      ...maintenanceData,
      [item?._id]: {
        ...data,
        next_maintenance_date: date,
        isChange: true,
        errors: {
          ...data?.errors,
          next_maintenance_date: ''
        }
      }
    }
    setMaintenanceData(updated);
  }

  const commentChangeHandler = (item: any, value: string) => {
    let data = maintenanceData[item?._id]
    let updated = {
      ...maintenanceData,
      [item?._id]: {
        ...data,
        comment: value,
        isChange: true,
        commentUpdated: true,
      }
    }
    setMaintenanceData(updated);
  }

  const removeMaintenaceItem = async (checkListId: string) => {
    try {
      const checkList = maintenanceData[checkListId];
      if(checkList) {
        const params = {
          sensorbox_id: sensorBoxId,
          check_list_id: checkListId,
        }
        await maintenaceService.removeMaintenanceCheckListItem(params);
      }
    } catch (error) {
      let errMsg = error?.message;
      if(error?.response?.data?.message) {
        errMsg = error?.response?.data?.message
      }
      toast.error(errMsg);
    }
  }

  const enableCheckList = (item: any, e: ChangeEvent<HTMLInputElement>) => {
    if(e.target.checked) {
      let updated = { ...maintenanceData, [item?._id]: {} };
      setMaintenanceData(updated);
      return;
    }
    removeMaintenaceItem(item?._id)
    delete maintenanceData[item?._id];
    setMaintenanceData({ ...maintenanceData });

  }

  const validate = (checkListId: string) => {
    const checkList = maintenanceData[checkListId];
    const errors: any = {};
    if(!checkList?.next_maintenance_date) {
      errors.next_maintenance_date = 'This field is required'
    }
    setMaintenanceData({
      ...maintenanceData,
      [checkListId]: {
        ...checkList,
        errors
      }
    })
    return !Object.keys(errors).some(key => errors[key])
  }
  
  const saveHandler = async (checkListId: string) => {
    const checkList = maintenanceData[checkListId];
    if(checkList && validate(checkListId)) {
      try {
        const params = {
          sensorbox_id: sensorBoxId,
          check_list_id: checkListId,
          last_service_done_date: checkList?.last_service_done_date?.getTime(),
          next_maintenance_date: checkList?.next_maintenance_date?.getTime(),
          comment: checkList?.comment,
        }
        setSaving(true);
        await maintenaceService.saveMaintenanceCheckList(params);
        toast.success('Maintenance checklist updated')
        getSensorBoxMaintenanceList();
      } catch (error) {
        setSaving(false);
        let errMsg = error?.message;
        if(error?.response?.data?.message) {
          errMsg = error?.response?.data?.message
        }
        toast.error(errMsg);
      }
    }
  }

  return (
    <div id="maintenance-checklist">
      <Tabs variant="pills" className="checklist-tab my-3">
        {
          Object.keys(checkLisItems).map((key: any, i) => (
            <Tab key={key} eventKey={key} title={key}>
              <div className="table-wrapper">
                <table className="table mt-4">
                  <thead>
                    <tr>
                      <th style={{ width: '25%' }}>Checklist</th>
                      <th style={{ width: '20%' }}>Service Done Date</th>
                      <th style={{ width: '20%' }}>Next Maintenance Date</th>   
                      {!authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) &&     
                      <th style={{ width: '27%' }}>Comment</th>
                        }
                      <th style={{ width: '8%' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      checkLisItems[key]?.map((item: any) => (
                        <tr key={item?._id}>
                          <td className="text-truncate" title={item?.check_list_item}>
                            <input
                              type="checkbox"
                              className="mr-2"
                              checked={maintenanceData[item?._id] || false}
                              onChange={(e) => enableCheckList(item, e)}
                              disabled={!editMode}
                            />
                            {item?.check_list_item}
                          </td>
                          <td>
                            <DatePicker
                              placeholderText="Choose date"
                              value={maintenanceData[item?._id]?.last_service_done_date}
                              dateFormat="MM/dd/yyyy"
                              maxDate={new Date()}
                              onChange={(value : any)=> {
                                lastServiceDoneDateChangeHandler(item, value)
                              }}
                              disabled={!maintenanceData[item?._id] || !editMode}
                            />
                          </td>
                          <td>
                            <DatePicker
                              placeholderText="Choose date"
                              value={maintenanceData[item?._id]?.next_maintenance_date}
                              onChange={(value : any)=> {
                                nextMaintenanceDateChangeHandler(item, value)
                              }}
                              dateFormat="MM/dd/yyyy"
                              minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                              disabled={!maintenanceData[item?._id] || !editMode}
                            />
                            {
                              maintenanceData[item?._id]?.errors?.next_maintenance_date &&
                              <div className="error-msg">
                                {maintenanceData[item?._id]?.errors?.next_maintenance_date }
                              </div>
                            }
                          </td>
                          {!authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) &&
                          <><td>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Comment"
                                onChange={(e) => commentChangeHandler(item, e.target.value)}
                                value={maintenanceData[item?._id]?.comment || ''}
                                disabled={!maintenanceData[item?._id] || !editMode}
                                maxLength={200} />
                            </td><td>
                                <button
                                  className="btn btn-hvac-primary btn-sm px-3"
                                  onClick={() => saveHandler(item._id)}
                                  disabled={!maintenanceData[item?._id] || !maintenanceData[item?._id]?.isChange || !editMode || saving}
                                >
                                  Save
                                </button>
                              </td></>
}
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </Tab>
          ))
        }
      </Tabs>
    </div>
  )
}

export default MaintenanceCheckList;

