import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import './services.css';
import Servicesimage from '../../assets/service-image1.jpg';


export interface ServiceState { }
export interface ServiceProps extends RouteComponentProps {}

class Service extends Component<ServiceProps, ServiceState>
{
  
 constructor(props : any){
     
     super(props);
     this.state = {

     }
 }


  render() {
    return (
        <div className="container">
          <div className="row">
            <div className="col-md-6 services-wrapper">
               <h4>Services</h4>
               <ul className="service-list">
                 <li>HVAC system operation analysis</li> 
                 <li>Data collection strategy and analysis</li> 
                 <li>Data reporting and alerts (onsite or remotely)</li> 
                 <li>Equipment Predictive and Preventive maintenance strategy</li> 
               </ul>
            </div>
            <div className="col-md-6 service-img-wrapper">
                 <img src={Servicesimage} className="" alt="services" />
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(Service);