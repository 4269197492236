import Api from '../api';
import { Organization } from '../interface';

class OrganizationService {

    getOrganizations = async (pageNumber?: number, limit?: number, typeFilter?: string, searchFilter?: string) => {
        return Api.get(`/organizations?page=${pageNumber ? pageNumber : 1}&limit=${limit ? limit : 0}&type=${typeFilter??""}&search=${searchFilter??""}`)
    }
    addOrganization = async (data: Organization) => {
        return Api.post('/organizations', data)
    }
    updateOrganization = async (data: Organization) => {
        return Api.put(`/organizations/${data._id}`, data)
    }
    deleteOrganization = async (id: String ,action : any) => {
        return Api.put(`/organizations/action/${id}`, action)
    }
    enableDisableOrganization = async (id: String,action : any) => {
        return Api.put(`/organizations/action/${id}`, action)
    }
    hardDeleteOrganization = async (id: String) => {
        return Api.delete(`/organizations/${id}`)
    }

    isOrganizationNameExist = async (organizationName: string, orgId?: string) => {
        return Api.get(`/organization_exist/${organizationName}?oid=${orgId || ''}`)
    }
   
}

export default new OrganizationService();
