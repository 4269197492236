import React, {useState} from 'react';
import logo from '../../assets/logo.png';
import './passwordchange.css';
import Utility from '../../helpers/Utility'
import userService from '../../services/userService';
import LoadingIndicator from '../loadingwheel';
import validate from '../../helpers/validation';
import constants from '../../config/constants';
import authStore from '../../store/authStore';
import MessageBox from '../../components/messagebox';


function ChangePassword(props : any) {

    const [error, setError]                         = useState({}) as any;
    const [errorPassword, setErrorPassword]         = useState({}) as any;
    const [errorNewPassword, setErrorNewPassword]           = useState({}) as any
    const [errorConfirmPassword, setErrorConfirmPassword]         = useState({}) as any

    const [currentpassword, setCurrentPassword]     = useState('');
    const [newpassword, setNewPassword]             = useState('');
    const [confirmpassword, setConfirmPassword]     = useState('');
    const [isLoading, toggleLoader]                 = useState(false)
    const [isModalVisible, toggleModalVisible]      = useState(false)
    const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
    const [newPasswordShown, setNewPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const [showMessageBoxModal ,setShowMessageBoxModal] = useState(false);

    


    async function setChangedPassword() {
        try {
            let isValidCredential = await isValidCredentials();
            if(isValidCredential){           
                toggleLoader(true)
                let response = await userService.setChangePassword(newpassword,confirmpassword)
                if(response) {
                    toggleModalVisible(true)
                    setShowMessageBoxModal(true)
                }                
                toggleLoader(false)
                closeModal()
            }
        }catch(error) {
            toggleLoader(false)
            setError({message:error.response.data.message})
        }
    }
 
   function closeModal(){
        setCurrentPassword('')
        setNewPassword('')
        setConfirmPassword('')
        setErrorPassword('')
        setErrorNewPassword('')
        setErrorConfirmPassword('')
        setError('')
        Utility.closeModal('changePassword')
        if(props.closeModal){
            props.closeModal()
        }
       
   }

   function closePasswordModals() {
       toggleModalVisible(false)
       setShowMessageBoxModal(true)
       authStore.signOut()
    }

   function keyPress(e : any) {
        if(e.which === 13){
            setChangedPassword()
        }
   }
    
    function changeCurrentPasswordValue(value : string){
            setCurrentPassword(value)
    }

    function changeNewPasswordValue(value : string){
        setNewPassword(value)           
      
    }

    function changeConfirmPasswordFieldValue(value : string){
        setConfirmPassword(value)
    }

    async function validateCurrentPassword(currentpassword : string){
        let obj     = {} as any
        let isValid = true
        setError('')
        try{
            if(validate.isEmpty(currentpassword)){
                obj.currentpassword = constants.message.password_field_mandatory
                isValid   = false            
            }else{
                let currentPasswordDb = await userService.getCurrentPassword(currentpassword)
                if(currentPasswordDb) {
                    isValid = true;
                    obj.currentpassword = '';
                }else{
                    isValid   = false 
                    obj.currentpassword = constants.message.password_field_mismatch 
                }
            }           
        }catch(error){
           isValid   = false
           setError({message: error.response.data.message})
        }  
        setErrorPassword(obj)
        return isValid

    }

    function validateNewPassword(newpassword:string){
        let obj     = {} as any
        let isValid = true
        setError('')
        if(validate.isEmpty(newpassword)){
            obj.newpassword = constants.message.password_field_mandatory
            isValid      = false
        }else{
            if(!validate.isValidPassword(newpassword)){
                obj.newpassword = constants.message.invalid_password 
                setError({message:constants.message.password_field_validation})
                isValid      = false
            }
        }
        setErrorNewPassword(obj)
        return isValid
    }

    function validateConfirmPassword(confirmpassword:string){
        let obj     = {} as any
        let isValid = true
        setError('')
        if(validate.isEmpty(confirmpassword)){
            obj.confirmpassword = constants.message.password_field_mandatory
            isValid      = false
        }else{
            if(!validate.isValidPassword(confirmpassword)){
                obj.confirmpassword = constants.message.invalid_password 
                setError({message:constants.message.password_field_validation});
                isValid      = false    
            }
            if(newpassword !== confirmpassword){
                obj.confirmpassword = constants.message.password_field_mismatch 
                isValid      = false
            }
        }
        setErrorConfirmPassword(obj)
        return isValid
    }

    async function isValidCredentials(){
        let isValid = await validateCurrentPassword(currentpassword) && validateNewPassword(newpassword) && validateConfirmPassword(confirmpassword)
        return isValid
   }

   const toggleCurrentPasswordVisiblity = () => {
    setCurrentPasswordShown(currentPasswordShown ? false : true);
   };

   const toggleNewPasswordVisiblity = () => {
    setNewPasswordShown(newPasswordShown ? false : true);
   };

   const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
   };
   
   return (
    <div className="login-modal-wrap">
        <div className="modal fade" id="changePassword" role="dialog">
        <div className="modal-dialog modal-md modal-custom">
          <div className="modal-content login-modal">
              <button type="button" className="close dismis-btn" onClick={()=>closeModal()}>&times;</button>
            <div className="modal-body modal-body-content">
            <img src={logo} className="logo-img" alt="logo" />
            
                     <form className="form-wrapper">
                        <div className="form-group row mt-40">
                            <div className="col-sm-12 mb-15">
                            <i className="fa fa-key input-ic" aria-hidden="true"></i>
                                <input type={currentPasswordShown ? "text" : "password"} 
                                    className="form-control login-ip mt-10" 
                                    id="currentpassword" 
                                    placeholder="Current password"
                                    required={true}
                                    minLength={6}
                                    maxLength={20} 
                                    value={currentpassword}             
                                    onKeyPress={(e)=>keyPress(e)}
                                    onBlur={(e)=>validateCurrentPassword(e.target.value)}
                                    onChange={(e)=>changeCurrentPasswordValue(e.target.value)} />
                                    <i className={['fa eye-class' , currentPasswordShown ? 'fa-eye' : 'fa-eye-slash'].join(' ') } aria-hidden="true" onClick={toggleCurrentPasswordVisiblity}></i>
                                    <div className="error-msg">{errorPassword.currentpassword}</div>
                            </div>
                            <div className="col-sm-12 mb-15">
                            <i className="fa fa-key input-ic" aria-hidden="true"></i>
                                <input type={newPasswordShown ? "text" : "password"} 
                                    className="form-control login-ip mt-10" 
                                    id="newpassword" 
                                    placeholder="New password"
                                    required={true} 
                                    value={newpassword}
                                    minLength={6}
                                    maxLength={20}
                                    onKeyPress={(e)=>keyPress(e)}
                                    onBlur={(e)=>validateNewPassword(e.target.value)}
                                    onChange={(e)=>changeNewPasswordValue(e.target.value)}
                                     />
                                    <i className={['fa eye-class' , newPasswordShown ? 'fa-eye' : 'fa-eye-slash'].join(' ') } aria-hidden="true" onClick={toggleNewPasswordVisiblity}></i>
                                    <div className="error-msg">{errorNewPassword.newpassword}</div>
                            </div>
                            <div className="col-sm-12 mb-15">
                            <i className="fa fa-key input-ic" aria-hidden="true"></i>
                                <input type={confirmPasswordShown ? "text" : "password"}  
                                    className="form-control login-ip mt-10" 
                                    id="confirmpassword" 
                                    placeholder="Confirm New password"
                                    value={confirmpassword}
                                    required={true} 
                                    minLength={6}
                                    maxLength={20}
                                    onKeyPress={(e)=>keyPress(e)}
                                    onBlur={(e)=>validateConfirmPassword(e.target.value)}
                                    onChange={(e)=>changeConfirmPasswordFieldValue(e.target.value)} />
                                    <i className={['fa eye-class' , confirmPasswordShown ? 'fa-eye' : 'fa-eye-slash'].join(' ') } aria-hidden="true" onClick={toggleConfirmPasswordVisiblity}></i>
                                    <div className="error-msg">{errorConfirmPassword.confirmpassword}</div>
                            </div>
                        </div>
                        
                        
                    </form>
                    
                    <button type="button" className="btn btn-primary btn-lg btn-block btn-login" onClick={()=>setChangedPassword()}>SUBMIT</button>
                    <div className="error-msg error-msg-center">{error.message}</div>
            </div>

          
          </div>
        </div>
        {isLoading?
            <LoadingIndicator/>
            :   null }        
      </div>
      {isModalVisible?
      <MessageBox show={showMessageBoxModal} title={""}  message = {constants.message.password_change} closePasswordModal={()=>closePasswordModals()}/>
      : null}
      </div>
    );
  }
 
export default ChangePassword;