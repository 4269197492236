import React, { useState } from 'react';
import {RouteComponentProps, withRouter ,Link} from "react-router-dom";
import logo from '../../assets/logo.png';
import './home.css';
import authStore from '../../store/authStore'
import Utility from '../../helpers/Utility'
import validate from '../../helpers/validation';
import constants from '../../config/constants';
import LoadingWheel from '../../components/loadingwheel'
import Routes from "../../pages/Routes";

export interface HomeProps extends RouteComponentProps {}

function Home(props : HomeProps) {

    const [responseError, setResponseError] = useState('');
    const [username, setUserName]           = useState('');
    const [password, setPassword]           = useState('');
    const [error, setError]                 = useState({}) as any
    const [isLoading, toggleLoader]         = useState(false)
    const [passwordShown, setPasswordShown] = useState(false);

    async function login() {

        setResponseError('')
        try{    

            if(isValidCredentials()){
                toggleLoader(true)
                let role_id:any
                if(props.location.search){
                    const urlParams = new URLSearchParams(props.location.search);
                    role_id = urlParams.get('role_id')
                }

                await authStore.signInWithUserNameAndPassword(username.trim(), password,role_id)
                toggleLoader(false)
                closeSigninModal()  
            }
             
        }catch(error){
            toggleLoader(false)
            setResponseError(error.response.data.message)
        }
        
    }
    
   function isValidUserName() {
        let isValid = true
        setError({...error,email: ""});
        if(validate.isEmpty(username)){
            setError({...error,email: constants.message.email_mandatory})
            isValid   = false            
        }else{  

            if(!validate.isEmail(username.trim())){
                setError({...error,email: constants.message.email_valid})
                isValid   = false
            }
        }
        return isValid
   }

   function isValidPassword() {
        let isValid = true;
        setError({...error,password: ""});
        if(validate.isEmpty(password)){
            setError({...error,password: constants.message.password_field_mandatory})
            isValid      = false
        }else{
            if(!validate.isPassword(password)){
                // at the time of login password valid message is not required. instead show invalid message
                setError({...error,password: constants.message.invalid_password})
                isValid      = false
            }
        }
        return isValid
   }

   function isValidCredentials(){
        return  isValidUserName() && isValidPassword();
   }

   function closeSigninModal(){
    setUserName('')
    setPassword('')
    setResponseError('')
    setError({})
    Utility.closeModal('myModal')
   }

   function keyPress(e : any) {
        if(e.which === 13){
            login()
        }
   }
    
    function changeUsername(value : string){
        setUserName(value.trimLeft())
    }
    
    function changePassword(value : string){
        setPassword(value.trimLeft())
    }
   
    
    function showForgotPassword(){
        Utility.showModal('forgotPassword')
        closeSigninModal()
    }

    const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
    };

    return (
      <div className="d-flex align-items-center" style={{ minHeight: 'calc(100vh - 2.33rem)' }}>
        <div className="container col-12 col-lg-4 my-3">  
        <img src={logo} className="logo-img" alt="logo" />   
        <div className="container mt-3 login-card">   
          <form className="form-wrapper-home">
            <div className="form-group row mt-40">
              <div className="col-sm-12">
                <i className="fa fa-user-o input-ic" aria-hidden="true"></i>
                  <input type="text" 
                        className="form-control login-ip mt-10" 
                        id="email-inputtext" 
                        placeholder="Email"
                        value={username}
                        maxLength={50}
                        onChange={(e)=>changeUsername(e.target.value)} 
                        onBlur ={()=>{isValidUserName()}}/>
              </div>
              <div className="error-msg msg-position">{error.email}</div>
            </div>
            <div className="form-group row">
              <div className="col-sm-12">
                      
                <i className="fa fa-key input-ic" aria-hidden="true"></i>
                    <input type={passwordShown ? "text" : "password"}
                          className="form-control login-ip mt-10" 
                          id="inputPassword" 
                          placeholder="Password"
                          value={password}
                          minLength={6}
                          maxLength={20}
                          onKeyPress={(e)=>keyPress(e)}
                          onChange={(e)=>changePassword(e.target.value)} 
                          onBlur ={()=>{isValidPassword()}}/>
                          <i className={['fa eye-class' , passwordShown ? 'fa-eye' : 'fa-eye-slash'].join(' ') } aria-hidden="true" onClick={togglePasswordVisiblity}></i>
              </div>
              <div className="error-msg msg-position">{error.password}</div>
            </div>
          </form>
            <div className="row">
              <div className="col-sm-12">
                <span className="frgt-pswd" ><span className="frgt-click" onClick={()=>showForgotPassword()}>Forgot Password ?</span></span>
              </div>    
            </div>
              <button type="button" className="btn btn-primary btn-lg btn-block btn-login" onClick={()=>login()}>LOGIN</button>
              {responseError && 
              <div className="error-msg error-msg-center">{responseError}</div> 
              }
              <div className="text-lg-center mt-4 warning-msg">
              <label className="d-inline-block"> By continuing, you agree to MechaSense's <Link to={Routes.termsservice} target="_blank">Terms of Service</Link> </label> 
              </div> 
              </div>
              {isLoading?
              <LoadingWheel/>
              :   null }    
        </div>
      </div>
    );
  }
 
export default withRouter(Home);
