import Api from '../api';

class softSavingsService {
    getSoftSavingsDetails = async ( page : any ,limit : any, searchFilter?: string) => {

        let url = `/soft-savings-categories?page=${page}&limit=${limit}&search=${searchFilter??""}`

        let response = await Api.get(url)
        return response.data
    }

    addSoftSavingsDetails = async (data: any) => {

        let url = `/soft-savings-categories`

        return Api.post(url, data)
    }

    updateSoftSavingsDetails = async (data: any, id : any) => {

        let url = `/soft-savings-categories/${id}`

        return Api.put(url, data)
    }

    deleteSoftSavings = async (id?: any) => {

        let url = `/soft-savings-categories/${id}`
        return Api.delete(url)
    }

}

export default new softSavingsService()