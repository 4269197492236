import React, { useEffect, useState } from 'react';
import { Col, Input, Button, Form, Typography, Checkbox, Divider, Skeleton, Image } from 'antd';
import { PaperClipOutlined } from "@ant-design/icons";
import rules from '../../screens/issue-tracker-details/issue-tracker-validation';
import Utils from '../../helpers/Utility';
import constants from '../../config/constants';
import authStore from '../../store/authStore';
import moment from 'moment';
import AttachmentUpload from '../issue-tracker-ticket-form/attachmentUpload';
import  Permissions from '../../config/permssions'

const {
    create_edit_attachment_issue_tracker
} = Permissions

const { TextArea } = Input;
const {Text} = Typography
interface Props {
    issueDetails?: any,
    onResolveTicket: (values: any, action: any) => void;
    onIgnoreTicket: () => void;
    onCancel: () => void;
    imageList: any[],
    skeletonOn: boolean,
    imageCreatedBy: any[],
    imageNotes: any[],
    userType: any[],
    fetchIssueDetailsWithId: () => void;
}

const IssueTrackerDiagnosticDetails: React.FC<Props> = ({ issueDetails, onResolveTicket, onIgnoreTicket, onCancel, imageList, skeletonOn, imageCreatedBy, imageNotes, userType, fetchIssueDetailsWithId }) => {
    const [form] = Form.useForm();
    const [saveChangesState, setSaveChangesState] = useState(false)
    const [uploadDiagnosticAttachments, setUploadDiagnosticAttachments] = useState(false)
    const [diagnosticDetailsLoading, setDiagnosticDetailsLoading] = useState(false)
    
    const [markedAsIncomplete, setMarkedAsIncomplete] = useState(issueDetails?.marked_as_incomplete ? issueDetails?.marked_as_incomplete : false)

    useEffect(() => {
        if (issueDetails?.hasOwnProperty('diagnosis') && issueDetails?.hasOwnProperty('overall_summary')) {
            form.setFieldsValue({
                diagnosis: issueDetails?.diagnosis,
                overall_summary: issueDetails?.overall_summary,
            });
            setSaveChangesState(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issueDetails])

    const onFinish = (values: any) => {
        values.markedAsIncomplete = markedAsIncomplete
        onResolveTicket(values, saveChangesState ? "savechanges" : "resolve")
    };

    const onResolve = () => {
        onResolveTicket({diagnosis : issueDetails?.diagnosis, overall_summary: issueDetails?.overall_summary}, "resolve")
    };

    const handleIgnoreTicket = () => {
        onIgnoreTicket()
    }

    const handleCancelOrDiscard = () => {
        if (saveChangesState || markedAsIncomplete !== issueDetails?.marked_as_incomplete) { // discard

            form.setFieldsValue({
                diagnosis: issueDetails?.diagnosis,
                overall_summary: issueDetails?.overall_summary,
            });
            setSaveChangesState(false)
            setMarkedAsIncomplete(issueDetails?.marked_as_incomplete ? issueDetails?.marked_as_incomplete : false)
        } else { //cancel
            onCancel()
        }
    }

    const handleFormValuesChange = () => {

        let diagonosticObj = {} as any
        if (issueDetails?.diagnosis && issueDetails?.overall_summary) {
            diagonosticObj.diagnosis = issueDetails?.diagnosis
            diagonosticObj.overall_summary = issueDetails?.overall_summary
        }

        if (!(issueDetails?.hasOwnProperty('diagnosis')) && !(issueDetails?.hasOwnProperty('overall_summary'))) {
            setSaveChangesState(true)
            return
        }

        setSaveChangesState(!(JSON.stringify(diagonosticObj) === JSON.stringify(form.getFieldsValue())))

    }

    const showAttachments = () => {
        return (
            (!skeletonOn ?
            (imageList?.length > 0 &&
            imageList?.map((row, rowIndex) => (
                ((userType[rowIndex] === constants.role['Field Technician']) &&
                <div key={rowIndex} className='mb-3'>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 5 }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                    <div className="avatar" style={{ marginRight: 25, fontSize: 15 }}>
                        {imageCreatedBy[rowIndex]?.createdBy ? (imageCreatedBy[rowIndex]?.createdBy?.charAt(0) + imageCreatedBy[rowIndex]?.createdBy?.slice(-1))?.toUpperCase() : 'U'}
                    </div>
                    <div>
                        <p></p>
                        <p style={{ fontSize: 12 }}>
                        Uploaded on {moment(imageCreatedBy[rowIndex]?.createdAt).format("DD-MMMM-YYYY - HH:mm:ss") !==
                            "Invalid Date"
                            ?  moment(imageCreatedBy[rowIndex]?.createdAt).format("DD-MMMM-YYYY - HH:mm:ss")
                            : "-"}  
                            {imageCreatedBy[rowIndex]?.createdBy && (imageCreatedBy[rowIndex]?.createdBy !== authStore.currentUser?.name) ? ` by ${imageCreatedBy[rowIndex]?.createdBy} ` : ''}
                        </p>
                    </div>
                    </div>
                </div>
                <div>
                    <p style={{ maxWidth: '550px', overflowWrap: 'break-word', marginLeft: 64, fontSize: 11 }}>{imageNotes[rowIndex]}</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 70 }}>
                    {row?.map((image: any, colIndex: any) => (
                    <div key={colIndex} style={{ marginRight: 10 }}>
                        <Image src={image} style={{ height: 120, padding: 20 }} />
                    </div>
                    ))}
                </div>
                </div>
                )
            ))
            )
            :
            <Col>
                <Skeleton.Avatar active className="mr-3" />
                <Skeleton.Input active style={{ width: 250, maxWidth: 250, minWidth: 200 }} className="mr-1 mb-1" />
                <Skeleton.Input active style={{ width: 250, maxWidth: 250, minWidth: 200 }} />
            </Col>
            )
        )
    }

    return (
        <>
              {issueDetails?.status === "OPEN" ?

                    <Form form={form} layout="vertical" autoComplete="off" onFinish={onFinish} onChange={handleFormValuesChange}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between"}} className='mb-3'>
                                <div style={{ display: 'flex', alignItems: 'center'}}>
                                      <p style={{ fontSize: "14px", fontWeight: 5, marginBottom: 0 }} className='mr-2'>
                                        Diagnosis Details
                                      </p>
                                      <span className="mand-star mt-1">*</span>
                                    </div>
                                    <div>
                                    <Checkbox checked={markedAsIncomplete} value={markedAsIncomplete} onChange={(value) => {setMarkedAsIncomplete(value?.target?.checked)}} className='mr-4'>
                                        Mark as incomplete
                                    </Checkbox>
                                    {authStore?.currentUser?.access?.includes(create_edit_attachment_issue_tracker) &&
                                    <Button onClick={() => setUploadDiagnosticAttachments(true)} className='mr-5'>
                                       <PaperClipOutlined /> Add Attachments
                                    </Button>
                                    }
                                      </div>
                                    </div>
                                    {showAttachments()}
                                    <Form.Item normalize={Utils.trimSpacesInValues} name="diagnosis"  rules={rules.diagnosis} className='m-md-5'>
                                        <TextArea
                                            showCount
                                            maxLength={constants.overallSummaryLength}
                                            style={{
                                                height: 100,
                                            }}
                                            placeholder="Enter diagnosis..."
                                        />
                                    </Form.Item>
                                    <Form.Item normalize={Utils.trimSpacesInValues} name="overall_summary"  rules={rules.overall_summary} className='m-md-5'>
                                        <TextArea
                                            showCount
                                            maxLength={constants.overallSummaryLength}
                                            style={{
                                                height: 100,
                                            }}
                                            placeholder="Enter overall summary..."
                                        />
                                    </Form.Item>
                            </Form>

                                :
                            <div className='W-100'>
                            <p style={{ fontSize: "19px", fontWeight: 5 }} className='mb-4'>
                                Diagnosis Details
                            </p>
                            {showAttachments()}
                            <p className="paragraph">
                                <p style={{ fontSize: "13px", fontWeight: 5 }}>
                                    Diagnosis
                                </p>
                                <div className="d-flex align-items-center">
                                    <Text className="mr-1 mb-0">
                                        {issueDetails?.diagnosis
                                            ? issueDetails?.diagnosis
                                            : ``}
                                    </Text>
                                </div>
                            </p>
                            <p className="paragraph">
                                <p style={{ fontSize: "13px", fontWeight: 5 }}>
                                    Overall Summary
                                </p>
                                <div className="d-flex align-items-center">
                                    <Text className="mr-1 mb-0">
                                        {issueDetails?.overall_summary
                                            ? issueDetails?.overall_summary
                                            : ``}
                                    </Text>
                                </div>
                            </p>
                        </div>
}

                {issueDetails?.status === "OPEN" &&
                      <><Divider type="horizontal" className="mt-3 mb-4 invisible"></Divider><Col span={24}>
                    <div className="text-right mt-4">
                        <Button className="text-left float-left" type="text" onClick={handleIgnoreTicket}>Ignore Ticket</Button>
                        <Button className="text-left" type="text" onClick={handleCancelOrDiscard}>{saveChangesState || markedAsIncomplete !== issueDetails?.marked_as_incomplete ? `Discard Changes` : `Cancel`}</Button>
                        {authStore?.currentUser?.active_role?.role_type === constants.role['Field Technician'] &&
                            <Button className="h-36 py-0 pl-4 pr-4 fs-14 ins_bg_clr border-0 font-weight-bold" type="primary" onClick={form.submit}>{saveChangesState || markedAsIncomplete !== issueDetails?.marked_as_incomplete ? `Save Changes & Submit Later` : `Submit & Complete Diagnosis `}</Button>}
                    </div>
                </Col></>
                }
                {issueDetails?.status === "PENDING REVIEW" && authStore?.currentUser?.active_role?.role_type === constants.role.Admin &&
                <><Divider type="horizontal" className="mt-3 mb-4 invisible"></Divider><Col span={24}>
                    <div className="text-right mt-4">
                        <Button className="text-left" type="text" onClick={handleCancelOrDiscard}>{`Cancel`}</Button>
                        <Button className="h-36 py-0 pl-4 pr-4 fs-14 ins_bg_clr border-0 font-weight-bold" type="primary" onClick={onResolve}>{`Resolve Ticket`}</Button>
                    </div>
                </Col></>
                }
                {issueDetails?.status === "INCOMPLETE" && authStore?.currentUser?.active_role?.role_type === constants.role.Admin &&
                      <>
                      <Divider type="horizontal" className="mt-3 mb-4 invisible"></Divider>
                      <Col span={24}>
                    <div className="text-right mt-4">
                        <Button className="text-left" type="text" onClick={handleCancelOrDiscard}>{`Cancel`}</Button>
                        <Button className="h-36 py-0 pl-4 pr-4 fs-14 ins_bg_clr border-0 font-weight-bold" type="primary" onClick={onResolve}>{`Create Sub Ticket`}</Button>
                    </div>
                </Col>
                </>
                }
                <AttachmentUpload
                    isModalOpen={uploadDiagnosticAttachments}
                    setIsModalOpen={setUploadDiagnosticAttachments}
                    ticketId={issueDetails?.issue_id}
                    setLoading={setDiagnosticDetailsLoading}
                    loading={diagnosticDetailsLoading}
                    fetchIssueDetailsWithId={fetchIssueDetailsWithId}
                    />
        </>
    );
}
export default IssueTrackerDiagnosticDetails;
