/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Breadcrumb, Divider, Row, Spin, message} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import Routes from '../../routing/routes';
import userService from '../../services/userService'
import IssueTicketDetails from '../../components_new/issue-tracker-ticket-form';
import IssueCustomerBenefitsAndRevenue from '../../components_new/issue-tracker-customer-benefits-revenue';
import issueTrackerServices from '../../services/issueTrackerServices';
import IssueEditTicket from '../../components_new/issue-tracker-edit-ticket';

import IssueDetailsCard from '../../components_new/issue-tracker-details-card';
import IssueBenefitsCard from '../../components_new/issue-tracker-customer-benefits-card';

import authStore from '../../store/authStore';
import sensorService from '../../services/sensorService';
import constants from '../../config/constants';
import Utils from '../../helpers/Utility';

const IssueTrackerDetails: React.FC = () => {

  let history = useHistory();
  const location = useLocation() as any;

  const system_id = new URLSearchParams(location?.search).get('system_id');
  const status = new URLSearchParams(location?.search).get('status');
  const orgCondition = authStore?.currentUser?.active_role?.role_type === 0 || (authStore?.currentUser?.active_role?.role_type === 1 && authStore.currentUser.is_default)


  const ticket_id = new URLSearchParams(location?.search).get('ticket_id');
  const org_id = new URLSearchParams(location?.search).get('org_id') as string;

  const [issueDetails, setIssueDetails] = useState<any>({})
  const [softSavings, setSoftSavings] = useState<any>([])
  const [orgAdminUsers, setOrdAdminUsers] = useState<any>([])
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [skeletonOn, setSkeletonOn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [issueId, setIssueId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [imageList, setImageList] = useState([])
  const [imageNotes, setImageNotes] = useState([])
  const [imageCreatedBy, setImageCreatedBy] = useState([{createdBy: '', createdAt: ''}])
  const [userType, setUserType] = useState([])
  const [contractorList, setContractorList] = useState([]);
  const [technicianUnderContractor, setTechnicianUnderContractor] = useState([]);

  useEffect(() => {
    if (system_id) {
      fetchSystemDetails()
    } else if (ticket_id) {
      fetchIssueDetailsWithId(ticket_id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [system_id, ticket_id])

  const fetchSystemDetails = async () => {
    try {
      setLoading(true)
      let sensorBoxDetails = await userService.getSensorBoxList(system_id as any)
      setContractorList(sensorBoxDetails?.contractor)
      let customerDetails = await userService.getUsersBySensors(system_id as any)
      let alertDatas = await sensorService.getLatestAlertLogs(sensorBoxDetails?.sensor_box_name)
      let alertLogs = alertDatas?.data?.logs

      let datas = {
        consumer: sensorBoxDetails?.consumer ? sensorBoxDetails?.consumer : null,
        contractor: sensorBoxDetails?.contractor ? sensorBoxDetails?.contractor : null,
        address: sensorBoxDetails?.place ? sensorBoxDetails?.place : null,
        phone: customerDetails?.consumerDetails ? customerDetails?.consumerDetails?.phonenumber : null,
        email: customerDetails?.consumerDetails ? customerDetails?.consumerDetails?.email : null,
        sensor_box: sensorBoxDetails,
        alert_status: status,
        alert_code: alertLogs.length > 0 ? alertLogs[0]?.diagnostics_code : null,
        alert_description: alertLogs.length > 0 ? alertLogs[0]?.description : null
      } as any

      if (sensorBoxDetails?.sensorbox_group) {
        datas.group_id = sensorBoxDetails?.sensorbox_group
      }

      setIssueDetails(datas)
      setLoading(false)

    } catch (error) {
      setLoading(false)
      message.destroy()
      message.error(error?.response?.data?.message)
    }
  }

  const imageData = async (issueDetails: any) => {
    try{
      setSkeletonOn(true)
      let notes: any = []
      let creatorDetails: any = []
      let uploaderRoleType: any = []
      if (issueDetails && issueDetails?.notes) {
        let attachmentsResponse = issueDetails?.notes?.map(
          async (note: any) => {
            notes.push(note?.text);
            creatorDetails.push({createdBy: note?.creator_name, createdAt: note?.dateAdded})
            uploaderRoleType.push(note?.creator_user_role || -1)
            if (note?.attachments?.length) {
              let res: any = await Promise.all(
                (note?.attachments || []).map(async (element: any) => {
                  return await issueTrackerServices.getAttachedImageByimageId(
                    element
                  );
                })
              );
              let urls = res?.map((item: any) => {
                if (item && item?.data) {
                let url = URL.createObjectURL(item?.data);
                return url;
                }
              });

              return urls;
            }
          }
        );
        const responses: any = await Promise.all(attachmentsResponse);
        setImageList(responses)
        setImageNotes(notes)
        setImageCreatedBy(creatorDetails)
        setUserType(uploaderRoleType)
        setSkeletonOn(false)
    }
  } catch (error) {
      setSkeletonOn(false)
  }
  }

  const fetchIssueDetailsWithId = async (id: any) => {
    try {

      setLoading(true)

      let issuesTracker = await issueTrackerServices.IssueTrackerDetailsById(id, org_id)

      let sensorBoxDetails = await userService.getSensorBoxList(issuesTracker?.issue?.sensor_box?._id as any)
      setContractorList(sensorBoxDetails?.contractor)
      let customerDetails = await userService.getUsersBySensors(issuesTracker?.issue?.sensor_box?._id as any)

      let softSavingsDetails = await issueTrackerServices.getSoftSavingsCategory()
      setSoftSavings(softSavingsDetails?.softSavings)

      const technicianList = authStore?.currentUser?.active_role?.role_type === constants.role.Contractor ? true : false
      let orgAdminList = await userService.getUserBasedOnRoleType(org_id, constants.role.Contractor, technicianList)
      setOrdAdminUsers(technicianList ? orgAdminList.result : orgAdminList)
      setTechnicianUnderContractor(technicianList ? orgAdminList?.technicianList : [])

      let customer_datas = {
        address: sensorBoxDetails?.place ? sensorBoxDetails?.place : null,
        phone: customerDetails?.consumerDetails ? customerDetails?.consumerDetails?.phonenumber : null,
        email: customerDetails?.consumerDetails ? customerDetails?.consumerDetails?.email : null,
      }

      let datas = { ...issuesTracker.issue, ...customer_datas }
      setIssueDetails(datas)
      imageData(datas)
      setLoading(false)
    } catch (error: any) {
      const errorData = error?.response?.data
        const isUnauthorizedIssue = Utils.issueTrackerErrorHandler(errorData, constants.responseCode.unAuthorized, constants.message.issue_allocation_changed, constants.message.issue_doesnot_exist)
        if (isUnauthorizedIssue) {
            message.destroy()
            await message.error(error?.response?.data?.message)
            history.push({pathname : Routes.issuetracker});
            setLoading(false)
        } else {
            message.destroy()
            message.error(error?.response?.data?.message || constants.message.internalServerError)
            setLoading(false)
        }
    }

  }

  const onClickBreadCrumpItem = () => {
    history.push({
      pathname: `${Routes.issuetracker}`
    });
  }

  const onCreateTicket = async (values: any) => {
    setIsLoading(true)
    try {
      let datas = {
        sensor_box: issueDetails?.sensor_box?._id,
        contractor: values?.contractor,
        alert_status: issueDetails?.alert_status,
        alert_code: issueDetails?.alert_code,
        issue_title: values?.issue_title,
        issue_priority: values?.issue_priority,
        issue_description: values?.issue_description
      }

      let issues = await issueTrackerServices.addIssueTrackerDetails(datas, org_id)
      let issue_id = issues?.data?.id
      setIssueId(issue_id)
      message.destroy()
      message.success("Successfully Created Ticket")

      let params = `?ticket_id=${issue_id}`

      if (orgCondition) {
        params += `&org_id=${org_id}`
      }
      setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    } catch (error) {
      setIsLoading(false)
      message.destroy()
      message.error(error?.response?.data?.message)
    }
  }

  const onClickCancelTicket = () => {
    history.push({
      pathname: `${Routes.issuetracker}`,
    });
  }

  const onResolveTicket = async (values: any, action: any) => {
    try {
      let issues;
      setLoading(true)
      if (action === "savechanges") {
        issues = await issueTrackerServices.saveChangesIssue(issueDetails?.issue_id, values, org_id)
        message.destroy()
        message.success("Successfully Saved Ticket")
      } else {
        issues = await issueTrackerServices.resolveIssue(issueDetails?.issue_id, values, org_id)
        message.destroy()
        message.success((issueDetails?.status === "OPEN") ? "Successfully Completed Diagnosis" :  "Successfully Resolved Ticket")
      }

      fetchIssueDetailsWithId(issues?.id)
      setLoading(false)

    } catch (error: any) {
      const errorData = error?.response?.data
      const isUnauthorizedIssue = Utils.issueTrackerErrorHandler(errorData, constants.responseCode.unAuthorized, constants.message.issue_allocation_changed, constants.message.issue_doesnot_exist)
      if (isUnauthorizedIssue) {
          message.destroy()
          await message.error(error?.response?.data?.message)
          history.push({pathname : Routes.issuetracker});
          setLoading(false)
      } else {
          message.destroy()
          message.error(error?.response?.data?.message)
          setLoading(false)
      }
    }
  }
  const onCreateSubTicket = async () => {
        let orgid = org_id ? org_id : issueDetails?.sensor_box?.org_id

        let params = `?system_id=${issueDetails?.sensor_box?._id}`

        if(authStore?.currentUser?.active_role?.role_type === 0 || (authStore?.currentUser?.active_role?.role_type === 1 && authStore.currentUser.is_default)){
            params += `&org_id=${orgid}`
        }

        history.push({
            pathname: `${Routes.issuetrackerdetails}`,
            search: params,
          });
  }


  const onCloseTicket = async (values: any, action: any) => {
    try {
      let issues;
      setLoading(true)

      if (action === "savechanges") {
        issues = await issueTrackerServices.saveChangesIssue(issueDetails?.issue_id, values, org_id)
        message.destroy()
        message.success("Successfully Saved Ticket")
      } else {
        issues = await issueTrackerServices.closeIssue(issueDetails?.issue_id, values, org_id)
        message.destroy()
        message.success("Successfully Closed Ticket")
      }

      fetchIssueDetailsWithId(issues?.id)
      setLoading(false)

    } catch (error: any) {
      const errorData = error?.response?.data
      const isUnauthorizedIssue = Utils.issueTrackerErrorHandler(errorData, constants.responseCode.unAuthorized, constants.message.issue_allocation_changed, constants.message.issue_doesnot_exist)
      if (isUnauthorizedIssue) {
          message.destroy()
          await message.error(error?.response?.data?.message)
          history.push({pathname : Routes.issuetracker});
          setLoading(false)
      } else {
          message.destroy()
          message.error(error?.response?.data?.message)
          setLoading(false)
      }
    }
  }

  const onIgnoreTicket = async () => {
    try {
      setLoading(true)
      let data = {
        ignored_by: authStore?.currentUser?.uid
      }

      await issueTrackerServices.ignoreIssue(issueDetails?.issue_id, data, org_id)
      message.destroy()
      message.success("Successfully Ignored Ticket")

      history.push({
        pathname: `${Routes.issuetracker}`,
      });

      setLoading(false)

    } catch (error) {
      setLoading(false)
      message.destroy()
      message.error(error?.response?.data?.message)
    }
  }

  const onEditTicket = () => {
    setEditModalVisible(true)
  }

  const onDelete = () => {
    //do nothing
  }

  const onClickEditCancel = () => {
    setEditModalVisible(false)
    fetchIssueDetailsWithId(ticket_id)
  }

  const onDeleteTicket = async () => {
    try {
      setLoading(true)
      await issueTrackerServices.deleteIssue(issueDetails?._id, org_id)
      message.destroy()
      message.success("Successfully Deleted Ticket")
      setLoading(false)
      history.push({
        pathname: `${Routes.issuetracker}`,
      });

    } catch (error) {
      setLoading(false)
      message.destroy()
      message.error(error?.response?.data?.message)
    }
  }

  const handleEditTicket = async (values: any, issueId?: any) => {
    try {
      setLoading(true)
      let issues = await issueTrackerServices.updateIssueTrackerDetails(issueId ? issueId :  issueDetails?._id, values, org_id)
      message.destroy()
      message.success(constants.message.edit_ticket_success)
      setEditModalVisible(false)

      await fetchIssueDetailsWithId(issues?.id)
      setLoading(false)
    } catch (error: any) {
      const errorData = error?.response?.data
      const isUnauthorizedIssue = Utils.issueTrackerErrorHandler(errorData, constants.responseCode.unAuthorized, constants.message.issue_allocation_changed, constants.message.issue_doesnot_exist)
      if (isUnauthorizedIssue) {
          setEditModalVisible(false)
          message.destroy()
          await message.error(error?.response?.data?.message)
          history.push({pathname : Routes.issuetracker});
          setLoading(false)
      } else {
          message.destroy()
          await message.error(error?.response?.data?.message)
          setLoading(false)
      }
    }
  }

  return (
    <div className="p-0">
      <Spin spinning={loading}>
        <div className="d-flex flex-column h-100 overflow-hidden p-0">
          <div className="flex-fill h-100 issue-tracker-padding">
            <div className="d-flex">
              <div className="flex-fill p-0 m-2">
                <Breadcrumb className='mb-4' >
                  <Breadcrumb.Item onClick={onClickBreadCrumpItem} className='ant-badge-count-sm'>
                    <a href="/" onClick={(e: any) => { e.preventDefault(); }}>Issue Tracker</a>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>{system_id ? `Create Ticket` : issueDetails?.issue_id}</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="d-lg-flex">
              <div className="flex-custom-fill mr-md-2">
                <div className="d-flex flex-column h-100">
                  <div className="flex-fill">
                  </div>
                  <div className="flex-auto">
                    <Row gutter={[16, 16]}>
                    {system_id &&

                      <>
                      <div className='p-2'>
                          <p className="font-w-600" style={{ fontSize: "16px" }}>Customer</p>
                          <p className="pt-1 paragraph">
                            {issueDetails?.consumer && issueDetails?.consumer?.length && issueDetails?.consumer[0] ? issueDetails?.consumer[0]?.name
                              : `None`}
                          </p>
                        </div><Divider
                            type="vertical" className='mr-3'/><div className='p-2'>
                            <p className="font-w-600" style={{ fontSize: "16px" }}>Address</p>
                            <p className="pt-1 paragraph">
                              {issueDetails?.address ? issueDetails?.address : `None`}
                            </p>
                          </div><Divider
                            type="vertical" className='mr-3'/><div className='p-2'>
                            <p className="font-w-600" style={{ fontSize: "16px" }}>Phone</p>
                            <p className="pt-1 paragraph">
                              {issueDetails?.phone ? issueDetails?.phone : `None`}
                            </p>
                          </div><Divider
                            type="vertical" className='mr-3'/><div className='p-2'>
                            <p className="font-w-600" style={{ fontSize: "16px" }}>Email</p>
                            <p className="pt-1 paragraph">
                              {issueDetails?.email ? issueDetails?.email : `None`}
                            </p>
                          </div><Divider
                            type="vertical" className='mr-5'/>
                    {/* {authStore?.currentUser?.active_role?.role_type === constants.role.Contractor ? 
                     '' :
                      <div className='p-2'>
                        <p className="font-w-600" style={{ fontSize: "16px" }}>Contractor</p>
                        <p className="pt-1 paragraph">
                          {issueDetails?.contractor && issueDetails?.contractor?.length && issueDetails?.contractor[0] ? issueDetails?.contractor[0]?.name 
                          : `None`}
                        </p>
                      </div>
                  } */}
                  </>
                }

                      {system_id &&
                        <IssueTicketDetails
                          issueDetails={issueDetails?.status === 'INCOMPLETE' ? [] : issueDetails}
                          onCreateTicket={(values: any) => onCreateTicket(values)}
                          onClickEdit={(values: any, issueId?: any) => handleEditTicket(values, issueId)}
                          onClickCancel={onClickCancelTicket}
                          onDelete={onDelete}
                          issueId={issueId}
                          isLoading={isLoading}
                          org_id={org_id}
                          contractorList={contractorList}
                        />
                      }

                      {ticket_id &&
                        <IssueDetailsCard
                          issueDetails={issueDetails}
                          onEditTicket={onEditTicket}
                          contractorList={contractorList} 
                          fetchIssueDetailsWithId={() => fetchIssueDetailsWithId(issueDetails?._id)}
                          imageList={imageList}
                          imageNotes={imageNotes}
                          imageCreatedBy={imageCreatedBy}
                          userType={userType}
                          skeletonOn={skeletonOn}
                          ticket_id={ticket_id}
                          onClickCancelTicket={onClickCancelTicket}
                          onIgnoreTicket={onIgnoreTicket}
                          onResolveTicket={(values: any, action: any) => onResolveTicket(values, action)}
                          onCreateSubTicket={() => onCreateSubTicket()}
                          technicianUnderContractor={technicianUnderContractor}
                          />
                      }

                      <IssueEditTicket
                        visible={editModalVisible}
                        issueDetails={issueDetails}
                        onClickCancel={onClickEditCancel}
                        onDelete={onDeleteTicket}
                        onClickEdit={(values: any) => handleEditTicket(values)}
                        contractorList={contractorList}
                      />


                      {(ticket_id && (issueDetails?.status === "RESOLVED")) && authStore?.currentUser?.active_role?.role_type === constants.role.Admin &&
                        <IssueCustomerBenefitsAndRevenue
                          issueDetails={issueDetails}
                          onCloseTicket={(datas: any, action: any) => onCloseTicket(datas, action)}
                          softSavingsOptions={softSavings}
                          orgAdminUsers={orgAdminUsers}
                          onCancel={onClickCancelTicket}
                        />
                      }

                      {ticket_id && issueDetails?.status === "CLOSED" &&
                        <IssueBenefitsCard
                          issueDetails={issueDetails}
                          orgAdminUsers={orgAdminUsers}
                        />
                      }

                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}
export default IssueTrackerDetails;
