import Api from '../api';
import { AlgorithmConfig } from '../interface';

class AlgorithmConfigServices {

    getAlgorithmConfig = async (pageNumber?: number, limit?: number, typeFilter?: string, searchFilter?: string) => {
        return Api.get(`/algorithm_config?page=${pageNumber ? pageNumber : 1}&limit=${limit ? limit : 0}&type=${typeFilter??""}&search=${searchFilter??""}&algorithm_type=chiller-larger`)
    }
    addAlgorithmConfig = async (data: AlgorithmConfig) => {
        return Api.post('/algorithm_config', data)
    }
    updateAlgorithmConfig = async (data: AlgorithmConfig ,id? : string) => {
        return Api.put(`/algorithm_config/${id}`, data)
    }
    deleteAlgorithmConfig = async (id: String) => {
        return Api.delete(`/algorithm_config/${id}?algorithm_type=chiller-larger`)
    }
    isAlgorithmCodeExists = async (code: string, id?: string) => {
        return Api.get(`/algorithm_config_exists/${code}?id=${id || ''}&algorithm_type=chiller-larger`)
    }   

    testAlgorithmConfig = async (data: any) => {
        return Api.post('/test-algorithm-config', data)
    }

    pinRepresentations = async () => {
        return Api.get(`/pin-representation`)
    }   
}

export default new AlgorithmConfigServices();
