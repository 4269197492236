import React, { useRef } from 'react';
import { List, Row, Col, Button, Tooltip, Divider } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import ImageNoteView from './ImageNoteView'; // Import your ImageNoteView component
// import "./styles/customCaracel.css"
const NoteItem = ({ note }) => {
  const carouselRef = useRef(null);

  const prevSlide = () => {
    carouselRef?.current?.prev();
  };

  const nextSlide = () => {
    carouselRef?.current?.next();
  };

  return (
    <List.Item>
      <div className="flex-column w-100">
        <Row>
          <h5>{note.dateFormatted}</h5>
        </Row>
        <Row>
          <p className='overflow-auto'>{note.text}</p>
        </Row>
        <Row className="w-100 justify-content-center flex-gap">
          <Col className="d-flex flex-wrap align-content-center">
            {note.attachments && note.attachments?.length > 1 && (
              <Tooltip title="previous">

                <Button onClick={prevSlide} shape="circle" icon={<LeftOutlined />} />
              </Tooltip>
            )}
          </Col>
          <Col style={{ display: 'block', maxWidth: "40%", minWidth: "20em" }}>
            {note.attachments && (
              <ImageNoteView carouselRef={carouselRef} imageIDArray={note.attachments} />
            )}
          </Col>
          <Col className="d-flex flex-wrap align-content-center">
            {note.attachments && note.attachments?.length > 1 && (
              <Tooltip title="next">
                <Button onClick={nextSlide} shape="circle" icon={<RightOutlined />} />
              </Tooltip>
            )}
          </Col>
        </Row>
        <Divider className="bg-dark" dashed />
      </div>
    </List.Item>
  );
};

const ViewNote = (props) => {
  let { notes } = props;
    notes = notes.map((note) => {
    const formattedDates = new Date(note.dateAdded)
      .toLocaleString(
        'en-US',
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }
      );
    return { ...note, dateFormatted: formattedDates };
  }).sort((a, b) => b.dateAdded - a.dateAdded);
  return (
    <List
      dataSource={notes}
      renderItem={(note) => <NoteItem note={note} />}
      itemLayout='horizontal'
      pagination={{
        pageSize: 2,
      }}
      size='small'
    />
  );
};

export default ViewNote;