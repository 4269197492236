import React from "react";
import {
  Row,
  Col,
  Card,
  Select,
  Pagination,
  Typography,
  Tabs,
  Collapse,
  DatePicker,
  Button,
} from "antd";
import Icon from "../../components_new/icon";
import Subsystem from "../../components_new/subsystem";
import SystemTable from "../../components_new/system-table";
import Maintenance from "../../components_new/maintenance";
const { Text } = Typography;
const { TabPane } = Tabs;
const { Panel } = Collapse;
function callback(key: any) {
  console.log(key);
}
const { Option } = Select;

function SystemDetail() {
  return (
    <>
      <div className="d-flex flex-column h-100 overflow-hidden">
        <div className="flex-fill h-100 overflow-x-hidden overflow-y-auto">
          <div className="d-lg-flex">
            <div className="flex-custom-fill mr-md-2">
              <div className="d-flex flex-column h-100">
                <div className="flex-auto">
                  <Row gutter={{ xs: 8, sm: 16 }}>
                    <Col className="gutter-row mb-3" xs={24} md={12}>
                      <Card className="custom-card online-device border-0">
                        <div className="d-flex">
                          <div className="flex-auto mr-3">
                            <div className="circle-icon">
                              <Icon className="icon-22" iconRef="#offline" />
                            </div>
                          </div>
                          <div className="flex-fill">
                            <Text className="mb-1">Contrator details</Text>
                            <p className="mb-0">Adress</p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col className="gutter-row mb-3" xs={24} md={12}>
                      <Card className="custom-card online-device border-0">
                        <div className="d-flex">
                          <div className="flex-auto mr-3">
                            <div className="circle-icon">
                              <Icon className="icon-22" iconRef="#online" />
                            </div>
                          </div>
                          <div className="flex-fill">
                          <Text className="mb-1">Contrator details</Text>
                            <p className=" mb-0">01/11/2021 12:23:09</p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div className="flex-fill mb-3">
                  <h6 className="page-title">Subsystem Details</h6>
                  <Row gutter={{ xs: 8, sm: 16 }}>
                    <Col className="mb-3" xs={24} lg={12}>
                      <Subsystem />
                    </Col>
                    <Col className="mb-3" xs={24} lg={12}>
                      <Subsystem />
                    </Col>
                    <Col className="mb-3" xs={24} lg={12}>
                      <Subsystem />
                    </Col>
                    <Col className="mb-3" xs={24} lg={12}>
                      <Subsystem />
                    </Col>
                    <Col className="mb-3" xs={24} lg={12}>
                      <Subsystem />
                    </Col>
                    <Col className="mb-3" xs={24} lg={12}>
                      <Subsystem />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div className="flex-custom-fill ml-md-2 mb-3">
              <Tabs defaultActiveKey="1">
                <TabPane className="no-input-border" tab="Parameters" key="1">
                  <Row gutter={{ xs: 8, sm: 16 }}>
                    <Col className="gutter-row mb-3" xs={24} md={6}>
                      <DatePicker className="w-100" />
                    </Col>
                    <Col className="gutter-row mb-3" xs={24} md={6}>
                      <DatePicker className="w-100" />
                    </Col>
                    <Col className="gutter-row mb-3" xs={24} md={8}>
                      <Select defaultValue="phase" className="w-100">
                      <Option value="phase">Select Parameter</Option>
                        <Option value="phase 1">Compressor current phase 1</Option>
                        <Option value="phase 2">Compressor current phase 2</Option>
                      </Select>
                    </Col>
                    <Col className="gutter-row mb-3" xs={24} md={4}>
                      <Button type="primary">View Graph</Button>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Power consumption" key="2">
                  <Row gutter={{ xs: 8, sm: 16 }}>
                    <Col className="gutter-row mb-3" xs={24} md={12}>
                      <Card className="custom-card">
                        <div className="d-flex align-items-center parameter-updates down">
                          <p className="mb-0 mr-2 small">Last Week:</p>{" "}
                          <h5 className="mb-0 mr-2">41.4kWh</h5>{" "}
                          <Icon
                            className="icon-12 progress-ic"
                            iconRef="#power_down"
                          />
                        </div>
                      </Card>
                    </Col>
                    <Col className="gutter-row mb-3" xs={24} md={12}>
                    <Card className="custom-card">
                        <div className="d-flex align-items-center parameter-updates up">
                          <p className="mb-0 mr-2 small">Last Week:</p>{" "}
                          <h5 className="mb-0 mr-2">41.4kWh</h5>{" "}
                          <Icon
                            className="icon-14 progress-ic"
                            iconRef="#power_up"
                          />
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </div>
          </div>
          <div className="d-flex flex-column flex-md-row align-items-sm-center mt-3">
            <div className="flex-fill sub-system-tab">
              <Tabs defaultActiveKey="1">
                <TabPane tab="System Details" key="1">
                  <Collapse defaultActiveKey={["1"]} onChange={callback}>
                    <Panel header="Contactor 1" key="1">
                      <SystemTable />
                    </Panel>
                    <Panel header="Contactor 2" key="2">
                      <SystemTable />
                    </Panel>
                    <Panel header="Contactor 4" key="3">
                      <SystemTable />
                    </Panel>
                  </Collapse>
                </TabPane>
                <TabPane tab="Maintenance" key="2">
                <Tabs className="maintenance-tab" defaultActiveKey="7">
                <TabPane className="no-input-border" tab="History" key="7">
                  <Maintenance/>
                </TabPane>
                <TabPane className="no-input-border" tab="Checklist" key="8">
                  
                </TabPane>
                </Tabs>
                </TabPane>
                <TabPane tab="Alert log" key="3">
                  Inspection Content of Tab Pane 3
                </TabPane>
                <TabPane tab="Inspection" key="4">
                  Content of Tab Pane 3
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
        <div className="flex-auto pagination-component">
          <Pagination defaultCurrent={1} total={50} />
        </div>
      </div>
    </>
  );
}
export default SystemDetail;
