import React, { useEffect, useState, FormEvent } from 'react';
import { Modal } from 'react-bootstrap';
import userStore from '../../store/userStore';
import toast from '../../components/toast';
import groupServices from '../../services/groupServices';
import userService from '../../services/userService';
import constants from '../../config/constants';
import authStore from '../../store/authStore';
import PlusBtn from '../../assets/add.png';
import NoSensorBoxSelected from '../../assets/sensor_empty.png';
import Close from '../../assets/close.png';
import Select from '../../components/select';
import './CreateGroupModal.css';
import  Permissions from '../../config/permssions'
import {SensorBox} from "../../interface";

const {
  view_contractor_sensorboxes_assets,
  view_assigned_users_sensorboxes_assets,
  create_or_update_sensorboxes_assets,
  delete_sensorboxes_assets,
  view_only_partial_asset_sensorbox_details
} = Permissions

interface Props {
  show: boolean;
  group?: any;
  editMode?: boolean;
  onClose: () => void;
  onSave?: () => void;
  onDelete?: () => void;
  // note : temp solution for the org admin access to all org
  organisationId? :   any;
}
// note : temp solution for the org admin access to all org
const CreateGroupModal: React.FC<Props> = ({ show, onClose, group, editMode, onSave, onDelete ,organisationId }) => {

  const [sensorboxList, setSensorboxList] = useState<Array<any>>([]);
  const [contractorList, setContractorList] = useState<Array<any>>([]);
  const [consumerList, setConsumerList] = useState<Array<any>>([]);
  const [formData ,setFormData] =useState<any>({})
  const [name, setName] = useState('');
  const [sensorboxes, setSensorboxes] = useState<Array<any>>([]);
  const [mergeTimeout, setMergeTimeout] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [firstBox, setFirstBox] = useState<SensorBox>();
  const [processing, setProcessing] = useState(false);
  const [editConsumer, setEditConsumer] = useState(false);
  const [editContractor, setEditContractor] = useState(false);
  const [userAccess ,setUserAccess] = useState<any>([]);
  const [showPower , setShowPower] = useState(false);
  const [errors, setErrors] = useState<any>({});

  useEffect(()=> {
    if(show) {
      if(group) {
        setName(group?.name);
        setFormData(group)
        setSensorboxes(group?.sensorboxes);
        setShowPower(group?.show_power_calculation)
        setMergeTimeout(group?.group_merge_timeout);
        if(group?.sensorboxes?.length) {
          setFirstBox(group?.sensorboxes[0])
        }
      }
      fetchSensorboxes();
      getCustomerList()
      getContractorList()
    }else{
      clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);


  const clear = () => {
    setName('');
    setMergeTimeout('');
    setSensorboxes([]);
    setFormData({})
    setEditConsumer(false)
    setEditContractor(false)
    setErrors({});
    setFirstBox(undefined);
    setSearchKeyword('');
  }
  

  const fetchSensorboxes = async () => {
    try {
      // note : temp solution for the org admin access to all org
      const resp = await groupServices.getSensorboxesForGroup(organisationId);
      if(resp) {
        let data = resp.data;
        if(group) {
          data = [ ...group?.sensorboxes, ...data ];
        }
        setSensorboxList(data);
      }
    } catch (error) {
      let errMsg = error?.message;
      if(error?.response?.data?.message) {
        errMsg = error?.response?.data?.message
      }
      toast.error(errMsg);
    }
  }

  const isGroupNameExist = async () => {
    if(!name?.trim()) return;
    try {
      // note : temp solution for the org admin access to all org
      const resp = await groupServices.isGroupNameExist(name, group?._id , organisationId);
      const { group_exist_status } = resp.data;
      let errorMsg = group_exist_status ? constants.message.group_name_exists : '';  
      setErrors((prev: any) => ({...prev, name: errorMsg }));
      return group_exist_status;
    } catch (error) {}
  }

  const getContractorList = async () => {       
    try{
        let contractorList = []
        let selectedItem;
        if(authStore?.currentUser?.access?.includes(view_contractor_sensorboxes_assets)) {
          selectedItem = {_id : authStore.currentUser.uid, name : authStore.currentUser.name}
          setContractorList([selectedItem])
        }else if(authStore?.currentUser?.access?.includes(view_assigned_users_sensorboxes_assets)){
            selectedItem = userStore.profileDetials && userStore.profileDetials.assigned_to;
            setContractorList([selectedItem])
        }else{
          // note : temp solution for the org admin access to all org
            let orgid = ''
            if(group?.org_id){
              orgid = group?.org_id
            }else{
              orgid = organisationId
            }
            contractorList = await userService.getContractorList(orgid)
            setContractorList(contractorList)
        }                        
    }catch(error){
        toast.error(error.response.data.message)
    } 
  }

  const getCustomerList = async()  =>{     
    try{
      // note : temp solution for the org admin access to all org
      let orgid = ''
      if(group?.org_id){
        orgid = group?.org_id
      }else{
        orgid = organisationId
      }
        let consumerList = await userService.getCustomerList(orgid)
        setConsumerList(consumerList)
        if(group?.user_access){
          const userAccessArray = consumerList?.filter((item:any)=>(
            group?.user_access?.includes(item?._id)
          ))  
          const filteredUserAccess = userAccessArray?.map((item:any)=>({value:item._id,label:item.name})) 
          setUserAccess(filteredUserAccess)
        }

    }catch(error){
        toast.error(error.response.data.message)
    }       
  }

  const createGroup = async (e: FormEvent<HTMLFormElement>)=> {
    e.preventDefault();
    try {
      if(validate()) return false;
      
      const isNameExist = await isGroupNameExist();
      if(isNameExist) return false;

      const userAccesValue = userAccess?.map((item :any) => item.value)

      const params = {
        name,
        sensorboxes: sensorboxes.map(s => s?._id),
        group_merge_timeout: parseInt(mergeTimeout),
        consumer: editMode ? formData?.consumer : firstBox?.consumer,
        contractor: editMode ? formData?.contractor : firstBox?.contractor,
        user_access : editMode ? userAccesValue : userAccesValue,
        show_power_calculation : showPower
      }
      setProcessing(true);
      if(editMode) {
        // note : temp solution for the org admin access to all org
        await groupServices.editGroup(group?._id, params , organisationId);
      }else {
        // note : temp solution for the org admin access to all org
        await groupServices.createGroups(params, organisationId);
      }
      setProcessing(false);
      onClose()
      if(onSave) onSave();
    } catch (error) {
      setProcessing(false);
      let errMsg = error?.message;
      if(error?.response?.data?.message) {
        errMsg = error?.response?.data?.message
      }
      toast.error(errMsg);
    }
  }

  const onChooseSensorbox = (sensorbox: any) => {
    clearFieldError('sensorboxes')
    if(sensorboxes.length === 0) {
      setFirstBox(sensorbox);
    }
    if(searchKeyword) setSearchKeyword(''); // clear the searchbox after selection
    setSensorboxes((prev) => [...prev, sensorbox])
  }

  const clearFieldError = (field: string) => {
    if(errors[field]) {
      setErrors((prev: any) => ({ ...prev, [field]: ''}))
    }
  }

  const handleContractorSelection = (option: any) => {
    clearFieldError('contractor')
    setFormData((prevState :any )=> ({
        ...prevState,
        'contractor': convertDropdownData(option,'r')
    }));
  }

  const handleConsumerSelection = (option: any) => {
    clearFieldError('consumer')
    setFormData((prevState :any )=> ({
        ...prevState,
        'consumer': convertDropdownData(option,'r')
    }));
  }

  const validate = () => {
    const errors: any = {};
    if(!name?.trim()) {
      errors.name = constants.message.group_name_mandatory;
    }

    if(!mergeTimeout) {
      errors.group_merge_timeout = constants.message.group_merge_timeout_required;
    }else if(parseInt(mergeTimeout) < 1 || parseInt(mergeTimeout) > 60) {
      errors.group_merge_timeout = constants.message.group_merge_timeout_valid_range;
    }

    if(sensorboxes?.length < 1 ) {
      errors.sensorboxes = constants.message.group_sensorboxes_min
    }else if(sensorboxes.length > 15){
      errors.sensorboxes = constants.message.group_sensorboxes_max
    }

    setErrors(errors);
    return Object.keys(errors).some(field => field !== '')
  }

  const removeSensorbox = (sensorbox: any) => {
    const updated = sensorboxes.filter(s => s?._id !== sensorbox?._id);
    if(updated.length === 0) {
      setFirstBox(undefined);
    }
    setSensorboxes(updated);
  }

  const updatePowerCalculationPermission = (e: any) => {
    setShowPower(e.target.checked)
  }

  let list = sensorboxList.filter(s => (sensorboxes.findIndex(sb => sb?._id === s?._id) < 0) );
  if(searchKeyword) {
    const skw= searchKeyword.toLowerCase()
    list = list?.filter(s => s?.sensor_box_name?.toLowerCase().includes(skw) ||
    s?.alias?.toLowerCase().includes(skw) ||
      s?.consumer?.some((i:any)=>i?.name.toLowerCase().includes(skw)) ||
        s?.contractor?.some((i:any)=>i?.name.toLowerCase().includes(skw))
    )
  }

  if(firstBox) {
    list = list?.filter(s =>  !(firstBox?.consumer?.some((i:any)=> i?._id === s?.consumer?._id)) &&
       !(firstBox?.contractor?.some((i:any)=> i?._id===s?.contractor?._id ))
    );
  }

  const listClassName = ['sensorbox-list p-0']
  const sensorBoxClassName = ['d-flex align-items-center bg-light rounded p-2 mb-2 mr-2']
  if(!authStore?.currentUser?.access?.includes(create_or_update_sensorboxes_assets)){
    listClassName.push('group-disabled')
    sensorBoxClassName.push('group-disabled')
  }

  const handleEditConsumer = (event : any) => {
    setEditConsumer(event.target.checked)
  }

  const handleEditContractor = (event : any) => {
    setEditContractor(event.target.checked)
  }

  const customerData = (customers : any) => {

    let filteredUsers = customers?.filter((customer : any) => {
      const role = customer?.roles?.filter((role : any) => role?.role_type > 1)
      return role.length > 0
    })

    return filteredUsers
  }

  const demoCheck = (authStore?.currentUser?.active_role?.role_type === -1);

  const handleUserAccessSelection  = (option : any) => {
    setUserAccess(option)
  };
  const convertDropdownData = (data:any, direction:string)=>{
    switch (direction){
      case 'f': return data?.map((i:any)=>({value: i?._id,label: i?.name}))?? []
      case 'r': return data?.map((i:any)=>({_id:i?.value,name:i?.label}))?? []
    }
  }
  return (
    <Modal
      show={show}
      onHide={onClose}
      className="create-group-modal"
      backdrop="static"
    >
      <Modal.Header className="py-2 px-3" closeButton>

      {editMode ? authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) ? 'View Asset Details' :  !authStore?.currentUser?.access?.includes(create_or_update_sensorboxes_assets) ? name : 'Edit Asset' : 'Create Asset'}
      </Modal.Header>
      <Modal.Body>
        <div className="" id="create-group-modal-body">
          <form onSubmit={createGroup}>
            <div className="mb-2">
              <label htmlFor="">Name <span className="mand-star">*</span></label>
              <input 
                className="form-control"
                type="text"
                placeholder="Enter name"
                value={name}
                disabled = {!authStore?.currentUser?.access?.includes(create_or_update_sensorboxes_assets) || authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details)}
                onChange={(e)=>{
                  clearFieldError('name');
                  setName(e.target.value);
                }}
                onBlur={isGroupNameExist}
                maxLength={50}
              />
              <div className="error-msg">{errors?.name}</div>
            </div>
            <div className="mb-2">
              <label htmlFor="">Asset merge timeout (1-60) minute <span className="mand-star">*</span></label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter timeout (1-60) minute"
                value={mergeTimeout}
                disabled = {!authStore?.currentUser?.access?.includes(create_or_update_sensorboxes_assets) || authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details)}
                onChange={(e)=> {
                  clearFieldError('group_merge_timeout');
                  setMergeTimeout(e.target.value)
                }}
                max={60}
                min={1}
              />
              <div className="error-msg">{errors?.group_merge_timeout}</div>
            </div>
          { editMode && 
            <div className="row">
              <div className={authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) ? "col-md-12" : "col-md-6"}>
                <div className="form-group">
                  <label className="custom-label" >Consumer and Team</label>
                  {!authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) && 
                  <div className="edit-role-group">
                            <input 
                                type="checkbox"
                                className="mr-2"
                                disabled = {demoCheck}
                                checked={editConsumer}
                                onChange={handleEditConsumer}
                            />
                            <label className="custom-label">
                              Edit Consumer Team
                            </label>
                  </div>
                  }
                  <Select  name="consumer" 
                           isDisabled = { !authStore?.currentUser?.access?.includes(create_or_update_sensorboxes_assets)  || !editConsumer}
                           data = { consumerList || '' } 
                           value={ demoCheck ? '' : ((convertDropdownData(formData?.consumer,'f'))?.length !== 0 && (convertDropdownData(formData?.consumer,'f'))[0]?.value) ? convertDropdownData(formData?.consumer,'f') :  '' }
                           onChange={handleConsumerSelection}
                           isMulti={true}
                  />
                </div>           
              </div>
          {!authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) &&
              <div className="col-md-12">
                <div className="form-group">
                  <label className="custom-label" >Property Mgmt/Service Provider and Team</label>
                  <div className="edit-role-group">
                            <input 
                                type="checkbox"
                                disabled = {demoCheck}
                                className="mr-2"
                                checked={editContractor}
                                onChange={handleEditContractor}
                            />
                            <label className="custom-label">
                              Edit Team Member(s)
                            </label>
                  </div>
                  <Select  isMulti={true}
                           name="contractor"
                           isDisabled = { !authStore?.currentUser?.access?.includes(create_or_update_sensorboxes_assets) || !editContractor}
                           data = { contractorList || '' } 
                           value={ demoCheck ? '' : convertDropdownData(formData?.contractor,'f')  }
                           onChange={handleContractorSelection}

                  />
                </div>
              </div>
              }
            </div>
            }

            <div className="mb-4">
              {authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) ?
              <label htmlFor="">Sensor boxes <span className="mand-star">*</span></label>
              :
              <label htmlFor="">Add sensor boxes <span className="mand-star">*</span></label>
              }
              <div className="border rounded">
                <div className="row no-gutters">
                  {!authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) &&
                  <div className="col-5 border-right">
                    <div className="border-bottom ">
                      <input
                        type="text"
                        placeholder="Search"
                        className="form-control border-0 rounded-0"
                        value={searchKeyword || ''}
                        disabled = { !authStore?.currentUser?.access?.includes(create_or_update_sensorboxes_assets)}
                        onChange={(e) => setSearchKeyword(e.target.value)}
                        onKeyDown={(e) => {
                          if(e.which === 13) {
                            e.preventDefault()
                          }
                        }}
                      />
                    </div>
              
                    <ul className={listClassName.join(' ')}>
                      {
                        list.map((sensorbox: any) => (
                          <li key={sensorbox?._id} className="sensorbox-list-item" onClick={()=> onChooseSensorbox(sensorbox)}>
                            <img className="mr-2" src={PlusBtn} style={{height: 12}} alt=''/>
                            <span>{sensorbox?.sensor_box_name}</span>{sensorbox?.alias && <span className='text-truncate'>&nbsp;&nbsp;( {sensorbox?.alias} )</span>}
                          </li>
                        ))
                      }
                      {
                       (list.length === 0) &&
                        <li className="text-center py-5 text-secondary">
                          No sensor boxes
                        </li>
                      }
                    </ul>
                
                  </div>
                  }
                  <div className={!authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) ? "col-7" : "col-12"}>
                      {
                        sensorboxes?.length === 0 && (
                          <div className="d-flex h-100 p-3">
                            <div className="m-auto text-center">
                              <img className="mb-2" src={NoSensorBoxSelected} height={48} alt=''/>
                              <p className="m-0 text-secondary">No sensor boxes 
                              <br/>{!authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) ? 'selected' : ''}</p>
                            </div>
                          </div>
                        )
                      }
                      <div className="d-flex flex-wrap p-3">
                        {
                          sensorboxes?.map((sensorbox: any) => (
                            <div key={sensorbox?._id} className={sensorBoxClassName.join(' ')}>
                              {sensorbox?.sensor_box_name}
                              <img
                                src={!authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) ? Close : ''}
                                className={!authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) ? "ml-2 cursor-pointer" : "ml-2"}
                                height="10"
                                onClick={() => removeSensorbox(sensorbox)}
                                alt=''
                              />
                            </div>
                          ))
                        }
                      </div>
                  
                  </div>
                </div>
              </div>
              {errors?.sensorboxes && <div className="error-msg">{errors?.sensorboxes}</div>}
            </div>

            <div className="row">
              {!authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) &&
              <div className="col-md-12">
                              <div className="input-text-field">
                                  <div className="form-group">
                                      <label className="custom-label"> User Access </label>
                                          <Select 
                                              isMulti = {true}
                                              name="useraccess"
                                              isDisabled={!authStore?.currentUser?.access?.includes(create_or_update_sensorboxes_assets)}
                                              data={ customerData(consumerList) }
                                              value={demoCheck ? '' : userAccess}
                                              onChange={(option :any) => handleUserAccessSelection(option)}
                                              maxOptions={15}
                                          />
                                  </div>
                              </div>
              </div>
          }
          {!authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) &&
              <div className="col-md-12 mb-3">
                        <div className="form-inline">
                            <input 
                                type="checkbox"
                                className="mr-3"
                                checked={showPower || false}
                                disabled={!authStore?.currentUser?.access?.includes(create_or_update_sensorboxes_assets)}
                                onChange={updatePowerCalculationPermission}
                            />
                            <label className="custom-label" style={{ fontSize: '1rem' }}>
                                Show power calculations
                            </label>
                        </div>
              </div>
          }
            </div>
 
            {!authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) &&
            <button
              className="btn btn-primary btn-sm rounded-0 btn-dark-blue"
              disabled={processing || demoCheck}
            >
              {editMode ? 'Save' : 'Create'}
            </button>
            } 
            {
              editMode && authStore?.currentUser?.access?.includes(delete_sensorboxes_assets) && !authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) &&
              <button 
                className="btn bg-white btn-sm rounded-0 ml-3"
                disabled={demoCheck}
                onClick={(e) => {
                  e.preventDefault();
                  if(onDelete) onDelete()
                }}
              >
                <span className="text-secondary">Delete</span> 
              </button>
            }
          </form>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CreateGroupModal;

