import React from 'react';
import { Modal } from 'react-bootstrap';

export interface ConfirmDialogModalProps {
    message: string;
    title: string;
    visible: boolean;
    confirm : string;
    action: () => void;
    toggleVisibility: () => void;
}

function ConfirmDialogModal(props: ConfirmDialogModalProps) {

    return (
        <Modal
            show={props.visible}
            onHide={() => props.toggleVisibility()}
        >
            <Modal.Header closeButton>
                {props.title}
            </Modal.Header>
            <Modal.Body>
                <div className="modal-body modal-text">
                    {props.message}
                </div>
                <div className="modal-footer pb-0">
                    <button type="button" className="btn default-btn" data-dismiss="modal" onClick={() => props.toggleVisibility()}>CANCEL</button>
                    <button
                        type="button"
                        className="btn default-btn"
                        onClick={() => props.action()}
                    >
                        {props.confirm}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ConfirmDialogModal
