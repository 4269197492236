import React,{ Component } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Dropdown } from "react-bootstrap";
import {Organization} from '../../interface'
import Utility from '../../helpers/Utility'
import SensorBoxForm from '../../components/sensormodal'
import {SensorBox} from '../../interface'
import LoadingWheel from '../../components/loadingwheel'
import toast from '../../components/toast'
import userService from '../../services/userService'
import constants from '../../config/constants';
import './dashboard.css'
import Pagination from "react-js-pagination";
import Map from './map'
import "./dashboard.css"
import eventStore from "../../store/eventStore";
import userStore from '../../store/userStore';
import authStore from '../../store/authStore'
import refreshIcon from '../../assets/refresh.svg'
import Utils from "../../helpers/Utility";
import CreateGroupModal from '../../components/creategroupmodal/CreateGroupModal';
import groupIcn from '../../assets/group.png';
import sensorIcn from '../../assets/sensorbox.png';
import childIcn from '../../assets/child.png';
import organizationService from '../../services/organizationService';
import SelectComponent from '../../components/select';
import DeviceConfigLogModal from '../../components/device-config-log-modal/DeviceConfigLogModal';
import ResetSensorbox from '../../components/confirmDialogModal'
import Permissions from '../../config/permssions';


const {
  view_assigned_users_sensorboxes_assets,
  create_or_update_sensorboxes_assets
} = Permissions

export interface DashboardState { 
  currentPage     :   number;
  userCount       :   number;
  roleFilter      :   any;
  organizationList:   Array<Organization>;
  searchFilter    :   any;
  organizationFilter? : any;
  selectedSensorBox?   :   SensorBox;
  isLoading       :   boolean;
  sensorBoxList        :   Array<SensorBox>
  sensorBoxes     : Array<SensorBox>
  setClassSideBar :   boolean;
  visible         : boolean;
  setVisibleClose : boolean;
  createGroupModalVisible: boolean;
  deviceSort: string;
  statusSort: string;
  centerCoord : Array<any> | undefined;
  DeviceConfigLogModalVisible: boolean;
  processing: boolean;
  SSEPendings: Array<any>;
  showResetSensorBoxModal: boolean;
}

export interface DashboardProps extends RouteComponentProps {

}

let timerId: any;

class Dashboard extends Component<DashboardProps, DashboardState> {
  
  searchSensorBox : any
  constructor(props : any) {     
    super(props);
    this.state = {
      selectedSensorBox   :   undefined,
      currentPage : 1,
      organizationList    : [],
      userCount   : 1,
      roleFilter  : null,
      searchFilter: '',
      isLoading   : true,
      organizationFilter : {},
      sensorBoxes : [],
      sensorBoxList : [],
      setClassSideBar : false,
      visible         :   false,
      setVisibleClose : true,
      createGroupModalVisible: false,
      deviceSort: 'group', // group or sensorbox
      statusSort: 'desc', // asc or desc
      centerCoord : undefined,
      DeviceConfigLogModalVisible: false,
      SSEPendings: [],
      processing: false,
      showResetSensorBoxModal: false,
    }
    this.searchSensorBox = Utility.debounce(this.getSensorBox, 700)
  }

  setSearchFilter(value : string) {
      this.setState({searchFilter : value, currentPage : 1},()=>{
      this.searchSensorBox()})
  }

  isFieldTech(){
    return authStore.currentUser.role === constants.role["Field Technician"]
  }

  showSensorBoxCreateForm() {
    Utility.showModal('sensorboxdetails');

  }

  clearSelect = () => {
    this.setState({selectedSensorBox : {} as SensorBox})
  }

  async componentDidMount() {
    // note : temp solution for the org admin access to all org
    if(authStore?.currentUser?.active_role?.role_type === 0 || (authStore?.currentUser?.active_role?.role_type === 1 && authStore.currentUser.is_default) ){
      await this.getOrganisationsSensors()
    }
    await this.getSensorBox()
    this.listenAndUpdateSensordata()
    // this.setMapCenter()
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  componentDidUpdate(prevProps: DashboardProps, prevState: DashboardState) {
    if(prevState.processing !== this.state.processing) {
      if(!this.state.processing && this.state.SSEPendings.length){
        this.state.SSEPendings.forEach((data: any) => {
          this.updataSSE(data);
        })
        this.setState({SSEPendings: []});
      }
    }
  }

  handleConnectionChange = async () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      await this.getSensorBox(this.state.currentPage)
    }
  }

  setMapCenter = () => {
    if(this.state.sensorBoxes.length > 1) {
      let profleData : any = userStore.profileDetials
      this.setState({centerCoord: profleData.location?.coordinates})
    }else{
      this.setState({centerCoord: [40.7619926,-73.99346919999999]}) // default north america map centering
    }
  }

  handleFilterOrganization(option: any){
    this.setState({organizationFilter : option},()=>{
      this.getSensorBox(1)
    })
  }

  getOrganisationsSensors :any = async () => {
    try {
            const response = await organizationService.getOrganizations()
            this.setState({organizationList : response.data.organizations , organizationFilter : response.data.organizations[0]})
    } catch (error) {
            toast.error(error.response.data.message)
            this.setState({isLoading : false})
    }
  }

  resetCoords = () => {
    this.setState({centerCoord: [undefined,undefined]})
  }

  showSensorBoxForm(item? : SensorBox) {

    const regularUser = ((
      item?.contractor?.[0]?._id === authStore?.currentUser?.uid
        || ( authStore?.currentUser?.access.includes(view_assigned_users_sensorboxes_assets) ? item?.contractor?.[0]?._id === authStore?.currentUser?.assigned_to : false)
        || authStore?.currentUser?.active_role?.role_type < 2) 
    )

      if(item?.group) {
        const list = this.state.sensorBoxList.slice();
        let index = list.findIndex(d => item?._id === d?._id);
        list[index].open = !list[index].open;
        this.setState({
          sensorBoxList: list
        })
        return
      }

        if(item?.is_deleted){
          this.showResetSensorBoxModal(item)
        }else{
          if(item?.user_access?.includes(authStore?.currentUser?.uid ) && !regularUser){
            return
          }
          
          this.setState({selectedSensorBox : item, visible:true},()=>{
            Utility.showModal('sensorboxdetails')
          })
        }
  }

  showDeviceLog = (item: SensorBox) => {
    this.setState({selectedSensorBox : item, DeviceConfigLogModalVisible:true});
  }

  getSensorBox = async(pageNumber : number = 0, sensorBoxName? : string, groupName? : string, callBack? : any) => {
    pageNumber = pageNumber === 0? this.state.currentPage : pageNumber;
    try { 
        let promise1 = userService.getSensorBoxes(pageNumber, constants.maxLimit, this.state.searchFilter, this.state.deviceSort, this.state.statusSort,this.state.organizationFilter._id)
        let promise2 = userService.getSensorBoxesForMap(this.state.organizationFilter._id)
        this.setState({ processing: true, isLoading : true });
        let data     = await Promise.all([promise1,promise2])
        let sensorBoxList = data[0]
        let sensorBoxes = data[1]

        this.setState({ 
          sensorBoxList : sensorBoxList.result, 
          userCount : sensorBoxList.count,
          currentPage : pageNumber,   
          sensorBoxes : sensorBoxes.result,
          isLoading:false,
          processing: false,
        },()=>{
          if(callBack){
            let groupSensorbox, individualSensor;
            if(groupName){
              groupSensorbox = this.state.sensorBoxList.find(sensorbox => sensorbox.name === groupName);
              individualSensor = groupSensorbox?.sensorboxes.find(sensorbox => sensorbox.sensor_box_name === sensorBoxName);          
            }else{
              individualSensor = this.state.sensorBoxList.find(sensorbox => sensorbox.sensor_box_name === sensorBoxName);
            }
            callBack(individualSensor)
          }
        })
    }catch(error) {
        if(error.message !== "Operation canceled due to new request."){
          this.setState({isLoading : false, processing: false})
          toast.error(error.response.data.message)
        }
    }      
  }

  listenAndUpdateSensordata = () =>{
    
    if(eventStore.eventSource){
      eventStore.eventSource.onmessage = (event) => {
          if(event.data !== "CONNECTED") {
            let data = JSON.parse(event.data);
            if(this.state.processing) {
              this.setState({ SSEPendings: [...this.state.SSEPendings, data] });
              return;
            }
            this.updataSSE(data)     
          }
      }
    }
  }

  updataSSE(data: any) {
    if(data?.type === 'CONFIG_UPDATE') {
      const sensorboxes = this.state.sensorBoxList.slice();
      if(data?.groupId) {
        let deviceIndex = sensorboxes?.findIndex((device: any) => device?._id === data?.groupId);
        if(deviceIndex >= 0 ) {
          let childIndex = sensorboxes[deviceIndex]?.sensorboxes.findIndex((sb: any) => sb?.sensor_box_name === data?.sensor_box_name);
          if(childIndex >= 0) {
            sensorboxes[deviceIndex].sensorboxes[childIndex].device_config_status = data?.data?.status;
          }
        }
      }else {
        let index = sensorboxes.findIndex(s => s?.sensor_box_name === data?.sensor_box_name);
        if(index >= 0) {
          sensorboxes[index].device_config_status = data?.data?.status;
        }
      }
      this.setState({ sensorBoxList: sensorboxes });

      if(this.state.selectedSensorBox?.sensor_box_name === data?.sensor_box_name) {
        const sensorbox = { ...this.state.selectedSensorBox, device_config_status: data?.data?.status } as SensorBox
        this.setState({ selectedSensorBox: sensorbox })
      }
      return
    }
    if(data?.type !== "OFFLINE_STATUS_UPDATE"){
      let newSensorBoxList = Utility.updateSensoBoxData(this.state.sensorBoxes, data)
      let filteredSensorBoxList = Utility.updateSensoBoxData(this.state.sensorBoxList, data)
      this.setState({sensorBoxes : newSensorBoxList, sensorBoxList : filteredSensorBoxList }) 
    }
  }

  shouldPageChange = (isAdd: boolean) => {
    let currentPage = 0;
    if (isAdd) {
      currentPage = Math.ceil( (this.state.userCount + 1)/constants.maxLimit );
    } else if(this.state.sensorBoxList.length === 1 && this.state.currentPage > 1) {
      currentPage = this.state.currentPage -1
    }

    return currentPage;
  }

  setVisible = () => {
    this.setState({visible:false});
  }

  showSensorBoxList = ()=> {
    this.setState({setClassSideBar : !this.state.setClassSideBar})  
  }

  getCircleColor(sensor_data : any){
    if(sensor_data){

        let status   =   constants.sensor_status.green
        if(sensor_data.sc && sensor_data.sc[0] && sensor_data.sc[0].status){
            status   =   sensor_data.sc[0].status
        }
       
        if(sensor_data.sh && sensor_data.sh[0] && sensor_data.sh[0].status && status < sensor_data.sh[0].status ){
            status   =   sensor_data.sh[0].status   
        }

        return status === constants.sensor_status.red ? "danger-status" :  status === constants.sensor_status.orange ? "warning-status" :   "ok-status"

    }else{
        return  "ok-status"
    }
}

  onAddBtnClick = (eventKey: string | null) => {
    if(eventKey === 'group') {
      this.setState({ createGroupModalVisible: true })
    }else if(eventKey === 'sensorbox') {
      this.showSensorBoxForm()
    }
  }

  sortDevice = () => {
    const { deviceSort } = this.state;
    this.setState({ deviceSort: deviceSort === 'group' ? 'sensorbox' : 'group' }, () => {
      this.getSensorBox(1);
    })
  }

  refreshData = () => {
    this.setState({isLoading : true}, async () => {
      await this.getSensorBox(this.state.currentPage)
    })
  }

  sortStatus = () => {
    const { statusSort } = this.state;
    this.setState({ statusSort: statusSort === 'desc' ? 'asc' : 'desc' }, () => {
      this.getSensorBox(1);
    })
  }

  setCenterCoord = (item: any) => {

    if (timerId) {
      clearTimeout(timerId)
    }
    let coords : Array<any> = []

    if (item.group && item.sensorboxes[0]) {
      coords = item.sensorboxes[0].location?.coordinates
    }else {
      coords = item.location?.coordinates
    }

    timerId = setTimeout(() => {
      this.setState({ centerCoord: coords })
      clearTimeout(timerId)
    }, 1000);

  }

  showResetSensorBoxModal(item : any) {
    this.setState({ selectedSensorBox : item ,showResetSensorBoxModal :true })
  }

  closeResetSensorBoxModal = () => {
    this.setState({ showResetSensorBoxModal: false })
  }

  resetSensorBox = async (item : any) => {
    try {
      let resetSensorbox = await userService.resetSensorBox(item._id)
      if(resetSensorbox === 'Success.'){
      this.setState({ showResetSensorBoxModal: false })
      await this.getSensorBox(this.state.currentPage)
      }
    } catch (error) {
    }
  }

  sensorBoxList() {
    const demoCheck = (authStore?.currentUser?.active_role?.role_type === -1);
    return (
      
      <div className="remove-overflow" id="wrapper">
      <div className="full-width justify-content-between" >
      <div id="sidebar-wrapper" className={this.state.setClassSideBar ? 'sidebartoggle' : undefined}>
      <div className="sensorlist-container">
              <div className="row-fluid head-section">
                <h5 className="header-text">SENSOR BOX</h5>
                <div className="col-xs-4 col-sm-4 col-md-3 col-lg-3 media-margin-tp pr-0 ml-5">
                {// note : temp solution for the org admin access to all org
                (authStore?.currentUser?.active_role?.role_type === 0 || (authStore?.currentUser?.active_role?.role_type === 1 && authStore.currentUser.is_default)) &&
                        <div className="filter-select">
                                <SelectComponent name="typeFilter"
                                                 data={this.state.organizationList}
                                                 value={this.state.organizationFilter}
                                                 onChange={(e:any)=>this.handleFilterOrganization(e)}
                                />
                        </div>
                }
                </div>
                <div className="col-xs-4 col-sm-4 col-md-3 col-lg-3 media-margin-tp pr-0 ml-auto">
                  <input className="form-control search-form custom-form-control" type="text" placeholder="Search" 
                    value={this.state.searchFilter ? this.state.searchFilter : ''} 
                    onChange={(e)=>this.setSearchFilter(e.target.value)}/>
                  <span className="search-icon" aria-hidden="true"><i className="fa fa-search" aria-hidden="true"></i></span>
                </div>
              <img className="ml-3 refresh-icon" src={refreshIcon} onClick={this.refreshData} title="Refresh" alt=''/>
              </div> 
              <div className="row-fluid">
                <div className="col-md-12 no-padding">
                {this.state.sensorBoxList.length !== 0 ?
                  <div className="table-wrapper">
                    <table className="table table-custom" style={{ tableLayout: 'fixed'}}>
                      <thead className="">
                        <tr className="">
                          <th className="th-head text-center" style={{ width: '5%' }}  scope="col"></th>
                          <th className="th-head cursor-pointer"  style={{ width: '20%' }}  scope="col" onClick={this.sortDevice}>
                            Devices
                            <i className={`fa ml-3 ${this.state.deviceSort === 'group' ? 'fa-caret-up': 'fa-caret-down'}`}></i>
                          </th>
                          <th className="th-head" style={{ width: '20%' }} scope="col">Consumer</th>
                          <th className="th-head" style={{ width: '25%' }} scope="col">Address</th>
                          <th className="th-head text-center cursor-pointer" style={{ width: '16%' }} scope="col" onClick={this.sortStatus}>
                            Status
                            <i className={`fa ml-3 ${this.state.statusSort === 'desc' ? 'fa-caret-up': 'fa-caret-down'}`}></i>
                          </th>
                          <th className="th-head" style={{ width: '14%' }} scope="col">Config</th>
                        </tr>
                      </thead>
                      <tbody onMouseLeave={() => {clearTimeout(timerId)}} className="tbody-row">                      
                          {this.state.sensorBoxList ?
                                this.state.sensorBoxList.map((item : SensorBox,index) =>{
                                  const tableClassName = ['td-data table-row-border heading-row']
                                  if(item?.is_deleted){
                                    tableClassName.push('dashboard-grey-tr')
                                  }
                                    return(
                                      <>                                    
                                        <tr className={tableClassName.join(' ')} key={index} onMouseOver={()=>{this.setCenterCoord(item)}}  onClick={()=>{this.showSensorBoxForm(item)}} title={item.sensor_box_name}>
                                          <td className="text-center">
                                            {
                                              item?.group === 1 &&
                                              <i style={{fontSize: 12}} className={ item?.open ? 'fa fa-chevron-down' : 'fa fa-chevron-right'}aria-hidden="true"></i>
                                            }
                                          </td>
                                          <td className="td-data truncate-text text-truncate">
                                            {
                                              demoCheck ?
                                              item && item.group  ? `Group ${index+1}` : item.sensor_box_name :
                                              (item && item.group ? item.name : item && item.alias ? item.alias : item.sensor_box_name)
                                            }
                                          </td>                                       
                                          <td className="td-data truncate-text text-truncate">
                                             {
                                              demoCheck ?
                                              `Consumer ${index+1}` :
                                              (item?.consumer?.[0]?.name??'')
                                             }
                                          </td>
                                          <td className="td-data truncate-text text-truncate">
                                            {
                                              demoCheck ?
                                              `-` :
                                              (item?.group === 0 ? item?.place || '' : item?.sensorboxes[0]?.place)
                                            }
                                          </td>
                                          <td className="td-data text-center" title = {item.status === -1 ? 'Algorthm not defined' : ''}>
                                            <i className={`fa fa-circle ${Utils.getSensorStatus(item.status)}`} aria-hidden="true"></i>
                                          </td>
                                          <td className="td-data text-center">
                                            {item?.device_config_status &&
                                              <span className="btn-link td-data" onClick={(e) => {
                                                  e.stopPropagation();
                                                  this.showDeviceLog(item);
                                              }}>
                                                {constants.deviceStatus.get(item?.device_config_status)?.text || ''}
                                              </span>
                                            }
                                          </td>
                                        </tr>
                                        {
                                          item?.open && item.group === 1 &&
                                          <>
                                          {
                                            item?.sensorboxes?.map((box: any, i: number) => (
                                              <tr key={box?._id} className="cursor-pointer" onMouseOver={()=>{this.setCenterCoord(box)}} onClick={()=>{this.showSensorBoxForm(box)}} title={box.sensor_box_name} >
                                                <td className="td-data">
                                                  <img src={childIcn} alt="" height={12} />
                                                </td>
                                                <td className="td-data truncate-text text-truncate">
                                                  {
                                                    demoCheck ?
                                                    box?.sensor_box_name && box?.sensor_box_name :
                                                    (box?.alias  ? box?.alias : box?.sensor_box_name)
                                                  }
                                                </td>
                                                <td className="td-data truncate-text text-truncate">
                                                  {
                                                    demoCheck ?
                                                    `Consumer ${index+1}` :
                                                    (box?.consumer?.[0]?.name??'')
                                                  }
                                                </td>
                                                <td className="td-data truncate-text text-truncate">
                                                  {
                                                   demoCheck ?
                                                   `-` :
                                                   (box.place ? box.place : "" )
                                                  }
                                                </td>
                                                <td className="td-data text-center" title = {box.status === -1 ? 'Algorthm not defined' : ''}>
                                                  <i className={`fa fa-circle ${Utils.getSensorStatus(box.status)}`} aria-hidden="true"></i>
                                                </td>
                                                <td className="td-data text-center">
                                                  {box?.device_config_status &&
                                                    <span className="btn-link td-data" onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.showDeviceLog(box);
                                                    }}>
                                                      {constants.deviceStatus.get(box?.device_config_status)?.text || ''}
                                                    </span>
                                                  }
                                                </td>
                                              </tr>
                                            ))
                                          }
                                          </>
                                        }
                                      </>  
                                    )
                                })
                                : null
                            }
                      </tbody>
                    </table>
                  </div>
                  :
                <div className="text-center w-100 no-card-ph">
                    <div className="no-result-container">
                    { !this.state.isLoading ?
                    <p className="no-cards"> {this.state.searchFilter === ''? "No sensor box found!" : "No results found!"}</p>
                    : ''}
                    </div>
                </div>
               }
                </div>
              </div>
              {
                Math.ceil( this.state.userCount/constants.maxLimit ) > 1  ?
                <div className="mb-5">
                  <Pagination
                      hideNavigation
                      activePage={this.state.currentPage}
                      itemsCountPerPage={constants.maxLimit}
                      totalItemsCount={this.state.userCount}
                      itemClass={"item-class"}
                      activeClass={"active-class"}
                      pageRangeDisplayed={5}
                      onChange={(pageNumber)=>{
                          this.setState({isLoading : true}, () => {
                            this.getSensorBox(pageNumber)
                          })
                        }}
                      />
                </div>
                : null
              }

        
        {this.state.visible ?
        <SensorBoxForm 
          sensorbox = {this.state.selectedSensorBox} updateSensorBoxList = {this.getSensorBox} 
          clearSelectedSensorBox = {this.clearSelect} shouldPageChange={this.shouldPageChange} 
          setVisibleClose={this.setVisible}
          updateEventSourceListener={this.listenAndUpdateSensordata}
          organisationId={this.state.organizationFilter ? this.state.organizationFilter._id : ''}
          organisationList = {this.state.organizationList ? this.state.organizationList : []}/>
        : null}

        <ResetSensorbox
            title={"Reset Sensorbox"}
            message={"Are you sure want to reset this sensorbox"}
            visible = {this.state.showResetSensorBoxModal}
            confirm ={"YES"}
            action={() => (this.resetSensorBox(this.state.selectedSensorBox))}
            toggleVisibility={this.closeResetSensorBoxModal}
        />

        <CreateGroupModal
          show={this.state.createGroupModalVisible}
          onClose={()=> this.setState({ createGroupModalVisible: false })}
          // note : temp solution for the org admin access to all org
          organisationId={this.state.organizationFilter ? this.state.organizationFilter._id : ''}
          onSave={() => {
            this.setState({isLoading : true}, () => {
              this.getSensorBox(1)
            })
          }}
        />
        <DeviceConfigLogModal
          show={this.state.DeviceConfigLogModalVisible}
          onClose={()=> this.setState({ DeviceConfigLogModalVisible: false })}
          sensorbox={this.state.selectedSensorBox}
        />
        {authStore?.currentUser?.access?.includes(create_or_update_sensorboxes_assets) &&
          <div className="">
            <Dropdown drop="up" onSelect={this.onAddBtnClick}>
              <Dropdown.Toggle
                className="add-icon-dashboard"
                bsPrefix={'add'}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-add-menu">
                <Dropdown.Item eventKey="sensorbox" className="dashboard-add-menu-item">
                  <span>
                    <img className="mr-2" src={sensorIcn} height={12} alt=''/>
                    Add sensor box
                  </span>
                </Dropdown.Item>
                <Dropdown.Item eventKey="group" className="dashboard-add-menu-item">
                  <span>
                    <img className="mr-2" src={groupIcn} height={12} alt=''/>
                    Create asset
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          }
       
       </div>

      
       
       
      </div>
      </div>
      </div>
      
    )
    }

    
  render() {
    return (

        <div className="dashboard-container">
        <div> { this.state.isLoading ? <LoadingWheel/>  :  null}</div>
          <div className="sensorbox-container">{this.sensorBoxList()} </div>
         
          <Map sensorBoxList={this.state.sensorBoxes} sidebar={this.state.setClassSideBar} centerCoord = {this.state.centerCoord} resetCoords={this.resetCoords}/>
          <button onClick={()=>this.showSensorBoxList()} className="btn btn-primary btn-collapse-toggle" data-toggle="sidebar">
            <i className={this.state.setClassSideBar ? 'fa fa-chevron-right' : 'fa fa-chevron-left '} aria-hidden="true"  ></i>
          </button>
        </div>



    );
  }
}

export default withRouter(Dashboard);

