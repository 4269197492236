import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import SystemForm from '../../components/inspection-system-form/index'
import validate from '../../helpers/validation';
import constants from '../../config/constants';
import inspectionChecklistService from '../../services/InspectionChecklistServices';

interface Props {
  show: boolean;
  onClose: () => void;
  systemDetails?: any;
  onEditSystem: (data: any) => void;
}

const InspectionSystemEditModal: React.FC<Props> = ({ show, systemDetails,  onClose , onEditSystem }) => {

  const [errors, setErrors] = useState<any>({})

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemDetails]);

  const clearFieldError = (field: string) => {
    let prevErrors = errors
    prevErrors[field] = ''
    setErrors(prevErrors)
  }

  const onCloseModal = () => {
    clearFieldError('system_name')
    onClose()
  }

  const isSystemExist = async (data : any) => {
    if(!data.system_name?.trim()) return false;
    try {
      const resp = await inspectionChecklistService.isSystemExist(data.system_name,data?._id,data?.consumer);
      const { system_exist_status } = resp.data;
      let errorMsg = system_exist_status ? constants.message.system_exists : '';  
      setErrors((prev: any) => ({...prev, system_name: errorMsg }));
      return system_exist_status ? true : false;
    } catch (error) {}
  }

  const toNextTabFromSystem = async (data: any) => {
    if(validateSystemForm(data) && !await isSystemExist(data)){
        onEditSystem(data)
    }
  }

  const toPrevTabFromSystem = async () => {
    //do nothing
  }

const validateSystemForm = (systemFormData : any) => {

    let error: any = {}

    if (validate.isEmpty(systemFormData?.system_name)) {
      error.system_name = constants.message.inspection_systemname_required
    } else {
      error.system_name = ''
    }

    setErrors(error)
    return !(error.system_name)
}

  return (
    <Modal
      show={show}
      onHide={onCloseModal}
      size="lg"
    >
      <Modal.Header  closeButton>
      <div>Edit System Info</div>
      </Modal.Header>
      <Modal.Body>
        
        <SystemForm 
          isEditable={true}
          systemDetails = {systemDetails}
          errors = {errors}
          clearFieldError={(data: any) => clearFieldError(data)}
          toPrevTabFromSystem ={() => toPrevTabFromSystem()}
          toNextTabFromSystem={(data : any) => toNextTabFromSystem(data)}
        />
        
      </Modal.Body>
    </Modal>
  )
}

export default InspectionSystemEditModal;
