import React, { Component, FormEvent, ChangeEvent } from 'react'
import { RouteComponentProps } from 'react-router-dom';
import toast from '../../../components/toast';
import LoadingWheel from '../../../components/loadingwheel';
import settingsService, { ISettings } from '../../../services/settingsService';
import '../settings.css';

interface Props extends RouteComponentProps { }
interface State {
  loading: boolean,
  editMode: boolean,
  algorithmFile: File | null,
  settings: ISettings | null,
  settingsFormData: any;
  algorithmFileError: string;
}
export class systemConfig extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      editMode: false,
      algorithmFile: null,
      settings: null,
      settingsFormData: {},
      algorithmFileError: '',
    }
  }

  componentDidMount() {
    this.getSettings();
  }

  async getSettings() {
    try {
      this.setState({ loading: true });
      const response = await settingsService.getSettings();
      if(response) {
        const settings = response.data;
        this.setState({ settings, loading: false });
        if (settings) {
          const values = {
            merge_timeout: settings?.merge_timeout,
            config_req_res_timeout: settings?.config_req_res_timeout,
            offline_alert_interval: settings?.offline_alert_interval,
            device_offline_checking_duration: settings?.device_offline_checking_duration,
            generator_device_offline_checking_duration : settings?.generator_device_offline_checking_duration,
            lockrouter_device_offline_checking_duration : settings?.lockrouter_device_offline_checking_duration,
            cyclic_device_offline_checking_duration : settings?.cyclic_device_offline_checking_duration,
            cycle_offline_duration : settings?.cycle_offline_duration,
            cooling_heating_time_interval:settings?.cooling_heating_time_interval,
            invalid_range_alert_threshold:settings?.invalid_range_alert_threshold
          }
          this.setState({ settingsFormData: values });
        }
      }
    } catch (error) {
      let errMsg = error?.message;
      if (error?.response?.data?.message) {
        errMsg = error?.response?.data?.message
      }
      toast.error(errMsg);
    }
  }

  saveHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { settingsFormData } = this.state;
    try {
      const params = {
        merge_timeout: settingsFormData.merge_timeout,
        config_req_res_timeout: settingsFormData.config_req_res_timeout,
        offline_alert_interval: settingsFormData?.offline_alert_interval,
        device_offline_checking_duration: settingsFormData.device_offline_checking_duration,
        generator_device_offline_checking_duration : settingsFormData.generator_device_offline_checking_duration,
        lockrouter_device_offline_checking_duration : settingsFormData.lockrouter_device_offline_checking_duration,
        cyclic_device_offline_checking_duration : settingsFormData.cyclic_device_offline_checking_duration,
        cycle_offline_duration : settingsFormData.cycle_offline_duration,
        cooling_heating_time_interval:settingsFormData.cooling_heating_time_interval,
        invalid_range_alert_threshold: settingsFormData.invalid_range_alert_threshold
      }
      this.setState({ loading: true });

      await settingsService.saveSettings(params);
      toast.success('Configuration saved!');
      this.setState({ editMode: false });
      await this.getSettings();
    } catch (error) {
      let errMsg = error?.message;
      if (error?.response?.data?.message) {
        errMsg = error?.response?.data?.message
      }
      toast.error(errMsg??`Failed to save configuration!`);
    }
  }

  editButtonHandler = () => {
    const { settings } = this.state;
    this.setState((prev) => ({
      editMode: !prev.editMode,
      algorithmFileError: '',
      algorithmFile: null,
      settingsFormData: {
        merge_timeout: settings?.merge_timeout,
        config_req_res_timeout: settings?.config_req_res_timeout,
        offline_alert_interval: settings?.offline_alert_interval,
        device_offline_checking_duration: settings?.device_offline_checking_duration,
        generator_device_offline_checking_duration : settings?.generator_device_offline_checking_duration,
        lockrouter_device_offline_checking_duration : settings?.lockrouter_device_offline_checking_duration,
        cyclic_device_offline_checking_duration : settings?.cyclic_device_offline_checking_duration,
        cycle_offline_duration : settings?.cycle_offline_duration,
        cooling_heating_time_interval:settings?.cooling_heating_time_interval,
        invalid_range_alert_threshold:settings?.invalid_range_alert_threshold
      }
    }))
  }

  onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target
    this.setState(prev => ({
      settingsFormData: {
        ...prev.settingsFormData,
        [name]: value
      },
    }))
  }

  onAlgorithmFileChoose = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files?.length) {
      let ext = files[0]?.name.substring(files[0]?.name.lastIndexOf('.') + 1).toLowerCase();
      if (ext === 'js' && files[0].type.match('javascript')) {
        this.setState({ algorithmFile: files[0], algorithmFileError: '' });
        return
      }
      this.setState({ algorithmFileError: 'Please choose javascript file.' });
    }
  }

  algorithmFileUploadHandler = async () => {
    const { algorithmFile } = this.state;
    if (!algorithmFile) return
    try {
      const formData = new FormData();
      formData.append('script', algorithmFile);
      this.setState({ loading: true });
      await settingsService.uploadAlgorithmScript(formData);
      this.setState({ editMode: false, algorithmFile: null });
      toast.success('Algorithm script updated');
      await this.getSettings();
    } catch (error) {
      this.setState({ loading: false });
      toast.error('Script upload failed');
    }
  }

  resetAlgorithmHandler = async () => {
    try {
      this.setState({ loading: true });
      await settingsService.resetAlgorithm();
      this.setState({ editMode: false });
      toast.success('Algorithm script reset successfully');
      await this.getSettings();
    } catch (error) {
      this.setState({ loading: false });
      toast.error('Reseting script failed');
    }
  }

  render() {
    const { loading, editMode, settings, settingsFormData, algorithmFile, algorithmFileError } = this.state;
    return (
    <div className="padding-b50">
      <div className="p-4 card">
        {
          loading &&
          <LoadingWheel />
        }
            <div>
              {
                settings &&
                <div className="clearfix">
                  <button
                    className="btn btn-edit"
                    onClick={this.editButtonHandler}
                  >
                    {editMode ? 'Cancel Edit' : 'Edit'}
                  </button>
                </div>
              }
            </div>
              <div className="row my-4">
                <div className="col-12">
                  <div className="row no-gutters align-items-end pb-5">
                    <div className="col-lg-8 col-md-6">
                      <label>Upload algorithm script</label>
                      <div className="custom-file">
                        <input type="file" accept=".js" className="custom-file-input" id="scriptUpload" disabled={loading || (!editMode)} onChange={this.onAlgorithmFileChoose} />
                        <label className="custom-file-label" htmlFor="scriptUpload" data-text={settings?.custom_algorithm ? 'Change' : 'Browse'}>
                          {
                            algorithmFile?.name ? algorithmFile?.name :
                              settings?.custom_algorithm ? 'Algorithm file uploaded' : 'Choose algorithm file'
                          }
                        </label>
                      </div>
                      {
                        algorithmFileError &&
                        <div className="error-msg">{algorithmFileError}</div>
                      }
                    </div>
                    <div className="col-lg-4 col-md-6 clearfix">
                      {
                        settings?.custom_algorithm &&
                        <button
                          className="btn btn-secondary btn-edit px-4 ml-3"
                          onClick={this.resetAlgorithmHandler}
                          disabled={loading || (!editMode)}
                        >
                          Reset
                  </button>
                      }
                      <button
                        className="btn btn-secondary btn-edit px-4"
                        onClick={this.algorithmFileUploadHandler}
                        disabled={loading || !editMode || !algorithmFile}
                      >
                        Upload
                </button>
                    </div>
                  </div>
                </div>
              </div>

              <form onSubmit={this.saveHandler}>
                <div className="row"> 
                  <div className="col-md-6">
                    <div className="form-group"> 
                      <label htmlFor="">Commercial device merge timeout</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter timeout (1-60) minute"
                          value={settingsFormData.merge_timeout || ''}
                          onChange={this.onChangeHandler}
                          name="merge_timeout"
                          disabled={(!editMode && !!settings)}
                          max={60}
                          min={1}
                              required
                            /> 
                          </div>
            
                  </div>

                  <div className="col-md-6">
                    <div className="form-group"> 
                      <label htmlFor="">Config Req/Resp timeout</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Config req/resp (1-60) minute"
                          value={settingsFormData.config_req_res_timeout || ''}
                          onChange={this.onChangeHandler}
                          name="config_req_res_timeout"
                          disabled={(!editMode && !!settings)}
                          max={60}
                          min={1}
                          required
                        /> 
                    </div>
                  </div>   

                  <div className="col-md-6">
                    <div className="form-group"> 
                      <label htmlFor="">Offline alert interval (hours)</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter timeout in hours"
                          value={settingsFormData.offline_alert_interval || ''}
                          onChange={this.onChangeHandler}
                          name="offline_alert_interval"
                          disabled={(!editMode && !!settings)}
                          min={1}
                          required
                        /> 
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group"> 
                      <label htmlFor="">Device offline checking duration (minutes)</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter timeout in minutes"
                          value={settingsFormData.device_offline_checking_duration || ''}
                          onChange={this.onChangeHandler}
                          name="device_offline_checking_duration"
                          disabled={(!editMode && !!settings)}
                          min={30}
                          required
                        /> 
                    </div>
                  </div>  

                  <div className="col-md-6">
                    <div className="form-group"> 
                      <label htmlFor="">Generator device offline checking duration (minutes)</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter timeout in minutes"
                          value={settingsFormData.generator_device_offline_checking_duration || ''}
                          onChange={this.onChangeHandler}
                          name="generator_device_offline_checking_duration"
                          disabled={(!editMode && !!settings)}
                          min={1}
                          required
                        /> 
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group"> 
                      <label htmlFor="">Lock rotor device offline checking duration (minutes)</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter timeout in minutes"
                          value={settingsFormData.lockrouter_device_offline_checking_duration || ''}
                          onChange={this.onChangeHandler}
                          name="lockrouter_device_offline_checking_duration"
                          disabled={(!editMode && !!settings)}
                          min={1}
                          required
                        /> 
                    </div>
                  </div> 

                  <div className="col-md-6">
                    <div className="form-group"> 
                      <label htmlFor="">Cyclic device offline checking duration (minutes)</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter timeout in minutes"
                          value={settingsFormData.cyclic_device_offline_checking_duration || ''}
                          onChange={this.onChangeHandler}
                          name="cyclic_device_offline_checking_duration"
                          disabled={(!editMode && !!settings)}
                          min={1}
                          required
                        /> 
                    </div>
                  </div> 

                  <div className="col-md-6">
                    <div className="form-group"> 
                      <label htmlFor="">Field test cycle offline checking timeout (minutes)</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter timeout in minutes"
                          value={settingsFormData.cycle_offline_duration || ''}
                          onChange={this.onChangeHandler}
                          name="cycle_offline_duration"
                          disabled={(!editMode && !!settings)}
                          min={1}
                          required
                        /> 
                  </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">Consecutive Invalid-Range Alerts Threshold</label>
                      <input
                          type="number"
                          className="form-control"
                          placeholder="Default is 1"
                          value={settingsFormData.invalid_range_alert_threshold || ''}
                          onChange={this.onChangeHandler}
                          name="invalid_range_alert_threshold"
                          disabled={(!editMode && !!settings)}
                          min={1}
                          required
                      />
                    </div>
                  </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">System running longer alert interval (Hours) </label>
                      <input
                          type="number"
                          className="form-control"
                          placeholder="cooling_heating_time_interval (Hours)"
                          value={settingsFormData.cooling_heating_time_interval || ''}
                          onChange={this.onChangeHandler}
                          name="cooling_heating_time_interval"
                          disabled={(!editMode && !!settings)}
                          min={1}
                          required
                      />
                    </div>
                  </div>
                  
              <button
                type="submit"
                className="btn btn-secondary btn-edit px-4 ml-auto"
                disabled={loading || (!editMode && !!settings)}
              >
                Save
              </button>                     
              </form>         
      </div>
    </div>
    )
  }
}

export default systemConfig
