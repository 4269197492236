import React, { useState } from 'react';
import './exportsensordata.css';
import { Modal } from 'react-bootstrap';
import DatePicker from '../../components/datepicker'
import sensorService from '../../services/sensorService';
import { toast } from 'react-toastify';
import LoadingIndicator from '../loadingwheel';
import validate from '../../helpers/validation';
import constants from '../../config/constants';
import MessageBox from '../messagebox';


export interface ExportProps {
    show: boolean;
    closeModal: () => void,
    SensorBoxName?: string;
    GroupId?: string;
    orgId? : string
}

function Exportsensordata(props: ExportProps) {

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isLoading, toggleLoader] = useState(false)
    const [error, setError]: any = useState({})
    const [showMessageBoxModal , setShowMessageBoxModal] = useState(false);



    async function exportReport() {

        try {
            toggleLoader(true)
            let errorMsg = validateDates()
            if (errorMsg === '' && props.SensorBoxName) {
                await sensorService.sendReport(props.SensorBoxName, startDate, endDate , props.orgId)
                showMessageBox()
            }else if (errorMsg === '' && props.GroupId) {
                await sensorService.sendGroupReport(props.GroupId, startDate, endDate , props.orgId)
                showMessageBox()
            }else {
                setError(errorMsg)
            }
            toggleLoader(false)
        } catch (err) {
            toggleLoader(false)
            toast.error(err)
        }
    }

    function showMessageBox() {
        closeModal()
        setShowMessageBoxModal(true)
    }

    function closeMessageBox() {
        setShowMessageBoxModal(false)
    }

    function closeModal() {
        setStartDate(new Date())
        setEndDate(new Date())
        setError({})
        props.closeModal()
    }


    function validateDates() {

        if (validate.isEmpty(startDate)) {
            return { startDate: constants.message.start_date_mandatory }
        }

        if (validate.isEmpty(endDate)) {
            return { endDate: constants.message.end_date_mandatory }
        }

        if (!validate.isValidDates(startDate, endDate)) {
            return { endDate: constants.message.invalid_dates }
        }
        return ''
    }

    function get6MonthsBackDate() {
        let d = new Date();
        d.setMonth(d.getMonth() - 6);
        return d;
    }


    return (
        <div>
            <Modal
            show={props.show}
            onHide={props.closeModal}
            >
            <Modal.Header closeButton>
            <div>Export</div>
            </Modal.Header>
            <Modal.Body>
                
            <div className="modal-content export-sensor-data">
            <div className="modal-body">
                <div className="row justify-centre more-type-wrap">
                    <div className="col-md-6">
                        <DatePicker value={startDate} maxDate={new Date()} minDate={get6MonthsBackDate()} onChange={(value: any) => setStartDate(value)} placeholderText="Start Date" />
                        <div className="col-md-12">
                            {error && error.startDate ? <div style={{ color: 'red', fontSize: '12px' }}>{error.startDate}</div> : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <DatePicker value={endDate} maxDate={new Date()} minDate={get6MonthsBackDate()} onChange={(value: any) => setEndDate(value)} placeholderText="End Date" />
                        <div className="col-md-12">
                            {error && error.endDate ? <div style={{ color: 'red', fontSize: '12px' }}>{error.endDate}</div> : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn default-btn" onClick={() => closeModal()}>CANCEL</button>
                <button type="button" className="btn default-btn" onClick={() => exportReport()}>EXPORT</button>
            </div>
            </div>
            </Modal.Body>
            </Modal>
            <MessageBox show={showMessageBoxModal} title={""} closePasswordModal={() => closeMessageBox()} message={constants.message.report_send} />
                {isLoading ?
                    <LoadingIndicator />
                : null}
        </div>
    );
}

export default Exportsensordata;
