import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { AlgorithmConfig } from '../../interface'
import algorithmConfigService from '../../services/algorithmConfigServices'
import constants from '../../config/constants';
import Utility from '../../helpers/Utility';
import validate from '../../helpers/validation';
import SelectComponent from '../select';

export interface algorithmConfigFormProps {
    setVisibleClose?: () => void;
    selectedAlgorithm?: AlgorithmConfig;
    visible: boolean;
    addAlgorithmConfig: (algo: AlgorithmConfig) => void;
    updateAlgorithmConfig: (algo: AlgorithmConfig) => void;
    refrigerentList : any;
    pinRepresentationList : any;
}

const statusTypes = [
    { _id: 0,  name: 'Green' },
    { _id: 1,  name: 'Yellow' },
    { _id: 2,  name: 'Orange' },
    { _id: 3,  name: 'Red' },
    { _id: 4,  name: 'Darkred' },
    { _id: 5,  name: 'Purple' }
]

const ruleTypes = [
    { _id: 1,  name: 'All should have values or all zeros' },
    { _id: 2,  name: 'Multiple' },
]

const formatTypes = [
    { _id: 1, name: 'Greater than' },
    { _id: 2, name: 'Less than' },
    { _id: 3, name: 'Greater than or equal to' },
    { _id: 4, name: 'Less than or equal to' },
    { _id: 5, name: 'Equal to' },
    { _id: 6, name: 'Not equal to' }
]

const gateTypes = [
    { _id: 'AND', name: 'AND' },
    { _id: 'OR', name: 'OR' },
]

function AddAlgorithmConfigModal(props: algorithmConfigFormProps){

    const [rules, setRules] = useState<any>([{}]);
    const [combinationRule ,setCombinationRule] = useState<any>([]);
    const [isUpdate, setIsUpdate] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [needToSave, setNeedToSave] = useState(false)
    const [formData ,setFormData] =useState<any>({})
    const [errors, setErrors] = useState<any>({})

    useEffect(() => {
        if(props.visible){
            initialSetup();
        }else{
            closeForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.visible]);

    useEffect(()=>{
        setNeedToSave(true);
    },[rules])
    const initialSetup = async () => {
        if (props.selectedAlgorithm?.hasOwnProperty('_id')) {
            setIsUpdate(true)
            setIsEdit(false)
            setFormData(props.selectedAlgorithm)
            if(props.selectedAlgorithm?.rule_type === 1){
                const representationArray = props.pinRepresentationList.filter((item:any)=>(
                    props.selectedAlgorithm?.combination_rule?.includes(item._id)
                ))  
                const filteredArray = representationArray?.map((item:any)=>({value:item._id,label:item.name})) 
                setCombinationRule(filteredArray)
            }else{
                setRules(props.selectedAlgorithm.rules)
            }
        }
    }

    const handleStatusSelection = (option: any) => {

        setNeedToSave(true)
        clearFieldError('status')
        setFormData((prevState :any )=> ({
            ...prevState,
            'status': option._id
        }));
    }

    const handleRefrigerentSelection = (option: any) => {

        setNeedToSave(true)
        clearFieldError('refrigerent_type')
        setFormData((prevState :any )=> ({
            ...prevState,
            'refrigerent_type': option.name
        }));
    }

    const handleRuleSelection = (option: any) => {

        setNeedToSave(true)
        clearFieldError('rule_type')
        setRules([{}])
        setCombinationRule([])
        setFormData((prevState :any )=> ({
            ...prevState,
            'rule_type': option._id
        }));
    }

    const handleGateSelection = (option: any) => {

        setNeedToSave(true)
        clearFieldError('gate')
        setFormData((prevState :any )=> ({
            ...prevState,
            'gate': option._id
        }));
    }

    const saveAlgorithmConfig = async () => {
        if (validateField()){
            const isAlgorithmCodeExist = await isAlgorithmCodeExists();
            if(isAlgorithmCodeExist) return false;

            let value : any = { 
                code : formData?.code,
                status : formData?.status,
                refrigerent_type : formData?.refrigerent_type,
                rule_type : formData?.rule_type,
                description : formData?.description,
                gate : formData?.gate,
                algorithm_type : 'chiller-larger'
            }

            if(formData?.rule_type !== 1){
                value.rules = rules
                value.combination_rule = null
            }else{
                const optionValue= combinationRule.map((item :any) => item.value)
                value.combination_rule = optionValue
                value.rules = null
            }

            if (isUpdate) {
                if (!needToSave) {
                    closeForm();
                    return
                }
                props.updateAlgorithmConfig(value)
            }else {
                props.addAlgorithmConfig(value)
            }
        }
    }

    const isAlgorithmCodeExists = async () => {
        if(!formData.code?.trim()) return;
        try {
          const resp = await algorithmConfigService.isAlgorithmCodeExists(formData?.code,formData?._id);
          const { algorithmcode_exist_status } = resp.data;
          let errorMsg = algorithmcode_exist_status ? constants.message.algorithm_code_exists : '';  
          setErrors((prev: any) => ({...prev, code: errorMsg }));
          return algorithmcode_exist_status;
        } catch (error) {}
    }

    const closeForm = () => {
        setFormData({})
        setRules([{}])
        setIsEdit(true);
        setCombinationRule([])
        setIsUpdate(false);
        setErrors({});
        if (props?.setVisibleClose){
            props.setVisibleClose()
        }
    }

    const isDisabled = () => !isEdit

    const updateFormValue = (e :any)=> {
        const { name, value} = e.target;
        clearFieldError(name)
        setNeedToSave(true)
        setFormData((prevState :any )=> ({
            ...prevState,
            [name]: value
        }));
    };

    const validateField = () => {
        let error: any = {}
        if (validate.isEmpty(formData.code)) {
            error.code = constants.message.algorithm_code_required
        } else {
            error.code = ''
        }

        if (formData?.status !== undefined) {
            error.status = ''
        } else {
            error.status = constants.message.algorithm_status_required
        }

        if (!formData?.rule_type) {
            error.rule_type = constants.message.algorithm_role_type_required
        } else {
            error.rule_type = ''
        }

        if (validate.isEmpty(formData.description)) {
            error.description = constants.message.algorithm_description_required
        } else {
            error.description = ''
        }

        if(rules?.length > 1){
        if(!formData?.gate){
            error.gate = constants.message.algorithm_gate_required
        }else{
            error.gate = ''
        }
        }else{
            error.gate = ''
        }

        if(formData?.rule_type !== 1){
            const rulesValidation = rules.every((item : any) => item.threshold && item.condition && item.representation);
            if(!rulesValidation){
                error.rules = constants.message.algorithm_rules_required
            }else{
                error.rules = ''
                setNeedToSave(true)
            }
        }else{
            if(!combinationRule.length){
                error.rules = constants.message.algorithm_rules_required
            }else{
                error.rules = ''
            }
        }

        setErrors(error)
        return !(error.code || error.status  || error.description || error.gate || error.rules || error.rule_type)
    }

    const clearFieldError = (field: string) => {
        let prevErrors = errors
        prevErrors[field] = ''
        setErrors(prevErrors)
    }

    const getSaveButtonDisabledCondition = () => {
        if (!isUpdate) {
            return false
        } else {
            return !(isEdit && props.selectedAlgorithm?._id)
        }
    }

    const toggleEdit = () => {
        if (isEdit) {
            initialSetup()
        }
        setIsEdit(!isEdit)
    }

    const showConfirmModal = () => {
        Utility.showModal('confirmmodal')
    }

    const handleInputChange = (e :any, index : any) => {
        clearFieldError('rules')
        const { name, value } = e.target;
        const list = [...rules];
        list[index][name] = value;
        setRules(list);
    };

    const handleRepresentationDropDownChange = (option : any , index : any ) => {

        clearFieldError('rules')
        const list = [...rules];
        list[index]['representation'] = option?._id;
        setRules(list);
    };

    const handleConditionDropDownChange = (option : any , index : any ) => {
        clearFieldError('rules')
        const list = [...rules];
        list[index]['condition'] = option._id;
        setRules(list);
    };
 
    // handle click event of the Remove button
    const handleRemoveClick = (index : any) => {
        const list = [...rules];
        list.splice(index, 1);
        setRules(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setRules([...rules, { threshold: '', condition: '' , representation : '' }]);
    };

    const conditionValue  = (value : any) => {
        const condition = formatTypes?.find((format: any) => format._id === value)
        return condition
    };

    const handleMutipleRepresentationValueSelection  = (option : any) => {
        clearFieldError('rules')
        setNeedToSave(true)
        setCombinationRule(option)
    };

    const representationValue  = (representationValue : any) => {
        const representation = props.pinRepresentationList?.find((representation: any) => representation._id === representationValue)
        return representation
    };

    const statusValue  = (statusValue : any) => {
        const status = statusTypes?.find((status: any) => status._id === statusValue)
        return status
    };

    const refrigerentValue  = (refrigerentValue : any) => {
        const refrigerent_type = props.refrigerentList?.find((refrigerent: any) => refrigerent.name === refrigerentValue)
        return refrigerent_type
    };

    const ruleTypeValue  = (ruleTypeValue : any) => {
        const rule_type = ruleTypes?.find((ruletype: any) => ruletype._id === ruleTypeValue)
        return rule_type
    };

    const gateValue  = (gateValue : any) => {
        const gate = gateTypes?.find((gate: any) => gate._id === gateValue)
        return gate
    };

    return (
        <Modal show={props.visible} onHide={closeForm} size="lg">
            <Modal.Header className="py-3 px-3 font-weight-bold" closeButton>
                {isUpdate ? "Edit Larger Chiller Alert Code" : "Add Larger Chiller Alert Code"}
            </Modal.Header>
            <Modal.Body >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {isUpdate &&
                                <button type="button"
                                    className="btn btn-edit"
                                    onClick={toggleEdit}>
                                    {isEdit ? "Cancel Edit" : "Edit"}
                                </button>
                                }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="input-text-field">
                                <div className="form-group">
                                    <label className="custom-label">Diagnostic Code <span className="mand-star">*</span></label>
                                    <input type="text"
                                        className="form-control"
                                        name="code"
                                        value={formData.code  ? formData.code  : ''}
                                        onChange={updateFormValue}
                                        onBlur={isAlgorithmCodeExists}
                                        maxLength={50}
                                        disabled={isDisabled()}
                                        placeholder="Enter Diagnostic  Code" />
                                    <div className="error-msg">{errors && errors.code ? errors.code : ''}</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="input-text-field">
                                <div className="form-group">
                                    <label className="custom-label"> Status <span className="mand-star">*</span></label>
                                    <SelectComponent 
                                        name="status"
                                        isDisabled={isDisabled()}
                                        data={statusTypes}
                                        value={statusValue(formData?.status)}
                                        onChange={handleStatusSelection}
                                    />
                                    <div className="error-msg">{errors && errors.status ? errors.status : ''}</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="input-text-field">
                                <div className="form-group">
                                    <label className="custom-label"> Refrigerant Type </label>
                                    <SelectComponent 
                                        name="refrigerent_type"
                                        isDisabled={isDisabled()}
                                        data={props.refrigerentList}
                                        value={refrigerentValue(formData?.refrigerent_type)}
                                        onChange={handleRefrigerentSelection}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="custom-label" >Description <span className="mand-star">*</span></label>
                                    <input type="text"
                                        className="form-control"
                                        name="description"
                                        value={formData.description ? formData.description : ''}
                                        onChange={updateFormValue}
                                        maxLength={100}
                                        disabled={isDisabled()}
                                        placeholder="Enter Description" />
                                    <div className="error-msg">{errors && errors.description ? errors.description : ''}</div>
                            </div>
                              
                        </div>

                        <div className="col-md-6">
                            <div className="input-text-field">
                                <div className="form-group">
                                    <label className="custom-label"> Rule Type <span className="mand-star">*</span></label>
                                    <SelectComponent 
                                        name="rule_type"
                                        isDisabled={isDisabled()}
                                        data={ruleTypes}
                                        value={ruleTypeValue(formData?.rule_type)}
                                        onChange={handleRuleSelection}
                                    />
                                    <div className="error-msg">{errors && errors.rule_type ? errors.rule_type : ''}</div>
                                </div>
                            </div>
                        </div>
                        { formData?.rule_type === 2 &&
                        <>
                        <div className="col-md-12">
                            <div className="input-text-field">
                                <div className="form-group">
                                    <label className="custom-label">Add Rules <span className="mand-star">*</span></label>
                                    { errors.rules && <div className="error-msg">{errors && errors.rules ? errors.rules : ''}</div> }
                                </div> 
                            </div>
                        </div>
                        {
                        rules?.map((rule : any, index : any) => {
                            return (
                            <>
                            <div className="col-md-3">
                                <div className="input-text-field">
                                    <div className="form-group">
                                        <label className="custom-label"> Sensor parameter </label>
                                            <SelectComponent 
                                                    name="representation"
                                                    isDisabled={isDisabled()}
                                                    data={props.pinRepresentationList}
                                                    value={representationValue(rule.representation)}
                                                    onChange={(e :any) => handleRepresentationDropDownChange(e, index)}
                                            />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="input-text-field">
                                    <div className="form-group">
                                        <label className="custom-label"> Condition </label>
                                        <SelectComponent 
                                                name="condition"
                                                isDisabled={isDisabled()}
                                                data={formatTypes}
                                                value={conditionValue(rule.condition)}
                                                onChange={(e :any) => handleConditionDropDownChange(e, index)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="input-text-field">
                                    <div className="form-group">
                                        <label className="custom-label"> Threshold</label>
                                        <input type="number"
                                        className="form-control"
                                        name="threshold"
                                        value={rule.threshold}
                                        onChange={(e :any) => handleInputChange(e, index)}
                                        maxLength={100}
                                        disabled={isDisabled()}
                                        placeholder="Enter value" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mt-auto">
                                <div className="input-text-field">
                                    <div className="form-group">
                                    {
                                    rules.length !== 1 && 
                                    <button
                                        type="button"
                                        className="btn btn-primary default-btn"
                                        disabled={getSaveButtonDisabledCondition()}
                                        onClick={() => handleRemoveClick(index)}>Remove
                                    </button>
                                    }
                                    {
                                    rules.length - 1 === index && 
                                    <button 
                                        type="button"
                                        className="btn btn-primary default-btn ml-2"
                                        disabled={getSaveButtonDisabledCondition()}
                                        onClick={handleAddClick}>Add New
                                    </button>
                                    }
                                    </div>
                                </div>
                            </div>
                            </>                                                        
                            );
                        })}
                        </>
                        }
                        {formData?.rule_type === 1 && 
                        <>
                        <div className="col-md-12">
                            <div className="input-text-field">
                                <div className="form-group">
                                    <label className="custom-label">Add Rules <span className="mand-star">*</span></label>
                                    { errors.rules && <div className="error-msg">{errors && errors.rules ? errors.rules : ''}</div> }
                                </div> 
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="input-text-field">
                                <div className="form-group">
                                    <label className="custom-label"> Sensor parameter </label>
                                        <SelectComponent 
                                            isMulti = {true}
                                            name="representation"
                                            isDisabled={isDisabled()}
                                            data={props.pinRepresentationList}
                                            value={combinationRule}
                                            onChange={(option :any) => handleMutipleRepresentationValueSelection(option)}
                                        />
                                </div>
                            </div>
                        </div>
                        </>
                        }
                        { formData.rule_type === 2 && rules.length > 1  && // show gate only when there is combination
                        <div className="col-md-3">
                            <div className="input-text-field">
                                <div className="form-group">
                                    <label className="custom-label">Gate <span className="mand-star">*</span></label>
                                    <SelectComponent 
                                        name="gate"
                                        isDisabled={isDisabled()}
                                        data={gateTypes}
                                        value={gateValue(formData?.gate)}
                                        onChange={handleGateSelection}
                                    />
                                    <div className="error-msg">{errors && errors.gate ? errors.gate : ''}</div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </Modal.Body>

            <div className={props.selectedAlgorithm?._id && isEdit ? "modal-footer modal-footer-custom" : "modal-footer"}>
                {props.selectedAlgorithm?._id && isEdit ?
                    <button
                        type="button"
                        className="btn btn-inverse-info btn-rounded btn-icon delete-user-icon"
                        onClick={showConfirmModal}
                    >
                        <i className="fa fa-trash-o fa-lg" style={{color: "red"}}/>
                    </button>
                    : null}
                <button type="button" disabled={getSaveButtonDisabledCondition()} className="btn btn-primary default-btn"
                    onClick={() => saveAlgorithmConfig()}
                >{isUpdate ? "Update" : "Save"}
                </button>
            </div>
        </Modal>
    );
}

export default AddAlgorithmConfigModal
