import React, { useEffect, useState } from 'react';
import SelectComponent from '../select';
import "./device-parameter-display.css"
import Utility from '../../helpers/Utility';

interface Props { //props
  groupOptions: any,
  sensorBoxes: any
}

const DeviceParameterDisplay: React.FC<Props> = ({ groupOptions ,sensorBoxes }) => {

  const [selectedFilter, setSelectedFilter] = useState<any>()

  useEffect(() => {
    loadDefaultGroup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDefaultGroup = async () => {
    if(groupOptions.length > 0){
      let defaultOption = groupOptions[0].options[0]
      let  optionValue={
          _id  : defaultOption?.value,
          name : defaultOption?.label
      }
      setSelectedFilter(optionValue)
    }else{
      setSelectedFilter({})
    }
  }

  const handleFilterSelection = (option: any) => {

    let  optionValue={
        _id  : option.value,
        name : option.label
    }      
    
    setSelectedFilter(optionValue)
  }

  const customStyles = {
    option: (provided:any, state:any) => ({
      ...provided,
      borderBottom: '1px solid #707070',
      color: state.isSelected ? '#fff' : '#707070',
      background: state.isSelected ? '#707070' : '',
      paddingLeft:25
    }),
  }

  const sensorBoxName = selectedFilter?._id?.split("-")[0]
  const sensorBoxArray = sensorBoxes.find((sensor: any) => sensor.sensor_box_name === sensorBoxName ? sensorBoxName : '')
  const sensors = sensorBoxArray?.sensors?.find((sensor: any) => sensor.display_name === selectedFilter?.name)
  const valueLabel = Utility.formatSensorValue(sensors)
  const sensorBoxLabel = sensorBoxArray?.alias ? sensorBoxArray?.alias : sensorBoxArray?.sensor_box_name

  return (
    <div className="card-box dashboard-border">
      <SelectComponent 
          isGroup
          name="typeFilter"
          data={groupOptions}
          value={selectedFilter}
          onChange={handleFilterSelection}
          customStyles={customStyles}
      />
      <div className="mt-lg-5 mb-lg-5 pt-lg-4 pb-lg-4 mt-3 mb-3">
          <h1 className="d_tab_txt_clr"> {valueLabel} </h1>
      </div>
      <div className="sensor-label card-box-footer d_tab_txt_clr">
          {sensorBoxLabel}
      </div>
    </div>
  )
}

export default DeviceParameterDisplay;
