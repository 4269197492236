import React,{ Component, ChangeEvent } from "react";
import { withRouter, RouteComponentProps} from "react-router";
import { Link } from "react-router-dom";
import api from "../../api";
import './password.css';
import constants from "../../config/constants";
import toast from "../../components/toast";
import MessageBox from '../../components/messagebox'
import Routes from "../Routes";


export interface ContactState {
  confirmPassword : string;
  password        : string;
  error           : string;
  terms           : boolean;
  newPasswordShown: boolean;
  confirmPasswordShown: boolean;
  success         : string;
  newPasswordError : string;
  confirmPasswordError : string;
  passwordError : string;
  showMessageBoxModal: boolean;
}

export interface ContactProps extends RouteComponentProps {}



class Password extends Component<ContactProps, ContactState> {

  state = {
    confirmPassword:'',
    password:'',
    error : '',
    terms : false,
    newPasswordShown : false,
    confirmPasswordShown : false,
    success : '',
    newPasswordError : '',
    confirmPasswordError : '',
    passwordError : '',
    showMessageBoxModal: false
  }

  closePasswordModal = () =>{
    this.setState({showMessageBoxModal : false})
    this.props.history.push(Routes.home);
  }

  async componentDidMount() {
    this.getTokenValidationAPI();
  }

  async getTokenValidationAPI() {  
      try{
        let params   = this.props.match.params
        await api.post('/validateEmailToken',params)      
      }
      catch(error){
        this.setState({error:error.response.data.message});
      }    
      

  }

  changePassword(value:any){
    this.setState({password : value})
  }

  changeConfirmPassword(value:any){
    this.setState({confirmPassword : value})
  }
  

  validatenewPassword(){
    let isValid = true;
    this.setState({newPasswordError:'', passwordError:''});

    if(this.state.password === ''){
      this.setState({newPasswordError:constants.message.password_field_mandatory});
      isValid = false;
    } else {
      let regex =  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
      if(!regex.test(this.state.password)){
        this.setState({newPasswordError:constants.message.invalid_password});
        this.setState({passwordError:constants.message.password_field_validation});

        isValid = false;
      }
    }

    return isValid;
  }

  validateConfirmPassword(){
    let isValid = true;
    this.setState({confirmPasswordError:''});

    if (this.state.confirmPassword === '') {
      this.setState({confirmPasswordError:constants.message.password_field_mandatory});
      isValid = false;
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({confirmPasswordError:constants.message.password_field_mismatch});
      isValid = false;
    } else {
      let regex =  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
      if (!regex.test(this.state.confirmPassword)) {
        this.setState({confirmPasswordError:constants.message.invalid_password});
        this.setState({passwordError:constants.message.password_field_validation});
        isValid = false;
      }
    }

    return isValid;
  }

  async setPassword() {
    let params  = this.props.match.params
    this.setState({passwordError:''});
    try {
        if(this.validatenewPassword() && this.validateConfirmPassword()) {
          let response = await api.post('/password',{
            ...params,password : this.state.password,confirmPassword: this.state.confirmPassword, is_tc_accepted : this.state.terms
          })
          if(response.status === 200) {
            this.setState({showMessageBoxModal : true})
          }
        }
    } catch(error){
      toast.error(error.response.data.message)
    }    
  }


  updateTerms = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({terms: event.target.checked})
  }

  toggleNewPasswordVisiblity = () => {
    this.setState({ newPasswordShown: !this.state.newPasswordShown });
  };

  toggleConfirmPasswordVisiblity = () => {
    this.setState({ confirmPasswordShown: !this.state.confirmPasswordShown });
  };


  render(){

  const { newPasswordShown , confirmPasswordShown } = this.state;

  return (
    this.state.error !== '' ?
   
    <div className="error-wrap">
      <div className="error-center">{this.state.error}</div>
    </div>:

    <div className="container">
        <div className="password-reset">
            <div className="row">
              <div className="col-md-12 text-center">
                <h4>Set Your Password</h4>
              </div>
            </div>

            <div className="container pt-20">
              <div className="col-md-6 col-centered text-cente">
                  <label>New Password</label> <br />
                  <div className="position-relative">
                    <input type={newPasswordShown ? "text" : "password"} name="password" maxLength={20} className="input-type-wrapper" required={true} value ={this.state.password} onChange={(e)=>this.changePassword(e.target.value)} onBlur={()=>this.validatenewPassword()}></input>   
                    <i className={['fa eye-class' , newPasswordShown ? 'fa-eye' : 'fa-eye-slash'].join(' ') } aria-hidden="true" onClick={this.toggleNewPasswordVisiblity}></i>
                  </div>
                  <div className="error-msg">{this.state.newPasswordError}</div>  


                  <label>Confirm New Password</label> <br />  
                  <div className="position-relative"> 
                    <input type={confirmPasswordShown ? "text" : "password"} name="confirmPassword" maxLength={20} className="input-type-wrapper" required={true} value={this.state.confirmPassword} onChange={(e)=>this.changeConfirmPassword(e.target.value)} onBlur={()=>this.validateConfirmPassword()}></input>
                    <i className={['fa eye-class' , confirmPasswordShown ? 'fa-eye' : 'fa-eye-slash'].join(' ') } aria-hidden="true" onClick={this.toggleConfirmPasswordVisiblity}></i>
                  </div>
                  <div className="error-msg">{this.state.confirmPasswordError}</div>  
                  <div className="d-flex align-items-baseline">
                    <input 
                      type="checkbox"
                      className="mr-3"
                      onChange={this.updateTerms}
                    /> 
                        <label className="d-inline-block">   I have read and agree to the&nbsp;MechaSense <Link to={Routes.termsservice} target="_blank">Platform Terms of Service.</Link>
                            </label> 
                  </div>
                  <button disabled={!this.state.terms} className="btn btn-submit btn-block"  onClick={()=>this.setPassword()}>SUBMIT</button>
                  <div>{this.state.success}</div>
                  <div className="error-msg">{this.state.passwordError}</div>  
              </div>
            </div>       
        </div>
        <MessageBox show = {this.state.showMessageBoxModal}  title={""}  message = {constants.message.password_set} closePasswordModal={this.closePasswordModal}/>
    </div>

  );
  }
}

export default withRouter(Password);
