import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Select, Divider, Card, Typography, Button, Form } from 'antd';

import rules from '../../screens/issue-tracker-details/issue-tracker-validation';
import {
    PlusOutlined,
    MinusOutlined
} from '@ant-design/icons';
import Utils from '../../helpers/Utility';

const { Option } = Select;
const { Text } = Typography;

interface Props {
    issueDetails?: any,
    temp?: any
    softSavingsOptions?: any,
    onCloseTicket: (datas: any, action: any) => void;
    orgAdminUsers?: any
    onCancel: () => void;
}

const IssueTrackerCustomerBenefitsAndServiceRevenue: React.FC<Props> = ({ issueDetails, onCloseTicket, softSavingsOptions, orgAdminUsers, onCancel, temp }) => {

    const [form] = Form.useForm();
    const [saveChangesState, setSaveChangesState] = useState(false)
    const [hardSavings, setHardSavings] = useState<any>([{}]);
    const [powerSavings, setPowerSavings] = useState<any>([{}]);
    const [softSavings, setSoftSavings] = useState<any>([{}]);

    useEffect(() => {
        if (issueDetails?.hasOwnProperty('service_resolved_by')) {
            autoPopulateValues(JSON.parse(JSON.stringify(issueDetails)))
            setSaveChangesState(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issueDetails])

    const autoPopulateValues = async (data: any) => {

        form.setFieldsValue({ service_notes: data?.service_notes, mechasense_service_revenue: data?.mechasense_service_revenue, contractor_service_revenue: data?.contractor_service_revenue, service_resolved_by: data?.service_resolved_by?._id });

        // hardsavings
        if (data?.hard_savings?.length) {
            const hardSavingsDetails = data?.hard_savings;

            hardSavingsDetails?.forEach((item: any, index: any) => {
                form.setFieldsValue(
                    {
                        [`${index + 1}_hard_savings_details`]: item?.savings_details,
                        [`${index + 1}_hard_savings_amount`]: item?.savings_amount,
                    },
                );
            });

            setHardSavings(data?.hard_savings)
        }

        //powersavings

        if (data?.power_savings?.length) {
            const powerSavingsDetails = data?.power_savings;

            powerSavingsDetails?.forEach((item: any, index: any) => {
                form.setFieldsValue(
                    {
                        [`${index + 1}_power_savings_details`]: item?.savings_details,
                        [`${index + 1}_power_savings_amount`]: item?.savings_amount,
                    },
                );
            });

            setPowerSavings(data?.power_savings)
        }

        // softsavings

        if (data?.soft_savings?.length) {
            const softSavingsDetails = data?.soft_savings;

            softSavingsDetails?.forEach((item: any, index: any) => {
                form.setFieldsValue(
                    {
                        [`${index + 1}_soft_category`]: item?.category,
                        [`${index + 1}_soft_savings_details`]: item?.savings_details,
                    },
                );
            });

            setSoftSavings(data?.soft_savings)
        }
    }

    // handle click event of the Remove button
    const handleRemoveClickOfHardSavings = (index: any) => {
        const list = [...hardSavings];
        list.splice(index, 1);
        form.resetFields([`${index + 1}_hard_savings_details`, `${index + 1}_hard_savings_amount`]);
        setHardSavings(list);
    };

    // handle click event of the Add button
    const handleAddClickOfHardSavings = () => {
        setHardSavings([...hardSavings, { savings_amount: null as any, savings_details: '' }]);
    };


    const handleInputChangeOfHardSavings = (e: any, index: any) => {
        const { name, value, type } = e.target;
        const list = [...hardSavings];
        list[index][name] = (type === "number") ? Number(value) : value;
        setHardSavings(list);
    };

    // handle click event of the Remove button
    const handleRemoveClickOfPowerSavings = (index: any) => {
        const list = [...powerSavings];
        list.splice(index, 1);
        form.resetFields([`${index + 1}_power_savings_details`, `${index + 1}_power_savings_amount`]);
        setPowerSavings(list);
    };

    // handle click event of the Add button
    const handleAddClickOfPowerSavings = () => {
        setPowerSavings([...powerSavings, { savings_amount: '', savings_details: '' }]);
    };

    const handleInputChangeOfPowerSavings = (e: any, index: any) => {
        const { name, value, type } = e.target;
        const list = [...powerSavings];
        list[index][name] = (type === "number") ? Number(value) : value;
        setPowerSavings(list);
    };

    // handle click event of the Remove button
    const handleRemoveClickOfSoftSavings = (index: any) => {
        const list = [...softSavings];
        list.splice(index, 1);
        form.resetFields([`${index + 1}_soft_category`, `${index + 1}_soft_savings_details`]);
        setSoftSavings(list);
    };

    // handle click event of the Add button
    const handleAddClickOfSoftSavings = () => {
        setSoftSavings([...softSavings, { category: '', savings_details: '' }]);
    };

    const handleInputChangeOfSoftSavings = (e: any, index: any) => {
        const { name, value, type } = e.target;
        const list = [...softSavings];
        list[index][name] = value;
        list[index][name] = (type === "number") ? Number(value) : value;
        setSoftSavings(list);
    };

    const handleSubmit = (values: any) => {
        let hard_savings = hardSavings?.filter((saving: any) => saving.savings_amount !== '' && saving.savings_details !== '')
        let power_savings = powerSavings?.filter((saving: any) => saving.savings_amount !== '' && saving.savings_details !== '')
        let soft_savings = softSavings?.filter((saving: any) => saving.category !== '' && saving.savings_details !== '')

        let datas = {
            hard_savings,
            power_savings,
            soft_savings,
            mechasense_service_revenue: values?.mechasense_service_revenue,
            contractor_service_revenue: values?.contractor_service_revenue,
            service_resolved_by: values?.service_resolved_by,
            service_notes: values?.service_notes
        }

        onCloseTicket(datas, saveChangesState ? "savechanges" : "resolve")
    }

    const handleSoftSavingsDropDownChange = (option: any, index: any) => {
        const list = [...softSavings];
        list[index]['category'] = option;
        setSoftSavings(list);
        handleFormValuesChange()
    };

    const handleCancel = () => {

        if (saveChangesState) { // discard

            if (issueDetails?.hasOwnProperty('service_resolved_by')) {
                autoPopulateValues(JSON.parse(JSON.stringify(issueDetails)))
            } else {
                form.resetFields()
            }
            setSaveChangesState(false)

        } else { //cancel
            onCancel()
        }
    }

    const handleFormValuesChange = () => {

        if (!(issueDetails?.hasOwnProperty('service_resolved_by'))) {
            setSaveChangesState(true)
            return
        }

        let customerBenefitsAndRevenueObj = {
            hard_savings: issueDetails?.hard_savings,
            power_savings: issueDetails?.power_savings,
            soft_savings: issueDetails?.soft_savings,
            mechasense_service_revenue: issueDetails?.mechasense_service_revenue,
            contractor_service_revenue: issueDetails?.contractor_service_revenue,
            service_resolved_by: issueDetails?.service_resolved_by?._id,
            service_notes: issueDetails?.service_notes
        }

        let hard_savings = hardSavings?.filter((saving: any) => saving.savings_amount !== '' && saving.savings_details !== '')
        let power_savings = powerSavings?.filter((saving: any) => saving.savings_amount !== '' && saving.savings_details !== '')
        let soft_savings = softSavings?.filter((saving: any) => saving.category !== '' && saving.savings_details !== '')

        let updatedDatas = {
            hard_savings,
            power_savings,
            soft_savings,
            mechasense_service_revenue: form.getFieldValue(['mechasense_service_revenue']) ? form.getFieldValue(['mechasense_service_revenue']) : null,
            contractor_service_revenue: form.getFieldValue(['contractor_service_revenue']) ? form.getFieldValue(['contractor_service_revenue']) : null,
            service_resolved_by: form.getFieldValue(['service_resolved_by']) ? form.getFieldValue(['service_resolved_by']) : null,
            service_notes: form.getFieldValue(['service_notes']) ? form.getFieldValue(['service_notes']) : null,
        }

        setSaveChangesState(!(JSON.stringify(customerBenefitsAndRevenueObj) === JSON.stringify(updatedDatas)))
    }

    return (
        <>
            <Col span={18}>
                <Form layout="vertical" form={form} onFinish={handleSubmit} onChange={handleFormValuesChange} >
                    <Card className="custom-card online-device border-0">
                        <div className='d-flex align-items-center mb-2'>
                      <p style={{ fontSize: "14px", fontWeight: 5 }}>
                                Customer Benefits
                            </p>
                        </div>

                        <Row className="w-100 align-items-center">
                            <Col span={5}>
                                <Text
                                    className="mr-1 mb-0"
                                    style={{fontSize: 14}}
                                >Hard Savings 
                                </Text>
                                <span className="required-star"> *</span>
                            </Col>

                            {
                                hardSavings?.map((savings: any, index: any) => {
                                    return (
                                        <>
                                            {(index > 0) && <Col span={5}> </Col>}
                                            <Col span={8}>
                                                <Form.Item normalize={Utils.trimSpacesInValues} name={`${index + 1}_hard_savings_details`} className="mt-3 mr-3" rules={rules?.savings_details}>
                                                    <Input
                                                        className='rev_inpt'
                                                        showCount
                                                        name="savings_details"
                                                        maxLength={100}
                                                        placeholder="Enter Savings Details"
                                                        onChange={(e: any) => handleInputChangeOfHardSavings(e, index)}
                                                    />
                                                </Form.Item>

                                            </Col>


                                            <Col span={8}>
                                                <Form.Item name={`${index + 1}_hard_savings_amount`} className="mt-3 mr-3" rules={rules?.savings_amount}>
                                                    <Input
                                                        name="savings_amount"
                                                        type="number"
                                                        maxLength={100}
                                                        placeholder="Enter Savings Amount in $"
                                                        onChange={(e: any) => handleInputChangeOfHardSavings(e, index)}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col span={3}>
                                                <Form.Item className="mt-3 mr-3">
                                                    {hardSavings.length !== 1 &&
                                                        <Button className="mr-3" type="primary" shape="circle" onClick={() => handleRemoveClickOfHardSavings(index)} danger>
                                                            <MinusOutlined />
                                                        </Button>
                                                    }
                                                    {hardSavings.length - 1 === index &&
                                                        <Button type="primary" shape="circle" onClick={() => handleAddClickOfHardSavings()}>
                                                            <PlusOutlined />
                                                        </Button>
                                                    }
                                                </Form.Item>
                                            </Col>

                                        </>
                                    );
                                })}

                        </Row>

                        <Row className="w-100 align-items-center">
                            <Col span={5}>
                                <Text
                                    className="mr-1 mb-0"
                                    style={{fontSize: 14}}
                                >Power Savings
                                </Text>
                            <span className="required-star">*</span>
                            </Col>

                            {
                                powerSavings?.map((savings: any, index: any) => {
                                    return (
                                        <>
                                            {(index > 0) && <Col span={5}> </Col>}
                                            <Col span={8}>
                                                <Form.Item normalize={Utils.trimSpacesInValues} name={`${index + 1}_power_savings_details`} className="mt-3 mr-3" rules={rules?.savings_details}>
                                                    <Input
                                                        className='rev_inpt'
                                                        showCount
                                                        name="savings_details"
                                                        maxLength={100}
                                                        placeholder="Enter Savings Details"
                                                        onChange={(e: any) => handleInputChangeOfPowerSavings(e, index)}
                                                    />
                                                </Form.Item>

                                            </Col>


                                            <Col span={8}>
                                                <Form.Item name={`${index + 1}_power_savings_amount`} className="mt-3 mr-3" rules={rules?.savings_amount}>
                                                    <Input
                                                        name="savings_amount"
                                                        type="number"
                                                        maxLength={100}
                                                        placeholder="Enter Savings Amount in $"
                                                        onChange={(e: any) => handleInputChangeOfPowerSavings(e, index)}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col span={3}>
                                                <Form.Item className="mt-3 mr-3">
                                                    {powerSavings.length !== 1 &&
                                                        <Button className="mr-3" type="primary" shape="circle" onClick={() => handleRemoveClickOfPowerSavings(index)} danger>
                                                            <MinusOutlined />
                                                        </Button>
                                                    }
                                                    {powerSavings.length - 1 === index &&
                                                        <Button type="primary" shape="circle" onClick={() => handleAddClickOfPowerSavings()}>
                                                            <PlusOutlined />
                                                        </Button>
                                                    }
                                                </Form.Item>
                                            </Col>

                                        </>
                                    );
                                })}

                        </Row>

                        <Row className="w-100 align-items-center">
                            <Col span={5}>
                                <Text
                                    style={{fontSize: 14}}
                                    className="mr-1 mb-0"
                                >Soft Savings
                                </Text>
                                <span className="required-star">*</span>
                            </Col>

                            {
                                softSavings?.map((savings: any, index: any) => {
                                    return (
                                        <>
                                            {(index > 0) && <Col span={5}> </Col>}
                                            <Col span={8}>
                                                <Form.Item name={`${index + 1}_soft_category`} className="mt-3 mr-3" rules={rules?.soft_category}>
                                                    <Select placeholder="Choose Soft Savings" onChange={(e: any) => handleSoftSavingsDropDownChange(e, index)}>
                                                        {
                                                            softSavingsOptions &&
                                                            softSavingsOptions.map((data: any) => {
                                                                return (
                                                                    <Option value={data?.soft_savings_name}>{data?.soft_savings_name}</Option>
                                                                );
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>

                                            </Col>


                                            <Col span={8}>
                                                <Form.Item normalize={Utils.trimSpacesInValues} name={`${index + 1}_soft_savings_details`} className="mt-3 mr-3" rules={rules?.savings_details}>
                                                    <Input
                                                        name="savings_details"
                                                        maxLength={100}
                                                        placeholder="Enter Savings Amount in $"
                                                        onChange={(e: any) => handleInputChangeOfSoftSavings(e, index)}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col span={3}>
                                                <Form.Item className="mt-3 mr-3">
                                                    {softSavings.length !== 1 &&
                                                        <Button className="mr-3" type="primary" shape="circle" onClick={() => handleRemoveClickOfSoftSavings(index)} danger>
                                                            <MinusOutlined />
                                                        </Button>
                                                    }
                                                    {softSavings.length - 1 === index &&
                                                        <Button type="primary" shape="circle" onClick={() => handleAddClickOfSoftSavings()}>
                                                            <PlusOutlined />
                                                        </Button>
                                                    }
                                                </Form.Item>
                                            </Col>

                                        </>
                                    );
                                })}

                        </Row>
                    </Card>
                    <Card className="custom-card online-device border-0 mt-3">
                        <div className='d-flex align-items-center mb-2'>
                        <p style={{ fontSize: "14px", fontWeight: 5 }}>
                              Service Revenue
                            </p>
                        </div>
                        <Row className="w-100 align-items-center">
                         <Col span={7} className='mr-3'>
                            <Text
                                style={{fontSize: 14}}
                                className="mr-1 mb-0"
                                >MechaSense Service
                                </Text>
                            </Col>
                            <Col span={13}>
                                <Form.Item name="mechasense_service_revenue" rules={rules?.revenue} className="mt-4">
                                    <Input
                                        maxLength={100}
                                        placeholder="Enter Revenue ($)"
                                    />
                                </Form.Item>
                            </Col>
                    <Col span={7} className='mr-3'>
                            <Text
                                style={{fontSize: 14}}
                                className="mr-1 mb-0"
                                >Contractor Service
                                </Text>
                            </Col>
                            <Col span={13}>
                                <Form.Item name="contractor_service_revenue" rules={rules?.revenue} className="mt-4">
                                    <Input
                                        maxLength={100}
                                        placeholder="Enter Revenue ($)"
                                    />
                                </Form.Item>
                            </Col>
                    <Col span={7} className='mr-3'>
                            <Text
                                style={{fontSize: 14}}
                                className="mr-1 mb-0"
                                >Service Resolved By
                                </Text>
                                <span className="required-star">*</span>
                            </Col>
                            <Col span={13}>
                                <Form.Item name="service_resolved_by" rules={rules?.service_resolved_by} className="mt-4">
                                    <Select placeholder="Choose User" onChange={handleFormValuesChange}>
                                        {
                                            orgAdminUsers &&
                                            orgAdminUsers.map((data: any) => {
                                                return (
                                                    <Option value={data?._id}>{data?.name}</Option>
                                                );
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                   <Col span={7} className='mr-3'>
                            <Text
                                style={{fontSize: 14}}
                                className="mr-1 mb-0"
                                >Service Notes
                                </Text>
                            </Col>
                            <Col span={13}>
                                <Form.Item normalize={Utils.trimSpacesInValues} name="service_notes" className="mt-4">
                                    <Input
                                        className='rev_inpt'
                                        showCount
                                        maxLength={100}
                                        placeholder="Enter Notes"
                                    />
                                </Form.Item>
                            </Col>

                        </Row>
                    </Card>
                    {!(issueDetails?.status === 'CLOSED') &&
                        <Col span={24}>
                            <div className="text-right mt-4">
                                <Button className="text-left" type="text" onClick={handleCancel}>{saveChangesState ? `Discard Changes` : `Cancel`}</Button>
                                <Button className="h-36 py-0 pl-4 pr-4 fs-14 ins_bg_clr border-0 font-weight-bold mr-3" type="primary" onClick={form.submit}>{saveChangesState ? `Save Changes & Submit Later` : `Submit & Close Ticket`}</Button>
                            </div>

                        </Col>
                    }
                </Form>
            </Col>
        </>
    );
}
export default IssueTrackerCustomerBenefitsAndServiceRevenue;
