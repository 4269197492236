import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Select,
  Card,
  Button,
  Form,
  message,
  Spin,
  Tooltip,
  Modal,
  Pagination,
  Image,
} from "antd";

import Utils from "../../helpers/Utility";
import rules from "../../screens/issue-tracker-details/issue-tracker-validation";
import {
  PlusOutlined,
  UserOutlined
} from "@ant-design/icons";
import noAttachmentYet from "../../assets/images/no_attachment_yet.png";
import noResultsImage from "../../assets/images/undefined_image.jpg";
import Meta from "antd/lib/card/Meta";
import issueTrackerServices from "../../services/issueTrackerServices";
import AttachmentUpload from "./attachmentUpload";
import constants from "../../config/constants";
import authStore from "../../store/authStore";
import ConfirmationModal from "../confirmation-modal";
import { useHistory } from "react-router-dom";
import Routes from "../../routing/routes";

const { Option } = Select;

const priority = [
  { _id: "HIGH", name: "High" },
  { _id: "LOW", name: "Low" },
  { _id: "MEDIUM", name: "Medium" },
];

interface Props {
  issueDetails?: any;
  onCreateTicket: (values: any) => void;
  onClickEdit: (values: any, id: any) => void;
  onClickCancel: () => void;
  onDelete: () => void;
  issueId?: any;
  isLoading: boolean;
  org_id?: any;
  isEditReq?: boolean;
  contractorList?: any
}

const IssueTicketDetails: React.FC<Props> = ({
  issueDetails,
  onCreateTicket,
  onClickEdit,
  onClickCancel,
  onDelete,
  issueId,
  isLoading,
  org_id,
  isEditReq,
  contractorList
}) => {
  const [form] = Form.useForm();
  const [attachmentNote, setAttachmentNote] = useState<any>([]);
  const [imageList, setImageList] = useState<any>([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [attachmentsIndex, setAttachmentsIndex] = useState<any>(0);
  const [storedImageIndex, setStoredImageIndex] = useState<any>(0);
  const [storedNoteIndex, setStoredNoteIndex] = useState<any>(0);
  const [innerImageLength, setInnerImageLength] = useState<any>([]);
  const [innerImageIndex, setInnerImageIndex] = useState<any>(0);
  const [fieldSaved, setFieldSaved] = useState(false);
  const [seeMoreNote, setSeeMoreNote] = useState(false);
  const [ticketId, setTicketId] = useState(null);
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);
  const history = useHistory();
  
  const [fieldValues, setFieldValues] = useState({
    issue_title: "",
    issue_priority: "",
    issue_description: "",
    contractor: ""
  });
  const [previousFieldValues, setPreviousFieldValues] = useState({
    issue_title: "",
    issue_priority: "",
    issue_description: "",
    contractor: ""
  });

  useEffect(() => {
    if (issueDetails?.hasOwnProperty("issue_id")) {
      autoPopulateValues(issueDetails);
      setFieldValues({
        issue_title: issueDetails?.issue_title,
        issue_priority: issueDetails?.issue_priority,
        contractor: issueDetails?.contractor?._id,
        issue_description: issueDetails?.issue_description
          ? issueDetails?.issue_description
          : "",
      });
      setPreviousFieldValues({
        issue_title: issueDetails?.issue_title,
        issue_priority: issueDetails?.issue_priority,
        contractor: issueDetails?.contractor?._id,
        issue_description: issueDetails?.issue_description
          ? issueDetails?.issue_description
          : "",
      });
    } else {
      form.setFieldsValue({
        issue_title: issueDetails?.alert_description,
        contractor: contractorList[0]?._id
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issueDetails]);

  const onFinish = (values: any) => {
    onCreateTicket(values);
  };

  const autoPopulateValues = async (data: any) => {
    form.setFieldsValue({
      issue_title: data?.issue_title,
      issue_priority: data?.issue_priority,
      issue_description: data?.issue_description,
      contractor: contractorList?.find((element: { _id: string; name: string}) => element?._id === data?.contractor?._id) && data?.contractor?._id
    });
  };

  const handleEdit = () => {
    onClickEdit(form.getFieldsValue(), id);
  };

  const handleCancel = () => {
    onClickCancel();
  };

  const handleDelete = () => {
    onDelete();
  };

  const handleSaveTicket = () => {
    try {
        form.submit();
      if (
        form.getFieldValue("issue_title") &&
        form.getFieldValue("issue_priority") && form.getFieldValue("contractor")
      ) {
        
        setFieldSaved(true);
        setFieldValues({
          issue_title: form.getFieldValue("issue_title"),
          contractor: form.getFieldValue("contractor"),
          issue_priority: form.getFieldValue("issue_priority"),
          issue_description: form.getFieldValue("issue_description")
            ? form.getFieldValue("issue_description")
            : "",
        });
        setPreviousFieldValues({
          issue_title: form.getFieldValue("issue_title"),
          contractor: form.getFieldValue("contractor"),
          issue_priority: form.getFieldValue("issue_priority"),
          issue_description: form.getFieldValue("issue_description")
            ? form.getFieldValue("issue_description")
            : "",
        });
      }
    } catch (error) {
      message.destroy()
      message.error(constants.message.internalServerError)
    }
  };

  const fetchIssueDetailsWithId = async () => {
    try {
      let attachmentNotes: any[] = [];
      let imageListTotalLength: any[] = [];
      if (
        (issueId || issueDetails?._id || id) &&
        (org_id || issueDetails?.org_id)
      ) {
        setImageLoading(true);
        const issuesDetails =
          await issueTrackerServices.IssueTrackerDetailsById(
            issueId ? issueId : issueDetails?._id,
            org_id ? org_id : issueDetails?.org_id
          );
        setTicketId(issuesDetails?.issue?.issue_id);
        setId(issuesDetails?.issue?._id);

        if (
          !issuesDetails?.issue ||
          !issuesDetails?.issue?.notes ||
          !Array.isArray(issuesDetails?.issue.notes)
        ) {
          setImageLoading(false);
          return [];
        }
        setAttachmentsIndex(issuesDetails?.issue?.notes?.length);
        let attachmentsResponse = issuesDetails?.issue?.notes?.map(
          async (note: any) => {
            attachmentNotes.push(note?.text);
            if (note?.attachments?.length) {
              let res: any = await Promise.all(
                (note?.attachments || []).map(async (element: any) => {
                  return await issueTrackerServices.getAttachedImageByimageId(
                    element
                  );
                })
              );
              let urls = res?.map((item: any) => {
                let url = URL.createObjectURL(item?.data);
                return url;
              });

              return urls;
            }
          }
        );
        const responses: any = await Promise.all(attachmentsResponse);
        responses?.forEach((resDatas: any[]) => {
          imageListTotalLength.push(resDatas?.length);
        });
        setInnerImageLength(imageListTotalLength);
        setImageList(responses);
        setAttachmentNote(attachmentNotes);
        setShowToolTip(true)
        setTimeout(() => {
            setShowToolTip(false);
          }, 4000);
        setImageLoading(false);
      } else {
        setImageLoading(false);
      }
    } catch (error: any) {
      setImageLoading(false);
      const errorData = error?.response?.data
        const isUnauthorizedIssue = Utils.issueTrackerErrorHandler(errorData, constants.responseCode.unAuthorized, constants.message.issue_allocation_changed, constants.message.issue_doesnot_exist)
        if (isUnauthorizedIssue) {
            message.destroy()
            await message.error(error?.response?.data?.message)
            history.push({pathname : Routes.issuetracker});
        } else {
            message.destroy()
            message.error(error?.response?.data?.message || constants.message.internalServerError)
        }
    }
  };

  useEffect(() => {
    fetchIssueDetailsWithId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issueId, org_id, issueDetails?._id, issueDetails?.org_id]);

  const handleFieldChange = (fieldName: any, value: any) => {
    if (value === constants.addNewContractor) {
      history.push({
        pathname: `${Routes.users}`,
        state: {
          shouldBeVisible: true,
        },
      });
    } else {
    setFieldValues((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  }
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(() => {
        setIsVisible(true);
      })
      .catch((errorInfo) => {
      });
  };


  const system_name = issueDetails.group_id?.name
    ? `${issueDetails?.group_id?.name} / ${issueDetails?.sensor_box?.alias
      ? issueDetails?.sensor_box?.alias
      : issueDetails?.sensor_box?.sensor_box_name
    }`
    : issueDetails?.sensor_box?.alias
      ? issueDetails?.sensor_box?.alias
      : issueDetails?.sensor_box?.sensor_box_name;

  const seeMore = (note: any) => {
    return (
      <>
        {note}......
        <Button type="link" size="small" onClick={() => setSeeMoreNote(true)}>
          see more
        </Button>
      </>
    );
  };

  return (
    <>
      <Col span={18}>
        <Card
        style={{height:410}}
          className="custom-card online-device border-0 border-1"
          title={
            <div className="card-title-wrapper">
              <span>{system_name}</span>
              <div
                className={`small-circle-alert ml-1 ${Utils.getStatus(
                  Number(issueDetails?.alert_status)
                )}`}
              ></div>
            </div>
          }
        >
          <Spin spinning={isLoading ? isLoading : loading}>
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              onFinish={onFinish}
            >
              <Row gutter={[16, 16]} className="m-3">
                <Col span={16}>
                  <Form.Item
                    normalize={Utils.trimSpacesInValues}
                    name="issue_title"
                    label="Ticket Title"
                    rules={rules?.issue_title}
                  >
                    <Input
                      onChange={(e) =>
                        handleFieldChange("issue_title", e.target?.value)
                      }
                      maxLength={100}
                      showCount
                      placeholder="Enter Title"
                      className="custom-input"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="issue_priority"
                    label="Ticket Priority"
                    rules={rules.issue_priority}
                  >
                    <Select
                      onChange={(value) =>
                        handleFieldChange("issue_priority", value)
                      }
                      className="slct_style"
                      placeholder="Choose Priority"
                      disabled={authStore?.currentUser?.active_role?.role_type !== constants.role.Admin}
                    >
                      {priority &&
                        priority.map((data: any) => {
                          return <Option value={data._id}>{data?.name}</Option>;
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}  className="m-3">
                <Col span={authStore?.currentUser?.active_role?.role_type === constants.role.Admin ? 16 : 24}>
                  <Form.Item
                    normalize={Utils.trimSpacesInValues}
                    name="issue_description"
                    label="Ticket Description"
                  >
                    <Input.TextArea
                      showCount
                      maxLength={400}
                      onChange={(e) =>
                        handleFieldChange("issue_description", e.target?.value)
                      }
                      className="descrptn_bx"
                      placeholder="Enter Description"
                      style={{ height: "50px" }}
                      autoSize={{ minRows: 1.7, maxRows: 6 }}
                    />
                  </Form.Item>
                </Col>
                {authStore?.currentUser?.active_role?.role_type === constants.role.Admin &&
                <Col span={8}>
                  <Form.Item
                    name="contractor"
                    label="Assign Contractor"
                    rules={rules.issue_assigned_to_contractor}
                  >
                    <Select
                      className="slct_style"
                      onChange={(value) =>
                        handleFieldChange("contractor", value)
                      }
                      placeholder={constants.contractorFieldPlaceholder}
                    >
                      {contractorList?.length &&
                        contractorList?.map((data: any) => {
                          return <Option value={data._id}>{data?.name}</Option>
                        })}
                        <Select.Option value={constants.addNewContractor}>
                          <UserOutlined /> {constants.addNewContractor}
                        </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
            }
              </Row>
            </Form>
          </Spin>
        </Card>
        <br />
        <Row gutter={[16, 16]}>
          <Col span={4} style={{ marginLeft: '66.5%', alignItems: 'flex-end' }}>
            <Form.Item normalize={Utils.trimSpacesInValues}>
              <Button block className="cncl-btn" onClick={handleCancel}>
                Cancel
              </Button>
            </Form.Item>
          </Col>
          <Col span={4} style={{ alignItems: 'flex-end' }}>
            <Form.Item
              normalize={Utils.trimSpacesInValues}
              name="saveTicket"
            >
              <Button
                block
                className="save-btn"
                type="primary"
                onClick={handleSubmit}
                disabled={
                  ((fieldValues?.issue_title)?.trim()  !==
                    (previousFieldValues?.issue_title)?.trim() && form.getFieldValue("issue_title") !== '') ||
                    fieldValues?.issue_priority !==
                    previousFieldValues?.issue_priority ||
                    (fieldValues?.issue_description)?.trim() !==
                    (previousFieldValues?.issue_description)?.trim() ||
                    (fieldValues?.contractor !==
                    previousFieldValues?.contractor)
                    ? false
                    : true
                }
              >
                {issueDetails?.issue_id || fieldSaved
                  ? `Update`
                  : `Create`}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Col>


      <Col span={6} style={{ alignSelf: "flex-end" }} >
        {imageLoading ? (
          <Card
            className={
              fieldSaved
                ? "img-preview-card-no-attchmt"
                : "img-preview-card-no-attchmt-opq"
            }
          >
            <div className="image-container-prv">
              <Spin spinning={imageLoading} size="large"></Spin>
            </div>
          </Card>
        ) : imageList?.length ? (
          <Card
            className="img-preview-card"
          >
            <div className="image-container-prv">
              <div style={{ alignContent: "center" }}>
                <Image
                  src={
                    imageList[storedImageIndex]?.length > 1
                      ? ([imageList[storedImageIndex][innerImageIndex]] ? [imageList[storedImageIndex][innerImageIndex]] : noResultsImage)
                      : imageList[storedImageIndex] ? imageList[storedImageIndex] : noResultsImage
                  }
                  key={imageList}
                  preview={imageList[storedImageIndex]?.length > 0 ? true : false}
                  alt={`${storedImageIndex}`}
                  className="centrd-img"
                />
                {imageList[storedImageIndex]?.length > 1 ? (
                <Pagination total={innerImageLength[storedImageIndex]} size="small" current={innerImageIndex + 1} 
                className="chartDaysLabel"
                defaultPageSize={1}
                onChange={(target) => setInnerImageIndex(target - 1)}/>
                ) : (
                  ""
                )}
              </div>
            </div>
            <Meta
              className="meta-descrpt-attmnt"
              description={
                attachmentNote[storedNoteIndex]?.length > 35
                  ? seeMore(attachmentNote[storedNoteIndex].substring(0, 35))
                  : attachmentNote[storedNoteIndex]
              }
            />
            <Tooltip title={constants.navigateThroughImages} open={showToolTip}>
            <Pagination total={attachmentsIndex} 
            defaultPageSize={1}
            simple 
            className="pagination-bottom-crd"
            onChange={(target)=> {
                    setStoredImageIndex(target - 1)
                    setStoredNoteIndex(target - 1)
                    setInnerImageIndex(0);
            }}/>
            </Tooltip>
          </Card>
        ) : (
          <Card
            className={
              fieldSaved || isEditReq
                ? "img-preview-card-no-attchmt"
                : "img-preview-card-no-attchmt-opq"
            }
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div className="image-container-prv">
              <img
                src={noAttachmentYet}
                alt={`${storedImageIndex}`}
                className="centered-image"
              />
            </div>

            <Meta
              title="No Attachments Yet !"
              style={{ textAlignLast: "center" }}
            />
          </Card>
        )}

        <Form.Item
          normalize={Utils.trimSpacesInValues}
          name="addAttachment"
        >
          <Button
            block
            type="primary"
            style={{
              height: "45px",
              marginTop: 20,
              whiteSpace: "normal",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
            onClick={() => setIsModalOpen(true)}
            disabled={issueDetails?.issue_id || fieldSaved ? false : true}
          >
            <PlusOutlined/>{" "}
            Add Attachment
          </Button>
        </Form.Item>
      </Col>
      {authStore?.currentUser?.active_role?.role_type === constants.role.Admin &&
      <Col span={24}>
        <div className="text-right mt-4">
          {issueDetails?.issue_id && !fieldSaved && (
            <Button
              key="delete"
              type="primary"
              danger
              onClick={handleDelete}
              className="float-left"
            >
              Delete
            </Button>
          )}
        </div>
      </Col>
}
      <AttachmentUpload
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        ticketId={ticketId}
        setLoading={setLoading}
        loading={loading}
        fetchIssueDetailsWithId={fetchIssueDetailsWithId}
      />
      <Modal
        className="p-5"
        onCancel={() => setSeeMoreNote(false)}
        closable={false}
        footer={null}
        open={seeMoreNote}
        width={800}
      >
        {attachmentNote[storedNoteIndex]}
      </Modal>
      <ConfirmationModal 
        isVisible={isVisible} 
        setIsVisible={setIsVisible} 
        onConfirmFunction={id ? handleEdit : handleSaveTicket} 
        confirmationMessage={"Are you sure you want to proceed ?"} 
        confirmationTitle={id ? "Update Ticket" : "Create Ticket"} />
    </>
  );
};
export default IssueTicketDetails;
