import React from 'react';
import { Modal } from 'antd';

export interface snoozeProps {
    title?: String;
    isVisible?: boolean;
    onClose: () => void;
}

function SnoozeModal(props: snoozeProps) {

    const handleOk = () => {
        // setIsModalVisible(false);
    };

    return (
        <Modal title={props.title} visible={props.isVisible} onOk={handleOk} onCancel={props.onClose}>
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
        </Modal>
    );
}

export default SnoozeModal;
