import Api from '../api'

class Authentication {

    authListener    = null as any;
    currentUser     = null as any;
    token           = null as any;

    onAuthStateChanged(listener : any) {
        this.authListener = listener;
        this.token = sessionStorage.getItem("token");
        if (this.token) {
            this.currentUser = this.decodeJwt(this.token);
            if (this.authListener) {
                this.authListener(this.currentUser);
            }
        }
    }


    async signInWithUserNameAndPassword(username : string, password : string, role_id? : string) {

        const params={
            username    :   username,
            password    :   password,
            role_id     :   role_id
        }

        let response = await Api.post('/login',params)
               
        this.token        = response.data.result;
        this.currentUser  = this.decodeJwt(this.token)
        sessionStorage.setItem("token", this.token);

        if (this.authListener) {
            this.authListener(this.currentUser);
        }
            
        return this.currentUser
                    
   }
    

    signOut() {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("profile");
        this.token       = null;
        this.currentUser = null;
        if (this.authListener) {
            this.authListener(null);
        }
    }

    decodeJwt(token : string) {
        var payload = token.split('.')[1];
        var base64 = payload.replace('-', '+').replace('_', '/');
        return JSON.parse(atob(base64));
    }
}

const appAuth = new Authentication()
export default appAuth