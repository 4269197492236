import React from 'react';
import { Card } from 'antd';

interface Props {
    count?: any,
    description?: any
    onHandleFilterOnCard: () => void;
}

const IssuetrackerOverviewCard: React.FC<Props> = ({ count, description, onHandleFilterOnCard}) => {

    return (
        <>
            <Card className="ovrvw-crd" onClick={(e: any) => {onHandleFilterOnCard(); e.preventDefault();}}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h2 >{count}</h2>
                    <p className="ovrflw-ellpss" style={{ textAlign: 'center' }}>{description}</p> 
                </div>
        </Card>
        </>
    );
}
export default IssuetrackerOverviewCard;
