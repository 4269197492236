import Api from '../api';
import { Role } from '../interface';

class RoleService {

    getRoles = async (id?: number) => {
        if(!id){
        return Api.get(`/roles`)
        }
        return Api.get(`/roles?o_id=${id}`)
    }
    addRole = async (data: Role) => {
        return Api.post('/roles', data)
    }
    updateRole = async (data: Role) => {
        return Api.put(`/roles/${data._id}`, data)
    }
    deleteRole = async (id: String) => {
        return Api.delete(`/roles/${id}`)
    }

    isRoleExist = async (roleName: string, roleId?: string) => {
        return Api.get(`/role_exist/${roleName}?roleid=${roleId || ''}`)
    }
   
}

export default new RoleService();
