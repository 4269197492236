import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

interface Props { //props
  errors? : any
  systemDetails? : any
  isEditable? : boolean;
  clearFieldError : (name : any) => void
  toPrevTabFromSystem : () => void
  toNextTabFromSystem  : (data : any) => void
}

const InspectionSystemForm: React.FC<Props> = ({ clearFieldError , errors , systemDetails , isEditable , toPrevTabFromSystem , toNextTabFromSystem   }) => {

  const [systemFormData ,setSystemFormData] =useState<any>({})

  useEffect(() => {
    if(isEditable){
      setSystemFormData(systemDetails)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemDetails]);

  const updateSystemFormValue = (e :any)=> {
    const { name, value} = e.target;
    clearFieldError(name)
    setSystemFormData((prevState :any )=> ({
        ...prevState,
        [name]: value
    }));
  };

  function toPrevTabFromSystemInfo( e : any){
    toPrevTabFromSystem()
  }

  async function toNextTabFromSystemInfo( e : any){
    toNextTabFromSystem(systemFormData)
  }

  return (
    <div className="row">

              <div className="col-sm-6 mb-2 pr-sm-2">
                <label className="fs-12 ins_lable_clr">System <span className="mand-star">*</span></label>
                <input type="text" 
                      className="form-control shadow-none"
                      name="system_name"
                      value={systemFormData.system_name  ? systemFormData.system_name  : ''}
                      onChange={updateSystemFormValue}
                      placeholder="Enter system name"
                ></input>
                <div className="error-msg">{errors && errors.system_name ? errors.system_name : ''}</div>
              </div>

              <div className="col-sm-6 mb-2 pl-sm-2">
                <label className="fs-12 ins_lable_clr">Building Information</label>
                <input type="text" 
                    className="form-control shadow-none"
                    name="building_info"
                    value={systemFormData.building_info  ? systemFormData.building_info  : ''}
                    onChange={updateSystemFormValue}
                    placeholder="Enter building information"
                ></input>
              </div>

              <div className="col-sm-6 mb-2 pr-sm-2">
                <label className="fs-12 ins_lable_clr">Service Area</label>
                <input type="text" 
                      className="form-control shadow-none"
                      name="service_area"
                      value={systemFormData.service_area  ? systemFormData.service_area  : ''}
                      onChange={updateSystemFormValue}
                      placeholder="Enter service area"
                ></input>
              </div>

              <div className="col-sm-6 mb-2 pl-sm-2">
                <label className="fs-12 ins_lable_clr">Age</label>
                <input type="text" 
                    className="form-control shadow-none"
                    name="age"
                    value={systemFormData.age  ? systemFormData.age  : ''}
                    onChange={updateSystemFormValue}
                    placeholder="Enter age"
                ></input>
              </div>

              <div className="col-sm-6 mb-2 pr-sm-2">
                <label className="fs-12 ins_lable_clr">Refrigent Type</label>
                <input type="text" 
                      className="form-control shadow-none"
                      name="refrigerant_type"
                      value={systemFormData.refrigerant_type  ? systemFormData.refrigerant_type  : ''}
                      onChange={updateSystemFormValue}
                      placeholder="Enter refrigerant type"
                ></input>
              </div>

              <div className="col-sm-6 mb-2 pl-sm-2">
                <label className="fs-12 ins_lable_clr">Make</label>
                <input type="text" 
                      className="form-control shadow-none"
                      name="make"
                      value={systemFormData.make  ? systemFormData.make  : ''}
                      onChange={updateSystemFormValue}
                      placeholder="Enter make"
                ></input>
              </div>

              <div className="col-sm-6 mb-2 pr-sm-2">
                <label className="fs-12 ins_lable_clr">Location</label>
                <input type="text" 
                      className="form-control shadow-none"
                      name="location"
                      value={systemFormData.location  ? systemFormData.location  : ''}
                      onChange={updateSystemFormValue}
                      placeholder="Enter location"
                ></input>
              </div>

              <div className="col-sm-6 mb-2 pl-sm-2">
                <label className="fs-12 ins_lable_clr">Location VH</label>
                <input type="text" 
                      className="form-control shadow-none"
                      name="location_v_h"
                      value={systemFormData.location_v_h  ? systemFormData.location_v_h  : ''}
                      onChange={updateSystemFormValue}
                      placeholder="Enter location"
                ></input>
              </div>

              <div className="col-sm-6 mb-2 pr-sm-2">
                <label className="fs-12 ins_lable_clr">Pkg unit m#</label>
                <input type="text" 
                      className="form-control shadow-none"
                      name="pkg_unit_m"
                      value={systemFormData.pkg_unit_m  ? systemFormData.pkg_unit_m  : ''}
                      onChange={updateSystemFormValue}
                      placeholder="Enter Pkg unit  m#"
                ></input>
              </div>

              <div className="col-sm-6 mb-2 pl-sm-2">
                <label className="fs-12 ins_lable_clr">Pkg unit s#</label>
                <input type="text" 
                      className="form-control shadow-none"
                      name="pkg_unit_s"
                      value={systemFormData.pkg_unit_s  ? systemFormData.pkg_unit_s  : ''}
                      onChange={updateSystemFormValue}
                      placeholder="Enter Pkg unit  s#"
                ></input>
              </div>

              <div className="col-sm-6 mb-2 pr-sm-2">
                <label className="fs-12 ins_lable_clr">Condenser m#</label>
                <input type="text" 
                      className="form-control shadow-none"
                      name="condenser_m"
                      value={systemFormData.condenser_m  ? systemFormData.condenser_m  : ''}
                      onChange={updateSystemFormValue}
                      placeholder="Enter condenser  m#"
                ></input>
              </div>

              <div className="col-sm-6 mb-2 pl-sm-2">
                <label className="fs-12 ins_lable_clr">Condenser s#</label>
                <input type="text" 
                      className="form-control shadow-none"
                      name="condenser_s"
                      value={systemFormData.condenser_s  ? systemFormData.condenser_s  : ''}
                      onChange={updateSystemFormValue}
                      placeholder="Enter condenser  s#"
                ></input>
              </div>

              <div className="col-sm-6 mb-2 pr-sm-2">
                <label className="fs-12 ins_lable_clr">Ah m#</label>
                <input type="text" 
                      className="form-control shadow-none"
                      name="ah_m"
                      value={systemFormData.ah_m  ? systemFormData.ah_m  : ''}
                      onChange={updateSystemFormValue}
                      placeholder="Enter Ah  m#"
                ></input>
              </div>

              <div className="col-sm-6 mb-2 pl-sm-2">
                <label className="fs-12 ins_lable_clr">Ah s#</label>
                <input type="text" 
                      className="form-control shadow-none"
                      name="ah_s"
                      value={systemFormData.ah_s  ? systemFormData.ah_s  : ''}
                      onChange={updateSystemFormValue}
                      placeholder="Enter Ah  s#"
                ></input>
              </div>

              <div className="col-sm-6 mb-2 pr-sm-2">
                <label className="fs-12 ins_lable_clr">Coil m#</label>
                <input type="text" 
                      className="form-control shadow-none"
                      name="coil_m"
                      value={systemFormData.coil_m  ? systemFormData.coil_m  : ''}
                      onChange={updateSystemFormValue}
                      placeholder="Enter coil  m#"
                ></input>
              </div>

              <div className="col-sm-6 mb-2 pl-sm-2">
                <label className="fs-12 ins_lable_clr">Coil s#</label>
                <input type="text" 
                      className="form-control shadow-none"
                      name="coil_s"
                      value={systemFormData.coil_s  ? systemFormData.coil_s  : ''}
                      onChange={updateSystemFormValue}
                      placeholder="Enter coil  s#"
                ></input>
              </div>

              <div className="col-12 text-right mt-4">
                {!isEditable &&
                <Button className="h-36 py-0 pl-4 pr-4 fs-14 ins_bg_clr border-0 font-weight-bold mr-3" 
                        onClick={(e : any)=> {toPrevTabFromSystemInfo(e)}}
                        >Prev
                </Button>
                }
                <Button className="h-36 py-0 pl-4 pr-4 fs-14 ins_bg_clr border-0 font-weight-bold"   
                        onClick={(e : any)=> {toNextTabFromSystemInfo(e)}}
                        >{isEditable ? 'Submit' : 'Next'}
                </Button>
              </div> 
    </div>
  )
}

export default InspectionSystemForm;
