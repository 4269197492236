import authStore from './authStore';

const { EventSourcePolyfill } = require('event-source-polyfill');
const EventSource = EventSourcePolyfill;

class  EventStore {

    eventSource? : EventSource

    constructor(){
        this.eventSource = undefined
    }

    async connectEventSource(){
        this.eventSource = new EventSource(`${process.env.REACT_APP_SSE_BASE_URL}/sensorboxevents`,{
          headers : {
            'authorization' : `Bearer ${authStore.token}`
          }
        });
      }
}

const eventStore = new EventStore()
export default eventStore