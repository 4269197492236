const rules: any = {
    password: [{ required: true, message: "Please enter your password!" }],
    email: [
        { required: true, message: "Please input your email!" },
        {
            pattern: /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            message: 'Invalid email',
        }
    ]
};

  export default rules;