import React, { useEffect, useState } from 'react';
import { Button, Tabs , Tab } from 'react-bootstrap';
import { RouteComponentProps ,useHistory, useLocation} from "react-router-dom";
import Routes from '../../../pages/Routes'
import _ from 'lodash';
import authStore from '../../../store/authStore';
import SelectComponent from '../../../components/select';
import LoadingWheel from '../../../components/loadingwheel'
import inspectionChecklistService from '../../../services/InspectionChecklistServices';
import userService from '../../../services/userService';
import roleService from '../../../services/roleService';
import constants from '../../../config/constants';
import toast from '../../../components/toast';
import validate from '../../../helpers/validation';
import CustomerForm from '../../../components/inspection-user-form/index'
import SystemForm from '../../../components/inspection-system-form/index'
import ChecklistsForm from '../../../components/inspection-checklist-form/index'
import './inspection-form.css';

interface Props extends RouteComponentProps {}

const InspectionForm: React.FC<Props> = () => {
  let history = useHistory();
  const location = useLocation() as any;
  const org_id = location?.state?.org_id;
  const page_number = location?.state?.page_number;
  const checklist_id = new URLSearchParams(location?.search).get('checklist_id');
  const inspection_id = new URLSearchParams(location?.search).get('inspection_id');

  
  const [checkLisItems, setCheckLisItems] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [customerList ,setCustomerList] = useState([])
  const [visibleUserFrom ,setVisibleUserForm] = useState(false);
  const [customerDropDown, setCustomerDropDown] = useState<any>('');
  const [customerSelected , setCustomerSelected] = useState(false);
  const [systemInfoAdded ,setSystemInfoAdded] = useState(false);
  const [consumerId ,setConsumerId] = useState('');
  const [inspectionId, setInspectionId] = useState<any>(null);
  const [inspections , setInspections] = useState<any>([])
  const [activeTab ,setActiveTab] = useState('customerinfo')
  const [findings ,setFindings] = useState('')
  const [role , setRole ] = useState<any>({})
  const [errors, setErrors] = useState<any>({})

  useEffect(() => {
    if(checklist_id){
      fetchInspecionDetails(checklist_id)
    }

    if(inspection_id) {
      setInspectionId(inspection_id)
      setActiveTab('inspections');
    }

      fetchInspectionMasterData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchInspecionDetails = async (id : any) => {
    try{
      setLoading(true);
      let inspectionsMasterData = await inspectionChecklistService.getInspectionDetailsFromId(id);
      const { checklistInspections , message } = inspectionsMasterData.data;
      if(message === 'Success'){
        setLoading(false);
        setInspections(checklistInspections?.inspections)
        setFindings(checklistInspections?.findings)
        setActiveTab('inspections');
      }

    }catch(error){
      setLoading(false);
      let errMsg = error?.message;
      if(error?.response?.data?.message) {
        errMsg = error?.response?.data?.message
      }
      toast.error(errMsg);
    }
  }

  const fetchInspectionMasterData = async () => {
    try {
      setLoading(true);
      let inspectionsMasterData = await inspectionChecklistService.getInspectionsMasterData();
      const { masterDatas } = inspectionsMasterData.data;
      const checkListGroup = _.groupBy(masterDatas, 'check_list_category');
      setLoading(false);
      setCheckLisItems(checkListGroup)
      if(!checklist_id && !inspection_id){
        let consumerList = await userService.getCustomers(org_id)
        const rolesData = await roleService.getRoles(org_id ? org_id : '') 
        const customerRole = rolesData?.data.roles?.find((role:any) => role.role_type === 4);
        setRole(customerRole)
        setCustomerList(consumerList)
      }
    } catch (error) {
      setLoading(false);
      let errMsg = error?.message;
      if(error?.response?.data?.message) {
        errMsg = error?.response?.data?.message
      }
      toast.error(errMsg);
    }
  }

  const handleCustomerSelection = (option : any) => {
    clearFieldError('customer')
    setVisibleUserForm(false)
    setCustomerDropDown(option ? option : '')
  }

  const validateCustomerInfo = () => {
    let error: any = {}

    if(!customerDropDown){
      error.customer = constants.message.inspection_customer_required
    }else{
      error.customer = ''
    }

    setErrors(error)
    return !(error.customer )
  }

  const validateCustomerForm = (costomerFormData : any) => {
    let error: any = {}

    if (validate.isEmpty(costomerFormData?.name)) {
        error.name = constants.message.inspection_consumer_required
    } else {
      if(!validate.isName(costomerFormData?.name)){
        error.name = constants.message.name_valid
      }else{
        error.name = ''
      }
    }

    if (validate.isEmpty(costomerFormData?.email)) {
        error.email = constants.message.inspection_email_required
    } else {
        if(!validate.isEmail(costomerFormData?.email)){
            error.email = constants.message.email_valid
        }
    }

    if (!costomerFormData?.phonenumber) {
      error.phonenumber = constants.message.phoneNumber_mandatory
    } else if(!(validate.isPhoneNumber(costomerFormData?.phonenumber))){
      error.phonenumber = constants.message.phoneNumber_valid
    } else{
      error.phonenumber = ''
    }
    
    if (validate.isEmpty(costomerFormData?.street)) {
      error.street = constants.message.street_mandatory
    } else {
        error.street = ''
    }

    if (validate.isEmpty(costomerFormData?.city)) {
      error.city = constants.message.city_mandatory
    } else {
      if(validate.isValidCity(costomerFormData?.city)){
        error.city = ''
      }else{
        error.city = constants.message.city_valid
      }
    }

    if (validate.isEmpty(costomerFormData?.country)) {
      error.country = constants.message.country_mandatory
    } else {
        error.country = ''
    }

    if (validate.isEmpty(costomerFormData?.state)) {
      error.state = constants.message.state_mandatory
    } else {
        error.state = ''
    }

    if (validate.isEmpty(costomerFormData?.zip_code)) {
      error.zip_code = constants.message.zip_mandatory
    } else {
        if(validate.isValidZipCode(costomerFormData?.zip_code)) {
          error.zip_code = ''
        }else{
          error.zip_code = constants.message.zip_valid
        }
    }

    if(costomerFormData?.location){
        error.address = ''
      }else{
        error.address = constants.message.address_valid
    }   

    setErrors(error)
    return !(error.name || error.email || error.phonenumber || error.street || error.city || error.country || error.state || error.zip_code || error.address)
}


  const validateSystemForm = (systemFormData : any) => {
    let error: any = {}

    if (validate.isEmpty(systemFormData?.system_name)) {
        error.system_name = constants.message.inspection_systemname_required
    } else {
        error.system_name = ''
    }

    setErrors(error)
    return !(error.system_name)
  }

  const onHandleAddNewCustomer = () => {
    setVisibleUserForm(true)
  }

  const clearFieldError = (field: string) => {
    let prevErrors = errors
    prevErrors[field] = ''
    setErrors(prevErrors)
  }

  const toPrevTabFromSystem = async () => {
    if (activeTab === 'systeminfo') {
      clearFieldError('system_name')
      setActiveTab('customerinfo');
    }
  }

  const toPrevTabFromInspections = async () => {
    if (activeTab === 'inspections') {
      setActiveTab('systeminfo');
    }
  }

  const submitInspections = async (inspections? : any , findings? : any , action? : any ) => {      
    try{
      setLoading(true);
      setInspections(inspections);
      let data : any = {
        field_inspection_id : inspectionId,
        inspected_by : {_id : authStore.currentUser.uid, name : authStore.currentUser.name},
        findings : findings,
        inspections : inspections,
        action : action
      }

      if(checklist_id){
        data.field_inspection_id = checklist_id ? checklist_id : ''
        let inspection = await inspectionChecklistService.updateInspectionChecklist(data) as any;
        if(inspection?.data?.message === 'Success'){
          setLoading(false);
          toast.success('Successfully Edited Inspections')
          history.push({
            pathname: `${Routes.inspectionchecklist}`,
            state: {
              page_number : page_number
            },
          });
        }
      }else{
          let inspection = await inspectionChecklistService.addInspectionChecklist(data , org_id) as any;
          if(inspection?.data?.message === 'Success'){
            setLoading(false);
            toast.success('Successfully Created Inspections')
            history.push({
              pathname: `${Routes.inspectionchecklist}`,
              state: {
                page_number : page_number
              },
            });
          }
        }
      }
    catch(error){
      setLoading(false);
      toast.error(error.response?.data?.message)
    }
  }

  const isSystemExist = async (data : any) => {
    if(!data.system_name?.trim()) return false;
    try {
      const resp = await inspectionChecklistService.isSystemExist(data.system_name,data?._id,data?.consumer);
      const { system_exist_status } = resp.data;
      let errorMsg = system_exist_status ? constants.message.system_exists : '';  
      setErrors((prev: any) => ({...prev, system_name: errorMsg }));
      return system_exist_status ? true : false;
    } catch (error) {}
  }

  const toNextTabFromSystem = async (data : any) => {
    try{    
      data.consumer = customerDropDown?._id  ? customerDropDown?._id : consumerId
      if(validateSystemForm(data)){
        setLoading(true);
        if(systemInfoAdded){
            data._id = inspectionId
            const isSystemExists = await isSystemExist(data);
            if(isSystemExists) { setLoading(false); return false};
            let userData = await inspectionChecklistService.updateInspection(data);
            if(userData?.data?.message === 'Success'){
              setLoading(false);
              toast.success('Successfully Edited System Info')
              if (activeTab === 'systeminfo') {
                setActiveTab('inspections');
              }
            }
        }else{
            const isSystemExists = await isSystemExist(data);
            if(isSystemExists) {setLoading(false); return false};
            let systemData = await inspectionChecklistService.addInspection(data , org_id) as any;
            setInspectionId(systemData?.data?.id)
            if(systemData?.data?.message === 'Success'){
              setLoading(false);
              toast.success('Successfully Created System Info')
              if (activeTab === 'systeminfo') {
                setSystemInfoAdded(true)
                setActiveTab('inspections');
              }
            }
        }
      }
    }
    catch(error){
      setLoading(false);
      toast.error(error.response?.data?.message)
    }
  }

  const toPrevPageFromCustomerForm = async () => {
    setVisibleUserForm(false)
  }

  const createUser = async (data: any) => {
    try{
      if(customerDropDown){
        if (activeTab === 'customerinfo') {
            setActiveTab('systeminfo');
        }
        }else{
          if(visibleUserFrom){
            if(validateCustomerForm(data)){
              if(customerSelected){
                data._id = consumerId
                let userData = await inspectionChecklistService.editUser(data);
                if(userData?.data?.message === 'Success'){
                  toast.success('Successfully Edited User')
                  if (activeTab === 'customerinfo') {
                    setActiveTab('systeminfo');
                  }
                }
              }else{
                data.roles=role
                let userData = await inspectionChecklistService.addUsers(data , org_id) as any;
                setConsumerId(userData?.data._id)
                if(userData?.data?.message === 'Success'){
                  toast.success('Successfully Created User')
                  if (activeTab === 'customerinfo') {
                    setCustomerSelected(true)
                    setActiveTab('systeminfo');
                  }
                }
              }
            }
          }else{
            if(validateCustomerInfo()){
            }
          }
        }
    }catch(error){
      toast.error(error.response?.data?.message)
    }
  }

  return (
    <div className="padding-b50" id="groups">
      <h5 className="fs-18 fnt-w-bld mb-3">{checklist_id ? `EDIT INSPECTION` : 'INSPECTION'}</h5>
      <Tabs defaultActiveKey="customerinfo" activeKey={activeTab} id="form-tab" className="mb-3 ins_tab_style border-0">
        {(!checklist_id) && !inspection_id && 
        <Tab eventKey="customerinfo" title="Customer Info">
          <div className="tab_wrapper w-100 m-auto fnt-w-600">
            { !visibleUserFrom &&
            <>
            <div className="w-100 position-relative mb-4">
              <label className="custom-label">Enter Customer Name</label>
                <div className="form-group mb-0 ins_sel_close inspection_form_select w-100 h-36 fs-14 shadow-none mb-3 fnt-w-600 mb-4">
                  <SelectComponent
                    name="customer"
                    isDisabled={false}
                    data={customerList}
                    value={customerDropDown}
                    onChange={(value: any) => handleCustomerSelection(value)}
                    showClose={true}
                    clearValue={() => handleCustomerSelection(null)}
                  />
                  <div className="error-msg">{errors && errors.customer ? errors.customer : ''}</div>
                </div>
            </div>

            {Object.keys(customerDropDown).length === 0 && !(org_id ? authStore?.currentUser?.oid !== org_id : false) &&
            <p className="d-flex align-items-center">Customer Not Found <Button className="btn btn-small  border-0 ml-1" onClick={onHandleAddNewCustomer}>Add new</Button></p>
            }  
            </>
             }
            {
              <CustomerForm
                visibleUserFrom = {visibleUserFrom}
                errors = {errors}
                clearFieldError={(data: any) => clearFieldError(data)}
                createUser={(data: any) => createUser(data)}
                toPrevPageFromCustomerForm ={() => toPrevPageFromCustomerForm()}
              />
            }
            </div>
        </Tab>
        }
        {(!checklist_id) && !inspection_id && 
        <Tab eventKey="systeminfo" title="System Info">
          <div className="tab_wrapper w-100 m-auto fnt-w-600">
            <label className="fs-14">Enter System Information</label>
              <SystemForm
                errors = {errors}
                clearFieldError={(data: any) => clearFieldError(data)}
                toPrevTabFromSystem ={() => toPrevTabFromSystem()}
                toNextTabFromSystem={(data: any) => toNextTabFromSystem(data)}
              />
          </div>
        </Tab>
        }
        <Tab eventKey="inspections" title="Inspections">
          <ChecklistsForm
            inspectionDetails = {inspections}
            findings = {findings}
            isEditable={(checklist_id || inspection_id) ? true : false} 
            inspectionMasterData = {checkLisItems}
            toPrevTabFromInspections ={() => toPrevTabFromInspections()}
            submitInspections={(data: any, findings : any, action : any) => submitInspections(data, findings, action)}
          />
        </Tab>
      </Tabs>
      {
          loading && <LoadingWheel />
      }
    </div>
  )
}

export default InspectionForm
