import React from "react";
import { Table} from 'antd';
const columns = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
  },
  {
    title: 'Sensor name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Sensor type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Sensor value',
    dataIndex: 'value',
    key: 'value',
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (status : any) => {
      return(
        <div className="small-circle-alert danger"></div>
      )
    },
  },
];

const data = [
  {
    key: '1',
    index:'1',
    name: '	Compressor Phase I current	',
    type: 32,
    value: '0.00 amp',
    status: ['nice', 'developer'],
  },
  {
    key: '2',
    index:'2',
    name: '	Compressor Phase I current	',
    type: 42,
    value: '0.00 amp',
    status: ['loser'],
  },
];
function SystemTable() {
  return (
    <>
      <Table className="system-table" columns={columns} dataSource={data} pagination={false} />
    </>
  );
}
export default SystemTable;
