import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Organization } from '../../interface'
import userService from '../../services/userService';
import organizationService from '../../services/organizationService';
import constants from '../../config/constants';
import Select from '../select';
import Utility from '../../helpers/Utility';
import validate from '../../helpers/validation';
import toast from '../../components/toast';
import Close from '../../assets/close.png';
import ConfirmDialogModal from '../../components/confirmDialogModal'
import PhoneInput from 'react-phone-number-input'
import {isValidPhoneNumber} from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import CountryOptions from '../../config/country.json'

export interface organizationFormProps {
    setVisibleClose?: () => void;
    selectedOrganization?: Organization;
    visible: boolean;
    addOrganization: (org: Organization) => void;
    updateOrganization: (org: Organization) => void;
    enableDisableOrganizations : (org: Organization) => void;
}

function AddOrganization(props: organizationFormProps){

    const [isUpdate, setIsUpdate] = useState(false)
    const [confirmEnableDisableModal, setConfirmEnableDisableModal] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [needToSave, setNeedToSave] = useState(false)
    const [formData ,setFormData] =useState<any>({})
    const [adminDatas,setAdminDatas] =useState<any>({name : '',email :'',newValue : true})
    const [errors, setErrors] = useState<any>({})

    useEffect(() => {
        if(props.visible){
            initialSetup();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.visible]);

    const initialSetup = () => {
        if (props.selectedOrganization?.hasOwnProperty('_id')) {
            setIsUpdate(true)
            setIsEdit(false)
            setFormData(props.selectedOrganization)
        }
    }

    const saveOrganisation = async () => {
        if (validateField()){

            const isNameExist = await isOrganizationExist();
            if(isNameExist) return false;

            let formValue={...formData}
            let adminValues=formValue?.admins?.map((item :any) => {  //to remove newvalue property from admins array (we used to for remove close button while adding multiple admins)
                delete item.newValue; 
                return item; 
            })

            if (isUpdate) {
                if (!needToSave) {
                    closeForm();
                    return
                }
                    let value={
                        _id : formData._id,
                        name : formData.name,
                        street : formData?.street,
                        city : formData?.city,
                        state : formData?.state,
                        country : formData?.country,
                        zip_code : formData?.zip_code,
                        admins : adminValues,
                        phone : formData.phone,
                        office : formData?.office,
                        coordinates : formData.coordinates
                    }
                    props.updateOrganization(value)
                    closeForm()
            }else {
                let value={
                    name : formData.name,
                    street : formData?.street,
                    city : formData?.city,
                    state : formData?.state,
                    country : formData?.country,
                    zip_code : formData?.zip_code,
                    admins : adminValues,
                    phone : formData.phone,
                    office : formData?.office,
                    coordinates : formData.coordinates
                }
                props.addOrganization(value)
                closeForm()
            }
        }
    }

    const isOrganizationExist = async () => {
        if(!formData.name?.trim()) return;
        try {
          const resp = await organizationService.isOrganizationNameExist(formData.name,formData?._id);
          const { organization_exist_status } = resp.data;
          let errorMsg = organization_exist_status ? constants.message.organization_exists : '';  
          setErrors((prev: any) => ({...prev, name: errorMsg }));
          return organization_exist_status;
        } catch (error) {}
    }

    const enableDisableOrganization = (value : any) => {
            props.enableDisableOrganizations(value)
            setConfirmEnableDisableModal(false)
    }

    const closeForm = () => {
        setFormData({})
        setAdminDatas({name : '',email :'' ,newValue :true})
        setIsEdit(true);
        setIsUpdate(false);
        setErrors({});
        if (props?.setVisibleClose){
            props.setVisibleClose()
        }
    }

    const closeModal = () => {
        setConfirmEnableDisableModal(false)
    }

    const isDisabled = () => !isEdit

    const updateFormValue = (e :any)=> {
        const { name, value} = e.target;
        clearFieldError(name)
        setNeedToSave(true)
        setFormData((prevState :any )=> ({
            ...prevState,
            [name]: value
        }));
    };

    const updatePhoneNumber = (number :any)=> {
        clearFieldError('phone')
        setNeedToSave(true)
        setFormData((prevState :any )=> ({
            ...prevState,
            'phone': number
        }));
    };

    const updateOfficeNumber = (number :any)=> {
        clearFieldError('office')
        setNeedToSave(true)
        setFormData((prevState :any )=> ({
            ...prevState,
            'office': number
        }));
    };

    const updateAdminData = (e :any)=> {
        const { name, value } = e.target;
        clearFieldError(name)
        clearFieldError('admins')
        setAdminDatas((prevState :any )=> ({
            ...prevState,
            [name]: value
        }));
    };

    const addAdminData = () => {

        if (validateAdmins()){
            setNeedToSave(true)
            setFormData((prevState :any )=> ({
                ...prevState,
                admins: prevState.admins ? [...prevState.admins, adminDatas] : [adminDatas]
            }));

            setAdminDatas({name : '',email :'' ,newValue :true})
        }
    }

    const removeAdmin = (admin :any)=> {
        const checkAdmin=props.selectedOrganization?.admins?.includes(admin)
        if(!checkAdmin)
        {
            setFormData((prevState :any )=> ({
                ...prevState,
                admins: prevState.admins ? formData.admins.filter((item :any) => item.name !== admin.name) : formData.admins.filter((item :any) => item.email !== admin.email)
            }));
        }
    };

    const updateAddress = async (e: any) => {
        const { name, value } = e.target;
        setFormData((prevState :any )=> ({
            ...prevState,
            coordinates : ''
        }));
        clearFieldError(name)
        setNeedToSave(true)
        setFormData((prevState :any )=> ({
            ...prevState,
            [name]:value
        }));

        if(formData?.street && formData?.city && formData?.state && formData?.country && formData?.zip_code){
            try {
                let place = `${formData?.street} , ${formData?.state} , ${formData?.country} , ${formData?.zip_code}`
                const coordinates = await userService.getCoordinatesList(place)
                setFormData((prevState :any )=> ({
                    ...prevState,
                    coordinates: coordinates
                }));   
            } catch (error) {
                toast.error(error.response.data.message)
            }
        }
    }

    const validateAdmins = () => {
        let error: any = {}

        if (validate.isEmpty(adminDatas?.name)) {
            error.admin_name = constants.message.organization_admin_name_required
        } else {
            error.admin_name = ''
        }

        if (validate.isEmpty(adminDatas?.email)) {
            error.admin_email = constants.message.organization_admin_email_required
        } else {
            if(!validate.isEmail(adminDatas?.email)){
                error.admin_email = constants.message.email_valid
            }else{
                const emailExists=formData?.admins?.filter((item :any) => {return item.email === adminDatas?.email})
                if(emailExists?.length){
                    error.admin_email = constants.message.organization_admin_email_exists_required
                }
            }
        }

        setErrors(error)
        return !(error.admin_name || error.admin_email)
    }

    const validateField = () => {
        let error: any = {}

        if (validate.isEmpty(formData.name)) {
            error.name = constants.message.organization_name_required
        } else {
            if(validate.isValidOrgName(formData?.name)){
                error.name = ''
            }else{
                error.name = constants.message.orgnization_name_valid
            }
        }

        if(!formData?.street){
            error.street = constants.message.street_mandatory
        }else{
            error.street = ''
        }

        if(!formData?.city){
            error.city = constants.message.city_mandatory
        }else{
            if(validate?.isValidCity(formData?.city)){
                error.city = ''
            }else{
                error.city = constants.message.city_valid
            }
        }

        if(!formData?.state){
            error.state = constants.message.state_mandatory
        }else{
            error.state = ''
        }

        if(!formData?.country){
            error.country = constants.message.country_mandatory
        }else{
            error.country = ''
        }

        if(!formData?.zip_code){
            error.zip_code = constants.message.zip_mandatory
        }else{
            if(validate?.isValidZipCode(formData?.zip_code)){
                error.zip_code = ''
            }else{
                error.zip_code = constants.message.zip_valid
            }
        }

        if (!formData?.admins) {
            error.admins = constants.message.organization_admins_required
        } else {
            if(formData?.admins.length >0){
            error.admins = ''
            } else{
            error.admins = constants.message.organization_admins_required
            }
        }

        if (!formData?.phone) {
            error.phone = constants.message.phoneNumber_mandatory
        } else if(!(isValidPhoneNumber(formData?.phone))){
            error.phone = constants.message.phoneNumber_valid
        } else{
            error.phone = ''
        }

        if (!formData?.office) {
            error.office = constants.message.phoneNumber_mandatory
        } else if(!(isValidPhoneNumber(formData?.office))){
            error.office = constants.message.phoneNumber_valid
        } else{
            error.office = ''
        }

        if(formData?.street && formData?.city && formData?.state && formData?.country && formData?.zip_code){
            if(formData?.coordinates){
                error.address = ''
            }else{
                error.address = constants.message.address_valid
            }   
        }
        setErrors(error)
        return !(error.name || error.address || error.admins || error.admin_name || error.admin_email || error.phone || error.office || error.street || error.city || error.state || error.country || error.zip_code)
    }

    const clearFieldError = (field: string) => {
        let prevErrors = errors
        prevErrors[field] = ''
        setErrors(prevErrors)
    }

    const getSaveButtonDisabledCondition = () => {
        if (!isUpdate) {
            return false
        } else {
            return !(isEdit && props.selectedOrganization?._id)
        }
    }

    const disableOrEnableOrganization = () => {
        setConfirmEnableDisableModal(true)
    }
    
    const handleCountrySelection = (option: any) => {
        setNeedToSave(true)
        clearFieldError('country')
        setFormData((prevState :any )=> ({
            ...prevState,
            'country': option._id,
            'state' : ''
        }));
    }

    const handleStateSelection = (option: any) => {
        setNeedToSave(true)
        clearFieldError('state')
        setFormData((prevState :any )=> ({
            ...prevState,
            'state': option._id
        }));
    }

    const toggleEdit = () => {
        if (isEdit) {
            initialSetup()
        }
        setIsEdit(!isEdit)
    }

    const showConfirmModal = () => {
        closeForm()
        Utility.showModal('confirmmodal')
    }

    const countryValue = CountryOptions.find((value:any)=>(value._id === formData?.country)) 
    const statesValue = CountryOptions.find((value:any)=>(value._id === formData?.country)) 
    const states = statesValue ? statesValue?.states : [];
    const stateValue = states.find((value:any)=>(value._id === formData?.state)) 

    return (
        <Modal show={props.visible} onHide={closeForm}>
            <Modal.Header className="py-3 px-3 font-weight-bold" closeButton>
                {isUpdate ? "Edit Organization" : "Add Organization"}
            </Modal.Header>
            <Modal.Body >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {isUpdate &&
                                <button type="button"
                                    className="btn btn-edit"
                                    onClick={toggleEdit}>
                                    {isEdit ? "Cancel Edit" : "Edit"}
                                </button>
                                }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="input-text-field">
                                <div className="form-group">
                                    <label className="custom-label">Organization Name <span className="mand-star">*</span></label>
                                    <input type="text"
                                        className="form-control"
                                        name="name"
                                        value={formData.name  ? formData.name  : ''}
                                        onChange={updateFormValue}
                                        onBlur={isOrganizationExist}
                                        maxLength={50}
                                        disabled={isDisabled()}
                                        placeholder="Enter Organization Name" />
                                    <div className="error-msg">{errors && errors.name ? errors.name : ''}</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="custom-label" >Phone <span className="mand-star">*</span></label>
                                        <PhoneInput
                                            name = "phone"
                                            disabled={isDisabled()} 
                                            maxLength = {15}
                                            placeholder="Enter phone number"
                                            value={formData.phone ? formData.phone : ''}
                                            onChange={updatePhoneNumber}
                                        />
                                    <div className="error-msg">{errors && errors.phone ? errors.phone : ''}</div>
                            </div>
                        </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                        <label className="custom-label" >Office number <span className="mand-star">*</span></label>
                                        <PhoneInput
                                            name = "office"
                                            disabled={isDisabled()}
                                            maxLength = {15}
                                            placeholder="Enter phone number"
                                            value={formData.office ? formData.office : ''}
                                            onChange={updateOfficeNumber}
                                        />
                                        <div className="error-msg">{errors && errors.office ? errors.office : ''}</div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                        <label className="custom-label" >Street <span className="mand-star">*</span></label>
                                        <input type="text" 
                                                className="form-control" 
                                                id="" 
                                                aria-describedby="" 
                                                value={formData.street ? formData.street : ''}
                                                name="street"
                                                onChange={updateAddress}
                                                maxLength={50}
                                                disabled={isDisabled()}
                                                placeholder="Enter street" />
                                        <div className="error-msg">{errors && errors.street ? errors.street : ''}</div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                        <label className="custom-label" >City <span className="mand-star">*</span></label>
                                        <input type="text" 
                                                className="form-control" 
                                                id="" 
                                                aria-describedby="" 
                                                value={formData.city ? formData.city : ''}
                                                name="city"
                                                onChange={updateAddress}
                                                maxLength={50}
                                                disabled={isDisabled()}
                                                placeholder="Enter city" />
                                        <div className="error-msg">{errors && errors.city ? errors.city : ''}</div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                        <label className="custom-label" >Country <span className="mand-star">*</span></label>
                                        <Select  name="country" 
                                                isDisabled = {isDisabled()}
                                                data       = {CountryOptions} 
                                                value      = {countryValue ? countryValue : ''}  
                                                onChange   = {handleCountrySelection}
                                        />
                                        <div className="error-msg">{errors && errors.country ? errors.country : ''}</div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                        <label className="custom-label" >State <span className="mand-star">*</span></label>
                                            <Select  name="state" 
                                                isDisabled = {isDisabled()}
                                                data       = {states} 
                                                value      = {stateValue ? stateValue : ''}  
                                                onChange   = {handleStateSelection}
                                            />
                                        <div className="error-msg">{errors && errors.state ? errors.state : ''}</div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                        <label className="custom-label" >Zip Code <span className="mand-star">*</span></label>
                                        <input type="text" 
                                                className="form-control" 
                                                id="" 
                                                aria-describedby="" 
                                                value={formData.zip_code ? formData.zip_code : ''}
                                                name="zip_code"
                                                onChange={updateAddress}
                                                maxLength={50}
                                                disabled={isDisabled()}
                                                placeholder="Enter zip code" />
                                        <div className="error-msg">{errors && errors.zip_code ? errors.zip_code : ''}</div>
                                </div>
                            </div>
                            
                        {errors?.address &&
                        <div className="col-md-12">
                            <div className="form-group">
                                <div className="error-msg">{errors && errors.address ? errors.address : ''}</div>
                            </div>
                        </div>
                        }

                        <div className="col-md-12">
                            <div className="input-text-field">
                                <div className="form-group">
                                    <label className="custom-label">Invite Admins <span className="mand-star">*</span></label>
                                        {
                                        formData?.admins?.length > 0 &&
                                        <div className="card p-3 organisation-admin-list">  
                                            <div className="input-text-field" >
                                                    <div className="float-left mh-1 d flex-wrap">
                                                        {
                                                            formData?.admins?.map((admin: any) => (
                                                                <div className="d-flex align-items-center bg-light rounded p-2 mb-2 mr-2">
                                                                    {admin?.name+"("+admin?.email+")"}
                                                                    {admin.newValue &&
                                                                    <img
                                                                    src={Close}
                                                                    className="ml-2 cursor-pointer"
                                                                    height="10"
                                                                    alt=""
                                                                    onClick={() => removeAdmin(admin)}
                                                                />
                                                                    }
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                            </div>
                                        </div>
                                        }
                                    <div className="error-msg">{errors && errors.admins ? errors.admins : ''}</div>
                                </div> 
                            </div>
                        </div>
                        
                        <div className="col-md-5">
                            <div className="input-text-field">
                                <div className="form-group">
                                        <input type="text"
                                            className="form-control"
                                            name="name"
                                            value={adminDatas?.name}
                                            onChange={updateAdminData}
                                            maxLength={100}
                                            disabled={isDisabled()}
                                            placeholder="Enter Admin's Name" />
                                        <div className="error-msg">{errors && errors.admin_name ? errors.admin_name : ''}</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div className="input-text-field">
                                <div className="form-group">
                                        <input type="text"
                                            className="form-control"
                                            name="email"
                                            value={adminDatas?.email}
                                            onChange={updateAdminData}
                                            maxLength={100}
                                            disabled={isDisabled()}
                                            placeholder="Enter Email Address" />
                                        <div className="error-msg">{errors && errors.admin_email ? errors.admin_email : ''}</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="input-text-field">
                                <div className="form-group">
                                    <button
                                    type="button" 
                                    disabled={getSaveButtonDisabledCondition()} 
                                    className="btn btn-primary default-btn"
                                    onClick={() => addAdminData()}
                                    >Add
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <ConfirmDialogModal
                            title={formData.is_active? "Disable Organization" : "Enable Organization"}
                            message={formData.is_active? "Are you sure you want to disable this organization" : "Are you sure you want to enable this organization"}
                            visible = {confirmEnableDisableModal}
                            confirm ={"YES"}
                            action={() => enableDisableOrganization(formData)}
                            toggleVisibility={closeModal}
                        />

            <div className={props.selectedOrganization?._id && isEdit ? "modal-footer  organization-modal-footer-custom" : "modal-footer"}>
                {props.selectedOrganization?._id && isEdit &&
                    <button
                        type="button"
                        className="btn btn-inverse-info btn-rounded btn-icon"
                        onClick={showConfirmModal}
                    >
                        <i className="fa fa-trash-o fa-lg" style={{color: "red"}}/>
                    </button>
                    }

                {props.selectedOrganization?._id && isEdit &&
                    <button type="button" 
                            className="btn btn-secondary mr-auto"
                            onClick={() => disableOrEnableOrganization()}
                            >
                            {props.selectedOrganization?.is_active ? "Disable" : "Enable"}
                   </button>
                    }

                <button type="button" disabled={getSaveButtonDisabledCondition()} className="btn btn-primary default-btn"
                    onClick={() => saveOrganisation()}
                >{isUpdate ? "Update" : "Save"}
                </button>
            </div>
        </Modal>
    );
}

export default AddOrganization
