import React, { useState } from "react";
import { Form, Input, Button, message, Spin } from "antd";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";

import userService from "../../services/userService";
import authStore from "../../store/authStore";
import Routes from "../../routing/routes";
import rules from "./loginform.validation";

import logo from "../../assets/images/logo.png";
import LoginImage from "../../assets/images/login-image.png";

export interface LoginProps extends RouteComponentProps {}

function Login(props: LoginProps) {
  const [loginVisible, setLoginVisible] = useState(true);
  const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);
  const [isLoading, toggleLoader] = useState(false);

  const onFinishLogin = async (values: any) => {
    try {
      if(!isLoading){
        toggleLoader(true);
        let role_id: any;
        if (props.location.search) {
          const urlParams = new URLSearchParams(props.location.search);
          role_id = urlParams.get("role_id");
        }

        await authStore.signInWithUserNameAndPassword(
            values?.username?.trim(),
            values?.password,
            role_id
        );
        message.success("Successfully logged in");
      }
    } catch (error) {
      toggleLoader(false);
      message.error(error.response.data.message);
    }
  };

  const onFinishForgotPassword = async (values: any) => {
    try {
      toggleLoader(true);
      await userService.sendResetEmail(values.email);
      toggleLoader(false);
      message.success(
        "Link to reset your password has been sent to your email id. Please check your email."
      );
      setForgotPasswordVisible(false);
      setLoginVisible(true);
    } catch (error) {
      toggleLoader(false);
      message.error(error.response.data.message);
    }
  };

  const onFinishForgotPasswordFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onClickForgotPassword = () => {
    setLoginVisible(false);
    setForgotPasswordVisible(true);
  };

  const onCancelForgotPassword = () => {
    setForgotPasswordVisible(false);
    setLoginVisible(true);
  };

  return (
    <>
      <div className="d-flex login-container" style={{ maxHeight: "calc(100vh)" }}>
        <div className="flex-fill login-left position-relative">
          <img src={LoginImage} alt="login" />
          <div className="position-absolute left-text">
            <p className="mb-0">
              A patented device that provides intelligent monitoring on any
              commercial or residential building system
            </p>
          </div>
        </div>
        <div className="flex-auto hvac-login-form">
          <div className="d-flex h-100 justify-content-center align-items-center hvac-form-container">
            <div className="flex-fill image-container">
              <Link to={""}>
                <img src={logo} className="logo-img mb-5" alt="logo" />
              </Link>
              {loginVisible && (
                <Form
                  name="basic"
                  layout="vertical"
                  initialValues={{ remember: true }}
                  onFinish={onFinishLogin}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Username"
                    name="username"
                    rules={rules.email}
                  >
                    <Input size="large" />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={rules.password}
                  >
                    <Input.Password size="large" />
                  </Form.Item>

                  <Form.Item className="text-right">
                    <Button
                      type="link"
                      className="login-form-forgot"
                      onClick={onClickForgotPassword}
                    >
                      Forgot password?
                    </Button>
                  </Form.Item>

                  <Form.Item className="login-button">
                    <Button type="primary" block htmlType="submit" size="large">
                      Login
                    </Button>
                    <div className="terms-service mt-3">
                      By continuing, you agree to MechaSense's{" "}
                      <Link to={Routes.termsservice} target="_blank">
                        Terms of Service
                      </Link>
                    </div>
                  </Form.Item>
                </Form>
              )}

              {forgotPasswordVisible && (
                <Form
                  name="basic"
                  layout="vertical"
                  initialValues={{ remember: true }}
                  autoComplete="off"
                  onFinish={onFinishForgotPassword}
                  onFinishFailed={onFinishForgotPasswordFailed}
                >
                  <p className="page-title text-center">Forgot Password</p>
                  <Form.Item label="Email" name="email" rules={rules.email}>
                    <Input size="large" />
                  </Form.Item>
                  <Form.Item className="login-button">
                    <Button
                      type="primary"
                      className="mb-3"
                      block
                      htmlType="submit"
                      size="large"
                    >
                      Submit
                    </Button>
                    <Button
                      className="secondary"
                      block
                      htmlType="submit"
                      size="large"
                      onClick={onCancelForgotPassword}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
      {isLoading ? <Spin /> : null}
    </>
  );
}

export default withRouter(Login);
