/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Row, Col, Pagination, Spin, Button, Tooltip, Divider, message, Select, Input, Tag, Typography } from 'antd';
import { useHistory } from 'react-router-dom';

import {
  FilterOutlined
} from '@ant-design/icons';
import Routes from '../../routing/routes'
import Table from '../../components_new/table'
import Utils from '../../helpers/Utility';
import issueTrackerService from '../../services/issueTrackerServices'
import authStore from '../../store/authStore';
import organizationService from '../../services/organizationService';
import IssuetrackerOverviewCard from '../../components_new/issue-tracker-overview-card';
import IssueTrackerFilterDrawer from '../../components_new/issue-tracker-filterdrawer';

import constants from '../../config/constants';
import _ from "lodash";
import userService from '../../services/userService';

const { Option } = Select;
const { Search } = Input;
const { Text } = Typography;

const userTypes = new Map([
  ['consumer', 'Customer'],
  ['contractor', 'Contractor'],
  ['service_resolved_by', 'Resolved By'],
  ['OPEN','Open'],
  ['CLOSED','Closed'],
  ['IGNORED','Ignored'],
  ['RESOLVED','Resolved'],
  ['PENDING REVIEW', 'Pending Review'],
  ['INCOMPLETE', 'Incomplete']
])

function IssuetrackerListing() {

  let history = useHistory();

  const [issues, setIssues] = useState<any>([]);
  const [issuesOverview, setIssuesOverview] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [searchFilter, setSearchFilter] = useState('')
  const [organizationFilterData, setOrganizationFilterData] = useState([]) //org
  const [selectedFilter, setSelectedFilter] = useState('') //org
  const [totalCount, setTotalCount] = useState(0)
  const [technicianUnderContractor, setTechnicianUnderContractor] = useState([])
  const [page, setPage] = useState(1);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [filterData, setFilterData] = useState<any>({});

  const organizationDropDownCondition = authStore?.currentUser?.active_role?.role_type === 0 || (authStore?.currentUser?.active_role?.role_type === 1 && authStore.currentUser.is_default)

  useEffect(() => {
    if (organizationDropDownCondition) {
      getIssuesWithOrganizationDetails()
    } else {
      fetchIssueTracker()
      fetchIssueOverview()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchIssueOverview = async (orgid?: any) => {
    try {
      // setLoading(true)
      let issuesOverview = await issueTrackerService.getIssueTrackerOverview(orgid)
      setIssuesOverview(issuesOverview?.issuesCounts)
      // setLoading(false)
    } catch (error) {
      // setLoading(false)
      message.error(error?.response?.data?.message)
    }
  }

  const fetchIssueTracker = async (pageNumber: number = 0, searchKeyword: string = '', orgid?: any, status?: any, consumer?: any, contractor?: any, resolved_by?: any) => {

    pageNumber = pageNumber === 0 ? page : pageNumber;
    try {
      setLoading(true)
      const issuesTrackerDatas = await issueTrackerService.getIssueTrackerDetails(pageNumber, constants.maxLimit, orgid, status, consumer, contractor, resolved_by, searchKeyword)
      const technicianList = authStore?.currentUser?.active_role?.role_type === constants.role.Contractor ? true : false
      if (technicianList) {
      const technicianUnserContractor = await userService.getUserBasedOnRoleType(orgid, constants.role['Field Technician'], technicianList)
        setTechnicianUnderContractor(technicianList ? technicianUnserContractor?.technicianList : [])
      }
      setTotalCount(issuesTrackerDatas?.count)
      setIssues(issuesTrackerDatas?.issues)
      setLoading(false)
      setPage(pageNumber)
    } catch (error) {
      setLoading(false)
      message.error(error?.response?.data?.message)
    }
  }

  const getIssuesWithOrganizationDetails = async () => {

    try {
      const response = await organizationService.getOrganizations()
      setSelectedFilter(response.data.organizations[0]._id)
      fetchIssueOverview(response.data.organizations[0]._id)
      fetchIssueTracker(1, searchFilter, response.data.organizations[0]._id, null)
      setOrganizationFilterData(response.data.organizations);
    } catch (error) {
      setLoading(false)
      message.error(error?.response?.data?.message)
    }
  }

  const searchDebounce = React.useCallback(_.debounce(fetchIssueTracker, 700), [])

  const handleSearchFilter = (e: any) => {
    const { value } = e.target;
    setSearchFilter(value);
    let consumer = filterData?.consumer ? [filterData?.consumer[0]?._id] : null;
    let contractor = filterData?.contractor ? [filterData?.contractor[0]?._id] : null;
    let resolved_by = filterData?.service_resolved_by ? [filterData?.service_resolved_by[0]?._id] : null;

    searchDebounce(1, value, selectedFilter, filterData?.status, consumer, contractor, resolved_by)
  };

  const onPageChange = (page: any) => {
    let consumer = filterData?.consumer ? [filterData?.consumer[0]?._id] : null;
    let contractor = filterData?.contractor ? [filterData?.contractor[0]?._id] : null;
    let resolved_by = filterData?.service_resolved_by ? [filterData?.service_resolved_by[0]?._id] : null;

    fetchIssueTracker(page, searchFilter, selectedFilter, filterData?.status, consumer, contractor, resolved_by)
    fetchIssueOverview(selectedFilter)
  };

  const columns = [
    {
      title: 'Ticket ID',
      dataIndex: 'issue_id',
      key: 'issue_id',
      ellipsis: {
        showTitle: false,
      },
      render: (name: {} | null | undefined) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
      width: '8%',
      align: 'left' as const,
    }
    ,
    {
      title: 'Customer',
      dataIndex: 'consumer',
      key: 'consumer',
      ellipsis: {
        showTitle: false,
      },
      render: (name: {} | null | undefined) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
      width: '11%',
      align: 'left' as const,
    }
    ,
    {
      title: 'Issue Title',
      dataIndex: 'issue_title',
      key: 'issue_title',
      ellipsis: {
        showTitle: false,
      },
      render: (name: {} | null | undefined) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
      // width: '10%',
      align: 'left' as const,
    }
    ,
    {
      title: 'System',
      key: 'system',
      dataIndex: 'system',
      ellipsis: {
        showTitle: false,
      },
      render: (name: {} | null | undefined) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
      // width: '10%',
      align: 'left' as const,
    }
    ,
    {
      title: 'Days Open',
      key: 'daysopen',
      dataIndex: 'daysopen',
      ellipsis: {
        showTitle: false,
      },
      render: (name: {} | null | undefined) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
      width: '8%',
      align: 'left' as const,
    }
    ,
    {
      title: 'Hard Savings',
      key: 'hard_savings_revenue',
      dataIndex: 'hard_savings_revenue',
      ellipsis: {
        showTitle: false,
      },
      render: (name: {} | null | undefined) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
      width: '8%',
      align: 'left' as const,
    }
    ,
    {
      title: 'Power Savings',
      key: 'power_savings_revenue',
      dataIndex: 'power_savings_revenue',
      ellipsis: {
        showTitle: false,
      },
      render: (name: {} | null | undefined) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
      width: '8%',
      align: 'left' as const,
    }
    ,
    {
      title: 'Mechasense Revenue',
      key: 'mechasense_service_revenue',
      dataIndex: 'mechasense_service_revenue',
      ellipsis: {
        showTitle: false,
      },
      render: (name: {} | null | undefined) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
      width: '8%',
      align: 'left' as const,
    }
    ,
    {
      title: 'Contractor Revenue',
      key: 'contractor_service_revenue',
      dataIndex: 'contractor_service_revenue',
      ellipsis: {
        showTitle: false,
      },
      render: (name: {} | null | undefined) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
      width: '8%',
      align: 'left' as const,
    }
    ,
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (data: any) => (
        <>
          <Tag color={Utils.getIssueTrackerStatus(data)} key={data}>
            {data.toUpperCase()}
          </Tag>
        </>
      ),
      // width: '7%',
      align: 'left' as const,
      ellipsis: {
      showTitle: false,
  },
    },
  ];

  const orgAdminColumn = {
  title: 'Assigned Contractor',
  key: 'assigned_contractor',
  dataIndex: 'assigned_contractor',
  ellipsis: {
    showTitle: false,
  },
  // render: (data: any, record: any) => (
  //   <>
  //      <Text>{}</Text>
  //   </>
  // ),
  // width: '10%',
  align: 'left' as const,
};

  const contractorColumn = {
  title: 'Assigned Technician',
  key: 'issue_assigned_to',
  dataIndex: 'issue_assigned_to',
  ellipsis: {
    showTitle: false,
  },
  render: (data: any, record: any) => (
    <>
       <Text>{(Utils.getObjectById(record?.issue_assigned_to, technicianUnderContractor) || {}).name || '-'}</Text>
    </>
  ),
  // width: '10%',
  align: 'left' as const,
};

  const tableDatas = issues?.map((issue: any, index: number) => {

    const system_name = issue.group_id?.name ? (`${issue?.group_id?.name} / ${issue?.sensor_box?.alias ? issue?.sensor_box?.alias : issue?.sensor_box?.sensor_box_name}`)
      : (issue?.sensor_box?.alias ? issue?.sensor_box?.alias : issue?.sensor_box?.sensor_box_name);

    let daysOpen = (issue.status === 'OPEN') ? Utils.getMomentFromNow(issue?.created_on) : ''

    let hardSavingsRevenue = issue?.hard_savings?.reduce((accumulator: any, object: any) => {
      return accumulator + object?.savings_amount;
    }, 0);

    let powerSavingsRevenue = issue?.power_savings?.reduce((accumulator: any, object: any) => {
      return accumulator + object?.savings_amount;
    }, 0);

    let result: any = {
      _id: issue?._id,
      key: index + 1,
      issue_id: issue?.issue_id,
      consumer: issue?.consumer ? issue?.consumer?.name : '',
      issue_title: issue?.issue_title,
      issue_priority: issue?.issue_priority,
      issue_assigned_to: issue?.assigned_to,
      assigned_contractor: issue?.sensor_box?.contractor?.find((element: { _id: string; name: string}) => element?._id === issue?.contractor?._id) ? issue?.contractor?.name : '-',
      system: system_name,
      daysopen: daysOpen,
      hard_savings_revenue: hardSavingsRevenue ? `$ ${(Math.round(hardSavingsRevenue * 100) / 100).toLocaleString()}` : '-',
      power_savings_revenue: powerSavingsRevenue ? `$ ${(Math.round(powerSavingsRevenue * 100) / 100).toLocaleString()}` : '-',
      mechasense_service_revenue: issue?.mechasense_service_revenue ? `$ ${(Math.round(issue?.mechasense_service_revenue * 100) / 100).toLocaleString()}` : '-',
      contractor_service_revenue: issue?.contractor_service_revenue ? `$ ${(Math.round(issue?.contractor_service_revenue * 100) / 100).toLocaleString()}` : '-',
      status: issue?.status
    };
    return result
  })

  const onSelectRow = (record: any) => {
    let params = `?ticket_id=${record?._id}`
    if (organizationDropDownCondition) {
      params += `&org_id=${selectedFilter}`
    }

    history.push({
      pathname: `${Routes.issuetrackerdetails}`,
      search: params
    });
  }

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);

    let consumer = filterData?.consumer ? [filterData?.consumer[0]?._id] : null;
    let contractor = filterData?.contractor ? [filterData?.contractor[0]?._id] : null;
    let resolved_by = filterData?.service_resolved_by ? [filterData?.service_resolved_by[0]?._id] : null;

    fetchIssueTracker(page, searchFilter, selectedFilter, filterData?.status, consumer, contractor, resolved_by)
  };

  const onHandleFilterOnCard = (status: any) => {

    const data = { ...filterData };
    delete data?.consumer
    delete data?.contractor
    delete data?.service_resolved_by
    data[`status`] = [status]
    setFilterData(data);

    fetchIssueTracker(1, searchFilter, selectedFilter, [status], data?.consumer, data?.contractor, data?.service_resolved_by)
  }

  const onApplyFilter = (values: any) => {

    setFilterData(values)

    let consumer = values?.consumer ? [values?.consumer[0]?._id] : null;
    let contractor = values?.contractor ? [values?.contractor[0]?._id] : null;
    let resolved_by = values?.service_resolved_by ? [values?.service_resolved_by[0]?._id] : null;

    fetchIssueTracker(1, searchFilter, selectedFilter, values?.status, consumer, contractor, resolved_by)
    setDrawerVisible(false);
  }

  const handleClearAll = () => {
    setFilterData({});
    fetchIssueTracker(1, searchFilter, selectedFilter, null, null, null, null)
    setDrawerVisible(false)
  }

  const onCloseTags = (key: any, tag: any) => {
    const statusData = { ...filterData };
    if (key === 'status') {
      statusData['status'] = statusData?.status?.filter((status: any) => status !== tag)
      if (!(statusData?.status?.length)) {
        delete statusData[key];
      }
    } else {
      delete statusData[key];
    }
    setFilterData(statusData);

    let consumer = statusData?.consumer ? [statusData?.consumer[0]?._id] : null;
    let contractor = statusData?.contractor ? [statusData?.contractor[0]?._id] : null;
    let resolved_by = statusData?.service_resolved_by ? [statusData?.service_resolved_by[0]?._id] : null;

    fetchIssueTracker(1, searchFilter, selectedFilter, statusData?.status, consumer, contractor, resolved_by)
  }

  const handleFilterSelection = (option: any) => {
    setSelectedFilter(option)

    let consumer = filterData?.consumer ? [filterData?.consumer[0]?._id] : null;
    let contractor = filterData?.contractor ? [filterData?.contractor[0]?._id] : null;
    let resolved_by = filterData?.service_resolved_by ? [filterData?.service_resolved_by[0]?._id] : null;

    fetchIssueTracker(1, searchFilter, option, filterData?.status, consumer, contractor, resolved_by)
    fetchIssueOverview(option)
  }

  const openIssuesCount = issuesOverview?.find((issue: any) => issue?.status === 'OPEN')
  const closedIssuesCount = issuesOverview?.find((issue: any) => issue?.status === 'CLOSED')
  const ignoredIssuesCount = issuesOverview?.find((issue: any) => issue?.status === 'IGNORED')
  const resolvedIssuesCount = issuesOverview?.find((issue: any) => issue?.status === 'RESOLVED')
  const pendingReviewsCount = issuesOverview?.find((issue: any) => issue?.status === 'PENDING REVIEW')
  const incompleteIssuesCount = issuesOverview?.find((issue: any) => issue?.status === 'INCOMPLETE')

  return (
    <div className="p-0">
      <Spin spinning={loading}>
        <div className="d-flex flex-column h-100 overflow-hidden p-0">
          <div className="flex-fill h-100 overflow-hidden issue-tracker-padding">
            <div className="d-flex">
              <div className="flex-fill">
                <p className="page-title text-left">Issue Tracker</p>
              </div>
              <div className="d-flex mb-3">

                {
                  (organizationDropDownCondition) &&
                  <div className="d-flex pb-3 justify-content-end mr-4">
                    <Select value={selectedFilter} defaultValue={selectedFilter} onChange={handleFilterSelection} size="large" style={{ minWidth: "220px" }}>
                      {
                        organizationFilterData &&
                        organizationFilterData.map((data: any) => {
                          return (
                            <Option value={data._id}>{data.name}</Option>
                          );
                        })
                      }
                    </Select>
                  </div>

                }
                <Search
                  className='mr-3'
                  placeholder="Search"
                  value={searchFilter || ""}
                  size="large"
                  onChange={handleSearchFilter}
                  allowClear
                  enterButton />

                <Button type="text" icon={<FilterOutlined />} size="large" onClick={showDrawer}>Filter</Button>
                <IssueTrackerFilterDrawer
                  data={filterData}
                  orgid={selectedFilter}
                  visible={drawerVisible}
                  onCloseDrawer={onCloseDrawer}
                  onApplyFilter={onApplyFilter}
                  onClearAll={handleClearAll}
                />
              </div>
            </div>

            {Object.values(filterData).length > 0 &&
              <>
                <Divider
                  type="horizontal"
                  style={{ backgroundColor: "#000", margin: "auto" }}
                />
                <div className='mb-2'>
                  <Row gutter={[16, 16]}>
                    <Col span={22}>

                      {
                        Object.keys(filterData).map((key: any) => {
                          return filterData[key]?.map((tag: any) => (

                            <Tag className='mt-3 h6' color="grey" closable onClose={(e: any) => { onCloseTags(key, tag); e.preventDefault(); }}>
                              {key === 'status' ? userTypes.get(tag) : tag.name} {key !== 'status' && <span className='ml-2 text-uppercase font-weight-bold'>
                                {userTypes.get(key)}
                              </span>}
                            </Tag>
                          ))
                        })
                      }

                    </Col>
                    <Col span={2}>
                      <p className='mt-3 float-right'> <a href="/" onClick={(e: any) => { handleClearAll(); e.preventDefault(); }}>
                        CLEAR ALL
                      </a></p>
                    </Col>
                  </Row>
                </div>
                <Divider
                  type="horizontal"
                  style={{ backgroundColor: "#000", margin: "auto" }}
                />
              </>
            }

            <div className="d-lg-flex mb-3">
              <div className="flex-custom-fill mr-md-2">
                <div className="d-flex flex-column h-100">
                  <div className="flex-fill mb-3">
                  </div>
                  <div className="flex-auto">
                    <Row gutter={[8, 8]}>
                      <Col span={4} >
                        <IssuetrackerOverviewCard
                          count={openIssuesCount ? openIssuesCount?.count : 0}
                          description={<Tag color={Utils.getIssueTrackerStatus('OPEN')} key={'OPEN'}>{'OPEN'}</Tag>}
                          onHandleFilterOnCard={() => onHandleFilterOnCard('OPEN')}
                        />

                      </Col>
                      <Col span={4} >
                        <IssuetrackerOverviewCard
                          count={pendingReviewsCount ? pendingReviewsCount?.count : 0}
                          description={<Tag color={Utils.getIssueTrackerStatus('PENDING REVIEW')} key={'PENDING REVIEW'}>{'PENDING REVIEW'}</Tag>}
                          onHandleFilterOnCard={() => onHandleFilterOnCard('PENDING REVIEW')}
                        />
                      </Col>
                      <Col span={4} >
                        <IssuetrackerOverviewCard
                          count={resolvedIssuesCount ? resolvedIssuesCount?.count : 0}
                          description={<Tag color={Utils.getIssueTrackerStatus('RESOLVED')} key={'RESOLVED'}>{'RESOLVED'}</Tag>}
                          onHandleFilterOnCard={() => onHandleFilterOnCard('RESOLVED')}
                        />

                      </Col>
                      <Col span={4} >
                        <IssuetrackerOverviewCard
                          count={ignoredIssuesCount ? ignoredIssuesCount?.count : 0}
                          description={<Tag color={Utils.getIssueTrackerStatus('IGNORED')} key={'IGNORED'}>{'IGNORED'}</Tag>}
                          onHandleFilterOnCard={() => onHandleFilterOnCard('IGNORED')}
                        />
                      </Col>
                    <Col span={4} >
                        <IssuetrackerOverviewCard
                          count={incompleteIssuesCount ? incompleteIssuesCount?.count : 0}
                          description={<Tag color={Utils.getIssueTrackerStatus('INCOMPLETE')} key={'INCOMPLETE'}>{'INCOMPLETE'}</Tag>}
                          onHandleFilterOnCard={() => onHandleFilterOnCard('INCOMPLETE')}
                        />
                    </Col>
                      <Col span={4} >
                        <IssuetrackerOverviewCard
                          count={closedIssuesCount ? closedIssuesCount?.count : 0}
                          description={<Tag color={Utils.getIssueTrackerStatus('CLOSED')} key={'CLOSED'}>{'CLOSED'}</Tag>}
                          onHandleFilterOnCard={() => onHandleFilterOnCard('CLOSED')}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <div className='style-table'>
              <Table
                data={tableDatas}
                columns={authStore?.currentUser?.active_role?.role_type === constants.role.Contractor ? [...columns, contractorColumn] : 
                  authStore?.currentUser?.active_role?.role_type === constants.role.Admin ? [...columns, orgAdminColumn] : columns}
                onSelectRow={(record: any) => onSelectRow(record)}
              />
            </div>

            <div className='py-4'>
              {Math.ceil(totalCount / constants.maxLimit) > 1 &&
                <Pagination
                  defaultCurrent={1}
                  current={page}
                  total={totalCount}
                  pageSize={constants.maxLimit}
                  responsive={true}
                  className="float-right"
                  showSizeChanger={false}
                  onChange={onPageChange} />
              }
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}
export default IssuetrackerListing;
