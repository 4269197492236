import React, { useState, useEffect } from 'react';
import { Drawer, List, Checkbox, Row, Col, Divider, Button, Select } from 'antd';
import userService from '../../services/userService';
import constants from '../../config/constants';
import authStore from '../../store/authStore';

import {
    RightOutlined,
    CheckCircleFilled
} from '@ant-design/icons';

interface Props {
    data?: any,
    visible?: any,
    orgid?: any
    onCloseDrawer: () => void,
    onApplyFilter: (values: Array<object>) => void,
    onClearAll: () => void
}

const { Option } = Select;

const IssueTrackerFilterDrawer: React.FC<Props> = ({ data, visible, onCloseDrawer, orgid, onApplyFilter, onClearAll }) => {

    const [activeFilterCategory, setActiveFilterCategory] = useState('')
    const [users, setUsers] = useState<any>([]);
    const [filterData, setFilterData] = useState<any>({});

    useEffect(() => {
        setFilterData(data)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, visible])

    const filterSubjects = ['Status', 'Customer', 'Contractor', 'Resolved By']
    const contractorFilterSubjects = ['Status', 'Customer', 'Resolved By']

    const ticketStatus = [{ status: 'OPEN', title: 'Open' }, { status: 'RESOLVED', title: 'Resolved' }, { status: 'CLOSED', title: 'Closed' }, { status: 'IGNORED', title: 'Ignored' }, { status: 'PENDING REVIEW', title: 'Pending Review' }, { status: 'INCOMPLETE', title: 'Incomplete' }]

    const onFilterCategorySelect = (filterCategory: string) => {
        let role = 0;
        if (filterCategory === 'Contractor') {
            role = 2
        } else if (filterCategory === 'Resolved By') {
            role = 1
        }

        setActiveFilterCategory(filterCategory)
        getUsers(role, orgid)
    }

    const getUsers = async (role?: any, org_id?: any) => {

        const userList = await userService.getUserBasedOnRoleType(org_id, role)
        setUsers(userList)
    }

    const onApplyHandler = () => {
        setActiveFilterCategory('')
        onApplyFilter(filterData)
    }

    const handleClearAll = () => {
        setActiveFilterCategory('')
        setFilterData({})
        onClearAll()
    }

    const handleCloseDrawer = () => {
        setActiveFilterCategory('')
        setFilterData({})
        onCloseDrawer()
    }

    const isActive = (item: any) => {
        let isActive = false
        if (item === 'Status') {
            isActive = (filterData?.status?.length > 0)
        } else if (item === 'Customer') {
            isActive = (filterData?.consumer?.length > 0)
        } else if (item === 'Contractor') {
            isActive = (filterData?.contractor?.length > 0)
        } else if (item === 'Resolved By') {
            isActive = (filterData?.service_resolved_by?.length > 0)
        }

        return isActive
    }

    const onFilterChange = (filterCategory: string, value: Array<string>) => {
        const data = { ...filterData };
        let user = users?.find((user: any) => user?._id === value)
        data[filterCategory] = filterCategory === 'status' ? value : [user]
        setFilterData(data);
    }

    return (
        <>
            <Drawer title="Filter Ticket" placement="right" onClose={handleCloseDrawer} visible={visible}>
                {
                    activeFilterCategory === '' &&
                    <List
                        className='font-weight-bold pointer'
                        dataSource={authStore?.currentUser?.active_role?.role_type === constants.role.Contractor ? contractorFilterSubjects : filterSubjects}
                        renderItem={(item) => <List.Item onClick={() =>
                            onFilterCategorySelect(item)} >{item}
                            {isActive(item) && <div className='d-inline ml-2'><CheckCircleFilled /></div>}
                            <div className='float-right'><RightOutlined /></div></List.Item>
                        }
                    />
                }
                {
                    activeFilterCategory === 'Status' &&
                    <div>

                        <a href="/" className="ins_edit_icn" onClick={(e: any) => { setActiveFilterCategory(''); e.preventDefault(); }}>
                            <span className="sr-only"></span>
                            <h6
                                className="mr-4 font-weight-bold"
                            >{`< Back `}
                            </h6>
                        </a>
                        <div className='issue-tracker-filter'>
                            <Checkbox.Group value={filterData?.status} onChange={(value: any) => { onFilterChange('status', value) }}>
                                <Row>
                                    {
                                        ticketStatus?.map((status: any) =>
                                            <Col span={24}>
                                                <p className='mt-2 paragraph'><Checkbox value={status?.status}>{status?.title}</Checkbox></p>
                                            </Col>
                                        )
                                    }
                                </Row>
                            </Checkbox.Group>
                        </div>

                    </div>
                }
                {
                    activeFilterCategory === 'Customer' &&
                    <div>
                        <a href="/" className="ins_edit_icn" onClick={(e: any) => { setActiveFilterCategory(''); e.preventDefault(); }}>
                            <span className="sr-only"></span>
                            <h6
                                className="mr-4 font-weight-bold"
                            >{`< Back `}
                            </h6>
                        </a>

                        <p className='font-weight-bold mt-3'>Customer</p>
                        <Col span={24}>
                            <Select value={filterData?.consumer ? filterData?. consumer[0]._id : null} placeholder="Choose" style={{ minWidth: "100%" }} onChange={(value: any) => { onFilterChange('consumer', value) }} optionFilterProp="children" showSearch>
                                {
                                    users &&
                                    users.map((user: any) => {
                                        return (
                                            <Option value={user?._id}>{user.name}</Option>
                                        );
                                    })
                                }
                            </Select>
                        </Col>


                    </div>
                }
                {
                    activeFilterCategory === 'Contractor' &&
                    <div>
                        <a href="/" className="ins_edit_icn" onClick={(e: any) => { setActiveFilterCategory(''); e.preventDefault(); }}>
                            <span className="sr-only"></span>
                            <h6
                                className="mr-4 font-weight-bold"
                            >{`< Back `}
                            </h6>
                        </a>

                        <p className='font-weight-bold mt-3'>Contractor</p>

                        <Col span={24}>
                            <Select value={filterData?.contractor ? filterData?.contractor[0]?._id : null} placeholder="Choose" style={{ minWidth: "100%" }} onChange={(value: any) => { onFilterChange('contractor', value) }} optionFilterProp="children" showSearch>
                                {
                                    users &&
                                    users.map((user: any) => {
                                        return (
                                            <Option value={user?._id}>{user.name}</Option>
                                        );
                                    })
                                }
                            </Select>
                        </Col>

                    </div>

                }
                {
                    activeFilterCategory === 'Resolved By' &&
                    <div>
                        <a href="/" className="ins_edit_icn" onClick={(e: any) => { setActiveFilterCategory(''); e.preventDefault(); }}>
                            <span className="sr-only"></span>
                            <h6
                                className="mr-4 font-weight-bold"
                            >{`< Back `}
                            </h6>
                        </a>

                        <p className='font-weight-bold mt-3'>Resolved By</p>

                        <Col span={24}>
                            <Select value={filterData?.service_resolved_by ? filterData?.service_resolved_by[0]?._id : null} placeholder="Choose" style={{ minWidth: "100%" }} onChange={(value: any) => { onFilterChange('service_resolved_by', value) }} optionFilterProp="children" showSearch>
                                {
                                    users &&
                                    users.map((user: any) => {
                                        return (
                                            <Option value={user?._id}>{user.name}</Option>
                                        );
                                    })
                                }
                            </Select>
                        </Col>

                    </div>
                }

                <div className='mt-3'>
                    <Divider
                        type="horizontal"
                        style={{ backgroundColor: "#000", margin: "auto" }}
                    />

                    <Col span={24}>
                        <div className="text-right mt-4">
                            <Button className="text-left" type="text" onClick={handleClearAll}>Clear All</Button>
                            <Button className="h-36 py-0 pl-4 pr-4 fs-14 ins_bg_clr border-0 font-weight-bold mr-3" type="primary" onClick={onApplyHandler}>Apply</Button>
                        </div>

                    </Col>
                </div>
            </Drawer>
        </>
    );
}
export default IssueTrackerFilterDrawer;
