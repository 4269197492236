import React from 'react';
import { Row, Col, Card, Divider, Tag, Typography } from 'antd';

interface Props {
    issueDetails?: any,
    orgAdminUsers?: any
}

const { Text } = Typography;

const IssueTrackerBenefitsCard: React.FC<Props> = ({ issueDetails, orgAdminUsers }) => {

    const resolved_by = orgAdminUsers.find((user: any) => (user._id === issueDetails?.service_resolved_by?._id))

    return (
        <>
            <Col span={18} >
                <Card className="custom-card online-device border-0">
                    <div className='d-flex align-items-center mb-2'>
                      <p style={{ fontSize: "14px", fontWeight: 5 }}>
                                Customer Benefits
                            </p>
                    </div>
                    {/* <Row gutter={[16, 16]} className="w-100">
                        <Col span={3}>
                            <h6 className="font-w-600">Savings Type</h6>
                        </Col>
                        <Col span={12}>
                            <h6 className="font-w-600">Savings Details</h6>
                        </Col>
                        <Col span={5}>
                            <h6 className="font-w-600">Savings Amount</h6>
                        </Col>

                        <Col span={4}>
                        </Col>
                    </Row> */}

                    <Row className="w-100 align-items-center">
                        <Col span={5}>
                            <p className="pt-2 paragraph">
                                Hard Savings
                            </p>
                        </Col>

                        {
                            issueDetails?.hard_savings?.map((savings: any, index: any) => {
                                return (
                                    <>
                                        {(index > 0) && <Col span={5}> </Col>}
                                        <Col span={12}>
                                            <p className="pt-2 paragraph">
                                               {savings?.savings_details}
                                            </p>

                                        </Col>


                                        <Col span={3}>
                                            <p className="pt-2 paragraph">
                                               {`$ ${(Math.round(savings?.savings_amount * 100) / 100).toLocaleString()}`}
                                            </p>
                                        </Col>

                                        <Col span={4}>
                                        </Col>

                                    </>
                                );
                            })}

                    </Row>

                    <Row className="w-100 align-items-center">
                        <Col span={5}>
                            <p className="pt-2 paragraph">
                                Power Savings
                            </p>
                        </Col>

                        {
                            issueDetails?.power_savings?.map((savings: any, index: any) => {
                                return (
                                    <>
                                        {(index > 0) && <Col span={5}> </Col>}
                                        <Col span={12}>
                                            <p className="pt-2 paragraph">
                                                {savings?.savings_details}
                                            </p>

                                        </Col>


                                        <Col span={3}>
                                            <p className="pt-2 paragraph">
                                                {`$ ${(Math.round(savings?.savings_amount * 100) / 100).toLocaleString()}`}
                                            </p>
                                        </Col>

                                        <Col span={4}>
                                        </Col>

                                    </>
                                );
                            })}

                    </Row>

                    <Row className="w-100 align-items-center">
                        <Col span={5}>
                            <p className="pt-2 paragraph">
                                Soft Savings
                            </p>
                        </Col>

                        {
                            issueDetails?.soft_savings?.map((savings: any, index: any) => {
                                return (
                                    <>
                                        {(index > 0) && <Col span={5}> </Col>}
                                        <Col span={12}>
                                            <p className="pt-2 paragraph">
                                                <Tag color="grey" className='tag-max-width'>
                                                    <Text
                                                        ellipsis={{ tooltip: savings?.category }}
                                                        className="mr-1 mb-0 tag-max-width text-color-white"
                                                    >{savings?.category}
                                                    </Text>
                                                </Tag>
                                            </p>

                                        </Col>


                                        <Col span={3}>
                                            <p className="pt-2 paragraph">
                                                {`${savings?.savings_details}`}
                                            </p>
                                        </Col>

                                        <Col span={4}>
                                        </Col>

                                    </>
                                );
                            })}

                    </Row>
                </Card>

                <Card className="custom-card online-device border-0 mt-3">
                    <div className='d-flex align-items-center mb-2'>
                        <div className='d-flex align-items-center mb-2'>
                        <p style={{ fontSize: "14px", fontWeight: 5 }}>
                                Service Revenue
                            </p>
                        </div>
                    </div>
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <div >
                                <h6 className="font-w-600">MechaSense Service Revenue</h6>
                                <p className="pt-2">
                                    <div className='d-flex align-items-center'>
                                        <p className="pt-2 paragraph">
                                            {issueDetails?.mechasense_service_revenue ? `$ ${(Math.round(issueDetails?.mechasense_service_revenue * 100) / 100).toLocaleString()}` : `None`}
                                        </p>
                                    </div>
                                </p>

                            </div>
                        </Col>
                        <Col span={6}>
                            <div >
                                <h6 className="font-w-600">Contractor Service Revenue</h6>
                                <p className="pt-2">
                                    <div className='d-flex align-items-center'>
                                        <p className="pt-2 paragraph">
                                            {issueDetails?.contractor_service_revenue ? `$ ${(Math.round(issueDetails?.contractor_service_revenue * 100) / 100).toLocaleString()}` : `None`}
                                        </p>
                                    </div>
                                </p>

                            </div>
                        </Col>
                        <Col span={6}>
                            <div >
                                <h6 className="font-w-600">Service Resolved By</h6>
                                <p className="pt-2">
                                    <div className='d-flex align-items-center'>
                                        <p className="pt-2 paragraph">
                                            {resolved_by ? resolved_by?.name : `None`}
                                        </p>
                                    </div>
                                </p>

                            </div>
                        </Col>

                        <Col span={6}>
                            <div >
                                <h6 className="font-w-600">Service Notes</h6>
                                <p className="pt-2">
                                    <div className='d-flex align-items-center'>
                                        <p className="pt-2 paragraph">
                                            {issueDetails?.service_notes ? issueDetails?.service_notes : `None`}
                                        </p>
                                    </div>
                                </p>

                            </div>
                        </Col>

                    </Row>
                </Card>
            </Col>
        </>
    );
}
export default IssueTrackerBenefitsCard;
