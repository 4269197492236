/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Row, Col, Input, Modal, Form, Button } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import rules from "./softsavings.validation";

const { confirm } = Modal;

interface Props {
    visible?: any,
    data?: any,
    onCancel: () => void;
    onClickOk: (values: any, id: any) => void;
    onClickDelete: (id: any) => void;
}

const CreateEditSoftSavings: React.FC<Props> = ({ visible, data, onCancel, onClickOk, onClickDelete }) => {

    const [form] = useForm();

    useEffect(() => {
        if (data?.hasOwnProperty('_id')) {
            fetchSoftSavingsModal()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const fetchSoftSavingsModal = async () => {
        form.setFieldsValue({
            soft_savings_name: data?.soft_savings_name
        });
    }

    const handleCancel = () => {
        form.resetFields()
        onCancel()
    };

    const showDeleteConfirm = () => {
        confirm({
            title: 'Are you sure delete this category?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',

            onOk() {
                form.resetFields()
                onClickDelete(data?.hasOwnProperty('_id') ? data?._id : null)
            },
        });
    };

    const handleDelete = () => {
        showDeleteConfirm()
    };

    const onFinish = (values: any) => {
        onClickOk(values, data?.hasOwnProperty('_id') ? data?._id : null)
        form.resetFields()
    };

    return (
        <div className="p-0">
            <Modal title={data?.hasOwnProperty('_id') ? 'Edit Soft Savings Category' : `Create Soft Savings Category`} visible={visible} onOk={form.submit} onCancel={handleCancel} width={1000} footer={[
                <Button className="float-left" key="delete" type="primary" danger hidden={data?.hasOwnProperty('_id') ? false : true} onClick={handleDelete}>
                    Delete
                </Button>,
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={form.submit}>
                    Submit
                </Button>
            ]}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Form form={form} layout="vertical" onFinish={onFinish} >
                            <Form.Item name="soft_savings_name" label="Name" rules={rules.name}>
                                <Input
                                    showCount
                                    name="soft_savings_name"
                                    maxLength={100}
                                    placeholder="Enter Title"
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Modal>
        </div>
    );
}

export default CreateEditSoftSavings;
