import React, { useState, useEffect } from 'react';
import './sensordata.css';
import Utility from '../../helpers/Utility';
import upArrow from '../../assets/up.png';
import downArrow from '../../assets/down.png'
import {
  Modal,
  Card,
  Accordion,
  Row,
  Col
} from 'react-bootstrap';
import SensorService from '../../services/sensorService';
import toast from '../toast';
import moment from 'moment'

export interface SensorProps {
  show: boolean
  closeModal: any
  sensorBox: any
}

function Sensordatadetailes(props: SensorProps) {

  const [openAccordion, setOpenAccordion] = useState<Array<any>>([]);
  const [currentGraphDataOfTheWeek, setCurrentGraphDataOfTheWeek] = useState<Array<any>>([]);
  const [previousGraphDataOfTheWeek, setPreviousGraphDataOfTheWeek] = useState<Array<any>>([]);
  const [weatherInfo, setWeatherInfo] = useState<Array<any>>([]);

  const system_id = props.sensorBox?.group === 1 ? props.sensorBox?._id : props.sensorBox?.sensor_box_name;
  const type = props.sensorBox?.group === 1 ? 'group' : 'single';
  const lat = props.sensorBox?.location?.coordinates[0];
  const lon = props.sensorBox?.location?.coordinates[1];

  useEffect(() => {
    fetchPowerConsumptionForSystem(system_id, 'weekly', type)
    if (lat && lon) {
      fetchWeatherInfo(lat, lon)
    }else{
      setWeatherInfo([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sensorBox]);

  const fetchPowerConsumptionForSystem = async (id: any, frequencyType?: any, systemType?: any) => {
    try {
      if (id) {
        let powerData = await SensorService.getPowerGraphData(id ? id : '', frequencyType, systemType)
        const { currentData, previousData, message } = powerData;
        if (message === 'Success') {
          setCurrentGraphDataOfTheWeek(currentData)
          setPreviousGraphDataOfTheWeek(previousData)
        }
      }
    } catch (error) {
      let errMsg = error?.message;
      if (error?.response?.data?.message) {
        errMsg = error?.response?.data?.message
      }
      toast.error(errMsg);
    }
  }

  const fetchWeatherInfo = async (lat: any, lon: any) => {
    try {
      let weatherInfo = await SensorService.getWeather(lat, lon)
      setWeatherInfo(weatherInfo?.weatherInfo)
    } catch (error) {
      let errMsg = error?.message;
      if (error?.response?.data?.message) {
        errMsg = error?.response?.data?.message
      }
      toast.error(errMsg);
    }
  }

  function SensorDataTable(value: any) {
    return (
      <table className="table sensor-list">
        <thead className="table-header-bg">
          <tr>
            <th className="text-center text-custom-size" scope="col">#</th>
            <th className="text-custom-size custom-width no-wrap" scope="col">Sensor name</th>
            <th className="text-center text-custom-size" scope="col">Sensor Type</th>
            <th className="text-center text-custom-size" scope="col">Sensor value</th>
            <th className="text-center text-custom-size" scope="col">Sensor status</th>
            <th className="text-center text-custom-size" scope="col">Last update</th>
          </tr>
        </thead>
        {
          value?.sensors?.sort((a: any, b: any) => (a?.order - b?.order))
            .map((item: any, index: number) => (
              <tbody className="" key={index}>
                <tr className={index % 2 !== 0 ? "background-color" : ""}  >
                  <th className="text-center text-custom-size" scope="row">{index + 1}</th>
                  <td className="text-custom-size custom-width no-wrap">{item?.display_name}</td>
                  <td className="text-center text-custom-size">{<img className="guage-icon" src={Utility.getSensorImage(item)} alt='' />} </td>
                  <td className="text-center text-custom-size">{Utility.formatSensorValue(item)}</td>
                  <td className="text-center">
                    {Utility.checkSensorRange(item) === "nil" ? "-" :
                      <i className={`fa fa-circle ${Utility.checkSensorRange(item)}`} aria-hidden="true"></i>}
                  </td>
                  <td className="text-center text-custom-size">
                    {value?.last_updated_time ?
                      moment(value?.last_updated_time).format('MM/DD/YYYY, HH:mm:ss') : '-'}
                  </td>
                </tr>
              </tbody>
            ))
        }
      </table>

    )
  }

  const currentWeekPower = (currentGraphDataOfTheWeek.reduce((total: any, next: any) => total + next.power, 0));
  const previousWeekPower = (previousGraphDataOfTheWeek.reduce((total: any, next: any) => total + next.power, 0));
  const isthisWeekHighestPower = (currentWeekPower > previousWeekPower) ? true : false;

  function powerModal() {
    return (
      <>
        <p className="page-title text-left">Power Consumption </p>
        <div className="px-3">
          <Row>
            <Col lg="6" sm="6" className="pl-0">
              <Card className="border">
                <Card.Body>
                  <Row>
                    <Col xs="12">
                      <div className="numbers text-md-center">
                        <Card.Title className="mb-0" as="h4">Last Week (Power) : {Math.round(previousWeekPower)}kWh &nbsp;
                          {previousWeekPower ? isthisWeekHighestPower ? <img className="mb-2" src={downArrow} height={18} alt='' />
                            : <img className="mb-2" src={upArrow} height={18} alt='' />
                            : <img className="mb-2" src={downArrow} height={18} alt='' />
                          }
                        </Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="6" sm="6" className="pr-0">
              <Card className="border">
                <Card.Body>
                  <Row>
                    <Col xs="12">
                      <div className="numbers text-md-center">
                        <Card.Title className="mb-0" as="h4">This Week (Power) : {Math.round(currentWeekPower)}kWh &nbsp;
                          {currentWeekPower ? isthisWeekHighestPower ? <img className="mb-2" src={upArrow} height={18} alt='' />
                            : <img className="mb-2" src={downArrow} height={18} alt='' />
                            : <img className="mb-2" src={downArrow} height={18} alt='' />
                          }
                        </Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    )
  }

  function weatherModal() {
    return (
      <>
        <p className="page-title text-left">Weather Information </p>
        <div className="px-3">
          <Row>
            <Col lg="6" sm="6" className="pl-0">
              <Card className="border">
                <Card.Body>
                  <Row>
                    <Col xs="12">
                      <div className="numbers text-md-center">
                        <Card.Title className="mb-0" as="h4">Outside Temperature : {Math.round(weatherInfo[0]?.temperature)} °F &nbsp;
                        </Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="6" sm="6" className="pr-0">
              <Card className="border">
                <Card.Body>
                  <Row>
                    <Col xs="12">
                      <div className="numbers text-md-center">
                        <Card.Title className="mb-0" as="h4">Outside Humidity : {Math.round(weatherInfo[0]?.humidity)} % &nbsp;
                        </Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    )
  }

  const onClickAccordionHeader = (indexOfHeader: number) => {
    (openAccordion.includes(indexOfHeader)) ?
      setOpenAccordion(openAccordion.filter(index => index !== indexOfHeader))
      : setOpenAccordion(prevState => ([...prevState, indexOfHeader]))
  }

  let filteredSensorBoxesForGroup = props.sensorBox?.sensorboxes?.filter((sensor: any) => sensor.device_type === 'general');

  return (
    <Modal
      show={props.show}
      onHide={props.closeModal}
      size="lg"
    >
      <Modal.Header className="page-title text-left" closeButton>
        <div>{props.sensorBox?.group === 1 ? props.sensorBox.name : props.sensorBox.sensor_box_name} {props.sensorBox.alias && <span className='pl-2'>( {props.sensorBox.alias} )</span>}</div>
      </Modal.Header>
      <Modal.Body>
        {weatherInfo?.length > 0 && weatherModal()}
        {(!(props.sensorBox.group) && (props.sensorBox.sensorbox_group)) ? null : (props.sensorBox?.show_power_calculation && powerModal())}
        <p className="page-title text-left pt-3">Sensor Details</p>
        {props.sensorBox?.group === 1 ?
          filteredSensorBoxesForGroup?.sort((a: any, b: any) => (a?.order - b?.order))
            .map((item: any, index: number) => {
              return (
                <Accordion key={index} defaultActiveKey={`${index}`} >
                  <Card className="accordion-card">
                    <Accordion.Toggle as={Card.Header}
                      onClick={() => { onClickAccordionHeader(index) }}
                      eventKey={`${index}`} >
                      {item?.sensor_box_name} {item?.alias && (`(${item?.alias})`)}
                      {(openAccordion.includes(index)) ? (
                        <i style={{ fontSize: 12 }} className={'fa fa-chevron-down'} aria-hidden="true"></i>
                      ) : (
                        <i style={{ fontSize: 12 }} className={'fa fa-chevron-up'} aria-hidden="true"></i>
                      )}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={`${index}`}>
                      <Card.Body className="p-0">
                        {item?.sensors?.length > 0 ?
                          SensorDataTable(item)
                          :
                          <div className="text-center w-100 no-card-ph">
                            <div className="no-result-container-text">
                              <p className="no-cards">
                                No sensors added
                              </p>
                            </div>
                          </div>
                        }
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              )
            })
          :
          props.sensorBox?.sensors?.length > 0 ?
            SensorDataTable(props.sensorBox)
            :
            <div className="text-center w-100 no-card-ph">
              <div className="no-result-container-text">
                <p className="no-cards">
                  No sensors added
                </p>
              </div>
            </div>
        }
      </Modal.Body>
    </Modal>
  );
}

export default Sensordatadetailes;
