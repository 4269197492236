import React from 'react';
import {withRouter,RouteComponentProps} from 'react-router-dom'



export interface EllipsisProps extends RouteComponentProps {
    text   : string;
} 

function EllipsisText (props : any) {

    function showText(){
        let textLength = props.length || 15
        let text = ""

        if(props.children.length <= textLength){
            text = props.children
        }else{
            text = props.children.substring(0, textLength) +   "..." 
        }

        return text        
    }
    
    return (
        <span className="truncate-text" title={props.children}>
            {showText()}
        </span>    
    );
}
    
export default withRouter(EllipsisText);
    
 
