import React , {useState} from 'react';
import GroupItem from '../group-items/index'
import  Switch from '../switch/index';

interface Props { //props
  groupList: any,
  loading : boolean
}

const CompactDashboard: React.FC<Props> = ({ groupList , loading  }) => {

  const [hideSensorBoxes ,setHideSensorBoxes] = useState(false);
  const [hideSensorValues ,setHideSensorValues] = useState(false);

  const updateHideSensorBoxState = (event : any) => {
    setHideSensorBoxes(event.target.checked)
  }

  const updateHideSensorValuesState = (event : any) => {
      setHideSensorValues(event.target.checked)
  }

  const groupClassName = ['padding-b50']
  if(hideSensorBoxes){
    groupClassName.push('d-flex flex-wrap')
  }

  if(hideSensorValues){
    groupClassName.push('d-flex flex-wrap')
  }
  return (
    <div className="col-12 px-0">
        <div className="hide-checkbox">
        <div className="form-inline mr-4">
            <Switch
              checked={hideSensorBoxes}
              label = "Hide sensorboxes"
              onChange={updateHideSensorBoxState}
            />
        </div>
        <div className="form-inline">
            <Switch
              checked={hideSensorValues}
              label = "Hide sensorboxes and readings"
              onChange={updateHideSensorValuesState}
            />
        </div>    
        </div>
    { !loading &&
    <div className={groupClassName.join(' ')} id="groups">
        {(groupList.length > 0) ?
            groupList?.map((groups :any) => {
                return(
                    <GroupItem groups = {groups} hideSensorBox = {hideSensorBoxes} hideSensorValues = {hideSensorValues} />
                )
            })
            :
            <div className="text-center w-100 no-card-ph">
                <div className="no-result-container">
                    <p>No Data</p>
                </div>
            </div>
        }
    </div>
    }
    </div>
  )
}

export default CompactDashboard;
