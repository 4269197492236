import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

export interface ResetHarddeleteModalProps {
    message: string;
    title: string;
    visible: boolean;
    confirm : string;
    action: (data? : any) => void;
    toggleVisibility: () => void;
    isSuperAdmin? : boolean;
}

function ResetHarddeleteModal(props: ResetHarddeleteModalProps) {

    const [radioValue, setRadioValue] = useState('reset')

    const handleRadioValue = (e : any) => {
        setRadioValue(e.target.value)
    }

    const onClickConfirm = ()=> {
       if(props.isSuperAdmin){
        props.action(radioValue)
       }else{
        props.action()
       }
       setRadioValue('reset')

    };

    const onCloseModal = ()=> {
        props.toggleVisibility()
        setRadioValue('reset')
    }

    return (
        <Modal
            show={props.visible}
            onHide={onCloseModal}
        >
            <Modal.Header closeButton>
                {props.title}
            </Modal.Header>
            <Modal.Body>
                <div className="modal-body modal-text">
                    {props.message}
                    {props.isSuperAdmin &&
                    <div className="d-flex mt-3">
                        <div className="form-inline px-3">
                            <input
                                id="reset"
                                type="radio"
                                className="mr-3"
                                name="reset"
                                value="reset"
                                checked={radioValue === 'reset'}
                                onChange={handleRadioValue}
                            />
                            <label htmlFor="heat_provided_by_furnace" className="custom-label" style={{ fontSize: '0.85rem' }}>
                                Reset sensorbox
                            </label>
                        </div>
                        <div className="form-inline px-3">
                            <input
                                id="hardelete"
                                type="radio"
                                className="mr-3"
                                name="hardelete"
                                value="hardelete"
                                checked={radioValue === 'hardelete'}
                                onChange={handleRadioValue}
                            />
                            <label htmlFor="heat_provided_by_heat_pump" className="custom-label" style={{ fontSize: '0.85rem' }}>
                                Hard delete sensorbox
                            </label>
                        </div>
                    </div>
                    }
                </div>
                <div className="modal-footer pb-0">
                    <button type="button" className="btn default-btn" data-dismiss="modal" onClick={onCloseModal}>CANCEL</button>
                    <button
                        type="button"
                        className="btn default-btn"
                        onClick={onClickConfirm}
                    >
                        {props.confirm}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default  ResetHarddeleteModal
