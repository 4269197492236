import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import CustomerForm from '../../components/inspection-user-form/index'
import validate from '../../helpers/validation';
import constants from '../../config/constants';

interface Props {
  show: boolean;
  onClose: () => void;
  consumerDetails?: any;
  onEditCustomer: (data: any) => void;
}

const InspectionUserEdit: React.FC<Props> = ({ show, consumerDetails,  onClose , onEditCustomer }) => {

  const [errors, setErrors] = useState<any>({})

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerDetails]);

  const validateCustomerForm = (customerFormData : any) => {

    let error: any = {}

    if (validate.isEmpty(customerFormData?.name)) {
        error.name = constants.message.inspection_consumer_required
    } else {
      if(!validate.isName(customerFormData?.name)){
        error.name = constants.message.name_valid
      }else{
        error.name = ''
      }
    }

    if (validate.isEmpty(customerFormData?.email)) {
        error.email = constants.message.inspection_email_required
    } else {
        if(!validate.isEmail(customerFormData?.email)){
            error.email = constants.message.email_valid
        }
    }

    if (!customerFormData?.phonenumber) {
      error.phonenumber = constants.message.phoneNumber_mandatory
    } else if(!(validate.isPhoneNumber(customerFormData?.phonenumber))){
      error.phonenumber = constants.message.phoneNumber_valid
    } else{
      error.phonenumber = ''
    }
    
    if (validate.isEmpty(customerFormData?.street)) {
      error.street = constants.message.street_mandatory
    } else {
        error.street = ''
    }

    if (validate.isEmpty(customerFormData?.city)) {
      error.city = constants.message.city_mandatory
    } else {
      if(validate.isValidCity(customerFormData?.city)){
        error.city = ''
      }else{
        error.city = constants.message.city_valid
      }
    }

    if (validate.isEmpty(customerFormData?.country)) {
      error.country = constants.message.country_mandatory
    } else {
        error.country = ''
    }

    if (validate.isEmpty(customerFormData?.state)) {
      error.state = constants.message.state_mandatory
    } else {
        error.state = ''
    }

    if (validate.isEmpty(customerFormData?.zip_code)) {
      error.zip_code = constants.message.zip_mandatory
    } else {
        if(validate.isValidZipCode(customerFormData?.zip_code)) {
          error.zip_code = ''
        }else{
          error.zip_code = constants.message.zip_valid
        }
    }

    if(customerFormData?.location){
          error.address = ''
    }else{
          error.address = constants.message.address_valid
    }   

    setErrors(error)
    return !(error.name || error.email || error.phonenumber || error.street || error.city || error.country || error.state || error.zip_code || error.address)
}

  const onCloseModal = () => {
    setErrors({})
    onClose()
  }

  const clearFieldError = (field: string) => {
    let prevErrors = errors
    prevErrors[field] = ''
    setErrors(prevErrors)
  }

  const createUser = async (data: any) => {
    if(validateCustomerForm(data)){
      onEditCustomer(data)
    }
  }

  const toPrevPageFromCustomerForm = async () => {
    //do nothing
  }

  return (
    <Modal
      show={show}
      onHide={onCloseModal}
      size="lg"
    >
      <Modal.Header closeButton>
      <div>Edit Customer Info</div>
      </Modal.Header>
      <Modal.Body>

      <CustomerForm
        isEditable = {true}
        visibleUserFrom = {true}
        consumerDetails = {consumerDetails}
        errors = {errors}
        clearFieldError={(data: any) => clearFieldError(data)}
        createUser={(data: any) => createUser(data)}
        toPrevPageFromCustomerForm ={() => toPrevPageFromCustomerForm()}
      />
      
      </Modal.Body>
    </Modal>
  )
}

export default InspectionUserEdit;
