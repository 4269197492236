/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Input } from 'antd';
import _ from 'lodash';
import toast from '../../../components/toast';
import Pagination from "react-js-pagination";
import LoadingWheel from '../../../components/loadingwheel';
import { Organization } from '../../../interface'
import EllipsisProps from '../../../components/textellipsis'
import constants from '../../../config/constants';
import AddOrganizationModal from '../../../components/add-organisation'
import ConfirmModal from '../../../components/confirmmodal';
import organizationService from '../../../services/organizationService';
import SelectComponent from '../../../components/select';
import Utility from '../../../helpers/Utility';
import HardDeleteModal from '../../../components/confirmDialogModal'
import '../settings.css';
import moment from 'moment';

const { Search } = Input;

const filterOptions = [
    { _id: '', name: 'All' },
    { _id: 'active', name: 'Active' },
    { _id: 'disabled', name: 'Disabled' },
    { _id: 'deleted', name: 'Deleted' }
]

function Organizations() {

    const [loading, setLoading] = useState(true)
    const [organization, setOrganization] = useState([])
    const [confirmHardDeleteModal, setConfirmHardDeleteModal] = useState(false)
    const [visible, setVisible] = useState(false)
    const [organizationTotal, setOrganizationTotal] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedFilter, setSelectedFilter] = useState<any>(filterOptions[0])
    const [selectedOrganization, setSelectedOrganization] = useState<any>({})
    const [searchFilter, setSearchFilter] = useState('')
    const [typeFilter, setTypeFilter] = useState('')

    useEffect(() => {
        getOrganization(currentPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getOrganization = async (pageNumber: number = 0 , typeFilter: string = '',searchKeyword: string = '') => {

        pageNumber = pageNumber === 0 ? currentPage : pageNumber;
        const limit= constants.maxLimit
        try {
            const response = await organizationService.getOrganizations(pageNumber, limit, typeFilter, searchKeyword)
            setOrganization(response.data.organizations);
            setCurrentPage(pageNumber)
            setOrganizationTotal(response.data.count)
            setLoading(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    const searchDebounce = React.useCallback(_.debounce(getOrganization, 700), [])   

    const addOrganization = async (data: Organization) => {
        setLoading(true)
        try {
            await organizationService.addOrganization(data)
            closeForm()
            let pageNumber = Math.ceil((organizationTotal + 1)/constants.maxLimit)
            getOrganization(pageNumber)
            setLoading(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    const updateOrganization = async (data: Organization) => {
        setLoading(true)
        try {
            await organizationService.updateOrganization(data)
            closeForm()
            getOrganization(currentPage)
            setLoading(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    const deleteOrganization = async (id: string) => {

        try {
            setLoading(true)
            setVisible(false)
            let data = {
                action: 'delete'
            }
            await organizationService.deleteOrganization(id,data)
            closeConfirmModal()
            closeForm()
            getOrganization(currentPage)
            setLoading(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }

    }

    const handleFilterSelection = (option: any) => {
        setSelectedFilter(option)
        setTypeFilter(option._id??'')
        getOrganization(1, option._id, searchFilter)
    }

    const hardDeleteOrganisation = async (id: string) => {
        try {
            setLoading(true)
            setVisible(false)
            await organizationService.hardDeleteOrganization(id)
            closeConfirmModal()
            closeForm()
            getOrganization(currentPage)
            setLoading(false)
            setConfirmHardDeleteModal(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    const enableDisableOrganization = async (selectedOrg: any) => {
        try {
            setLoading(true)
            setVisible(false)
            if(selectedOrg.is_active){
                let data = {
                    action: 'disable'
                }
                await organizationService.enableDisableOrganization(selectedOrg._id,data)
                closeForm()
                getOrganization(currentPage)
                setLoading(false)
            }else{
                let data = {
                    action: 'enable'
                }
                await organizationService.enableDisableOrganization(selectedOrg._id,data)
                getOrganization(currentPage)
                setLoading(false)
            }
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    const handleSelectingTable = (item :any)=> {
        if(item.is_deleted){
            setSelectedOrganization(item)
            setConfirmHardDeleteModal(true)
        }else{
            showOrganization(item)
            }
    };

    const handleSearchFilter = (e :any)=> {
        const { value } = e.target;
        setSearchFilter(value); 
        searchDebounce(1,typeFilter,value)
    };

    const showOrganization = (organization?: Organization) => {
        if (organization) {
            setSelectedOrganization(organization)
        }else{
            setSelectedOrganization({})
        }
        setVisible(true)
    }

    const closeForm = () => {
        setVisible(false)
    }

    const closeModal = () => {
        setConfirmHardDeleteModal(false)
    }

    const closeConfirmModal = () => {
        Utility.closeModal('confirmmodal')
    }

    return (
       <div className="">            
            <div>
                <div className="padding-b50">
                {
                loading &&
                <LoadingWheel />
                }
                    <div className="row mb-25">
                        <div className="col-lg">
                            <p className="table-title media-padding">Organizations</p>
                        </div>
                        <div className="filter-select">
                            <label className="custom-label">Status</label>
                                <SelectComponent name="typeFilter"
                                    data={filterOptions}
                                    value={selectedFilter}
                                    onChange={handleFilterSelection}
                                />
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 media-margin-tp mt-auto">
                            <Search
                                placeholder="Search"
                                value={searchFilter || ""}
                                size="large"
                                onChange={handleSearchFilter}
                                allowClear
                                enterButton />
                        </div>
                    </div>
                    {organization.length > 0 ?
                    <div className="row no-gutters table-wrap">
                        <table className="table custom-table table-style">
                            <thead className="table-header-bg">
                                <tr>
                                    <th className="text-left" scope="col">#</th>
                                    <th scope="col">Organization Name</th>
                                    <th className="text-left" scope="col">Location</th>
                                    <th className="text-left" scope="col">Created Time</th>
                                    <th className="text-left" scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {organization?.length > 0 &&
                                    organization?.map((item: any, index) => {
                                        const address = `${item?.street}, ${item?.city}, ${item?.state}, ${item?.country}, ${item?.zip_code}`
                                        return (
                                            <tr className={item.is_deleted ? "settings-tr" : "click-icon"} key={index} onClick={() => handleSelectingTable(item)}>
                                                <td className="text-left">{(constants.maxLimit * (currentPage - 1)) + index + 1}</td>
                                                <td><EllipsisProps length={30}>{item.name}</EllipsisProps></td>
                                                <td className="text-left"><EllipsisProps length={30}>{address}</EllipsisProps></td>
                                                <td className="text-left"><EllipsisProps length={30}>{item.created_at ? moment(item.created_at).format('MM/DD/YYYY, HH:mm:ss') : ''}</EllipsisProps></td>
                                                <td className={item.is_active ? "text-left" : item.is_deleted ? "text-left settings-color-disabled-deleted" : "text-left settings-color-disabled-deleted"}><EllipsisProps length={20}>{item.is_active ? "Active" : item.is_deleted ? "Deleted & Disabled" : "Disabled"}</EllipsisProps></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    :
                      <div className="text-center w-100 no-card-ph">
                          <div className="no-result-container">
                            <p>No organizations found</p>
                          </div>
                      </div>
                    }
                    <div className="pagination-container">
                        {Math.ceil(organizationTotal / constants.maxLimit) > 1 &&

                            <Pagination
                                hideNavigation={true}
                                activePage={currentPage}
                                itemsCountPerPage={constants.maxLimit}
                                totalItemsCount={organizationTotal}
                                itemClass={"item-class"}
                                activeClass={"active-class"}
                                pageRangeDisplayed={5}
                                onChange={(pageNumber) => getOrganization(pageNumber, typeFilter, searchFilter)}
                            />
                        }
                    </div>

                        <AddOrganizationModal
                            visible={visible}
                            setVisibleClose={closeForm}
                            selectedOrganization={selectedOrganization}
                            addOrganization={(data: Organization) => addOrganization(data)}
                            updateOrganization={(data: Organization) => updateOrganization(data)}
                            enableDisableOrganizations={(data: Organization) => enableDisableOrganization(data)}
                        />
                        
                        <ConfirmModal
                            title={"Delete Organization"}
                            message={"Are you sure you want to delete this organization"}
                            closeConfirmModal={closeConfirmModal}
                            delete={() => deleteOrganization(selectedOrganization._id)}
                            isLoading={false}
                        />

                        <HardDeleteModal
                            title={"Delete Organization"}
                            message={"Are you sure want to delete this organization from the  database"}
                            visible = {confirmHardDeleteModal}
                            confirm ={"YES"}
                            action={() => hardDeleteOrganisation(selectedOrganization._id)}
                            toggleVisibility={closeModal}
                        />

                    <div className="add-icons" onClick={() => { showOrganization() }}>
                        <i className="fa fa-plus" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Organizations