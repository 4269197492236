import Api from '../api';
import { IssueQueryParams } from '../helpers/IssueQueryHelper';
import constants from '../config/constants';

class issueTrackerService {

    buildBasicQueryString = async (params: IssueQueryParams) => {
        let url_query = ''

        let searchText = ''
        if (params?.searchKeyword) {
            searchText = encodeURIComponent(params?.searchKeyword)
        }

        url_query += `&filter=${JSON.stringify({ search: searchText })}`


        if (params?.status && params.status?.length > 0) {
            url_query += `&${params.status.map((n: any, index: any) => `status[${index}]=${n}`).join('&')}`
        }

        if (params?.consumer && params.consumer?.length > 0) {
            url_query += `&${params.consumer.map((n: any, index: any) => `consumer[${index}]=${n}`).join('&')}`
        }

        if (params?.contractor && params.contractor?.length > 0) {
            url_query += `&${params.contractor.map((n: any, index: any) => `contractor[${index}]=${n}`).join('&')}`
        }

        if (params?.service_resolved_by && params.service_resolved_by?.length > 0) {
            url_query += `&${params.service_resolved_by.map((n: any, index: any) => `service_resolved_by[${index}]=${n}`).join('&')}`
        }

        if (params?.orgid) {
            url_query += `&orgid=${params.orgid}`
        }

        if (params?.startDate) {
            url_query += `&startDate=${params.startDate}`
        }

        if (params?.endDate) {
            url_query += `&endDate=${params.endDate}`
        }

        return url_query
    }

    fetchIssueReport = async (params: IssueQueryParams, detail: string) => {
        let url = `/issue-tracker/report?detail=${detail}`
        url += await this.buildBasicQueryString(params)
        let response = await Api.get(url, {responseType: 'blob'})
        return response
    }

    getIssueReportOverview = async (params: IssueQueryParams) => {
        let url = `/issue-tracker-overview?`
        url += await this.buildBasicQueryString(params)
        let response = await Api.get(url)
        return response.data
    }

    getIssueTrackerDetails2 = async (params: IssueQueryParams) => {
        let page = params?.page ? params?.page : 1;
        let limit = params?.limit ? params?.limit : constants.maxLimit;

        let url = `/issue-tracker?page=${page}&limit=${limit}`
        url += await this.buildBasicQueryString(params)
        let response = await Api.get(url)
        return response.data
    }

    getIssueTrackerDetails = async (page: any, limit: any, orgid?: string, status?: any, consumer?: any,
        contractor?: any, service_resolved_by?: any, searchFilter?: any, startDate?: string, endDate?: string, group_id?: string, sensorbox_id?: string) => {

        let searchText = ''
        if (searchFilter) {
            searchText = encodeURIComponent(searchFilter)
        }

        let url = `/issue-tracker?page=${page}&limit=${limit}&filter=${JSON.stringify({ search: searchText })}`

        if (status && status?.length > 0) {
            url += `&${status.map((n: any, index: any) => `status[${index}]=${n}`).join('&')}`
        }

        if (consumer && consumer?.length > 0) {
            url += `&${consumer.map((n: any, index: any) => `consumer[${index}]=${n}`).join('&')}`
        }

        if (contractor && contractor?.length > 0) {
            url += `&${contractor.map((n: any, index: any) => `contractor[${index}]=${n}`).join('&')}`
        }

        if (service_resolved_by && service_resolved_by?.length > 0) {
            url += `&${service_resolved_by.map((n: any, index: any) => `service_resolved_by[${index}]=${n}`).join('&')}`
        }

        if (orgid) {
            url += `&orgid=${orgid}`
        }

        if (startDate) {
            url += `&startDate=${startDate}`
        }

        if (endDate) {
            url += `&endDate=${endDate}`
        }

        if (group_id) {
            url += `&group_id=${group_id}`
        }

        if (sensorbox_id) {
            url += `&sensorbox_id=${sensorbox_id}`
        }

        let response = await Api.get(url)
        return response.data
    }

    addIssueTrackerDetails = async (data: any, orgid?: string) => {

        let url = `/issue-tracker`

        if (orgid) {
            url += `?orgid=${orgid}`
        }

        return Api.post(url, data)
    }

    IssueTrackerDetailsById = async (id?: any, orgid?: string) => {

        let url = `/issue-tracker-details/${id}`

        if (orgid) {
            url += `?orgid=${orgid}`
        }

        let response = await Api.get(url)
        return response.data
    }

    updateIssueTrackerDetails = async (id: any, data: any, orgid?: string) => {

        let url = `/issue-tracker/${id}`

        if (orgid) {
            url += `?orgid=${orgid}`
        }

        let response = await Api.put(url, data)
        return response.data
    }

    getIssueTrackerOverview = async (orgid?: string) => {

        let url = `/issue-tracker-overview`

        if (orgid) {
            url += `?orgid=${orgid}`
        }

        let response = await Api.get(url)
        return response.data
    }

    resolveIssue = async (id?: any, data?: any, orgid?: string) => {

        let url = `/resolve-issue-tracker/${id}`

        if (orgid) {
            url += `?orgid=${orgid}`
        }

        let response = await Api.put(url, data)
        return response.data
    }

    saveChangesIssue = async (id?: any, data?: any, orgid?: string) => {

        let url = `/save-changes-issue-tracker/${id}`

        if (orgid) {
            url += `?orgid=${orgid}`
        }

        let response = await Api.put(url, data)
        return response.data
    }

    ignoreIssue = async (id?: any, data?: any, orgid?: string) => {

        let url = `/ignore-issue-tracker/${id}`

        if (orgid) {
            url += `?orgid=${orgid}`
        }

        let response = await Api.put(url, data)
        return response.data
    }

    closeIssue = async (id?: any, data?: any, orgid?: string) => {

        let url = `/close-issue-tracker/${id}`

        if (orgid) {
            url += `?orgid=${orgid}`
        }

        let response = await Api.put(url, data)
        return response.data

    }

    deleteIssue = async (id?: any, orgid?: string) => {

        let url = `/issue-tracker/${id}`

        if (orgid) {
            url += `?orgid=${orgid}`
        }

        return Api.delete(url)
    }

    getSoftSavingsCategory = async () => {

        let url = `/soft-savings-categories`

        let response = await Api.get(url)
        return response.data
    }

    getAttachedImageByimageId = async (imageId?: string) => {

        let url = `/issue-tracker/note/attachment/${imageId}`

        let response = await Api.get(url, { responseType: 'blob' })
        return response
    }

    getIssueNoteById = async (issueId?: string) => {

        let url = `/issue-tracker/note/?issue_id=${issueId}`

        let response = await Api.get(url)
        return response
    }

    addIssueTrackerAttachments = async (issueId: any, data: any) => {
        let url = `/issue-tracker/note?issue_id=${issueId}`
        return Api.post(url, data)
    }

}

const instance = new issueTrackerService();

export default instance;
// export default new issueTrackerService()