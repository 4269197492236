import Api from '../api';
import { PinConfiguration } from '../interface';

export interface ISettings {
  merge_timeout?: number
  config_req_res_timeout?: number
  offline_alert_interval?: number
  device_offline_checking_duration?: number
  generator_device_offline_checking_duration?: number
  lockrouter_device_offline_checking_duration? : number
  cyclic_device_offline_checking_duration? : number
  cycle_offline_duration? : number
  custom_algorithm?: boolean
  cooling_heating_time_interval?:number
  invalid_range_alert_threshold?:number
}

class SettingsService {
  getSettings = async () => {
    return Api.get('/settings')
  }
  saveSettings = async (settings: ISettings) => {
    return Api.post('/settings', settings)
  }
  uploadAlgorithmScript = async (data: FormData) => {
    return Api.upload(process.env.REACT_APP_SSE_BASE_URL + '/upload-algorithm-script', data);
  }
  resetAlgorithm = async () => {
    return Api.put('/reset-algorithm-script', {});
  }
  getPinConfigurations = async (pageNumber?: number, limit?: number, typeFilter?: string, searchFilter?: string) => {
    return Api.get(`/pin-config?pageNumber=${pageNumber ? pageNumber : 1}&limit=${limit ? limit : 0}&type=${typeFilter??""}&search=${searchFilter??""}`)
  }
  addPinConfiguration = async (data: PinConfiguration) => {
    return Api.post('/pin-config', data)
  }
  updatePinConfiguration = async (data: PinConfiguration) => {
    return Api.put(`/pin-config/${data._id}`, data)
  }
  deletePinConfiguration = async (id: string) => {
    return Api.delete(`/pin-config/${id}`)
  }
  pinRepresentations = async () => {
    let url = `/pin-representation`
    return Api.get(url)
  }   
}

export default new SettingsService()