import React from 'react';
import { Collapse, Typography } from 'antd';

import AlertTable from '../alerts-list-table';
import DoughnutComponent from '../doughnut';
import Icon from '../../components_new/icon';
import AlertCard from '../alert-card';
import authStore from '../../store/authStore'

import Utility from '../../helpers/Utility';
import constants from '../../config/constants';

const { Text } = Typography;
const { Panel } = Collapse;

interface Props {
  systemData?: any
  index?: any;
  setCenterCoordOnMouseHover: (data: any) => void;
  onClickCard: (data: any) => void;
  onClickSnooze: () => void;
}

const SystemCards: React.FC<Props> = ({ systemData, setCenterCoordOnMouseHover, onClickCard, onClickSnooze, index }) => {

  let labelArray = systemData?.sensorboxes?.map((sensor: any, index: number) => {
    return constants.statusColor.get(String(sensor.status))
  })

  const counts = {} as any;
  labelArray?.forEach((value: any) => {
    counts[value.toUpperCase()] = (counts[value.toUpperCase()] || 0) + 1;
  })
  const colorLabels = systemData?.group === 1 ? Object.keys(counts) : [constants.statusColor.get(String(systemData?.status))];
  const colorLabelValues = systemData?.group === 1 ? Object.values(counts) : [1];

  const hoursOutstanding = systemData?.alert_outstanding_time ? Utility.getMomentFromNow(systemData?.alert_outstanding_time) : 0

  const demoCheck = (authStore?.currentUser?.active_role?.role_type === -1);

  // const systemName = demoCheck ? `Group ${index + 1}` : systemData?.group === 1 ? systemData?.name : (systemData?.alias_name ? systemData?.alias_name : systemData?.sensor_box_name);
  const systemName = demoCheck ? systemData?.name : systemData?.group === 1 ? systemData?.name : (systemData?.alias_name ? systemData?.alias_name : systemData?.sensor_box_name);

  const consumerName = demoCheck ? `Consumer ${index + 1}` : systemData?.consumer?.[0]?.name ? systemData?.consumer?.[0]?.name : ''

  const location = demoCheck ? `-` : systemData?.group === 0 ? systemData?.place || '' : systemData?.sensorboxes?.[0]?.place ? systemData?.sensorboxes?.[0]?.place : ''


  const header = () => (
    <div className="d-flex w-100 align-items-center alert-collapse-container h-100" onMouseOver={() => { if (setCenterCoordOnMouseHover) setCenterCoordOnMouseHover(systemData) }} >
      <div className="flex-fill h-100 min-w-0 pr-4">
        <div className="d-flex flex-column h-100">
          <div className="flex-fill h-100">
            <div className="d-flex align-items-center">
              <Text 
                ellipsis={{ tooltip: systemName }}
                className="mr-1 mb-0 system-name"
                onClick={() => { onClickCard(systemData) }}
              >{systemName}
              </Text>
              <div className={`small-circle-alert ml-1 ${Utility.getStatus(systemData?.status)}`}></div>
            </div>
            {authStore?.currentUser?.active_role?.role_type !== 4 &&
            <Text 
              ellipsis={{ tooltip: consumerName }}
              className="d-block text-gray">
              <Icon className="icon-14 mr-2" iconRef="#user_icon" />
              {consumerName}
            </Text>
            }
            <Text
              ellipsis={{ tooltip: location }}
              className="d-block text-gray">
              <Icon className="icon-14 mr-2" iconRef="#location" />
              {location}
            </Text>
            <div className="d-flex system-button my-2">
              <div className="alert-hours mr-3">
                <Icon className="icon-14 mr-2" iconRef="#last_updated" />
                {hoursOutstanding ? hoursOutstanding : 'N/A'}
              </div>
            </div>
          </div>
          <div className="flex-auto">
            {/* <Button className="btn snooze-btn" onClick={(e) => {
              e.stopPropagation();
              onClickSnooze()
            }}>
              <Icon className="icon-14 mr-2" iconRef="#snooze" />
              Snozze
            </Button> */}
          </div>
        </div>

      </div>
      <div className="flex-auto">
        <div className="d-flex align-items-center">
          <div className="flex-fill">
            <div className="position-relative doughnut-container small">
              <DoughnutComponent
                colorLabel={colorLabels}
                colorValue={colorLabelValues}
              />
            </div>
          </div>
          <div className="flex-auto expand-arrow ml-3">
            <Icon className="icon-16" iconRef="#right" />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {systemData?.group === 1 ?
        <Collapse
          className="custom-collapse"
        >
          <Panel className="alert-table h-100 table-box-shadow" header={header()} key="1" >
            <AlertTable subSystem={systemData?.sensorboxes} onClickSnooze={() => onClickSnooze()} setCenterCoordOnMouseHover={(data: any) => { if (setCenterCoordOnMouseHover) setCenterCoordOnMouseHover(data) }} />
          </Panel>
        </Collapse>
        :
        <AlertCard subSystem={systemData} setCenterCoordOnMouseHover={(data: any) => { if (setCenterCoordOnMouseHover) setCenterCoordOnMouseHover(data) }} onClickCard={(data: any) => onClickCard(data)} onClickSnooze={() => onClickSnooze()} index={index} />
      }
    </>
  );
}
export default SystemCards;
