import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import LoadingWheel from '../loadingwheel';
import SensorService from '../../services/sensorService';
import Select from '../../components/select';
import toast from '../toast';


interface Props {
  show: boolean;
  onClose: () => void;
  sensorBoxDetails?: any;
  organisationList? : Array<any>;
  closeModal : (orgName : any) => void;
  isLoading? : boolean;
}

const MoveSensorboxModal: React.FC<Props> = ({ show, sensorBoxDetails, onClose, organisationList, closeModal, isLoading}) => {

  const [selectedOrgId, selectedOrgIdselectedOrgId] = useState(null)as any;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorBoxDetails?._id]);

  const handleOrganizationSelection= (option: any) => {
    selectedOrgIdselectedOrgId(option)
  }

  async function onCloseModal(){
    selectedOrgIdselectedOrgId(null)
    onClose()

  }

  async function submitMoveSensorboxes() {
    try {
        let datas = {
          id : sensorBoxDetails?._id,
          org_id : selectedOrgId
        }
        let response = await SensorService.moveSensorboxToOtherOrganization(datas)
        if(response === 'Success.'){
          const orgName = organisationList?.find((item :any) => item._id === datas?.org_id?._id)
          closeModal(orgName?.name)
    }
    }catch(error) {
      toast.error(error.response.data.message)
    }
  }

  const orgDatas = organisationList?.filter((item :any) => (item._id !== sensorBoxDetails?.org_id)) as Array<any>

  return (
    <div>
    <Modal
      show={show}
      onHide={onCloseModal}
      size="xl"
    >
      <Modal.Header closeButton>
      <div>Move Sensorbox {sensorBoxDetails?.alias ? sensorBoxDetails?.alias : sensorBoxDetails?.sensor_box_name}</div>
      </Modal.Header>
      <Modal.Body>
      <div className="mt-3">
                        <label className="custom-label">Select organization <span className="mand-star">*</span></label>
                        <Select
                          name="organization"
                          data={orgDatas} 
                          value={selectedOrgId}  
                          onChange={handleOrganizationSelection}
                        />
                        <div className="modal-footer">
                          <button type="button" className="btn default-btn" data-dismiss="modal" onClick={()=>submitMoveSensorboxes()} disabled={!selectedOrgId ? true : false}>Submit</button>
                        </div>
                      </div>
      </Modal.Body>
    </Modal>
    <div>
            {isLoading ?
            <LoadingWheel/>
            :   null}
        </div>
    </div>
  )
}

export default MoveSensorboxModal;
