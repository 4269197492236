import React, { useState } from "react";
import { Row, Col, Card, Tooltip, Button } from "antd";

import DoughnutComponent from '../../components_new/doughnut';
import constants from '../../config/constants';
import CountsCard from "../counts-card";
import Icon from "../icon";

interface Props {
    colorLabelsForDougnut?: any,
    colorCountForDoughnut?: any,
    redStatusCount?: any,
    yelowStatusCount? : any,
    greenStatusCount? : any,
    systemCountsForOverview? : any
    systemOverview? : any
    onClickStatus: (data: any) => void;
}

const OverviewCard: React.FC<Props> = ({ colorLabelsForDougnut, colorCountForDoughnut, redStatusCount, yelowStatusCount, greenStatusCount, systemCountsForOverview ,systemOverview , onClickStatus}) => {

    const [showOverview, setShowOverview] = useState(false)
    const renderOverview = () => {
        return showOverview ?
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
                <Col className="gutter-row mb-3" xs={24} md={12}>
                    <CountsCard
                        count={systemOverview?.onlineCount}
                        descriptionOne="Systems are"
                        descriptionTwo="Online"
                        iconClassName="icon-22"
                        iconRef="#online"
                    />
                </Col>
                <Col className="gutter-row mb-3" xs={24} md={12}>
                    <CountsCard
                        count={systemOverview?.offlineCount}
                        descriptionOne="Systems are"
                        descriptionTwo="Offline"
                        iconClassName="icon-22"
                        iconRef="#offline"
                    />
                </Col>
            </Row> : <></>
    }
    return (
        <>
            <Card className="custom-card border-0 h-100 overview-card">
                <div className="d-flex flex-column">
                    <div className="flex-fill h-100">
                        <div className="d-flex">
                            <div className="flex-fill">
                            <h5 className="font-w-600">Facility Overview</h5>
                                <p className="pt-2">
                                    Check out the status of your systems!
                                </p>
                            </div>
                            <div className="flex-auto">
                                <div className="position-relative doughnut-container large">
                                    <DoughnutComponent
                                        colorLabel={colorLabelsForDougnut}
                                        colorValue={colorCountForDoughnut}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-auto mb-4">
                        <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
                            {systemCountsForOverview?.length > 0 &&
                                systemCountsForOverview?.map((systemCount: any, index: number) => {
                                    return (
                                        <Col key={systemCount?.status} className="gutter-row mb-3" span={8}>
                                            <Tooltip title={constants.statusDescription.get(systemCount?.statusNumber)}>
                                                <Card className={`custom-card border-0 alert-device-count ${systemCount?.status}`} onClick={(data: any) => onClickStatus(systemCount)} hoverable>
                                                    {systemCount ? systemCount?.count : 0}
                                                </Card>
                                            </Tooltip>
                                        </Col>
                                   
                                    )
                                })
                            }
                           
                        </Row>
                    </div>
                    <div className="flex-auto mb-4">
                        {renderOverview()}
                        <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
                            <Button onClick={() => { setShowOverview(state => !state) }} type="text" block>
                                <div style={{ 'transform': showOverview ? 'rotateX(180deg)' : 'rotateX(0deg)' }}>
                                    <Icon className='icon-14' iconRef={'#downarrow'} />
                                </div>
                            </Button>
                        </Row>
                    </div>
                </div>
            </Card>
        </>
    );
}
export default OverviewCard;
