import axios from 'axios'
import authStore from '../store/authStore'

class Api {

  source: any; 
  getRequestInProgress:any
  constructor() {
    
    axios.interceptors.request.use(config => {
      if (authStore.token) {
        config.headers["Authorization"] = `Bearer ${authStore.token}`;
        config.headers = config.headers || {};
      }
      return config;
    });


    axios.interceptors.response.use(
        response => response,
        this.errorResponseHandler
    )
  }

  errorResponseHandler =(error:any) => {
    if (error && error.response && 401 === error.response.status && error.response.data.message === "Invalid Token") {
      authStore.signOut()
      window.location.href="/";
    }
    else
      return Promise.reject(error);
  }

  post = async (url: string, data?: any) => {
    try {
      let res = await axios.post(process.env.REACT_APP_BASE_URL + url, data)
      return res
    } catch (error) {

      throw this.formatError(error)
    }
  }


  search  = async (url: string) => {
    try {
      if (this.source !==  undefined) {
        this.source.cancel('Operation canceled due to new request.')
      }

      this.source = axios.CancelToken.source();

      let res = await axios.get(process.env.REACT_APP_BASE_URL + url, {
        cancelToken: this.source.token 
      })
      return res

    }catch (error) {
      throw this.formatError(error)
    }
  } 

  get = async (url: string, config?: any) => {
    try {
      let res = await axios.get(process.env.REACT_APP_BASE_URL + url, config)
      return res
    } catch (error) {

      throw this.formatError(error)
    }

  }
  cancelPendingAndGet  = async (url: string) => {
    try {
      if (this.getRequestInProgress !==  undefined) {
        this.getRequestInProgress.cancel('Operation canceled due to new request.')
      }
      this.getRequestInProgress = axios.CancelToken.source();
      let res = await axios.get(process.env.REACT_APP_BASE_URL + url, {
        cancelToken: this.getRequestInProgress.token
        
      })
      return res

    }catch (error) {
      throw this.formatError(error)
    }
  }

  put = async (url: string, data: any) => {

    try {
      let res = await axios.put(process.env.REACT_APP_BASE_URL + url, data)
      return res
    } catch (error) {

      throw this.formatError(error)
    }

  }

  delete = async (url: string, config?: any) => {
    try {
      let res = await axios.delete(process.env.REACT_APP_BASE_URL + url, config)
      return res
    } catch (error) {

      throw this.formatError(error)
    }

  }

  upload = async (url: string, data: FormData) => {
    try {
      let res = await axios.post(url, data);
      return res
    } catch (error) {

      throw this.formatError(error)
    }
  }

  formatError(error: any) {
    
    if (!error.response) {
      error.response = {
        data: {
          message: "Something went wrong, Please try again later"
        }
      }
    }
    return error
  }

}

const api = new Api()

export default api