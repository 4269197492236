import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Toast {

    constructor(){
        this.configure()
    }


    dismiss(){
       toast.dismiss();
    }
    
    configure(){
        toast.configure({
            draggable: false,
          });
    }

    success(msg : string) {
        this.dismiss()
        toast.success(msg, {
            autoClose: 4000,
        });
    }

    error(msg : string) {
        this.dismiss()
        toast.error(msg, {
            autoClose: 4000,
        });
    }
    
    ToastView() {
        return (
            <ToastContainer
                position={toast.POSITION.TOP_RIGHT}
            />
        )
    }
}
const toastMessage = new Toast()
export default toastMessage