import React from 'react'
import './terms-service.css';

function TermsService() {

    return (

        <div className="container terms-wrapper">   

        <h2><strong>MechaSense – Platform Terms of Service</strong></h2>
            
        <p>PLEASE REVIEW THESE TERMS CAREFULLY AS THEY AFFECT YOUR LEGAL RIGHTS AND CONTAIN A MANDATORY ARBITRATION PROVISION AND A WAIVER OF CLASS ACTION REMEDIES</p>
        
        <p>These Platform Terms of Service (these “<strong> Terms </strong>”) form a binding legal agreement between MechaSense Corp., a Delaware corporation (“<strong>we</strong>”, “<strong>us</strong>”, or “<strong>our</strong>”) and you regarding the use of our website, mobile app, platform, and related offerings and services (collectively, the “<strong>Platform</strong>”).</p>
        
        <p>By registering an account or otherwise accessing or using the Platform, you agree to be bound by these Terms. We may update these Terms from time to time in our discretion. We will always keep the current version of these Terms posted on this website. By using the Platform after a new version of these Terms have been posted, you agree to the terms and conditions of such version of these Terms. If you do not agree to these Terms, you must immediately cease your use of the Platform. You consent to the use of electronic signatures in connection with the Platform, including your acceptance of these Terms and our other policies. Your electronic signature (which may include checking boxes, clicking “I accept”, spelling out your name, or taking similar actions intended to indicate acceptance) will be as valid and binding as a handwritten signature.</p>
        
        <p>The Platform is intended to enable authorized users to monitor the status and performance of residential and commercial HVAC systems where applicable monitoring devices (“<strong>Devices</strong>”) have been installed. Users may include property owners or managers, tenants, and HVAC service providers and contractors.</p>

        <h5><strong>RELATIONSHIPS BETWEEN USERS</strong></h5>
        
        <p>Our Platform may be used by both end customers (e.g., a property owner or tenant) and contractors (e.g., property managers, HVAC service providers, and other contractors), but we are not a party to any agreements between such parties and have no responsibility for ensuring either party’s compliance with any such third-party agreements. You are solely responsible for choosing which third parties to contract with, and for fulfilling your own obligations relating to such transactions or contracts. If you are a property owner or tenant and you have contracted with a HVAC service provider, contractor, or other third party who acts as our reseller (“<strong>Reseller</strong>”), you may be required to direct any support or other requests to such Reseller, who will either manage the issue or escalate to us for additional assistance. We have no liability for the acts and omissions of any third parties, including other users.</p>
                
        <h5><strong>ACCOUNTS</strong></h5>
        
        <p>You will be required to provide certain information (including personal information) to register your account on the Platform. You are responsible for ensuring you provide complete and accurate information and keep such information up to date, and you are responsible for any liability or damages arising from false, fraudulent, or incomplete information. You are responsible for maintaining the security of your account and your login credentials, and you will be responsible for any actions taken using your account credentials. You are responsible for keeping your own records and making appropriate backups – we will not be responsible for any loss of data in our possession or control.</p>
                                
        <h5><strong>DATA</strong></h5>
        
        <p>The Platform processes and stores data related to HVAC system status and operation where applicable Devices have been installed, which may include, without limitation, HVAC manufacturer, model, performance data, temperature readings and settings, pressures, currents, and other such data (collectively, “<strong>System Data</strong>”). The Platform also automatically collects certain usage and technical data relating to the performance and usage of the Platform itself, which may include, without limitation, user pageviews, clicks, computer or mobile device type, operating system, browser, geo-location (if enabled), and other such data (collectively, “<strong>Usage Data</strong>”).</p>
                        
        <p>You confirm, agree, and acknowledge that: (i) if you are a property owner or tenant, you have the right and power to authorize (and have in fact authorized) the installation of applicable Devices and collection of System Data at your location(s), and that the relevant property manager, HVAC service provider, or other Reseller will be authorized to access the System Data through their own Platform account; (ii) if you are a property manager, HVAC service provider, or other Reseller, you have received authorization from the relevant property owner and tenant to install the Devices and access System Data; (iii) Devices may continue to transmit System Data to the Platform so long as they are properly installed, powered, and connected to the Internet, even if your access to the Platform has been suspended or terminated; and (iv) we may use, modify, analyze, and disclose System Data and Usage Data in any manner we deem appropriate, so long as we do not publicly disclose such data in a manner that identifies any particular individual, entity, or address.</p>

        <h5><strong>CONTENT; OWNERSHIP; FEEDBACK</strong></h5>
        
        <p>“<strong>Content</strong>” means all information, data, text, software, music, sound, photographs, graphics, video, messages, or other material or content that are displayed, used, or otherwise incorporated into the Platform. All Content is owned by us or our third-party licensors and is protected by applicable copyright, trademark, and/or other intellectual property laws. Nothing contained on the Platform should be construed as granting any license or right to use any of the Content without our written permission. Subject to your continued compliance with these Terms, we hereby grant you a personal, non-exclusive, revocable, non-transferable and non-sublicensable license to access and use the Platform and Content for your personal use. Any use of the Content (including our names, logos, and trademarks) will inure to our benefit.</p>
        
        <p>If you provide feedback, suggestions, improvements, or requests for additional functionality (collectively, “<strong>Feedback</strong>”), you grant us an unrestricted, perpetual, irrevocable, royalty-free, worldwide license to use, reproduce, display, perform, modify, transmit, distribute, and create derivative works of such Feedback in any way we deem reasonable, without any attribution or accounting to you. This paragraph will survive any termination or expiration of these Terms or of your account on the Platform.</p>

        <h5><strong>COMMUNITY STANDARDS; RESTRICTIONS</strong></h5>
        
        <p>You must at all times comply with any applicable laws, rules, and regulations. You represent, warrant, and agree that you will not: (i) remove any trademark or copyright notices contained in the Platform or Content; (ii) reproduce, modify, publish, distribute, transmit, disseminate, transfer, license, sell, lease, create derivative works based upon, or in any way commercially exploit the Platform or Content; (iii) use manual or automated means to trawl, mine, scrape, frame, or mirror the Platform or Content; (iv) disassemble, decompile or reverse engineer the Platform or Content; (v) attempt to hack, defeat, or overcome any encryption technology or security measures regarding the Platform or our other systems or those of any third party, or gain any unauthorized access to any systems or accounts (including attempting to access any System Data that you are not authorized to access); (vi) interfere with or disrupt the operation of the Platform or any other systems or otherwise interrupt or interfere with any other user’s use or enjoyment of the Platform; (vii) violate any applicable local, state, national or international law; (viii) post or transmit any Content that is unlawful, defamatory, abusive, harassing, threatening, obscene, fraudulent or otherwise inappropriate or infringes any intellectual property or privacy rights of any person; (ix) send unsolicited advertisements through the Platform; (x) use the Platform in a way that will adversely affect us or reflect negatively on us, any of our goodwill, name or reputation; or (xi) provide any false or misleading information or any information that you do not have the right to provide.</p>
        
        <h5><strong>THIRD PARTIES</strong></h5>
        
        <p>The Platform may contain links to websites owned or operated by third parties. We do not control or endorse such websites and we are not responsible for their content, nor are we responsible for the accuracy or reliability of any information, data, opinions, advice, or statements contained within such websites. We have the right, but not the obligation, to review any Content provided by third parties (including other users of the Platform). We do not endorse such third-party Content, and any views expressed therein are the views of the applicable author and do not necessarily align with our views. We are not responsible for the accuracy or reliability of any Content provided by third parties and will not be liable for any causes of action (including slander, libel, or invasion of privacy) relating to such third-party Content. We have no control over, and are not responsible for, your interactions (online or in person) with any third parties, including other users of the Platform even if introduced via the Platform. You are solely responsible for your interactions with any third parties. If you have any disputes with other users of the Platform, we may (at our discretion) attempt to facilitate a resolution but are under no obligation to do so.</p>
        
        <h5><strong>DISCLAIMERS</strong></h5>
        
        <p>YOUR USE OF THE PLATFORM AND CONTENT IS AT YOUR SOLE RISK AND THE PLATFORM ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. WE AND OUR LICENSORS EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND RELATED TO THE PLATFORM AND CONTENT, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, OR THAT USE OF THE PLATFORM WILL BE UNINTERRUPTED OR ERROR-FREE OR THE CONTENT WILL BE COMPLETE OR ACCURATE. WE ARE NOT RESPONSIBLE FOR ANY PROBLEMS OR TECHNICAL MALFUNCTION OF ANY ELECTRONIC NETWORK OR LINES, SERVERS, SOFTWARE, OR FAILURE OF TRANSMISSION AS A RESULT OF TECHNICAL PROBLEMS OR TRAFFIC CONGESTION ON THE INTERNET OR ON ANY OF THE PLATFORM, INCLUDING ANY INJURY OR DAMAGE TO ANY PERSON'S COMPUTER RESULTING FROM PARTICIPATION OR DOWNLOADING MATERIALS IN CONNECTION WITH THE PLATFORM.</p>
        
        <p>WE DO NOT SERVICE HVAC SYSTEMS AND ANY SUCH REQUESTS SHOULD BE SENT TO A PROFESSIONAL HVAC SERVICE PROVIDER. THE PLATFORM IS INTENDED TO PROVIDE INFORMATION AND INSIGHTS REGARDING HVAC SYSTEM PERFORMANCE AND OPERATION, BUT WE CANNOT GUARANTEE THAT THE DATA COLLECTED OR DISPLAYED WILL BE ACCURATE OR COMPLETE OR THAT THE PLATFORM WILL ALWAYS BE AVAILABLE. YOU EXPRESSLY AGREE NOT TO RELY ON THE PLATFORM IN ANY SITUATION WHERE FAILURE OF THE PLATFORM COULD RESULT IN DAMAGE TO OR LOSS OF PROPERTY, OR INJURY OR DEATH. WE CANNOT GUARANTEE ANY SPECIFIC RESULTS FROM USE OF THE PLATFORM OR CONTENT.</p>
        
        <p>UNDER NO CIRCUMSTANCES SHALL WE BE RESPONSIBLE FOR ANY LOSS OR DAMAGE, INCLUDING PERSONAL INJURY OR DEATH, RESULTING FROM USE OF THE PLATFORM, CONTENT, OR FROM THE CONDUCT OF ANY USERS OF THE PLATFORM OR OTHER THIRD PARTIES. WITHOUT LIMITING THE FOREGOING, WE ARE NOT RESPONSIBLE FOR ERRORS OR UNAVAILABILITY CAUSED BY IMPROPER INSTALLATION OF DEVICES, TAMPERING WITH OR DAMAGE TO DEVICES, LOSS OR THEFT OF DEVICES, LOSS OF DEVICE POWER OR CONNECTIVITY, OR FAILURE OF INTERNET TRANSMISSIONS.</p>

        <p>SOME STATES DO NOT ALLOW CERTAIN DISCLAIMERS OR LIMITATIONS ON WARRANTIES, SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. THESE LIMITATIONS WILL APPLY TO THE MAXIMUM EXTENT PERMITTED BY LAW.</p>

        <h5><strong>LIMITATION OF LIABILITY</strong></h5>
        
        <p>TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, NEITHER WE NOR OUR LICENSORS WILL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, OR DATA (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM THE USE OF OR THE INABILITY TO USE THE PLATFORM, UNDER ANY LEGAL THEORY WHATSOEVER (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE). TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, NEITHER WE NOR OUR LICENSORS WILL BE LIABLE TO YOU FOR AN AGGREGATE AMOUNT EXCEEDING THE GREATER OF $100 USD OR THE AMOUNTS PAID BY YOU TO US IN THE PAST THREE MONTHS. SOME STATES DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY FOR DAMAGES, SO THE ABOVE MAY NOT APPLY TO YOU. YOUR SOLE REMEDY IF YOU ARE DISSATISFIED WITH THE PLATFORM IS TO DISCONTINUE YOUR USE THEREOF.</p>
        
        <h5><strong>RELEASE AND INDEMNITY</strong></h5>
        
        <p>You will release, defend, indemnify, and hold us, our past, present, and future affiliates, licensors, licensees, marketing partners, and suppliers, together with their respective officers, directors, employees, and agents, harmless from and against any damages, losses, claims, actions or demands, liabilities and settlements including without limitation, reasonable legal and accounting fees, resulting from your use of the Platform, your violation of these Terms, and any services agreement or other contract between users. We may opt to defend such claims at our sole discretion, in which case you will indemnify us for the costs of such defense.</p>
        
        <p>CALIFORNIA RESIDENTS HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542, WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, AND THAT IF KNOWN BY HIM OR HER WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.”</p>
        
        <h5><strong>TERMINATION</strong></h5>
        
        <p>We reserve the right to modify or discontinue, temporarily or permanently, the Platform (or any part thereof) without notice. We may terminate or suspend your account at any time, if (i) you breach these Terms; (ii) you fail to pay any subscription fees due to us or the applicable Reseller, or otherwise breach the applicable separate contract with us or the applicable Reseller; or (iii) as otherwise advisable in our sole discretion, e.g., to prevent or halt a security incident or comply with applicable laws. You may stop using the Platform at any time; to delete your account please contact us as set forth at the end of these Terms. Termination will not relieve either party from any obligations incurred or arising prior to such termination, and those sections of these Terms which are by their nature intended to survive termination (including, without limitation, the disclaimers, limitation of liability, indemnity, and general clauses) shall so survive.</p>
        
        <h5><strong>DISPUTE RESOLUTION; MANDATORY ARBITRATION; WAIVER OF JURY TRIAL AND CLASS ACTION</strong></h5>
        
        <p>THE PARTIES AGREE THAT ANY DISPUTES ARISING IN CONNECTION WITH THE PLATFORM, DEVICES, OR THESE TERMS WILL BE EXCLUSIVELY RESOLVED THROUGH BINDING ARBITRATION IN DALLAS, TEXAS ADMINISTERED BY THE AMERICAN ARBITRATION ASSOCIATION USING ITS COMMERCIAL ARBITRATION RULES. JUDGMENT ON THE AWARD RENDERED BY THE ARBITRATOR(S) MAY BE ENTERED IN ANY COURT HAVING JURISDICTION. NOTWITHSTANDING THE FOREGOING OBLIGATION TO ARBITRATE DISPUTES, EACH PARTY SHALL HAVE THE RIGHT TO PURSUE INJUNCTIVE OR OTHER EQUITABLE RELIEF AT ANY TIME, FROM ANY COURT OF COMPETENT JURISDICTION.</p>

        <p>THE PREVAILING PARTY IN ANY PROCEEDING ARISING OUT OF THIS AGREEMENT WILL BE ENTITLED TO REASONABLE ATTORNEY’S FEES AND COSTS INCURRED BY THAT PARTY.</p>

        <p>THE PARTIES HERETO EXPRESSLY WAIVE THE RIGHT TO TRIAL BY JURY AND ANY RIGHT TO PARTICIPATE IN CLASS-ACTION PROCEEDINGS.</p>

        <h5><strong>FORCE MAJEURE</strong></h5>

        <p>We will not be responsible or liable for any delays or failures to perform due to causes beyond our reasonable control, which may include third-party actions or omissions, natural disasters, terrorist attacks, criminal activity, failure of internet or communications networks, health emergencies including pandemics or similar serious outbreaks of disease, or other force majeure events.</p>

        <h5><strong>GENERAL</strong></h5>

        <p>You may not assign these Terms or these rights and obligations without our prior written consent; any purported assignment in violation of these Terms will be null and void. If any provision of these Terms is determined to be void or unenforceable in whole or in part, the remaining provisions of these Terms shall not be affected thereby and shall remain in force and effect. These Terms are governed by and will be construed in accordance with the laws of the State of Texas, without regard to the conflicts of laws provisions thereof.</p>

        <p>These Terms and any policies referenced herein constitute the entire agreement between the parties regarding the subject matter thereof and supersede any prior or contemporaneous agreements with regards to such subject matter. A party's failure to exercise or enforce any right or provision of these Terms shall not operate as a waiver of such right or provision.</p>

        <p>You must provide notice to us by emailing us at <a href = "mailto: info@mechasense.io">info@mechasense.io</a>. We may provide notice to you by email or regular mail at the address listed in your account profile, or through messages displayed or sent via this website or the Platform.</p>

        <h5><strong>CONTACT</strong></h5>

        <p>For more information or for help in answering any questions, please contact us at <a href = "mailto: info@mechasense.io">info@mechasense.io</a></p>
    
    </div>

    )
}

export default TermsService