import React,{ Component } from "react";
import "./dashboard.css"
import {SensorBox} from '../../interface'
import Utils from "../../helpers/Utility";
import authStore from '../../store/authStore'
export interface MarkerState {
    sensorBoxList   : Array<SensorBox>
    isBubbleVisible : Boolean
 }

export interface MarkerProps{
    lat     : number;
    lng     : number;
    name    : string;
    group    : string;
    place   : string;
    value   : any;
    created_by: string;
    active : boolean;
    alert   :   string;
}

class Marker extends Component<MarkerProps, MarkerState>
{
  
    constructor(props : MarkerProps){
        
        super(props);
        this.state = {
        sensorBoxList   : [],
        isBubbleVisible : false
        }
    }

    async componentDidMount() {

    }

    toggleBubble(){
        this.setState({isBubbleVisible  : !this.state.isBubbleVisible })
    }

    render() {
        const demoCheck = (authStore?.currentUser?.active_role?.role_type === -1);
        return (
            <>

                <i className={`fa fa-map-marker marker-container ${Utils.getMapMarkerStatus(this.props.value)}`}
                    aria-hidden="true"
                    onMouseOver={() => this.toggleBubble()}
                    onMouseOut={() => this.toggleBubble()}>
                </i>
                {this.state.isBubbleVisible ?

                    <div>
                        <i className="fa fa-caret-up arrow-up" aria-hidden="true"></i>
                        <div className="talk-bubble popup-bubble tri-right border round btm-left-in remove-border-radius">

                            <div className="head-title ">{`Systems (${this.props.name ? this.props.name.split(',').length : ''})`}</div>
                            <div className="talktext">

                                {this.props.name && this.props.name.length > 0 &&
                                    <div className="d-wrap section-height">
                                        <div className="popup-div">
                                            <i className="fa fa-square-o ic-round" aria-hidden="true"></i>
                                        </div>
                                        <div className="">
                                            <h6 className="text-head2">System Name(s)</h6>
                                            <p className="text-head3">{this.props.name}</p>
                                        </div>
                                    </div>}

                                {this.props.alert && this.props.alert.length > 0 ?
                                    <div className="d-wrap section-height">
                                        <div className="popup-div">
                                            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                                        </div>
                                        <div className="">
                                            <h6 className="text-head2">Alert</h6>
                                            <p className="text-head3">{this.props.alert}</p>
                                        </div>
                                    </div>
                                    : null
                                }

                                <div className="d-wrap section-height">
                                    <div className="popup-div">
                                        <i className="fa fa-globe ic-round" aria-hidden="true"></i>
                                    </div>
                                    <div className="">
                                        <h6 className="text-head2">Place </h6>
                                        <p className="text-head3">{demoCheck ? '-' : this.props.place}</p>
                                    </div>
                                </div>

                                <div className="d-wrap section-height">
                                    <div className="popup-div">
                                        <i className="fa fa-map-marker ic-round" aria-hidden="true"></i>

                                    </div>
                                    <div className="">
                                        <h6 className="text-head2">Location</h6>
                                        <p className="text-head3"> {demoCheck ? '-' : (this.props.lat ? this.props.lat.toFixed(3) : '') + ", " + (this.props.lng ? this.props.lng.toFixed(3) : '')}
                                        </p>
                                    </div>
                                </div>

                                {/* <div className="d-wrap">
                                <div className="popup-div">
                                <i className={`fa ${this.props.active ? "fa-times" : "fa-check"} ic-round`}></i>
                                </div>
                                <div className="">
                                    <h6 className="text-head2">Active</h6>
                                    <p className="text-head3">{this.props.active?"False":"True"} </p>
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </div>


                    : null}
            </>
        );
    }
}

export default Marker

 