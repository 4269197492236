import React,{ Component } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import Feedback from "../../components/feedback"
import './contactus.css';
import Contactimage from '../../assets/about-banner.jpg';


export interface ContactState { }
export interface ContactProps extends RouteComponentProps {}

class ContactUs extends Component<ContactProps, ContactState>
{
  
 constructor(props : any){
     
     super(props);
     this.state = {

     }
 }


  render() {
    return (
        <div className="container">
        <div className="contact-us-wrapper">
            <img src={Contactimage} className="contact-img" alt="contact" />

          <div className="row">
            <div className="col-md-6">

              <div className="contact-us-text">
                <h4>Contact Us</h4>
                <p>Email: sapsangi@hvacintel.com</p>
                <p>Phone: 817-501-7684</p>
              </div>

              </div>
              <div className="col-md-6">
                <Feedback />
              </div>

          </div>   
         </div>
      </div>
    );
  }
}

export default withRouter(ContactUs);