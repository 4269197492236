import React from 'react';
import moment from 'moment'
import './graphmodal.css';
import constants from '../../config/constants'
import toast from '../../components/toast'
import Graph from './graph';
import sensorService from '../../services/sensorService';
import DatePicker from '../../components/datepicker'
import LoadingWheel from '../../components/loadingwheel'
import settingsService from '../../services/settingsService';
import Select from '../select';
import { Button } from 'antd';
import {
  CloseOutlined
} from '@ant-design/icons';


export interface GraphProps {
  closeGraphModal: () => void;
  SensorBoxName: string;
  sensorBox: any;
}

export interface GraphState {
  x_time: any;
  y_data: any;
  sensorData: any;
  startDate: Date;
  endDate: Date;
  isLoading: boolean;
  selectedSensor: any;
  dateError: string;
  configOptions: any,
  selectedConfig: any,
  sensorOptions: any,
  configMap: any,
  configDateString: String,
  selectedParameters: any,
  selectedGrid: any,
  fieldTestOfflineDuration: any;
  powerConsumption: any;
  threshold?: any;
}

class GraphModal extends React.PureComponent<GraphProps, GraphState>{

  constructor(props: GraphProps) {
    super(props);
    this.state = {
      x_time: {},
      y_data: {},
      sensorData: [],
      startDate: this.getDateWeekAgo(),
      endDate: new Date(),
      isLoading: false,
      sensorOptions: [],
      selectedSensor: { _id: '', name: "Select Sensor" },
      dateError: '',
      configOptions: [],
      selectedConfig: { _id: '', name: "Select Version" },
      configMap: new Map(),
      configDateString: "",
      selectedParameters: [],
      selectedGrid: [{}],
      fieldTestOfflineDuration: null,
      powerConsumption:[]
    }
  }

  getDateWeekAgo() {
    const date = new Date()
    const pastDate = new Date().getDate() - 7;
    date.setDate(pastDate);
    return date
  }

  validateDate() {
    return moment(this.state.startDate).add('month', 1) >= moment(this.state.endDate)
  }

  async componentDidMount() {
    await this.getConfigHistory()
    await this.generateGraphData()
  }

  async getConfigHistory() {
    const configHistory = await sensorService.getSensorBoxConfigHistory(this.props.SensorBoxName)
    await this.setConfigOptions(configHistory);
    await this.setSensorOptions(configHistory[configHistory.length - 1])    //to set latest sensor configuration
  }

  async setConfigOptions(data: any) {
    let configOptions: any = []
    let configMap: any = new Map()
    data.forEach((config: any) => {
      configOptions.push({ _id: (config.version).toString(), name: "v" + (config.version).toString() })
      configMap.set(config.version, config)
    })
    this.setState({ configOptions, configMap, selectedConfig: configOptions[configOptions.length - 1] })
    this.setConfigDateString(data[data.length - 1])
  }

  async setSensorOptions(configData: any) {
    let sensorOptions: any = []
    if(this.state.sensorData.length>0)
    await configData?.sensors.forEach((sensor: any) => {
      sensorOptions.push({ _id: `${sensor.representation}_${sensor.sensor_name}`, name: `${sensor.display_name}${configData?.sensors.filter((item: any) => item.representation === sensor.representation).length > 1 ? ` - ${sensor?.sensor_name}` : ''}`, representation: sensor.representation })
    })
    sensorOptions.push({_id:'powerConsumption',name:'Power Consumption',representation:'powerConsumption'})
    sensorOptions.push({_id:'outsideWeatherTemperature',name:'Outside Weather Temperature (F)',representation:'outsideWeatherTemperature'})
    sensorOptions.push({_id:'outsideWeatherHumidity',name:'Outside Weather Humidity (%)',representation:'outsideWeatherHumidity'})
    this.setState({ sensorOptions })

    if (!(this.state.sensorData.length)) {
      this.setState({ selectedGrid: [{}] })
    }
  }

  handleConfigSelection = async (e: any) => {

    if (this.state.selectedConfig !== e) {
      const selectedConfigDetails = this.state.configMap.get(parseInt(e._id))
      this.setConfigDateString(selectedConfigDetails)
      this.setState({ selectedConfig: e, selectedParameters: [], selectedGrid: [{}] }, async () => {
        await this.generateGraphData()
      })
    }

  }

  setConfigDateString = (selectedConfigDetails: any) => {
    let configDateString = ''
    if (selectedConfigDetails?.start_date && selectedConfigDetails?.end_date) {
      configDateString = `( Configuration v${selectedConfigDetails.version} active b/w ${moment(new Date(selectedConfigDetails?.start_date)).format("MM/DD/YYYY")} and ${moment(new Date(selectedConfigDetails?.end_date)).format("MM/DD/YYYY")} )`
    } else if (selectedConfigDetails?.start_date) {
      configDateString = ` ( Configuration v${selectedConfigDetails.version} active from ${moment(new Date(selectedConfigDetails?.start_date)).format("MM/DD/YYYY")} )`
    }
    this.setState({ configDateString })

  }

  async getDatainChunks() {
    let graphData: Array<any> = []
    let chunk: Array<any> = []
    let chunkLastTime = null
    while (true) {
      chunk = await sensorService.getGraphData(
        this.props.SensorBoxName,
        this.state.startDate,
        this.state.endDate,
        chunkLastTime,
        this.state.selectedConfig._id
      );
      if (chunk.length === 0) {
        break;
      }
      chunkLastTime = chunk[chunk.length - 1].added_time
      graphData = [...graphData, ...chunk]
    }

    return graphData
  }
  async generateGraphData() {
    try {
      const isValidDates = this.validateDate()

      const settings = await settingsService.getSettings();
      this.setState({fieldTestOfflineDuration : settings?.data?.cycle_offline_duration})

      if (!isValidDates) {
        this.setState({ dateError: constants.message.dateError })
        return
      }

      this.setState({ isLoading: true, dateError: '' })
      const data = await this.getDatainChunks()
      const powerConsumption = await sensorService.getPowerConsumptionData(this.props.SensorBoxName,'single', this.state.startDate, this.state.endDate)
      this.setState({ sensorData: data ,powerConsumption:powerConsumption.data}, () => {
        this.setSensorOptions(this.state.configMap.get(parseInt(this.state.selectedConfig._id)))
        this.setGraphData()
      })

      this.setState({ isLoading: false })

    } catch (error) {
      this.setState({ isLoading: false })
      toast.error(error)
    }

  }

  setGraphData = async () => {

    try {
      let y_data: any = {}
      let x_time: any = {}
      let threshold:any = {}
      await this.getSelectedConfigSensorBoxSensors().forEach((sensor: any, index: number) => {
        y_data[`${sensor.representation}_${sensor.sensor_name}`] = []
        x_time[`${sensor.representation}_${sensor.sensor_name}`] = []
        if(sensor.min || sensor.max)
        threshold[`${sensor.representation}_${sensor.sensor_name}`] = { min :sensor.min,max:sensor.max}
      });

      this.state.sensorData.forEach((item: any) => {
        Object.keys(y_data).forEach((representation) => {
          if (item[representation] !== null) {
            y_data[representation].push(item[representation])
            x_time[representation].push(item.added_time)
          }
        })
      })
      y_data['powerConsumption'] = []
      x_time['powerConsumption'] = []

      y_data['outsideWeatherTemperature'] = []
      x_time['outsideWeatherTemperature'] = []

      y_data['outsideWeatherHumidity'] = []
      x_time['outsideWeatherHumidity'] = []

      this.state.powerConsumption.forEach((item: any) => {
            y_data['powerConsumption'].push(item.power)
            x_time['powerConsumption'].push(item.timestamp)
            y_data['outsideWeatherTemperature'].push(item.weather_temperature)
            x_time['outsideWeatherTemperature'].push(item.timestamp)
            y_data['outsideWeatherHumidity'].push(item.weather_return_humidity)
            x_time['outsideWeatherHumidity'].push(item.timestamp)
      })
      Object.assign(this.state.y_data, y_data)
      this.setState({ y_data, x_time, threshold })
    } catch (error) {
      toast.error(error)
    }
  }

  closeModal = () => {
    this.props.closeGraphModal();
    this.setState({ selectedSensor: '', y_data: {}, x_time: {}, selectedParameters: [] })
  }

  handleGraphSelection = (e: any) => {
    this.setState({ selectedSensor: e })
  }

  handleMultupleGraphSelection = (option: any) => {
    const optionValue = option.map((item: any) => item.value)
    this.setState({ selectedParameters: optionValue })
  }

  getSelectedConfigSensorBoxSensors = () => {
    let sensors = this.state.configMap?.get(parseInt(this.state.selectedConfig?._id))?.sensors
    sensors?.forEach((sensor: any) => sensor.pin_with_representation = `${sensor.representation}_${sensor.sensor_name}`);
    sensors=[...sensors,{pin_with_representation: "powerConsumption", representation: "powerConsumption",type: "power",_id: "powerConsumption",display_name:"Power Consumption"},
      {pin_with_representation:"outsideWeatherTemperature",representation:"outsideWeatherTemperature",type: "temperature",_id:  "outsideWeatherTemperature",display_name:"Outside Weather Temperature"},
      {pin_with_representation:"outsideWeatherHumidity",representation:"outsideWeatherHumidity",type: "humidity",_id:  "outsideWeatherHumidity",display_name:"Outside Weather Humidity"}]
    return sensors
  }

  // handle click event of the Remove button
  handleRemoveClick = (index: any) => {
    const list = [...this.state.selectedGrid];
    list.splice(index, 1);
    this.setState({ selectedGrid: list })
  };

  // handle click event of the Add button
  handleAddClick = () => {
    this.setState({ selectedGrid: [...this.state.selectedGrid, { parameters: [] }] })
  };

  handleConditionDropDownChange = (option: any, index: any) => {
    const list = [...this.state.selectedGrid];
    const optionValue = option.map((item: any) => item.value)
    list[index]['parameters'] = optionValue;
    this.setState({ selectedGrid: list })
  };

  getParameterValues = (values: any) => {

    const parametersArray = this.state.sensorOptions?.filter((item: any) => (
      values?.includes(item?._id)
    ))
    const parameterValues = parametersArray?.map((item: any) => ({ value: item._id, label: item.name }))
    return parameterValues
  }

  onChangeStartDate = (value: any) => {
    this.setState({ startDate: value }, async () => {
      await this.generateGraphData()
    })
  }

  onChangeEndDate = async (value: any) => {
    this.setState({ endDate: value }, async () => {
      await this.generateGraphData()
    })
  }

  render() {

    const graphClassName = ['multiple-graph w-100 pt-3 pb-3']
    if(this.state.selectedGrid.length > 1){
      graphClassName.push('col-6')
    }else{
      graphClassName.push('col-12')
    }

    return (
      <div className="modal fade bd-example-modal-sm show" id="graphmodal" role="dialog" aria-labelledby="exampleModalLabel">
        <div className="modal-dialog modal-sensor-data-graph" role="document">
          <div className="modal-content">
            <div className="modal-header ">
              <h5 className="modal-title" id="exampleModalLabel">{<div>{this.props.SensorBoxName} {this.props.sensorBox.alias && <span className='pl-2'>( {this.props.sensorBox.alias} )</span>}</div>}</h5>
              <span aria-hidden="true" className="close" onClick={() => { this.closeModal() }}>&times;</span>
            </div>
            <div className="row mt-50 mb-25 ml-2 ht-custom">

              <div className="col-xs-4 col-sm-4 col-md-3 col-lg-3 media-margin-tp">
                <div className="form-group mb-0">
                  <DatePicker
                    value={this.state.startDate}
                    maxDate={this.state.endDate}
                    onChange={(value: Date) => this.onChangeStartDate(value)}
                    placeholderText="Start Date"
                  />
                </div>
              </div>
              <div className="col-xs-4 col-sm-4 col-md-3 col-lg-3 media-margin-tp">
                <div className="form-group mb-0">
                  <DatePicker
                    value={this.state.endDate}
                    minDate={this.state.startDate}
                    maxDate={new Date()}
                    onChange={(value: Date) => this.onChangeEndDate(value)}
                    placeholderText="End Date"
                  />
                </div>
              </div>
              {this.state.configOptions.length > 1 &&
                <div className="col-xs-4 col-sm-4 col-md-3 col-lg-3 media-margin-tp">
                  <div className="form-group mb-0">
                    <Select name="type"
                      data={this.state.configOptions}
                      value={this.state.selectedConfig}
                      onChange={this.handleConfigSelection}
                    />
                  </div>
                </div>}

              {this.state.sensorData.length > 0 &&
                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 media-margin-tp">
                  <div className="form-group mb-0">
                    <button className="btn btn-block default-btn btn-custom" onClick={this.handleAddClick}>Add New Graph</button>
                  </div>
                </div>
              }
            </div>

            <div className="error-msg ml-4">{this.state.dateError}</div>
            <div className="modal-body modal-height-graph px-3">
              <div className="d-flex flex-wrap w-100 ml-0">
                {(this.state.sensorData.length > 0 )?
                  this.state.selectedGrid?.map((grid: any, index: any) => {
                    const paramValues = this.getParameterValues(grid?.parameters)
                    return (
                      <>
                        <div className={graphClassName.join(' ')}>
                          <div className='border p-3 h-100'>


                            <div className='d-flex mb-3'>
                              {(this.state.sensorData?.length !== 0)  &&
                                <div className="flex-fill media-margin-tp select-min">
                                  <div className="form-group mb-0">
                                    <Select
                                      isMulti={true}
                                      name="parameters"
                                      data={this.state.sensorOptions}
                                      value={paramValues}
                                      onChange={(e: any) => this.handleConditionDropDownChange(e, index)}
                                      maxOptions={2}
                                    />
                                  </div>
                                </div>
                              }

                              <div className='flex-auto ml-4'>
                                <div className="form-group">
                                  {
                                    this.state.selectedGrid.length !== 1 &&
                                    <Button className="mr-3" type="primary" shape="circle" onClick={() => this.handleRemoveClick(index)} ghost>
                                      <CloseOutlined />
                                    </Button>
                                  }
                                </div>
                              </div>
                            </div>


                            {(grid?.parameters?.length > 0) ?
                              <Graph
                                key={index}
                                fieldTestOfflineDuration = {this.state.fieldTestOfflineDuration}
                                category={this.props.sensorBox?.category}
                                index={index}
                                x={this.state.x_time}
                                y={this.state.y_data}
                                threshold={this.state.threshold}
                                param={paramValues}
                                sensor_values={this.getSelectedConfigSensorBoxSensors()?? []}
                                configDate={this.state.configDateString}

                              />
                              :
                              <div className="no-data-message w-100 col-12">Select parameters to view the graph</div>
                            }
                          </div>
                        </div>
                      </>
                    );
                  })
                  : <div className="no-data-message w-100 col-12">No Data Available</div>}
              </div>
            </div>
            <div className="modal-footer modal-footer-custom-padding">
            </div>
          </div>
        </div>
        {this.state.isLoading ?
          <LoadingWheel />
          : null}
      </div>

    );
  }
}

export default GraphModal;
