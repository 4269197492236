import  'jquery'
import tempIcon from '../assets/temparature.svg'
import voltIcon from '../assets/voltmeter.svg'
import pressureIcon from '../assets/pressure.svg'
import tstatIcon from '../assets/ic-tv.svg'
import fanIcon from '../assets/ic-turbo.svg'
import switchIcon from '../assets/ic-circles.svg'
import humidityIcon from '../assets/humidity.svg'
import currentIcon from '../assets/ammeter.svg'
import soundIcon from '../assets/sound.png'
import lightIcon from '../assets/light.png'
import motorIcon from '../assets/icon-motor.svg'
import constants from '../config/constants';
import moment from 'moment';

declare var $: any;
    
class Utility {

    showModal(modalId : string) {  

        $(`#${modalId}`).modal({
            backdrop: "static",
            keyboard: false,
            show: true
          });
    }

    debounce = (fn : any, delay : number) => {
        let timer :any = null;
        return function (...args : any) {
            const context = this;
            timer && clearTimeout(timer);
            timer = setTimeout(() => {
               fn.apply(context, args);
            }, delay);
        };
    }
    
    checkModalOpen(modalId :string) {
      return   $(`#${modalId}`).is(':visible');
    }

    closeModal(modalId : string) {  

        $(`#${modalId}`).modal('hide')
    } 

    updateSensoBoxData(sensorBoxes : any, newData : any){
        if(newData?.group) {
            let deviceIndex = sensorBoxes?.findIndex((device: any) => device?._id === newData.group?.group_id);
            if(deviceIndex >= 0 ) {
                sensorBoxes[deviceIndex].status = newData?.group?.status;
                sensorBoxes[deviceIndex].last_updated_time = newData?.last_updated_time;

                let childIndex = sensorBoxes[deviceIndex]?.sensorboxes.findIndex((sb: any) => sb?.sensor_box_name === newData.sensor_box_name);
            
                if(childIndex >= 0) {
                    sensorBoxes[deviceIndex].sensorboxes[childIndex].status = newData?.status;
                    sensorBoxes[deviceIndex].sensorboxes[childIndex].is_online = newData?.is_online;
                    sensorBoxes[deviceIndex].sensorboxes[childIndex].last_updated_time = newData?.last_updated_time;
                    sensorBoxes[deviceIndex].sensorboxes[childIndex].sensors.forEach((sensor: any) => {
                        sensor.value = newData?.sensors[`${sensor.sensor_name}-${sensor.representation}`];
                    })
                    sensorBoxes[deviceIndex].sensorboxes[childIndex].sensor_values = newData?.sensors;
                }
            }
        }else {
            let sensorboxIndex = sensorBoxes?.findIndex((sb: any) => sb?.sensor_box_name === newData.sensor_box_name);
            if(sensorboxIndex >= 0) {
                sensorBoxes[sensorboxIndex].status = newData?.status;
                sensorBoxes[sensorboxIndex].is_online = newData?.is_online;
                sensorBoxes[sensorboxIndex].last_updated_time = newData?.last_updated_time;
                sensorBoxes[sensorboxIndex]?.sensors.forEach((sensor: any) => {
                    sensor.value = newData?.sensors[`${sensor.sensor_name}-${sensor.representation}`];
                })
                sensorBoxes[sensorboxIndex].sensor_values = newData?.sensors;
            }
        }
        
        return sensorBoxes
    }

    getSensorStatus(sensor_data : number){
        let color = "green-status"        
            switch(sensor_data){
                case constants.sensor_status.darkred: {
                    color = "darkred-status"
                    break;
                }
                case constants.sensor_status.red: {
                    color = "red-status"
                    break;
                }
                case constants.sensor_status.orange: {
                    color = "orange-status"
                    break;
                }
                case constants.sensor_status.yellow: {
                    color = "yellow-status"
                    break;
                }
                case constants.sensor_status.green: {
                    color = "green-status"
                    break;
                }
                case constants.sensor_status.grey: {
                    color = "gray-status"
                    break;
                }
                default: {
                    //color = "gray-status"
                    color = 'green-status'
                } 
            }
        return color;

    }

    getStatus(sensor_data : number){
        let color = "success"        
            switch(sensor_data){
                case constants.sensor_status.darkred: {
                    color = "darkred"
                    break;
                }
                case constants.sensor_status.red: {
                    color = "danger"
                    break;
                }
                case constants.sensor_status.orange: {
                    color = "orange"
                    break;
                }
                case constants.sensor_status.yellow: {
                    color = "warning"
                    break;
                }
                case constants.sensor_status.green: {
                    color = "success"
                    break;
                }
                case constants.sensor_status.grey: {
                    color = "grey"
                    break;
                }
                default: {
                    color = 'success'
                } 
            }
        return color;

    }

    getIssueTrackerStatus(status : any){
        let color = "Teal" 

        if(status === 'OPEN'){
            color = "blue"
        }else if(status === 'RESOLVED'){
            color = "green"
        }else if(status === 'IGNORED'){
            color = "grey"
        }else if(status === 'PENDING REVIEW'){
            color = "red"
        }else if(status === 'INCOMPLETE'){
            color = "orange"
        }
        return color;
    }

    formatSensorValue(item: any) {
        if (item?.value === undefined || item?.value === null || item?.value === '') {
            return '--';
        }
    
        let value = item?.value;
        if (item.sensor_name === "sc" && value === 0) {
            value = 'No SC';
        } else if (item.sensor_name === "sh" && value === 0) {
            value = 'No SH';
        } else if (item.format_type === 1) {
            value = Math.round(value);
        } else if (item.format_type === 2) {
            value = value.toFixed(2);
        } else if (item.format_type === 3) {
            value = value ? "ON" : "OFF";
        } else if (item.format_type === 4) {
            value = value ? "OPEN" : "CLOSED";
        }
    
        if (item?.unit && value !== 'No SC' && value !== 'No SH') {
            value = value + ' ' + item?.unit;
        }
        return value;
    }
    //old function to get status color
    getMapMarkerStatus(sensor_data : number){
        let color = "green-marker"      
            switch(sensor_data){
                case constants.sensor_status.darkred: {
                    color = "darkred-marker"
                    break;
                }
                case constants.sensor_status.red: {
                    color = "red-marker"
                    break;
                }
                case constants.sensor_status.orange: {
                    color = "orange-marker"
                    break;
                }
                case constants.sensor_status.yellow: {
                    color = "yellow-marker"
                    break;
                }
                case constants.sensor_status.green: {
                    color = "green-marker"
                    break;
                }
                default: {
                    //color = "gray-marker"
                    color = 'green-status'
                } 
            }
        return color;

    }

    getSensorImage(item:any) {
        if(item?.pin_type === "pressure") {
            return pressureIcon
        }
        else if(item?.pin_type === 'current' || item?.sensor_name === "blower_current") {
            return currentIcon
        } 
        else if(item?.pin_type === 'switch' && item?.display_name.includes('Fan')) {
            return fanIcon
        }
        else if(item?.pin_type === 'switch' && ['tstat_y','y1','y2','w1','w2','tstat_w','tstat_g','tstat_o','tstat_r'].includes(item?.representation)) {
            return tstatIcon
        }
        else if(item?.pin_type === 'switch') {
            return switchIcon
        }
        else if(item?.sensor_name === "sc" || item?.sensor_name === "sh" || item?.pin_type === 'temperature') {
            return tempIcon
        }
        else if (item?.pin_type === 'humidity'){
            return humidityIcon
        }
        else if (item?.pin_type === 'voltage'){
            return voltIcon
        }else if(item?.pin_type === 'sound'){
            return soundIcon
        }
        else if(item?.pin_type === 'light'){
            return lightIcon
        }else if(item?.pin_type === 'vibration'){
            return motorIcon
        }
        else  {
            return '';
        }
    }

    trimSpacesInValues(value: string) {

        if (value !== undefined && value !== null) {
        return value?.trimLeft()?.replace(/( )\1+/g, '$1');
        }
        
        return '';
    }

    getMomentFromNow(data : any){
        moment.locale('en', {
            relativeTime: {
              future: 'in %s',
              past: '%s ago',
              s:  'seconds',
              ss: '%ss',
              m:  'a minute',
              mm: '%dm',
              h:  'an hour',
              hh: '%dh',
              d:  'a day',
              dd: '%dd',
              M:  'a month',
              MM: '%dM',
              y:  'a year',
              yy: '%dY'
            }
        });
        return moment(data).fromNow()
    }

    checkSensorRange (sensor :any) {
       let color = "nil"  
        if((sensor?.sensor_type?.min === undefined && sensor?.sensor_type?.max === undefined) || 
           (sensor?.sensor_type?.min === '' && sensor?.sensor_type?.max === '') || sensor?.value === undefined ||
            (sensor?.sensor_name === 'sc' && sensor?.value === 0 ) ||
            (sensor?.sensor_name === 'sh'  && sensor?.value === 0 )){
            color = "nil"
        }   
        else if(sensor?.value !== undefined && this.checkWithinRange(sensor?.value, sensor.sensor_type)) {
            color = "green-status" 
        } 
        else {
            color = "red-status"
        }
        return color;

    }
    checkWithinRange(displayValue : number, range :any) {
        return (displayValue >= Number(range.min)) && (displayValue <= Number(range.max))

    }

    durationAsString (ms: any, maxPrecission = 3){
        const duration = moment.duration(ms)

        const items = []
        items.push({ timeUnit: 'd', value: Math.floor(duration.asDays()) })
        items.push({ timeUnit: 'h', value: duration.hours() })
        items.push({ timeUnit: 'm', value: duration.minutes() })
        items.push({ timeUnit: 's', value: duration.seconds() })

        const formattedItems = items.reduce((accumulator: any, { value, timeUnit }: any) => {
            if (accumulator.length >= maxPrecission || (accumulator.length === 0 && value === 0)) {
                return accumulator
            }

            accumulator.push(`${value}${timeUnit}`)
            return accumulator
        }, [])

        return formattedItems.length !== 0 ? formattedItems.join(' ') : '-'
    }

    getIssuePriorityColor(priority : any){
        let color = "yellow" 
        priority = priority.toUpperCase()

        if(priority === 'HIGH'){
            color = "volcano"
        }else if(priority === 'MEDIUM'){
            color = "orange"
        }else if(priority === 'LOW'){
            color = "yellow"
        }
        return color;
    }

    getObjectById(id: string, array: any[]) {
        let result = array.find(item => item?._id === id);
        return result ? result : null;
    }

    issueTrackerErrorHandler(errorData: any, errorCode: number, constantMessage: string, constantMessageAlt?: string) {
        if ((errorData && errorData?.code === errorCode && errorData?.message === constantMessage) || errorData?.message === constantMessageAlt) {
            return true
      } 
      return false
    }

}

const Utils = new Utility()
export default Utils