import React, { useEffect, useState } from 'react';
import {
  Modal,
  ToggleButtonGroup,
  ToggleButton,
  Card,
  Row,
  Col
} from 'react-bootstrap';
import LoadingWheel from '../loadingwheel';
import SensorService from '../../services/sensorService';
import toast from '../toast';
import moment from 'moment'
import upArrow from '../../assets/up.png';
import downArrow from '../../assets/down.png'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer
} from "recharts";
import './powerModal.css';


interface Props {
  show: boolean;
  onClose: () => void;
  systemId?: any;
  type?: any;
  SystemName?: any;
}

const PowerModal: React.FC<Props> = ({ show, systemId, onClose, type, SystemName }) => {

  const [loading, setLoading] = useState(false);
  const [currentGraphData, setCurrentGraphData] = useState<Array<any>>([]);
  const [frequencyType, setFrequencyType] = useState('')
  const [previousGraphData, setPreviousGraphData] = useState<Array<any>>([]);
  const [currentGraphDataOfTheWeek, setCurrentGraphDataOfTheWeek] = useState<Array<any>>([]);
  const [previousGraphDataOfTheWeek, setPreviousGraphDataOfTheWeek] = useState<Array<any>>([]);


  useEffect(() => {
    fetchPowerConsumptionForSystem(systemId, 'daily', type)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemId]);



  const fetchPowerConsumptionForSystem = async (id: any, frequencyType?: any, systemType?: any) => {
    try {
      if (id) {
        setLoading(true);
        let powerData = await SensorService.getPowerGraphData(id ? id : '', frequencyType, systemType)
        let powerDataForTheWeek = await SensorService.getPowerGraphData(id ? id : '', 'weekly', systemType) // to get weekly data 
        const { currentData, previousData, message } = powerData;
        if (message === 'Success') {
          setLoading(false);
          setFrequencyType(frequencyType)
          setCurrentGraphData(currentData);
          setPreviousGraphData(previousData)
        }
        setCurrentGraphDataOfTheWeek(powerDataForTheWeek?.currentData)
        setPreviousGraphDataOfTheWeek(powerDataForTheWeek?.previousData)
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      let errMsg = error?.message;
      if (error?.response?.data?.message) {
        errMsg = error?.response?.data?.message
      }
      toast.error(errMsg);
    }
  }

  async function onCloseModal() {
    onClose()
  }

  const processedCurrentGraphData = currentGraphData.map((data: any) => {
    let result: any = { time: data.timestamp, powerOfCurrent: data.power, timestamp: data.timestamp, power: data.power, temperature: data.weather_temperature ? `${((data.weather_temperature * 1.8) + 32).toFixed(2)}` : 'N/A', weather_return_humidity: data.weather_return_humidity };
    return result
  }).sort(function (a: any, b: any) { // sort on ascending order
    return a.timestamp - b.timestamp
  })

  const processedPreviousGraphData = previousGraphData.map((data: any) => {

    let result: any = { time: data.timestamp, powerOfPrevious: data.power, timestamp: data.timestamp, power: data.power, temperature: data.weather_temperature ? `${((data.weather_temperature * 1.8) + 32).toFixed(2)}` : 'N/A', weather_return_humidity: data.weather_return_humidity };
    return result
  }).sort(function (a: any, b: any) { // sort on ascending order
    return a.timestamp - b.timestamp
  })

  const handleRadioButtonClick = (event: any) => {
    setFrequencyType(event)
    fetchPowerConsumptionForSystem(systemId, event, type)
  }

  function handleXaxisLabel() {
    if (frequencyType === 'daily') {
      return 'Time'
    } else if (frequencyType === 'weekly') {
      return 'Days'
    } else if (frequencyType === 'monthly') {
      return 'Days'
    } else if (frequencyType === 'yearly') {
      return 'Months'
    }
  }

  const formatTicks = (data: any) => {
    if (frequencyType === 'daily') {
      return moment(data).format('h a')
    } else if (frequencyType === 'weekly') {
      return moment(data).format('dddd')
    }
    else if (frequencyType === 'monthly') {
      return moment(data).format('DD')
    } else if (frequencyType === 'yearly') {
      return moment(data).format('MMM')
    }
  }


  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active) {
      return (
        <Card>
          <Card.Body>
            <Row>
              <Col lg="12" sm="6">
                {
                  payload?.map((data: any) => {
                    return (
                      <>
                        {data?.color !== '#FF0000' &&
                          <p className="label" style={{ color: data?.color }}>{moment(data?.payload?.time).format('MMMM Do YYYY, h a')}</p>
                        }
                        {data?.color !== '#FF0000' &&
                          <p className="label" style={{ color: data?.color }}>{`Power : ${data?.value} kWh`}</p>
                        }
                        {data?.color !== '#FF0000' &&
                          <p className="label2" style={{ color: data?.color }}>{`Temperature : ${data?.payload?.temperature} ℉`}</p>
                        }
                      </>
                    )
                  })
                }
              </Col>
            </Row>
          </Card.Body>
        </Card>
      );
    }
    return null;
  };

  const averagePowerOfCurrentPowerGraph = processedCurrentGraphData.length > 0 ? processedCurrentGraphData.reduce((total: any, next: any) => total + next.power, 0) / processedCurrentGraphData.length : 0;

  const isCurrentDataAndPreviousLengthEqual = (processedCurrentGraphData.length === processedPreviousGraphData.length)

  const renderLegend = (props: any) => {
    let from, to;

    if (frequencyType === 'daily') {

      from = `Yesterday (${moment(((Date.now() - 24 * 3600000))).format('Do MMM YYYY')})`
      to = `Today (${moment(Date.now()).format('Do MMM YYYY')})`

    } else if (frequencyType === 'weekly') {

      from = `Last Week (${moment(((Date.now() - 24 * 7 * 3600000) - 24 * 7 * 3600000)).format('Do MMM YYYY')} - ${moment((Date.now() - 24 * 7 * 3600000)).format('Do MMM YYYY')})`
      to = `Current Week (${moment((Date.now() - 24 * 7 * 3600000)).format('Do MMM YYYY')} to ${moment(Date.now()).format('Do MMM YYYY')})`

    }
    else if (frequencyType === 'monthly') {

      from = `Last Month (${moment(((Date.now() - 24 * 30 * 3600000) - 24 * 30 * 3600000)).format('MMM YYYY')} - ${moment((Date.now() - 24 * 30 * 3600000)).format('MMM YYYY')})`
      to = `This Month (${moment((Date.now() - 24 * 30 * 3600000)).format('MMM YYYY')} - ${moment(Date.now()).format('MMM YYYY')})`

    } else if (frequencyType === 'yearly') {

      from = `${moment(((Date.now() - 24 * 365 * 3600000) - 24 * 365 * 3600000)).format('YYYY')} to ${moment((Date.now() - 24 * 365 * 3600000)).format('YYYY')}`
      to = `${moment((Date.now() - 24 * 365 * 3600000)).format('YYYY')} to ${moment(Date.now()).format('YYYY')}`

    }

    return (
      <div>
        <span key={`item1`}><div style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: '#808080', borderRadius: '100px' }}></div>&nbsp;{from}</span>&nbsp;&nbsp;
        <span key={`item2`}><div style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: '#00008B', borderRadius: '100px' }}></div>&nbsp;{to}</span>&nbsp;&nbsp;
        <span key={`item3`}><div style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: '#FF0000', borderRadius: '100px' }}></div>&nbsp;Temperature (F)</span>&nbsp;&nbsp;
      </div>
    );
  }

  const intervalOfXaxis = () => {
    if (frequencyType === 'daily') {
      return 0
    } else if (frequencyType === 'weekly') {
      return 20
    } else if (frequencyType === 'monthly') {
      return 45
    } else if (frequencyType === 'yearly') {
      return 180
    }
  }

  // Average power of current and previous view on cards conditions
  const currentWeekPower = (currentGraphDataOfTheWeek.reduce((total: any, next: any) => total + next.power, 0));
  const previousWeekPower = (previousGraphDataOfTheWeek.reduce((total: any, next: any) => total + next.power, 0));
  const isthisWeekHighestPower = (currentWeekPower > previousWeekPower) ? true : false;

  return (
    <div>
      <Modal
        show={show}
        onHide={onCloseModal}
        size="lg"
      >
        <Modal.Header closeButton>
          <div>{`Power consumption graph - ${SystemName}`}</div>
        </Modal.Header>
        <Modal.Body>
        <div className="px-3">
          <Row>
            <Col lg="6" sm="6" className="pl-0">
              <Card className="border">
                <Card.Body>
                  <Row>
                    <Col xs="12">
                      <div className="numbers text-md-center">
                        <Card.Title as="h4">Last Week (Power) : {Math.round(previousWeekPower)}kWh &nbsp;
                          {previousWeekPower ?  isthisWeekHighestPower ? <img className="mb-2" src={downArrow} height={18} alt='' />
                            : <img className="mb-2" src={upArrow} height={18} alt='' />
                            : <img className="mb-2" src={downArrow} height={18} alt='' />
                          }
                        </Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="6" sm="6" className="pr-0" >
              <Card className="border" >
                <Card.Body>
                  <Row>
                    <Col xs="12">
                      <div className="numbers text-md-center">
                        <Card.Title as="h4">This Week (Power) : {Math.round(currentWeekPower)}kWh &nbsp;
                          { currentWeekPower ? isthisWeekHighestPower ? <img className="mb-2" src={upArrow} height={18} alt='' />
                            : <img className="mb-2" src={downArrow} height={18} alt='' />
                            : <img className="mb-2" src={downArrow} height={18} alt='' />
                          }
                        </Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          </div>
          <div className="button-power-graph">
            <ToggleButtonGroup className="mt-4 float-right justify-content-sm-end" type="radio" name="button" defaultValue="daily" onChange={handleRadioButtonClick} style={{ zIndex: 1000 }}>
              <ToggleButton className="btn-group" variant="primary" value="daily">Daily</ToggleButton>
              <ToggleButton className="btn-group" variant="primary" value="weekly"> Weekly</ToggleButton>
              <ToggleButton className="btn-group" variant="primary" value="monthly">Monthly</ToggleButton>
              <ToggleButton className="btn-group" variant="primary" value="yearly">Yearly</ToggleButton>
            </ToggleButtonGroup>
            {averagePowerOfCurrentPowerGraph > 0 && <p className="mb-0 mt-1" key={`item3`}><div style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: '#2d62cc', borderRadius: '100px' }}></div>&nbsp;{`Average Power : ${Math.round(averagePowerOfCurrentPowerGraph)}kWh`}</p>}
          </div>
          {(!(previousGraphData.length > 0) && !(currentGraphData.length > 0)) ?
            <div>
              <p className='no-logs-text' style={{ padding: '100px' }}>No datas found</p>
            </div>
            :
            <div>
              <ResponsiveContainer height="100%" width="100%" aspect={3}>
              <LineChart
                data={processedCurrentGraphData}
                style={{ marginTop: "25px" }}
                margin={{ top: 5, right: 70, left: 20, bottom: 60 }}
              >

                <XAxis dataKey="time"
                  xAxisId={"timexaxis"}
                  tickFormatter={(unixTime: any) => formatTicks(unixTime) as any}
                  interval={intervalOfXaxis() as any}
                  label={{ value: handleXaxisLabel(), position: "insideBottomRight", dy: 25 }}
                />

                <XAxis hide={true} dataKey="time" xAxisId={"value"} />

                <YAxis
                  yAxisId={"yaxis"}
                  label={{ value: 'kWh', angle: -90, position: 'insideTopLeft', dy: 30 }}
                  axisLine={true}
                />

                <YAxis
                  yAxisId={"yaxistemp"}
                  unit="℉"
                  orientation="right"
                  label={{ value: 'Temp ℉', angle: -90, position: 'insideTopRight', dy: 30 }}
                  axisLine={true}
                />

                <CartesianGrid horizontal={true} vertical={true} />
                <Tooltip content={<CustomTooltip />} />
                <Legend className="font-italic"
                  verticalAlign="top"
                  align="left"
                  content={renderLegend}
                  wrapperStyle={{ width: 800, whiteSpace: "break-spaces" }}
                />

                {isCurrentDataAndPreviousLengthEqual &&
                  <Line type="monotone"
                    yAxisId={"yaxis"}
                    xAxisId={"value"}
                    data={processedPreviousGraphData}
                    dataKey="power"
                    stroke="#808080"
                    strokeWidth={4}
                  />
                }

                <Line type="monotone"
                  yAxisId={"yaxis"}
                  xAxisId={"timexaxis"}
                  data={processedCurrentGraphData}
                  dataKey="power"
                  stroke="#00008B"
                  strokeWidth={4}
                />

                <Line type="monotone"
                  yAxisId={"yaxistemp"}
                  xAxisId={"timexaxis"}
                  dataKey="temperature"
                  stroke="#FF0000"
                  strokeWidth={1}
                  strokeDasharray="8 8 8 8"
                />

                {averagePowerOfCurrentPowerGraph > 0 &&
                  <ReferenceLine
                    yAxisId={"yaxis"}
                    xAxisId={"timexaxis"}
                    y={Math.round(averagePowerOfCurrentPowerGraph)}
                    stroke="#2d62cc"
                    strokeDasharray="18 18"
                  />
                }
              </LineChart>
              </ResponsiveContainer>
            </div>
          }
        </Modal.Body>
      </Modal>
      <div>
        {loading ?
          <LoadingWheel />
          : null}
      </div>
    </div>
  )
}

export default PowerModal;

