/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { Input } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import toast from '../../components/toast'
import dashboardService from '../../services/dashboardServices'
import LoadingWheel from '../../components/loadingwheel'
import constants from '../../config/constants';
import _ from 'lodash';
import eventStore from '../../store/eventStore';
import Utility from '../../helpers/Utility';
import authStore from '../../store/authStore';
import organizationService from '../../services/organizationService';
import SelectComponent from '../../components/select';
import CompactDashboard from '../../components/compact-dashboard/index'
import TroubleshootDashboard from '../../components/troubleshoot-dashboard/index'
import "./device-status.css"

const { Search } = Input;

const deploymentStatusType = [
    { _id: '', name: 'All' },
    { _id: 'commissioned', name: 'Commissioned' },
    { _id: 'decommissioned', name: 'Decommissioned' },
    { _id: 'tested', name: 'Tested' },
    { _id: 'testing', name: 'Testing' }
]

interface Props extends RouteComponentProps { }

const DeviceStatus: React.FC<Props> = () => {

    const [groupList, setGroupList] = useState<Array<any>>([]);
    const groupRef = useRef(groupList);
    groupRef.current = groupList;
    const [loading, setLoading] = useState(true);
    const [selectedDeploymentStatus, setSelectedDeploymentStatus] = useState('')
    const [selectedFilter, setSelectedFilter] = useState<any>({ _id: '', name: 'Select Organization' }) //org
    const [organizationFilterData, setOrganizationFilterData] = useState([]) //org
    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        // note : temp solution for the org admin access to all org
        if (authStore?.currentUser?.active_role?.role_type === 0 || (authStore?.currentUser?.active_role?.role_type === 1 && authStore.currentUser.is_default)) {
            getOrganizations()
        } else {
            getSensorBoxGroups()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getSensorBoxGroups = async (orgid?: any, keyword?: any, deployment_status? : any) => {
        try {
            setLoading(true)
            let groupList = await dashboardService.getSensorBoxes('group', true, orgid, keyword, deployment_status) as any
            setGroupList(groupList.result);
            setLoading(false)
            listenAndUpdateSensordata()

        } catch (error) {
            setLoading(false)
            toast.error(error.response.data.message)
        }
    }

    const getOrganizations = async () => {

        try {
            const response = await organizationService.getOrganizations()
            setSelectedFilter(response.data.organizations[0])
            getSensorBoxGroups(response.data.organizations[0]._id, keyword, selectedDeploymentStatus)
            setOrganizationFilterData(response.data.organizations);
        } catch (error) {
            toast.error(constants.message.error_role_organization)
            setLoading(false)
        }
    }

    const listenAndUpdateSensordata = () => { //real time update in dashboard
        if (eventStore.eventSource) {
            eventStore.eventSource.onmessage = function (event) {
                if (event.data !== "CONNECTED") {
                    let event_data = JSON.parse(event.data);
                    if (event_data?.type !== "OFFLINE_STATUS_UPDATE") {
                        let newGroupList = Utility.updateSensoBoxData(groupRef.current, event_data)
                            .sort((a: any, b: any) => ((b?.status - a?.status) || (a?.created_at - b?.created_at)))
                        setGroupList(JSON.parse(JSON.stringify(newGroupList)));
                    }
                }
            }
        }
    }

    const handleFilterSelection = (option: any) => {
        setSelectedFilter(option)
        getSensorBoxGroups(option._id, keyword, selectedDeploymentStatus)
    }

    const search = React.useCallback(_.debounce(getSensorBoxGroups, 700), [])

    const onSearchChange = (e: any) => {
        setKeyword(e.target.value);
        search(selectedFilter?._id, e.target.value, selectedDeploymentStatus);
    }

    const handleDeploymentStatus = (option: any) => {
        setSelectedDeploymentStatus(option?._id)
        getSensorBoxGroups(selectedFilter._id, keyword, option?._id)

    }

    const tabsClassName = ['dashboard-tab dashboard-border']
    if((authStore?.currentUser?.active_role?.role_type === 0 || (authStore?.currentUser?.active_role?.role_type === 1 && authStore.currentUser.is_default))){
        tabsClassName.push('tabs-margin')
    }

    const deploymentStatusValue = deploymentStatusType.find((status:any)=>(status?._id === selectedDeploymentStatus)) 

    return (
        <div className='centered-tabs pt-4 position-relative'>

            <div className="d-flex mr-4 justify-content-end org-dropdown">
                {(authStore?.currentUser?.active_role?.role_type === 0 || (authStore?.currentUser?.active_role?.role_type === 1 && authStore.currentUser.is_default)) &&
                    <div className="filter-select pr-3">
                        <label className="custom-label">Organization</label>
                        <SelectComponent
                            name="typeFilter"
                            data={organizationFilterData}
                            value={selectedFilter}
                            onChange={handleFilterSelection}
                        />
                    </div>
                }

                        {(authStore?.currentUser?.active_role?.role_type === 0 || (authStore?.currentUser?.active_role?.role_type === 1 && authStore.currentUser.is_default)) &&
                                <div className="filter-select mr-3">
                                    <label className="custom-label">Deployment Status</label>
                                        <SelectComponent name="typeFilter"
                                                data={deploymentStatusType}
                                                value={deploymentStatusValue}
                                                onChange={handleDeploymentStatus}
                                        />
                                </div>
                        }

                <div className="media-margin-tp mt-auto w-100">
                    <Search
                        placeholder="Search"
                        value={keyword || ""}
                        size="large"
                        onChange={onSearchChange}
                        allowClear
                        enterButton />
                </div>
            </div>


            <Tabs variant="pills" className={tabsClassName.join(' ')}>
                <Tab eventKey="compactview" title="Compact View" className="mt-5">
                    <CompactDashboard groupList={groupList} loading={loading} />
                </Tab>
                <Tab eventKey="troubleshooting" title="Troubleshooting" className="mt-5">
                    <TroubleshootDashboard groupList={groupList} loading={loading} />
                </Tab>
            </Tabs>
            {
                loading && <LoadingWheel />
            }
        </div>
    )
}

export default DeviceStatus
