import React, { useState } from 'react';
import Select from '../select';
import authStore from '../../store/authStore';
import { Modal } from 'react-bootstrap';

export interface switchRoleModalProps {
  switch               : any;
  title                : string;
  visible              : boolean;
  roles                : Array<any>;
  toggleVisibility     : () => void;
}

function SwitchRoleModal (props : switchRoleModalProps) {
    const [newRole, setNewRole] = useState(null)as any;

    const handleRoleFilter = () => {

        const roleData = props.roles?.filter((role: any) => role._id !== authStore?.currentUser?.active_role._id)
        return roleData
    }

    const clickCancel =() =>{
      setNewRole(null)
      props.toggleVisibility()
    }

    const onHideModal =() =>{
      setNewRole(null)
      props.toggleVisibility()
    }

    const clickSwitch =() =>{
      props.switch(newRole)
      setNewRole(null)
    }

    return (
      <Modal
      show={props.visible}
      onHide={() => onHideModal()}
      >
      <Modal.Header closeButton>
          {props.title}
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body modal-text">
            <div className="mt-3">
              <label className="custom-label">Select a role <span className="mand-star">*</span></label>
                <Select
                  name="roles"
                  data={handleRoleFilter()} 
                  value={newRole}  
                  onChange={(value: any) => setNewRole(value)}
                />
            </div>
        </div>
        <div className="modal-footer">
        <button type="button" className="btn default-btn" data-dismiss="modal" onClick={() =>clickCancel()}>CANCEL</button>
            <button 
              type="button"
              className="btn default-btn"
              onClick={()=>clickSwitch()}
              disabled={!newRole ? true : false}
            >
              SWITCH
            </button>
        </div>
      </Modal.Body>
  </Modal>
    );
  }
 
export default SwitchRoleModal;
