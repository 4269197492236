import { Carousel, Image } from 'antd';
import React from 'react';
import issueTrackerService from '../../services/issueTrackerServices';
import { useState, useEffect } from 'react';
import "./styles/customCaracel.css"

const ImageNoteView = (props) => {


  const [imageUrls, setImageUrls] = useState([]);
  let { imageIDArray ,carouselRef} = props;

  useEffect(() => {
    async function fetchData() {
      let urls = imageIDArray?.map(async (imageID) => {
        let res = await issueTrackerService.getAttachedImageByimageId(imageID)
        let url = await URL.createObjectURL(res.data)
        return url;
      })

      const responses = await Promise.all(urls);
      setImageUrls(responses);
    }

    fetchData();
  }, [imageIDArray])

  return (
    <>
      <Carousel effect="fade" dotHeight={15} centerMode={true} ref={carouselRef}>
        {imageUrls.map((url, index) => (
          <div key={url} className="image-container">
            <Image src={url} alt="attachment" fallback="https://via.placeholder.com/150" />
          </div>
        ))}
      </Carousel>
    </>
  );
};

export default ImageNoteView;

