/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, ChangeEvent } from 'react';
import { Input } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import Pagination from "react-js-pagination";
import _ from 'lodash';
import authStore from '../../store/authStore';
import SelectComponent from '../../components/select';
import LoadingWheel from '../../components/loadingwheel'
import groupServices from '../../services/groupServices';
import constants from '../../config/constants';
import toast from '../../components/toast';
import Utility from '../../helpers/Utility';
import Confirmmodal from '../../components/confirmmodal';
import organizationService from '../../services/organizationService';
import GroupIcon from '../../assets/folder.png';
import CreateGroupModal from '../../components/creategroupmodal/CreateGroupModal';
import Permissions from '../../config/permssions';
import issueTrackerServices from "../../services/issueTrackerServices";

const { Search } = Input;

const {
  view_assigned_users_sensorboxes_assets,
  create_or_update_sensorboxes_assets,
  view_only_partial_asset_sensorbox_details
} = Permissions

interface Props extends RouteComponentProps {}

const Groups: React.FC<Props> = () => {

  const [groupList, setGroupList] = useState<Array<any>>([]);
  const [selectedGroup, setSelectedGroup] = useState<any>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [organizationFilterData, setOrganizationFilterData] = useState([])
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<any>({_id : '',name : 'Select Organization'})
  const [typeFilter, setTypeFilter] = useState('')
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [issueCount, setIssueCount] = useState<number>(0);

  useEffect(() => {
    // note : temp solution for the org admin access to all org
    if(authStore?.currentUser?.active_role?.role_type === 0  || (authStore?.currentUser?.active_role?.role_type === 1 && authStore.currentUser.is_default)){
      getOrganizationsGroups()
    }else{
      fetchGroups(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchGroups = async (pageNumber: number = 1, keyword = '' ,orgid? :any) => {
    try {
      setLoading(true);
      const resp = await groupServices.getGroups({ page: pageNumber, keyword , orgid :orgid});
      if(resp) {
        const { groups, count } = resp?.data;
        setGroupList(groups);
        setCount(count);
        setPage(pageNumber);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      let errMsg = error?.message;
      if(error?.response?.data?.message) {
        errMsg = error?.response?.data?.message
      }
      toast.error(errMsg);
    }
  }

  const editGroup = async (group: any) => {
    const regularUser = ((
      group.contractor?.[0]?._id === authStore?.currentUser?.uid
      || ( authStore?.currentUser?.access.includes(view_assigned_users_sensorboxes_assets) ? group.contractor?.[0]?._id === authStore?.currentUser?.assigned_to : false)
      || authStore?.currentUser?.active_role?.role_type < 2) 
    )

    if(authStore?.currentUser?.access?.includes(create_or_update_sensorboxes_assets) || authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details)){
      if( group?.user_access?.includes(authStore?.currentUser?.uid ) && !regularUser){
        return
      }
      const group_id = group?._id
      const params = {pageNumber: 1, limit: 10, orgId: group?.org_id, status: ['OPEN', 'PENDING REVIEW'], consumer: [], contractor: [], service_resolved_by: [], searchFilter: '', startDate: '', endDate: ''}
      const issueListExist = await issueTrackerServices.getIssueTrackerDetails(params.pageNumber, params.limit, params.orgId, params.status, params.consumer, params.contractor, params.service_resolved_by, params.searchFilter, params.startDate, params.endDate ,group_id)
      setIssueCount(issueListExist?.count)
      setSelectedGroup(group);
      setModalVisible(true)
    }

  }

  const handleOrganizationSelection = (option: any) => {
    setSelectedFilter(option)
    setTypeFilter(option._id ? option._id : '')
    fetchGroups(1,keyword,option._id);
  }

  const showConfirmation = () => {
    setModalVisible(false);
    Utility.showModal('confirmmodal')
  }

  const closeConfirmation = () => {
    Utility.closeModal('confirmmodal');
  }

  const getOrganizationsGroups = async () => {

    try {
        setLoading(true)
        const response = await organizationService.getOrganizations()
        setOrganizationFilterData(response.data.organizations);
        setSelectedFilter(response.data.organizations[0])
        setTypeFilter(response.data.organizations[0]._id ? response.data.organizations[0]._id : '')
        fetchGroups(1,'',response.data.organizations[0]._id);
    } catch (error) {
        toast.error(constants.message.error_role_organization)
        setLoading(false)
    }
  }

  const deleteGroup = async () => {
    try {
      if(!selectedGroup) return;
      await groupServices.deleteGroup(selectedGroup?._id);
      setModalVisible(false);
      Utility.closeModal('confirmmodal');
      let page = Math.ceil((count - 1)/constants.maxLimit);
      page = page <= 0 ? 1 : page;
      // note : temp solution for the org admin access to all org
      fetchGroups(page,'', selectedFilter?._id);
    } catch (error) {
      let errMsg = error?.message;
      if(error?.response?.data?.message) {
        errMsg = error?.response?.data?.message
      }
      toast.error(errMsg);
    }
  }

  const search = React.useCallback(_.debounce(fetchGroups, 700), [])   

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
    search(1, e.target.value, typeFilter);
  }

  const demoCheck = (authStore?.currentUser?.active_role?.role_type === -1);

  const GroupList = groupList?.map((group: any ,index : any) => (
    <div key={group?._id} className="col-lg-3 col-md-4 col-sm-6 px-2 mb-3">
      <div
        className="group-item pl-3 pr-5 py-2 card custom-card position-relative cursor-pointer"
        onClick={() => editGroup(group)}
      >
        <img src={GroupIcon} style={{ position: 'absolute', right: '1rem', top: '.5rem' }} alt=""/>
        <p className="mb-0 text-truncate">
          {
            demoCheck ?
            group.name = `Group ${index+1}`
            :group?.name
          }
        </p>
        <span className="mb-0 text-secondary small">{group?.sensorboxes?.length} sensor boxes</span>
      </div>
    </div>
  ))

  return (
    <div className="padding-b50" id="groups">
      <div className="row mb-25 justify-content-between">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-8">
            <p className="table-title media-padding">Assets</p>
        </div>
        {// note : temp solution for the org admin access to all org
        (authStore?.currentUser?.active_role?.role_type === 0 || (authStore?.currentUser?.active_role?.role_type === 1 && authStore.currentUser.is_default)) &&
          <div className="filter-select col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2">
            <label className="custom-label">Organization</label>
              <SelectComponent 
                name="typeFilter"
                data={organizationFilterData}
                value={selectedFilter}
                onChange={handleOrganizationSelection}
              />
          </div>
        }
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 media-margin-tp mt-auto">
          <Search
            placeholder="Search"
            value={keyword || ""}
            size="large"
            onChange={onSearchChange}
            allowClear
            enterButton />
        </div>
      </div>
      <div className="row px-2">
        {GroupList}
      </div>
      {
        loading && <LoadingWheel />
      }
      {
        GroupList?.length === 0 && !loading && (
          <div className="no-result-container text-center">
            <p>No assets found</p>
          </div>
        )
      }
      <div className="px-2 mt-4">
        {
          Math.ceil( count/constants.maxLimit ) > 1  &&
          <Pagination
              hideNavigation
              activePage={page}
              itemsCountPerPage={constants.maxLimit}
              totalItemsCount={count}
              itemClass={"item-class"}
              activeClass={"active-class"}
              pageRangeDisplayed={5}
              onChange={(pageNumber)=>fetchGroups(pageNumber , keyword, selectedFilter?._id)}
            />
        }
      </div>
      <CreateGroupModal
        show={modalVisible}
        onClose={() => setModalVisible(false)}
        group={selectedGroup}
        organisationId ={selectedFilter?._id}
        // note : temp solution for the org admin access to all org
        onSave={()=> fetchGroups(page,keyword, selectedFilter?._id)}
        onDelete={showConfirmation}
        editMode
      />
      <Confirmmodal
        title="Delete Asset" 
        message ={issueCount > 0 ? constants.message.deleteGroupWarning : `Do you want to delete asset ${selectedGroup?.name} ?`}
        closeConfirmModal={closeConfirmation}
        delete={deleteGroup}
        isLoading={loading}
      />
    </div>
  )
}

export default Groups
