import constants from "../config/constants"

class Validation {

    isEmpty(field : any){
        if(field && field.toString().trim().length !== 0){
            return false
        }else{
            return true
        }
    }

    isEmail(field : string){
      
        let regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if(field.match(regex)){
            return true
        }else{
            return false
        }
    }

    isJson(json : any) {
        try {
            JSON.parse(json);
            return true;
        } catch (e) {
            return false;
        }
    }

    isPassword(field : string){
        if(field.match(/^\S{6,20}$/)){
            return true
        }else{
            return false
        }
    }

    isName(field : string){
        if(field.match(/^[a-zA-Z\s.]{1,50}$/)){
            return true
        }else{
            return false
        }
    }
                    
    isAddress(field : string){
        if(field.match(/^.{1,100}$/)){
            return true
        }else{
            return false
        }
    }

    isPhoneNumber(field : number){
        if(field.toString().match(/^[+]?\d{7,15}$/)){
            return true
        }else{
            return false
        }
    }

    isValidCharacter = (name : string) =>{
        let regExp =  /^([A-Za-z0-9_ ]+)?$/;
        if(regExp.test(name)){
            return true
        }else{
            return false
        }
    }

    isValidSystemName = (name : string) =>{
        let regExp =  /^([A-Za-z0-9]+)?$/;
        if(regExp.test(name)){
            return true
        }else{
            return false
        }
    }

    isValidZipCode = (value : string) => {
        if(value) {
            let regExp = /(^\d{5,10}$)/
            if(regExp.test(value)){
                return true
            }else{
                return false
            }
        }else{
            return true
        }
    }

    isValidOrgName = (value : string) => {
        if(value) {
            let regExp = /^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/
            if(regExp.test(value)){
                return true
            }else{
                return false
            }
        }else{
            return true
        }
    }

    isValidCity = (value : string) => {
        if(value) {
            let regExp = /^[a-zA-Z0-9_ ]*$/
            if(regExp.test(value)){
                return true
            }else{
                return false
            }
        }else{
            return true
        }
    }

    isValidModel = (name : string) =>{
        let regExp =  /^([A-Za-z0-9/_ ]+)?$/;
        if(regExp.test(name)){
            return true
        }else{
            return false
        }
    }

    isValidValue = (value : string) =>{
        if(value) {
            let regExp = /^([-]?\d*[.]?\d*)?$/
            if(regExp.test(value)){
                return true
            }else{
                return false
            }
        }else{
            return true
        }
    }

    isValidCalibration = (value : string) =>{
        if(value) {
            let regExp = /^[-]?[0-9]{1,3}(?:\.[0-9]{1,2})?$/
            if(regExp.test(value)){
                return true
            }else{
                return false
            }
        }else{
            return true
        }
    }

    isValidAirflow  = (value : string) =>{
        if(value) {
            let regExp = /^(\d*[.]?\d*)?$/  
            if(regExp.test(value)){
                return true
            }else{
                return false
            }
        }else{
            return true
        }
    }

    isValidAirflowFormat  = (value : string) =>{
        if(value) {
            let regExp = /^(\d*[.]?\d+)?$/  
            if(regExp.test(value)){
                return true
            }else{
                return false
            }
        }else{
            return true
        }
    }
  
    isValidFormatValue = (value:string) =>{
        if(value) {
            let regExp = /^(-\d*(\.\d+|\d)|(\d*(\.\d+)?))$/ 
            if(regExp.test(value)){
                return true
            }else{
                return false
            }
        }else{
            return true
        }
        
    }

    isValidDates(startDate? : Date, endDate? : Date){
        if(startDate && endDate && startDate.getTime() > endDate.getTime()){
            return false
        }else{
            return true
        }
    }

    isValidMonthDiff(d1:Date, d2:Date,diff:number) {
        let months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth() + 1;
        months += d2.getMonth();
        months = months <= 0 ? 0 : months;
        return months <=diff
    }
    

    isValidEntries(value:string){
        let regExp = /^([A-Za-z0-9/_ ]+)?$/
        if(regExp.test(value)){
            return true
        }else{
            return false
        }
    }

    isValidAddress(value:string){
        let regExp =  /^([A-Za-z0-9/,&\-/\n\s. ]+)?$/;
        if(regExp.test(value)){
            return true
        }else{
            return false
        }
    }

    validatePhoneNumber(phonenumber : any){
        if(phonenumber){
            if(phonenumber.toString().match(/^[+]?\d{0,16}$/)){
                return true
            }else{
                return false
            }
        }
    }
    
    isValidPassword = (value:string) => {
        let regex =  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
        if(regex.test(value)){
            return true
        }else{
            return false
        }
    }

    validTimeZone = (value : string) => {
        let regex =  /^[a-zA-Z _/]*$/;
        if(regex.test(value)){
            return true
        }else{
            return false
        }
        
    }

    validateDates(startDate: Date, endDate: Date) {

        if (this.isEmpty(startDate)) {
          return { startDate: constants.message.start_date_mandatory }
        }
    
        if (this.isEmpty(endDate)) {
          return { endDate: constants.message.end_date_mandatory }
        }
    
        if (!this.isValidDates(startDate, endDate)) {
          return { endDate: constants.message.invalid_dates }
        }
        return ''
    
      }
}

const validate = new Validation()
export default validate