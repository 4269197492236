import React from 'react';
import { Input } from 'antd';
import './userList.css';
import { withRouter, RouteComponentProps } from 'react-router';
import Constants from '../../config/constants'
import Pagination from "react-js-pagination";
import userService from '../../services/userService'
import roleService from '../../services/roleService';
import organizationService from '../../services/organizationService';
import {User} from '../../interface'
import {Organization} from '../../interface'
import authStore from '../../store/authStore'
import constants from '../../config/constants';
import toast from '../../components/toast'
import Utility from '../../helpers/Utility'
import UserForm from '../../components/createuser'
import LoadingWheel from '../../components/loadingwheel'
import SelectComponent from '../../components/select';
import ResendMailForVerification from '../../components/confirmDialogModal'
import MessageBox from '../../components/messagebox/index';
import Permissions from '../../config/permssions';
import moment from 'moment';

const { Search } = Input;

const {
    super_admin_access,
    org_admin_access ,
    create_edit_users,
  } = Permissions

export interface UserProps extends RouteComponentProps {}
export interface UserState {
    userList        :   Array<User>
    roleList        :   Array<any>;
    organizationList:   Array<Organization>;
    currentPage     :   number;
    organizationFilter? : any;
    userCount       :   number;
    roleFilter      :   any;
    searchFilter    :   any;
    selectedUser?   :   User;
    isLoading       :   boolean;
    visible         : boolean;
    setVisibleClose : boolean;
    showResendMailForVerificationModal: boolean;
    showMessageBoxModal: boolean;
}

class UserList extends React.Component<UserProps, UserState> {

    searchUsers : any
    defaultRole: { _id: string; name: string; };
    history: Readonly<UserProps> & Readonly<{ children?: React.ReactNode; }>;
    constructor(props : any){
        super(props);

        this.defaultRole={ _id : '',name : 'All Roles'};
        this.history = this.props
        this.state = {
            userList    : [],
            organizationList    : [],
            roleList    : [this.defaultRole],
            currentPage : 1,
            organizationFilter : {},
            userCount   : 1,
            roleFilter  : this.defaultRole,
            searchFilter: '',
            selectedUser: undefined,
            isLoading   : true,
            visible         :   false,
            setVisibleClose : true,
            showResendMailForVerificationModal :  false,
            showMessageBoxModal: false
            
        }
        this.searchUsers = Utility.debounce(this.getUsers, 700);
    }

    setRole (roleId : number) { 
        return Object.keys(Constants.role).find(key => Constants.role[key] === roleId) as any
        
    }

    async componentDidMount(){

        if (this.history.location?.state?.shouldBeVisible) {
            this.setState({visible:true},()=>{
                Utility.showModal('createuser')
            })
        }
        if(authStore?.currentUser?.access?.includes(super_admin_access)){
            this.getOrganisationUsers()  
        }else{
            this.getRoles()
            this.getUsers()
        }
    }

    setVisible = () => {
        this.setState({visible:false});
      }

    getUsers : any = async(pageNumber : number = 0) => {
        pageNumber = pageNumber === 0? this.state.currentPage : pageNumber;
        try { 
            let orgid = (this.state.organizationFilter?._id ? this.state.organizationFilter?._id : authStore?.currentUser?.oid)
            let userList = await userService.getUsers(pageNumber, Constants.maxLimit, this.state.searchFilter,orgid,this.state.roleFilter?._id)
            this.setState({userList : userList.result, userCount : userList.count, currentPage : pageNumber, isLoading : false})
        }catch(error){
            if(error.message !== "Operation canceled due to new request."){
                this.setState({isLoading : false})
                toast.error(error.response.data.message)
            }
        }
    }

    handleFilterOrganization(option: any){
        this.setState({organizationFilter : option , roleFilter  : this.defaultRole},()=>{
            this.getUsers(1)
            this.getRoles() 
        })
    }

    closeMessageBoxModal = async () =>{
        this.setState({showMessageBoxModal : false})
        await this.getUsers(this.state.currentPage)
    }

    handleFilterRole(option: any){

        this.setState({roleFilter : option},()=>{
            this.getUsers(1)
        })
    }

    getRoles :any = async () => {
        try {
            const response = await roleService.getRoles(this.state.organizationFilter._id)
            let filteredValue=response.data.roles.filter((item :any)=> item.role_hierarchy > authStore.currentUser.role_hierarchy ? authStore.currentUser.role_hierarchy : 0)
            let orgFilteredValue=response.data.roles.filter((item :any)=> item.role_hierarchy >= authStore.currentUser.role_hierarchy ? authStore.currentUser.role_hierarchy :1)                

            if(authStore?.currentUser?.access?.includes(super_admin_access) || authStore?.currentUser?.access?.includes(org_admin_access)){
                this.setState({roleList : [this.defaultRole, ...orgFilteredValue] , isLoading : false})
            }
            else{
                this.setState({roleList : [this.defaultRole, ...filteredValue] , isLoading : false})
            }

        } catch (error) {
                toast.error(error.response.data.message)
                this.setState({isLoading : false})
        }
    }

    getOrganisationUsers :any = async () => {
        try {
                const response = await organizationService.getOrganizations()
                this.setState({organizationList : response.data.organizations , organizationFilter : response.data.organizations[0]},()=>{
                    this.getUsers()
                    this.getRoles() 
                })
        } catch (error) {
                toast.error(error.response.data.message)
                this.setState({isLoading : false})
        }
    }

    setRoleFilter(value : string) {
        this.setState({ roleFilter : value, currentPage : 1},()=>{
            this.getUsers()
        })
    }

    setSearchFilter(value : string){

            this.setState({searchFilter : value, currentPage : 1},()=>{
            this.searchUsers()
            
        })
    }

    showUserForm(userDetails? : User){
            this.setState({selectedUser : userDetails,visible:true},()=>{
                Utility.showModal('createuser')
            })
    }

    showResendMailForVerificationModal(item : any) {
        this.setState({ selectedUser : item, visible : false ,showResendMailForVerificationModal :true })
    }

    closeResendMailForVerificationModal = () => {
        this.setState({ showResendMailForVerificationModal: false })
    }

    resendMailVerfication = async (item : any) => {
        try {
            this.setState({isLoading : true})
            let resendMail = await userService.resendEmail(item._id)
            if(resendMail === 'Success.'){
                this.setState({isLoading : false})
                this.setState({ showResendMailForVerificationModal: false, showMessageBoxModal : true })
            }
          } catch (error) {
            this.setState({isLoading : false})
          }
    }


    clearSelect = () =>{
        this.setState({selectedUser : {} as User})
    }

    showUserCreateForm() {
        Utility.showModal('createuser')
    }

    shouldPageChange = (isAdd: boolean) => {
        let currentPage = 0;
        if (isAdd) {
            currentPage = Math.ceil( (this.state.userCount + 1)/constants.maxLimit);
        } else if(this.state.userList.length === 1 && this.state.currentPage > 1){
            currentPage = this.state.currentPage -1;
        }
        
        return currentPage;
    }

    render(){
        return (
            <div className="padding-b50">
              <div className="row mb-25">
              <div className="col-lg">
                        <p className="table-title media-padding">Users</p>
                  </div>
                    {authStore?.currentUser?.access?.includes(super_admin_access) &&
                        <div className="filter-select ml-auto">
                            <label className="custom-label">Organization</label>
                                <SelectComponent name="typeFilter"
                                                 data={this.state.organizationList}
                                                 value={this.state.organizationFilter}
                                                 onChange={(e:any)=>this.handleFilterOrganization(e)}
                                />
                        </div>
                    }
                        <div className={authStore?.currentUser?.access?.includes(super_admin_access) ? "filter-select ml-3" : "filter-select ml-auto"}>
                            <label className="custom-label">Role</label>
                                <SelectComponent name="typeFilter"
                                                 data={this.state.roleList}
                                                 value={this.state.roleFilter}
                                                 onChange={(e:any)=>this.handleFilterRole(e)}
                                />
                        </div>
                                         
                    <div className="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 media-margin-tp mt-auto">
                        
                        <Search
                            placeholder="Search"
                            value={this.state.searchFilter ? this.state.searchFilter : ''}
                            size="large"
                            onChange={(e)=>this.setSearchFilter(e.target.value)}
                            allowClear
                            enterButton />
                    </div>
               </div>
               {this.state.userList.length !== 0 ?
                <div className="row no-gutters table-wrap">
                    <table className="table custom-table table-style" style={{ tableLayout: 'fixed' }}>
                        <thead className="table-header-bg">
                            <tr>
                            <th scope="col" style={{ width: '5%' }}>#</th>
                            <th scope="col" style={{ width: '18%' }}>Name</th>
                            <th scope="col" style={{ width: '22%' }}>User roles</th>
                            <th scope="col" style={{ width: '20%' }}>Email</th>
                            <th scope="col" style={{ width: '10%' }}>Created by</th>
                            <th scope="col" style={{ width: '10%' }}>Created time</th>
                            <th scope="col" style={{ width: '15%' }} className="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.userList ?
                                this.state.userList.map((item : User,index) =>{
                                    let role_array = item.roles?.reduce((acc :any, itm :any) => acc.concat(itm?.name), [])
                                    return(
                                        <tr className="click-icon" key={index} onClick={()=>{this.showUserForm(item)}}>
                                        <th scope="row">{(constants.maxLimit * (this.state.currentPage - 1)) + index + 1}</th>
                                        <td className="text-truncate" title={item.name}>{item.name}</td>
                                        <td className="text-truncate" title={role_array ? role_array.join(", ") : ''}>{role_array ? role_array.join(", ") : ''}</td>
                                        <td className="text-truncate" title={item.email}>{item.email}</td>
                                        <td className="text-truncate" title={item.created_by ? item.created_by.name : ''}>{item.created_by ? item.created_by.name : ''}</td>
                                        <td className="text-truncate" title={item.created_on ? moment(item?.created_on).format('MM/DD/YYYY, HH:mm:ss') : ''}>{item.created_on ? moment(item?.created_on).format('MM/DD/YYYY, HH:mm:ss') : ''}</td>
                                        <td className="text-center">{(item?.is_accepted && item?.is_activated) ? `Active` : <button type="button" disabled={false} className="btn  btn-sm btn-outline-danger" onClick={(e)=>{ e.stopPropagation(); if(!(item?.is_accepted)) this.showResendMailForVerificationModal(item) }} > Resend</button>}</td>
                                        </tr>
                                    )

                                })
                                : null 
                            }
                        </tbody>
                        </table>
                </div>
                :
                <div className="text-center w-100 no-card-ph">
                    <div className="no-result-container">
                    { !this.state.isLoading ?
                    <p className="no-cards"> {this.state.searchFilter === ''   ? "No users found!" : "No results found!"}</p>
                    : ' '}
                    </div>
                </div>
               }
          
               <div className="pagination-container">
               {Math.ceil( this.state.userCount/Constants.maxLimit ) > 1?
   
                        <Pagination
                            hideNavigation
                            activePage={this.state.currentPage}
                            itemsCountPerPage={Constants.maxLimit}
                            totalItemsCount={this.state.userCount}
                            itemClass={"item-class"}
                            activeClass={"active-class"}
                            pageRangeDisplayed={5}
                            onChange={(pageNumber)=>{
                                this.setState({isLoading : true}, () => {
                                        this.getUsers(pageNumber)
                                })
                            }}
                            />
                    
                    : null
                }
                </div>
                {this.state.visible ?

                    <UserForm user = {this.state.selectedUser} updateUserList = {this.getUsers} 
                    clearSelectedUser = {this.clearSelect} shouldPageChange={this.shouldPageChange}
                    organisationId={this.state.organizationFilter._id ? this.state.organizationFilter._id : authStore?.currentUser?.oid}
                    setVisibleClose = {this.setVisible} roleList ={this.state.roleList}/>
            : null}
            {!authStore?.currentUser?.access?.includes(super_admin_access) && authStore?.currentUser?.access?.includes(create_edit_users) &&
             <div className="add-icons"   onClick={()=>{this.showUserForm()}}>
                <i className="fa fa-plus" aria-hidden="true"></i>
             </div>   
            }
            <ResendMailForVerification
                        title={"Resend Mail Verification"}
                        message={`Are you sure want to resend mail verification link for ${this.state.selectedUser?.name}`}
                        visible = {this.state.showResendMailForVerificationModal}
                        confirm ={"YES"}
                        action={() => (this.resendMailVerfication(this.state.selectedUser))}
                        toggleVisibility={this.closeResendMailForVerificationModal}
            />

            <MessageBox show = {this.state.showMessageBoxModal}  title={""}  message = {'Email has been sent successfully'} closePasswordModal={this.closeMessageBoxModal}/>


             {this.state.isLoading ? 
                <LoadingWheel/>
                :  null}
          </div> 
        )
    }
}

export default withRouter(UserList)

