
export interface IssueQueryParams {
    page?: number;
    limit?: number;
    orgid?: string;
    status?: any;
    searchKeyword?: string;
    consumer?: any;
    contractor?: any;
    service_resolved_by?: any;
    startDate?: string;
    endDate?: string;
}

export function buildSingleUserIssueQueryParams(data: any) {
    let params: IssueQueryParams = {
        page: data?.page ? data?.page : 1,
        orgid: data?.orgid,
        limit: data?.limit,
        status: data?.status,
        searchKeyword: data?.searchKeyword ? data.searchKeyword : '',
        consumer: data?.consumer ? [data?.consumer[0]?._id] : null,
        contractor: data?.contractor ? [data?.contractor[0]?._id] : null,
        service_resolved_by: data?.service_resolved_by ? [data?.service_resolved_by[0]?._id] : null,
        startDate: data?.dateRange ? data?.dateRange[0] : null,
        endDate: data?.dateRange ? data?.dateRange[1] : null,
    };
    return params;
}
