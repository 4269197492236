import React from "react";
import { Modal } from 'antd';


interface Props {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void,
  onConfirmFunction: () => void;
  confirmationMessage: string;
  confirmationTitle: string
  onCancelAssign?: () => void
}

const ConfirmationModal: React.FC<Props> = ({
  isVisible,
  setIsVisible,
  onConfirmFunction,
  confirmationMessage,
  confirmationTitle,
  onCancelAssign
}) => {

  const handleOk = async () => {
    try {
      onConfirmFunction()
      setIsVisible(false);
    } catch (e) {
      console.error('Oops errors occured!');
    }
  };

  const handleCancel = () => {
    if (onCancelAssign) {
        onCancelAssign()
    }
    setIsVisible(false);
  };

  return (
    <Modal
      title={confirmationTitle}
      open={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText='Continue'
    >
        {confirmationMessage}
    </Modal>
  );
}

export default ConfirmationModal;