import React, {createRef, useEffect, useRef, useState} from 'react'
import createPlotlyComponent from 'react-plotly.js/factory';
import Utils from '../../helpers/Utility';
import _ from 'lodash';
import {Button} from "antd";

const Plotly = window.Plotly;
const Plot = createPlotlyComponent(Plotly);

interface GraphProps {
    plots: any[]
    fieldTestOfflineDuration?: any;
    showThresholds1:boolean;
    showThresholds2:boolean;
}

const Graph = (props: GraphProps) => {

    const graphContainerRef: any = createRef()
    const [data, setData] = useState<any[]>([]);
    const [sameUnits, setSameUnits] = useState(false)
    useEffect(() => {
        prepData()
    }, [props.plots,props.showThresholds1,props.showThresholds2])
    useEffect(() => {
        if(data.length>0)
        setLayout(() => layouts(data.filter(x => !x.threshold)))
    }, [data])
    const [layout, setLayout] = useState({});
    const layouts = (lines: any) => {
        const min: number = _.min(lines.map((x: any) => x.min)) ?? 0
        const max: number = _.max(lines.map((x: any) => x.max)) ?? 0
        let result = {
            width: graphContainerRef?.current?.offsetWidth,
            // title: title,
            legend: {
                x: 0,
                y: 100
            },
            autosize: true,
            xaxis: {
                type: "date",
                tickformat: '%I:%M:%S %p\n%B %d, %Y',
                uirevision: 'time',
                // linewidth: 1,
                zerolinecolor: 'red', // Change the color of the X-axis origin
                zerolinewidth: 2,     // Change the width of the X-axis origin line
            },
            yaxis: {
                type: 'linear',
                title: lines[0]?.name,
                titlefont: {color: 'black'},
                tickfont: {color: 'black'},
                linewidth: 1,

            },

        } as any
        if(lines[1]){
            result['yaxis2'] = {
                type: 'linear',
                title: lines[1]?.name,
                titlefont: {color: 'black'},
                tickfont: {color: 'black'},
                overlaying: 'y',
                tickmode: 'match',
                side: 'right',
                linewidth: 1,
            }
        }
        if (lines[0]?.sensor_type === 'switch') {
            result.yaxis[`tickvals`] = [0, 1]
        }
        if (lines[1]?.sensor_type === 'switch') {
            result.yaxis2[`tickvals`] = [0, 1]
        }
        if (lines[0]?.sensor_type === lines[1]?.sensor_type) {
            const gap = .1;
            result.yaxis['range'] = [min - (max - min) * gap, max + (max - min) * gap]
            result.yaxis['anchor'] = 'x'
            result.yaxis2['range'] = [min - (max - min) * gap, max + (max - min) * gap]
            result.yaxis2['anchor'] = 'y'
            result.yaxis2['scaleanchor'] = 'y'
            result.yaxis2['tickmode'] = 'sync'
            setSameUnits(true)
        } else setSameUnits(false)
        const x = lines[0]?.x
        const xMin = x?.[0],
            xMax = x?.[x?.length - 1]

        if (xMin && xMax)
            result.xaxis.range = [xMin - 86400, xMax + 86400]
        else
            result.xaxis.autorange = true

        return result
    }
    const addThresholdsHighlight = (line: any) => {
        const maxLine = {
            ...line, y: _.times(line.y.length, _.constant(line.thresholds[1])),
            line: {
                color: 'red',
                width: 2
            }, name: `${line.name} Max.`, mode: 'lines', threshold: true
        }
        const minLine = {
            ...line, y: _.times(line.y.length, _.constant(line.thresholds[0])),
            line: {
                color: 'orange',
                width: 2
            }, name: `${line.name} Min.`, mode: 'lines', threshold: true
        }
        return [maxLine, minLine]
    }
    const prepData = () => {
        let thresholds: any[] = []
        let lines = props.plots.map((line: any, index: number) => {
            line.yaxis = 'y'
            if (index === 1 && line.lineId === 1)
                line.yaxis = 'y2'
            if (line.showThresholds) {
                thresholds = [...thresholds, ...addThresholdsHighlight(line)]
            }
            return line
        })
        setData(() => [...lines, ...thresholds])
    }

    let cycleInfo = {} as any

    let tstatWSwitch: any = props.plots?.find((sensor: any) => (sensor?.representation === 'tstat_w'))

    let isFieldTest = props.plots?.findIndex(x => x.category === 'fieldtest') !== -1

    if (tstatWSwitch && isFieldTest) {

        let startTime = null as any;
        let prevValue = null as any;
        let prevTime = null as any;
        let timeCycleInms = null as any;
        let cycle = 0;
        let offlineDuration = props.fieldTestOfflineDuration ? Number(props.fieldTestOfflineDuration) : 10;

        tstatWSwitch.y?.forEach((currentVal: any, index: any) => {

            if (index >= 0) {
                if (currentVal === 1 && prevValue === 0) {
                    cycle += 1;
                    startTime = tstatWSwitch.x?.length > 0 ? tstatWSwitch.x[index] : 0
                } else if (currentVal === 1 && prevValue === 1) {

                    let currentTime = tstatWSwitch.x?.length > 0 ? tstatWSwitch.x[index] : 0
                    let timeDiffOfCurrentAndPrevData = (currentTime - prevTime)
                    if (timeDiffOfCurrentAndPrevData >= offlineDuration * 60 * 1000) {

                        if (startTime) {
                            let timeDiffOfCycle = (tstatWSwitch.x?.length > 0 ? (tstatWSwitch.x[index] - startTime) : 0);
                            timeCycleInms += timeDiffOfCycle - timeDiffOfCurrentAndPrevData
                        }

                        cycle += 1;
                        startTime = tstatWSwitch.x?.length > 0 ? tstatWSwitch.x[index] : 0
                    }

                } else if (currentVal === 0 && prevValue === 1) {

                    let currentTime = tstatWSwitch.x?.length > 0 ? tstatWSwitch.x[index] : 0
                    let timeDiffOfCurrentAndPrevData = (currentTime - prevTime)

                    if (startTime) {
                        let timeDiffOfCycle = (tstatWSwitch.x?.length > 0 ? (tstatWSwitch.x[index] - startTime) : 0);
                        timeCycleInms += (timeDiffOfCurrentAndPrevData >= 10 * 60 * 1000 ? (timeDiffOfCycle - timeDiffOfCurrentAndPrevData) : timeDiffOfCycle)
                    }

                }

                prevValue = currentVal
                prevTime = (tstatWSwitch.x?.length > 0 ? tstatWSwitch.x[index] : 0)
            }

        })

        if ((tstatWSwitch.y[tstatWSwitch.y?.length - 1]) === 1) {
            cycle = cycle > 0 ? (cycle - 1) : cycle
        }

        cycleInfo['noOfCycles'] = cycle;
        cycleInfo['durationOfCycles'] = Utils.durationAsString(timeCycleInms); // in ms
    }

    return (
        <div
            className="graph-container"
            hidden={props.plots.length === 0}
            ref={graphContainerRef}
        >
            {sameUnits && <Button
                size='small'
                onClick={() => {
                    prepData()
                }}>
                Align Axes
            </Button>}
            {(tstatWSwitch && isFieldTest) &&
                <div className='d-flex float-left font-w-600'>
                    <div className='flex-fill'>
                        <p>{cycleInfo ? `No Of Cycles For TSTAT Heat 'W' : ${cycleInfo?.noOfCycles}` : ''}</p>
                    </div>
                    <div className='flex-auto ml-4'>
                        <p>{cycleInfo ? `Total Time Of Cycles For TSTAT Heat 'W' : ${cycleInfo?.durationOfCycles}` : ''}</p>
                    </div>
                </div>
            }

            <Plot
                data={data}
                layout={layout}
                config={{
                    scrollZoom: false,
                    displaylogo: false,
                    toImageButtonOptions: {
                        format: 'svg', // one of png, svg, jpeg, webp
                        filename: 'custom_image',
                        height: 500,
                        width: 700,
                        scale: 1 // Multiply title/legend/axis/canvas sizes by this factor
                    }
                }}
            />
        </div>
    );

}

export default Graph