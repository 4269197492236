/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Input, Pagination, Spin, message, Button } from 'antd';

import _ from 'lodash';
import Table from '../../components_new/table'
import constants from '../../config/constants';
import SoftSavingsModal from '../../components_new/create-edit-softsavings'
import softSavingsServices from '../../services/softSavingsServies';

const { Search } = Input;

function SoftSavings() {

    const [softSavings, setSoftSavings] = useState<any>([]);
    const [formData, setFormData] = useState<any>({});
    const [loading, setLoading] = useState(true);
    const [searchFilter, setSearchFilter] = useState('')
    const [totalCount, setTotalCount] = useState(0)

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [page, setPage] = useState(1);

    useEffect(() => {
        fetchSoftSavings()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchSoftSavings = async (pageNumber: number = 0, searchKeyword: string = '') => {

        pageNumber = pageNumber === 0 ? page : pageNumber;

        try {
            setLoading(true)
            let softSavingsDatas = await softSavingsServices.getSoftSavingsDetails(pageNumber, constants.maxLimit, searchKeyword)
            setTotalCount(softSavingsDatas?.total)
            setSoftSavings(softSavingsDatas?.softSavings)
            setLoading(false)
            setPage(pageNumber)
        } catch (error) {
            message.error(error.response.data.message);
            setLoading(false)
        }
    }

    const searchDebounce = React.useCallback(_.debounce(fetchSoftSavings, 700), [])

    const handleSearchFilter = (e: any) => {
        const { value } = e.target;
        setSearchFilter(value);
        searchDebounce(1, value)
    };

    const onPageChange = (page: any) => {
        fetchSoftSavings(page, searchFilter)
    };

    const onSelectRow = (record: any, rowIndex: any, event: any) => {
        setFormData(record)
        showModal()
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Title',
            dataIndex: 'soft_savings_name',
            key: 'soft_savings_name',
        },
    ];

    const sotSavingsData = softSavings?.map((saving: any, index: number) => {
        let result: any = {
            key: (constants.maxLimit * (page - 1)) + index + 1,
            _id: saving?._id,
            soft_savings_name: saving?.soft_savings_name
        };
        return result
    })

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setFormData({})
        setIsModalVisible(false);
    };

    const handleOk = async (values: any, id: any) => {
        try {
            if (id) {
                await softSavingsServices.updateSoftSavingsDetails(values, id)
            } else {
                await softSavingsServices.addSoftSavingsDetails(values)
            }
            setIsModalVisible(false);
            let pageNumber = id ? page : Math.ceil((totalCount + 1) / constants.maxLimit)
            setFormData({})
            fetchSoftSavings(pageNumber, searchFilter)
        } catch (error) {
            message.error(error.response.data.message);
            setIsModalVisible(true);
        }

    };

    const handleDelete = async (id: any) => {
        try {
            await softSavingsServices.deleteSoftSavings(id)
            setIsModalVisible(false);
            setFormData({})
            fetchSoftSavings(1, searchFilter)
        } catch (error) {
            message.error(error.response.data.message);
        }
    }

    return (
        <div className="p-0">
            <Spin spinning={loading}>
                <div className="d-flex flex-column h-100 overflow-hidden p-0">
                    <div className="flex-fill h-100 overflow-hidden softsavings-padding">
                        <div className="d-flex">
                            <div className="flex-fill">
                                <p className="page-title text-left">Soft Savings Category</p>
                            </div>
                            <div className="d-flex mb-3">
                                <Button onClick={showModal} className="mr-3 h-38 py-0 fs-14 ins_bg_clr border-0 btn btn-primary" type="primary"><i className="fa fa-plus mr-2"></i>Add New</Button>
                                <Search
                                    placeholder="Search"
                                    value={searchFilter || ""}
                                    size="large"
                                    onChange={handleSearchFilter}
                                    allowClear
                                    enterButton />
                            </div>
                        </div>
                        <div className='style-table'>
                            <Table
                                data={sotSavingsData}
                                columns={columns}
                                onSelectRow={(record: any, rowIndex: any, event: any) => onSelectRow(record, rowIndex, event)}
                            />
                        </div>

                        <div className='py-4'>
                            {Math.ceil(totalCount / constants.maxLimit) > 1 &&
                                <Pagination
                                    defaultCurrent={1}
                                    current={page}
                                    total={constants.maxLimit}
                                    pageSize={15}
                                    responsive={true}
                                    className="float-right"
                                    showSizeChanger={false}
                                    onChange={onPageChange} />
                            }
                        </div>

                        <SoftSavingsModal
                            visible={isModalVisible}
                            onCancel={handleCancel}
                            data={formData}
                            onClickOk={handleOk}
                            onClickDelete={handleDelete}
                        />


                    </div>
                </div>
            </Spin>

        </div>
    );
}
export default SoftSavings;
