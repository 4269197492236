import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './marker'
import {SensorBox} from '../../interface'
import { fitBounds } from 'google-map-react/utils';
import {featureGroup,  marker} from 'leaflet';
import constants from '../../config/constants';

export interface MapProps {
  sensorBoxList : Array<SensorBox>
  sidebar?       : boolean,
  centerCoord   : Array<any> | undefined,
  resetCoords   : () => void
}

export interface MapState {
  center? : { lat : number , lng : number}
  zoom?   : number
}

class MapScreen extends Component <MapProps, MapState>{

  coordList : any = new Map()
  private mapContainer: React.RefObject<HTMLDivElement>;
  constructor(props : MapProps){
    super(props);

    this.state = {
      center : {lat:40.7619926, lng:73.99346919999999},
      zoom   : 1
    }
    this.mapContainer = React.createRef()
  }
 

  componentDidUpdate(prevProps: any) {
    if (prevProps.sensorBoxList !== this.props.sensorBoxList) {
      this.setBounds(this.props.sensorBoxList)
      this.coordList.clear()
    }
    if (prevProps.centerCoord !== this.props.centerCoord) {
      this.setMapCenter()
    }
  }

  setBounds(sensorBoxList : Array<SensorBox>){


    if(sensorBoxList.length !== 0){
      let coords = [] as any

      let isSameCoords = true
      
      sensorBoxList.forEach((sensorBox, index)=>{

        if(sensorBox.location?.coordinates && sensorBox.location?.coordinates.length === 2){
          if(index !== 0 && sensorBoxList[index - 1]?.location?.coordinates.toString() !== sensorBox.location.coordinates.toString() ){
            isSameCoords = false
          }
          coords.push(marker(sensorBox.location.coordinates as any))
        }
      })
          if(coords.length !== 0){

            if(!isSameCoords){
              let group       = featureGroup(coords);
              let boundPoints = group.getBounds() as any

              let bounds = {

                sw: {
                  lat: boundPoints._southWest.lat,
                  lng: boundPoints._southWest.lng
                },
                ne: {
                  lat: boundPoints._northEast.lat,
                  lng: boundPoints._northEast.lng
                }
              };
              
              let size = {
                width : this.mapContainer.current?.clientWidth,
                height: this.mapContainer.current?.clientHeight 
              };
              
              let {center, zoom} = fitBounds(bounds as any, size as any) as any

              
              this.setState({center, zoom: zoom >= 15 ? 15 : zoom})

            }else{

              let coordinates = sensorBoxList[0].location.coordinates
              let center = {
                              lat: coordinates[0],
                              lng: coordinates[1]
                          }
              let zoom = 15
              this.setState({center, zoom})
            }
      }else{
        this.setState({center : {lat:40.7619926, lng:73.99346919999999} , zoom : 1})
      }
    }else{
      
      this.setState({center : {lat:40.7619926, lng:73.99346919999999} , zoom : 1})
    }

}

  setMapCenter(){
    if (this.props.centerCoord && this.props.centerCoord[0] && this.props.centerCoord[1]) {
      this.setState({center : { lat : this.props.centerCoord[0], lng : this.props.centerCoord[1]}, zoom: 18});
    }
  }

  getlargest( arr: any ){
    return Math.max(...arr)
  }

  getAlertString(status:any){
    let sensors = ""
    for (let key in status){
      if( status[key] > constants.sensor_status.green ){
        sensors = `${sensors}${key},`
      }
    }
    return sensors.slice(0,sensors.length-1)
  }

  displayMarker(sensorBox: any) {

    if(sensorBox?.location?.coordinates?.length){
      sensorBox.location.coordinates[0] = Number(sensorBox?.location?.coordinates[0]?.toFixed(5))
      sensorBox.location.coordinates[1] = Number(sensorBox?.location?.coordinates[1]?.toFixed(5))
    }

    let sensorboxdetails = this.coordList.get(JSON.stringify(sensorBox.location.coordinates))
    let sensor_value = sensorBox.status !== undefined ? sensorBox.status : constants.sensor_status.green
    let system_name = sensorBox.group === 1 ? sensorBox.name : sensorBox?.alias ? sensorBox?.alias : sensorBox?.sensor_box_name

    if (!sensorboxdetails) {

      sensorboxdetails = {
        place: sensorBox.place,
        name:  system_name,
        lat: sensorBox.location.coordinates[0],
        lng: sensorBox.location.coordinates[1],
        status: { [system_name]: sensor_value },
        created_by: ''
      }

      this.coordList.set(JSON.stringify(sensorBox.location.coordinates), sensorboxdetails)


    }
    else {

      if (sensorboxdetails.name.length > 0 && !sensorboxdetails.name.includes(system_name)) {
        sensorboxdetails.name = `${sensorboxdetails.name}, ${system_name}`
      } else if (sensorboxdetails.name.length === 0) {
        sensorboxdetails.name = `${system_name}`
      }
      
      sensorboxdetails.status[system_name] = sensor_value

      this.coordList.set(JSON.stringify(sensorBox.location.coordinates), sensorboxdetails)
    }

    let arrayOfStatus = Object.values(sensorboxdetails.status)
    let currentStatus = this.getlargest(arrayOfStatus)
    let alert = this.getAlertString(sensorboxdetails.status)
    let display_data = this.coordList.get(JSON.stringify(sensorBox.location.coordinates))

    return (
      <Marker
        lat={display_data.lat}
        lng={display_data.lng}
        place={sensorBox.place}
        name={display_data.name}
        group={display_data.group ? display_data.group : ''}
        value={currentStatus}
        alert={alert}
        key={sensorBox.sensor_box_name}
        created_by={sensorBox.created_by ? sensorBox.created_by.name : ""}
        active={sensorBox.is_deleted}
      />
    )
  }

  render() {
    return (
      <div className="h-100 map-view-container" ref={this.mapContainer}>
        <GoogleMapReact
          bootstrapURLKeys  = {{key: constants.googleMapAPIKey}}
          center            = {this.state.center}
          zoom              = {this.state.zoom}
          options           = {{clickableIcons : false, fullscreenControl: false}}
          // onDrag            = {this.props.resetCoords}
        >
          {this.props.sensorBoxList?.map((sensorBox : SensorBox) =>{

              if(sensorBox?.location?.coordinates && sensorBox?.location?.coordinates?.length === 2){
                return this.displayMarker(sensorBox)
              }
              return null
            })
          }
        </GoogleMapReact>
      </div>
    );
  }
}
 
export default MapScreen;