const rules: any = {
    issue_title: [{ required: true, message: "Ticket title is required" }],
    issue_priority: [{ required: true, message: "Ticket priority is required" }],
    issue_assigned_to_contractor: [{ required: true, message: "Please choose a contractor to assign the ticket" }],
    service_resolved_by: [{ required: true, message: "Please choose resolved by" }],
    savings_amount: [
      { required: true, message: "Please provide savings amount" },
      {
        // [0-9]* Zero or more digits between 0 and 9  (This allows .25 to be considered valid.)
        // ()? Matches 0 or 1 things in the parentheses.  (Allows for an optional decimal point)
        // Decimal point escaped with \.
        // If a decimal point does exist, it must be followed by 1 or more digits [0-9]
        // \d and [0-9] are equivalent 
        // ^ and $ anchor the endpoints so tthe whole string must match.
        pattern: /^[0-9]*(\.[0-9]+)?$/,
        message: 'Please provide a valid savings amount',
      }],
    savings_details: [{ required: true, message: "Please provide savings details" }],
    soft_category: [{ required: true, message: "Please choose soft savings category" }],
    revenue: [
      {
        // [0-9]* Zero or more digits between 0 and 9  (This allows .25 to be considered valid.)
        // ()? Matches 0 or 1 things in the parentheses.  (Allows for an optional decimal point)
        // Decimal point escaped with \.
        // If a decimal point does exist, it must be followed by 1 or more digits [0-9]
        // \d and [0-9] are equivalent 
        // ^ and $ anchor the endpoints so tthe whole string must match.
        pattern: /^[0-9]*(\.[0-9]+)?$/,
        message: 'Please provide a valid revenue amount',
      }
    ],
    diagnosis: [{ required: true, message: "Diagnosis is required" }],
    overall_summary: [{ required: true, message: "Overall summary is required" }],

};

  export default rules;