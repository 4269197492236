import Api from '../api';
// import qs from 'querystring';

class PinConfiguationService {

  getPinConfiguation = async () => {
    return await Api.get('/pin-config');
  }
   
}

export default new PinConfiguationService();
