import React, { useState } from 'react';
import AlertLogsModal from '../../components/AlertLogsModal';
import LogDataModal from "../../components/LogDataModal"
import ExportModal from '../../components/exportsensordata'
import Utility from '../../helpers/Utility';
import "./troubleshoot-card.css"
import moment from 'moment'

interface Props { //props
    groups: any,
}

const TroubleshootCard: React.FC<Props> = ({ groups  }) => {

    const [selectedSensorBox ,setSelectedSensorBox] = useState<any>({})
    const [selectedLog ,setSelectedLog] = useState<any>({})
    const [isLogDataVisible ,setIsLogDataVisible] = useState(false);
    const [showAlertLogsModal ,setShowAlertLogsModal] = useState(false);
    const [showExportModal ,setShowExportModal] = useState(false);


    const closeAlertLogsModal = async () => {
        setSelectedSensorBox({})
        setShowAlertLogsModal(false)
    }

    const showLogDataModal = async (logData : any) => {
        setSelectedLog(logData)
        setIsLogDataVisible(true)
        setShowAlertLogsModal(false)
    }

    const closeLogDataModal = async () => {
        setSelectedLog({})
        setIsLogDataVisible(false)
        setShowAlertLogsModal(true)

    }

    const showsAlertLogsModal = async (data : any) => {
        setSelectedSensorBox(data)
        setShowAlertLogsModal(true)

    }

    const showsExportModal = async (data : any) => {
        setSelectedSensorBox(data)
        setShowExportModal(true)
      }

    const closeExportModal = async () => {
        setSelectedSensorBox({})
        setShowExportModal(false)
    }


  return (
    <div className="row">
        {
            groups?.sensorboxes?.map((value : any) => {
                return (
                <div key={value?._id} className="col-lg-3 col-md-4 col-sm-6 mb-3 ">
                    <div className="dashboard-border">
                    <div
                    className="group-item pl-3 pr-5 py-2 dashboard-brd-btm position-relative"
                    >
                    <i className={`fa fa-circle ${Utility.getSensorStatus(value.status)}`} style={{ position: 'absolute', right: '1rem', top: '.5rem' }} aria-hidden="true"></i>
                    <p className="mb-0 text-truncate font-w-600" title = {value.sensor_box_name}>
                        {
                        value.alias ? value.alias : value.sensor_box_name
                        }&nbsp;&nbsp;
                        <i title={value?.is_online ? 'Online' : 'Offline'} className={ value?.is_online ? 'fa fa-signal online-status' : 'fa fa-ban offline-status'} aria-hidden="true"></i>
                    </p>
                    <span className="mb-0 text-secondary small">{value?.last_updated_time ? moment(value?.last_updated_time).format('MM/DD/YYYY, HH:mm:ss') : '-'}</span>
                    <i className="fa fa-download cursor-pointer" onClick={() => showsExportModal(value)} aria-hidden="true" style={{ position: 'absolute', right: '3rem', top: '2.5rem' }}></i>
                    <i className="fa fa-file-text-o cursor-pointer" onClick={() => showsAlertLogsModal(value)} aria-hidden="true" style={{ position: 'absolute', right: '1rem', top: '2.5rem' }}></i>
                    </div>
                {(value?.sensors?.length > 0) ?
                <div className="sensors-list-dashboard">
                {value?.sensors?.sort((a:any,b:any) => (a?.order - b?.order))
                    .map((item : any, index : number)=>{
                            return(   
                                <div
                                className="group-item pl-3 pr-5 py-2 border position-relative sensors-background"
                                >
                                {Utility.checkSensorRange(item) === "nil" ? null : 
                                <i className={`fa fa-circle ${Utility.checkSensorRange(item)}`} style={{ position: 'absolute', right: '1rem', top: '.5rem' }} aria-hidden="true"></i>}
                                <p className="mb-0 text-truncate">
                                    <img className="guage-icon" src={Utility.getSensorImage(item)} alt=''/>
                                    &nbsp;&nbsp;
                                    <span className="mb-0 text-secondary small">{Utility.formatSensorValue(item)}</span>
                                </p>
                                <p className="mb-0 text-truncate font-w-600 fs-14">
                                    {
                                    <span className="mb-0 text-secondary">{item.display_name}</span>
                                    }
                                </p>
                                <span className="mb-0 text-secondary small">{value?.last_updated_time ? moment(value?.last_updated_time).format('MM/DD/YYYY, HH:mm:ss') : '-'}</span>
                                </div>                         
                            )
                    })
                    }
                </div>
                :
                    <div className="text-center w-100 no-card-ph">
                        <div className="no-result-container">
                            <p>No sensors</p>
                                </div>
                    </div>
                }
                </div>
                </div>
                )
            })
        }
        <AlertLogsModal //alertlogsmodal
                show={showAlertLogsModal}
                onClose={closeAlertLogsModal}
                sensorBoxName={selectedSensorBox.sensor_box_name}
                sensorBox={selectedSensorBox}
                showLogDataModal={(logData:any) => showLogDataModal(logData)}
        />
        <LogDataModal //log data modal after modal is clicked
                logData={selectedLog.values} 
                sensorBoxName = {selectedLog.sensorbox_name} 
                onClose={closeLogDataModal}
                isVisible={isLogDataVisible}
        />
        <ExportModal //exportmodal
            show={showExportModal}
            closeModal={closeExportModal}
            SensorBoxName={selectedSensorBox.sensor_box_name}
        />
        </div>
  )
}

export default TroubleshootCard;
