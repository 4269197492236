import React, { useState } from 'react';
import TroubleshootCard from "../../components/troubleshoot-card/index"
import Utility from '../../helpers/Utility';
import ExportModal from '../../components/exportsensordata'
import ConsolidatedModal from '../../components/consolidated-alarm-logs'
import LogDataModal from "../../components/LogDataModal"
import "./troubleshoot-items.css"
import moment from 'moment'

interface Props { //props
    groups: any,
}

const TroubleShootItems: React.FC<Props> = ({ groups  }) => {

  const [selectedGroup ,setSelectedGroup] = useState<any>({})
  const [showExportModal ,setShowExportModal] = useState(false);
  const [selectedLog ,setSelectedLog] = useState<any>({})
  const [isLogDataVisible ,setIsLogDataVisible] = useState(false);
  const [showConsolidatedModal ,setShowConsolidatedModal] = useState(false);

  const showsExportModal = async (data : any) => {
    setSelectedGroup(data)
    setShowExportModal(true)
  }

  const closeExportModal = async () => {
    setSelectedGroup({})
    setShowExportModal(false)
  }

  const closeConsolidatedModal = async () => {
    setSelectedGroup({})
    setShowConsolidatedModal(false)
  }

  const showsConsolidatedModal = async (data : any) => {
    setSelectedGroup(data)
    setShowConsolidatedModal(true)
  }

  const showLogDataModal = async (logData : any) => {
    setSelectedLog(logData)
    setIsLogDataVisible(true)
    setShowConsolidatedModal(false)
  }

  const closeLogDataModal = async () => {
    setSelectedLog({})
    setIsLogDataVisible(false)
    setShowConsolidatedModal(true)

  }

  return (
    <div className="w-100">
        <label className="font-weight-bold ml-lg-2">{groups.name}</label>&nbsp;&nbsp;
        <i className={`fa fa-circle ${Utility.getSensorStatus(groups.status)}`} aria-hidden="true"></i>&nbsp;&nbsp;
        <span className="mb-0 text-secondary small">{groups?.last_updated_time ? moment(groups?.last_updated_time).format('MM/DD/YYYY, HH:mm:ss') : '-'}</span> &nbsp;&nbsp;
        <i className="fa fa-download cursor-pointer" onClick={() => {  showsExportModal(groups)  }} aria-hidden="true"></i> &nbsp;&nbsp;
        <i className="fa fa-file-text-o cursor-pointer" onClick={() => {  showsConsolidatedModal(groups)  }} aria-hidden="true"></i>
        <TroubleshootCard groups = {groups} />
        <ExportModal
            show={showExportModal}
            closeModal={closeExportModal}
            GroupId={selectedGroup._id}
        />
        <ConsolidatedModal //alert logs for each groups
                groupData={selectedGroup}
                show={showConsolidatedModal}
                onClose={closeConsolidatedModal}
                groupId={selectedGroup._id}
                groupName={selectedGroup.name}
                showLogDataModal={(logData:any) => showLogDataModal(logData)}
        />
        <LogDataModal //log data modal after modal is clicked
                logData={selectedLog.values} 
                sensorBoxName = {selectedLog.sensorbox_name} 
                onClose={closeLogDataModal}
                isVisible={isLogDataVisible}
        />
    </div>
  )
}

export default TroubleShootItems;
