import React, { Component} from "react";
import { withRouter, RouteComponentProps } from "react-router";
import AppHomeComponent from "../screens/AppHome"
export interface AppHomeProps extends RouteComponentProps { }

export interface AppHomeState { }

class AppHome extends Component<AppHomeProps, AppHomeState> {

  render(){
    return (
    <>
    <AppHomeComponent/>
    </>
//       <div className="h-100">
//          <Login/>
        
//          <div className="d-flex h-100 body-wrapper">
//          <div className="flex-auto left-menu" >
//           <Sidebar/>
//         </div>
//         <div className="flex-fill  overflow-auto" >
//         <div className="d-flex flex-column h-100">
//         <div className="flex-auto">
//           <TopMenu/>
//         </div>
//         <div className="flex-fill content-area">
// <Dashboard/>
//         </div>
//         <div className="flex-auto"></div>
//         </div>
        
//         </div>
//          </div>
        
//       </div>
      
      // <div className="page-body-wrapper" style = {{height : 'calc(100vh - 30px)'}}>
      //     <Header />
      //       {this.props.children}
      //     <Footer/>
      //   </div>
    );
  }
}
export default withRouter(AppHome)
