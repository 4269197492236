import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

interface Props  {
  inspectionDetails? : any
  isEditable? : boolean
  findings? : string
  inspectionMasterData : any;
  toPrevTabFromInspections  : () => void
  submitInspections : (data : any , findings : any, action : any) => void
}

const InspectionChecklistForm: React.FC<Props> = ({inspectionDetails, isEditable, inspectionMasterData, toPrevTabFromInspections  , submitInspections , findings }) => {  

  const [checkListData, setCheckListData] = useState<any>([]);
  const [finding , setFinding] = useState<any>('')

  useEffect(() => {
    setCheckListData(inspectionDetails)
    setFinding(findings)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionDetails, findings])


  const Headings = ({ headings, activeId }:any) => (
    <ul className="list_style_none p-0">
      {headings.map((heading:any) => (
        <li key={heading.id} className={heading.id === activeId ? "active mb-3" : "mb-3"}>
          <a
            href={`#${heading.id}`}
            onClick={(e) => {
              e.preventDefault();
              const element = document.querySelector(`#${heading.id}`)
              if(element !== null ){
              element.scrollIntoView({
              behavior: "smooth"
              });
              }
            }}
            className="ins_nav_a fnt-w-600"
          >
            {heading.title}
          </a>
        </li>
      ))}
    </ul>
  );
  
  /**
   * Dynamically generates the table of contents list, using any H2s and H3s it can find in the main text
   */
  const useHeadingsData = () => {
    const [nestedHeadings, setNestedHeadings] = React.useState([]);
    React.useEffect(() => {
      const headingElements = Array.from(
      document.querySelectorAll("main h2, main h3")
      );
      // Created a list of headings, with H3s nested
      const newNestedHeadings = getNestedHeadings(headingElements);
      setNestedHeadings(newNestedHeadings);
    }, []);
    return { nestedHeadings };
  };
  
  const getNestedHeadings = (headingElements : any) => {
    const nestedHeadings = [] as any;
    headingElements.forEach((heading : any, index : any) => {
      const { innerText: title, id } = heading;
      if (heading.nodeName === "H2") {
        nestedHeadings.push({ id, title, items: [] });
      } else if (heading.nodeName === "H3" && nestedHeadings.length > 0) {
        nestedHeadings[nestedHeadings.length - 1].items.push({
          id,
          title
        });
      }
    });
    return nestedHeadings;
  };
    
  const useIntersectionObserver = (setActiveId : any) => {
    const headingElementsRef = React.useRef({}) as any;
    useEffect(() => {
      const callback = (headings : any) => {
        headingElementsRef.current = headings.reduce((map : any, headingElement : any) => {
          map[headingElement.target.id] = headingElement;
          return map;
        }, headingElementsRef.current);
  
        const visibleHeadings : any= [];
        Object.keys(headingElementsRef.current).forEach((key) => {
          const headingElement = headingElementsRef.current[key];
          if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
        });
  
        const getIndexFromId = (id : any) =>
          headingElements.findIndex((heading) => heading.id === id);
  
        if (visibleHeadings.length === 1) {
          setActiveId(visibleHeadings[0].target.id);
        } else if (visibleHeadings.length > 1) {
          const sortedVisibleHeadings = visibleHeadings.sort(
            (a : any, b : any) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
          );
          setActiveId(sortedVisibleHeadings[0].target.id);
        }
      };
  
      const observer = new IntersectionObserver(callback, {
        rootMargin: "0px 0px -40% 0px"
      });
  
      const headingElements = Array.from(document.querySelectorAll("h2, h3"));
  
      headingElements.forEach((element) => observer.observe(element));
  
      return () => observer.disconnect();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setActiveId]);
  };
      
  const TableOfContents = () => {
    const [activeId, setActiveId] = React.useState();
    const { nestedHeadings } = useHeadingsData();

    useIntersectionObserver(setActiveId);
  
    return (
      <nav aria-label="Table of contents" className="ins_nav">
        <Headings headings={nestedHeadings} activeId={activeId} />
      </nav>
    );
  };

  const singleTypeChangeHandler = (item: any, value: string) => {
    let arrayVariable = [...checkListData];
    const index = arrayVariable.findIndex((obj :any) => obj?.checklist_id === item?._id);
    if (index >= 0) {
      arrayVariable[index].value = value;
    } else {
      arrayVariable.push({ checklist_id: item?._id, value: value});
    }
    arrayVariable = arrayVariable.filter((item: any) => item?.value);
    setCheckListData(arrayVariable);
  }

  const onChangeMutipleType = (item : any, items :any , value : any) => {
    let arrayVariable = [...checkListData] ;
    let objIndex = arrayVariable.findIndex((obj :any) => obj?.checklist_id === items?._id);
    let object = {
      [item] : value
    }
    if(arrayVariable[objIndex]){
      arrayVariable[objIndex].value = Object.assign(arrayVariable[objIndex].value , object)
    }else{
      arrayVariable.push({checklist_id : items?._id , value : object})
    }
    arrayVariable = arrayVariable.filter((item: any) =>{       
      return Object.values(item.value).some(value => { if (!value) { return false } return value })
    });
    setCheckListData(arrayVariable)
  }

  const onChangeFindings = (e: any) => {
    setFinding(e.target.value)
  }

  const onChangeRadioType = (item: any, value: string) => {
    let arrayVariable = [...checkListData] ;
    let objIndex = arrayVariable.findIndex((obj :any) => obj?.checklist_id === item?._id);
    if(arrayVariable[objIndex]){
      arrayVariable[objIndex].value = value
    }else{
      arrayVariable.push({checklist_id : item?._id , value : value})
    }
    setCheckListData(arrayVariable)
  }

  const singleTypeValue = (item : any) => {
    const checklistValue = checkListData?.find((checklist : any) => checklist?.checklist_id === item?._id);
    return checklistValue?.value
  }

  const MultipleTypeValue = (item : any , label : any) => {
    const checklistValue = checkListData?.find((checklist : any) => checklist?.checklist_id === item?._id);
    let value = checklistValue?.value[label]
    return value
  }

  async function onSubmitInspections(action : any) {
    submitInspections(checkListData , finding , action)
  }

  async function onSaveAsDraftHandler(action : any) {
    submitInspections(checkListData , finding , action)
  }
    
  return (
          <div className="row">
            <div className="col-lg-2">
              <TableOfContents />
            </div>
            <div className="col-lg-10">
              <main className="border">
              {
                  Object.keys(inspectionMasterData).map((key: any, i) => {
                    const singleType = inspectionMasterData[key].filter((value: any) => value.input_type === 'single'); // single type checklists
                    const radioType =  inspectionMasterData[key].filter((value: any) => value.input_type === 'radio'); // radio type checklists
                    const multipleType = inspectionMasterData[key].filter((value: any) => value.input_type === 'multiple'); // multiple type checklists
                    return(
                      <>
                      <div className="mb-4">
                      <div className="col-12 ins_tab_head_bg">
                        <h2 id={key?.split(' ').join('-')} className="fs-18 pt-2 pb-2 mb-0">{key}</h2>
                      </div>

                      <div className="pl-4 pr-4">
                    <div className="row">
                      {
                          singleType?.map((item: any , index : any) => (
                            <div className="col-md-4 col-sm-6 col-12 mt-2">
                              <label className="custom-label">{item?.check_list_item}</label>
                              <input type="text"
                                        className="form-control shadow-none"
                                        name="name"
                                        value={singleTypeValue(item)}
                                        onChange={(e) => singleTypeChangeHandler(item, e.target.value)}
                                        maxLength={50}
                                        placeholder="Enter Comment" />
                            </div>
                          ))
                      }
                    </div>
                    {
                          multipleType?.map((item: any) => (
                            <>
                              <h6 className="fs-14 mb-0 mt-4">{item?.check_list_item}</h6>
                              <div className="row">
                              {
                                item?.input_values.map((label: any) =>(
                                  <>
                                  <div className="col-md-4 col-sm-6 col-12 mt-2">
                                    <label className="fs-12">{label}</label>
                                    <input type="text"
                                          placeholder="Enter Comment"
                                          className="form-control shadow-none" 
                                          value={MultipleTypeValue(item , label)}
                                          onChange={(e : any) => {onChangeMutipleType( label, item ,e.target.value)}}
                                    >
                                    </input>
                                  </div>
                                  </>
                                ))
                              }
                                </div>
                                </>
                          ))
                    }
                    <div className="row mt-4">
                    {
                          radioType?.map((item: any , index : string) => (
                              <>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-2">
                              <label className="fs-12">{item?.check_list_item}</label>
                              <div>
                              { 
                                item?.input_values.map((label: any) =>{
                                  const matchedFlag = checkListData?.find((checklist : any) => checklist.checklist_id === item?._id);
                                  const flag = (matchedFlag !== undefined && label === matchedFlag.value ) ? true : false
                                  return(
                                    <>
                                    <input 
                                          type="radio"
                                          value={label}
                                          checked={flag}
                                          onChange={(e) => onChangeRadioType(item, e.target.value)} 
                                    />
                                    <label htmlFor="yes" className="ml-1 fs-12 mr-4">{label}</label>
                                    </>
                                  )
                                })
                              }
                              </div>
                            </div>
                            </>
                          ))
                    }
                    </div>
                  </div>
                      </div>
                      </>
                    )
                  })
                }
                <div className="col-md-12">
                  <label className="fs-12">Findings</label>
                    <textarea className ="contact-text-area"
                        name = "finding"
                        placeholder     = "Enter Findings"
                        value           = {finding}
                        maxLength       = {2000}
                        onChange        = {(e) => onChangeFindings(e)} 
                    ></textarea>
                </div>
              </main>
              <div className="text-right mt-4">
                {!isEditable &&

                <Button className="h-36 py-0 pl-4 pr-4 fs-14 ins_bg_clr border-0 font-weight-bold mr-3"
                        onClick={toPrevTabFromInspections}
                >Prev
                </Button>
                }

                <Button className="h-36 py-0 pl-4 pr-4 fs-14 ins_bg_clr border-0 font-weight-bold mr-3" 
                        onClick={(e : any)=> {onSaveAsDraftHandler('draft')}}
                >Save As Draft
                </Button>

                <Button className="h-36 py-0 pl-4 pr-4 fs-14 ins_bg_clr border-0 font-weight-bold" 
                        onClick={(e : any)=> {onSubmitInspections('save')}}
                >Submit
                </Button>

              </div>
            </div>
          </div>
  )
}

export default InspectionChecklistForm
