import React, {Fragment, useEffect, useState} from 'react';
import moment, {Moment} from 'moment'
import '../graphmodal/graphmodal.css';
import toast from '../../components/toast'
import Graph from './Graph';
import sensorService from '../../services/sensorService';
import constants from '../../config/constants'
import LoadingWheel from '../../components/loadingwheel'
import settingsService from '../../services/settingsService';
import Select from '../select';
import {Checkbox, DatePicker} from 'antd';
import _ from "lodash";

const {RangePicker} = DatePicker;
const {dateError} = constants.message

export interface GraphProps {
    closeGraphModal: () => void;
    sensorGroupName: string;
    sensorGroup: any;
}

const GraphModalGroup: React.FC<GraphProps> = (props: GraphProps) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dateFormat] = useState('MM-DD-YYYY')

    const [sensorGroup, setSensorGroup] = useState<any>(null);
    const [sensorboxes, setSensorboxes] = useState<Map<any, any>>(() => new Map());
    const [configHistories, setConfigHistories] = useState<any>([]);
    const [fieldTestOfflineDuration, setFieldTestOfflineDuration] = useState<any>(null);

    const [sensorboxOptions, setSensorboxOptions] = useState<any[]>([]);
    const [configOptions1, setConfigOptions1State] = useState<any>([]);
    const [configOptions2, setConfigOptions2State] = useState<any>([]);
    const [sensorOptions1, setSensorOptions1State] = useState<any>([]);
    const [sensorOptions2, setSensorOptions2State] = useState<any>([]);

    const [dateRange, setDateRange] = useState<any>(() => {
        const endDate = moment().unix();
        const startDate = moment().subtract(7, 'days').unix()
        return [startDate, endDate]
    })
    const [dateErrorState, setDateError] = useState<string>('');
    const [selectedSensorBox1, setSelectedSensorBox1] = useState<any>({_id: 0, name: 'Select a Sensorbox'});
    const [selectedSensorBox2, setSelectedSensorBox2] = useState<any>({_id: 0, name: 'Select a Sensorbox'});
    const [selectedConfig1, setSelectedConfig1] = useState<any>({_id: '', name: "Select Version"});
    const [selectedConfig2, setSelectedConfig2] = useState<any>({_id: '', name: "Select Version"});
    const [selectedParameter1, setSelectedParameter1] = useState<any>(null)
    const [selectedParameter2, setSelectedParameter2] = useState<any>(null)

    const [plotData, setPlotData] = useState(() => new Map())
    const [plots, setPlots] = useState<any>([])

    const [showThresholds1, setThresholds1] = useState<any>(false)
    const [showThresholds2, setThresholds2] = useState<any>(false)
    const [line1NoData, setLine1NoData] = useState<any>('')
    const [line2NoData, setLine2NoData] = useState<any>('')
    const [sensorGroupName, setSensorGroupName] = useState<string>();
    useEffect(() => {
        setIsLoading(true)
        setSensorGroup(_.cloneDeep(props.sensorGroup));
        setSensorGroupName(props.sensorGroupName)
    }, []);

    useEffect(() => {
        getSensorboxes();
        Promise.all([getConfigHistory(), fetchSettings()]).then(() => setIsLoading(false))
    }, [sensorGroup]);

    useEffect(() => {
        setDateError(() => (datesValid() ? '' : dateError));
    }, [dateRange]);

    useEffect(() => {
        const configIndex = configHistories.length > 0 ? configHistories.findIndex((i: any[]) => i.some(x => x.sensorBox_id === selectedSensorBox1._id)) : -1;
        setConfigOptions1(configHistories[configIndex])
    }, [selectedSensorBox1])
    useEffect(() => {
        const configIndex = configHistories.length > 0 ? configHistories.findIndex((i: any[]) => i.some(x => x.sensorBox_id === selectedSensorBox2._id)) : -1;
        setConfigOptions2(configHistories[configIndex])
    }, [selectedSensorBox2])
    useEffect(() => {
        selectedConfig1 && setSensorOptions1(selectedConfig1)
    }, [selectedConfig1])
    useEffect(() => {
        selectedConfig2 && setSensorOptions2(selectedConfig2)
    }, [selectedConfig2])
    useEffect(() => {
        setSelectedParameter1(() => (sensorOptions1[0]))
    }, [sensorOptions1, selectedSensorBox1, selectedConfig1])
    useEffect(() => {
        setSelectedParameter2(() => (sensorOptions2[0]))
    }, [sensorOptions2, selectedSensorBox2, selectedConfig2])

    useEffect(() => {
        setDateError(() => (datesValid() ? '' : dateError));
        if (datesValid() && selectedSensorBox1._id !== 0) {
            fetchData(sensorboxes.get(selectedSensorBox1._id), selectedConfig1)
        }
    }, [dateRange, selectedConfig1]);
    useEffect(() => {
        setDateError(() => (datesValid() ? '' : dateError));
        if (datesValid() && selectedSensorBox2._id !== 0) {
            fetchData(sensorboxes.get(selectedSensorBox2._id), selectedConfig2)
        }
    }, [dateRange, selectedConfig2]);

    useEffect(() => {
        setPlots(() => preparePlots())
    }, [plotData, selectedConfig2, selectedConfig1, selectedParameter1, selectedParameter2, showThresholds2, showThresholds1])
    useEffect(() => {
        setThresholds1(false)
    }, [selectedParameter1])
    useEffect(() => {
        setThresholds2(false)
    }, [selectedParameter2])
//==================================================================

    const handleDateChange = (dates: any) => {
        setDateRange(() => (dates.map((x: Moment) => x.unix())));
    };
    const disabledDate = (current: any) => {
        return current && current > moment().endOf('day');
    };
    const datesValid = () => {
        const start = moment.unix(dateRange[0])
        const end = moment.unix(dateRange[1])
        return end.diff(start, 'days') <= 30
    }
    const getSensorboxes = () => {
        const sensorbBoxes = sensorboxes
        const options: { _id: any; name: any; }[] = []
        sensorGroup?.sensorboxes?.map((i: any) => {
            sensorbBoxes.set(i._id, i)
            options.push({_id: i._id, name: i.alias||i.sensor_box_name})
        })
        setSensorboxes(sensorbBoxes)
        setSensorboxOptions(options)

    }
    const getConfigHistory = async () => {
        const configHistories = await sensorService.getSensorBoxConfigHistoryForAsset(sensorGroup?._id)
        setConfigHistories(configHistories)
    }
    const setConfigOptions1 = (data: any) => {
        let configOptions1: any = []
        let configMap1: any = new Map()
        data?.forEach((config: any) => {
            configOptions1.push({
                _id: (config.version).toString(),
                name: `v${(config.version).toString()} (${moment.unix(config.start_date / 1000).format(dateFormat)} - ${config.end_date ? moment.unix(config.end_date / 1000).format(dateFormat) : 'Now'})`,
                sensors: config.sensors
            })
            configMap1.set(config.version, config)
        })
        setConfigOptions1State(() => configOptions1)
        setSelectedConfig1(() => (configOptions1[configOptions1.length - 1]))

    }
    const setConfigOptions2 = (data: any) => {
        let configOptions2: any = []
        data?.forEach((config: any) => {
            configOptions2.push({
                _id: (config.version).toString(),
                name: `v${(config.version).toString()}(${moment.unix(config.start_date / 1000).format(dateFormat)} - ${config.end_date ? moment.unix(config.end_date / 1000).format(dateFormat) : 'Now'})`,
                sensors: config.sensors
            })
        })
        setConfigOptions2State(() => configOptions2)
        setSelectedConfig2(() => configOptions2[configOptions2.length - 1])
    }
    const setSensorOptions1 = (configData: any) => {
        let sensorOptions1: any = []
        configData?.sensors?.forEach((sensor: any) => {
            sensorOptions1.push({
                _id: `${sensor.representation}_${sensor.sensor_name}`,
                name: `${sensor.display_name}${configData?.sensors.filter((item: any) => item.representation === sensor.representation).length > 1 ? ` - ${sensor?.sensor_name}` : ''}`,
                representation: sensor.representation
            })
        })
        setSensorOptions1State(sensorOptions1)
    }
    const setSensorOptions2 = async (configData: any) => {
        let sensorOptions2: any = []
        await configData?.sensors?.forEach((sensor: any) => {
            sensorOptions2.push({
                _id: `${sensor.representation}_${sensor.sensor_name}`,
                name: `${sensor.display_name}${configData?.sensors.filter((item: any) => item.representation === sensor.representation).length > 1 ? ` - ${sensor?.sensor_name}` : ''}`,
                representation: sensor.representation
            })
        })
        // setState({sensorOptions2})
        setSensorOptions2State(sensorOptions2)
    }
    const handleConfig1Selection = async (e: any) => {
        if (selectedConfig1 !== e) {
            setSelectedConfig1(e)
        }
    }
    const handleConfig2Selection = async (e: any) => {
        if (selectedConfig2 !== e) {
            setSelectedConfig2(e)
        }
    }
    const getDatainChunks = async (sensorbox: any, config_id: any) => {
        let graphData: Array<any> = []
        let chunk: Array<any> = []
        let chunkLastTime = null
        const start = moment.unix(dateRange[0]).toDate()
        const end = moment.unix(dateRange[1]).toDate()
        while (true) {
            chunk = await sensorService.getGraphData(
                sensorbox,
                start,
                end,
                chunkLastTime,
                config_id
            );
            if (chunk.length === 0) {
                break;
            }
            chunkLastTime = chunk[chunk.length - 1].added_time
            graphData = [...graphData, ...chunk]
        }
        return graphData
    }
    const fetchSettings = async () => {
        const settings = await settingsService.getSettings();
        setFieldTestOfflineDuration(settings?.data?.cycle_offline_duration)
    }
    const fetchData = async (sensorbox: any, selectedConfig: any) => {
        setIsLoading(true)
        try {
            const data = await getDatainChunks(sensorbox.sensor_box_name, selectedConfig._id)
            if (!(data.length > 0)) {
                toast.error('No Records Found!')
                setPlotData((pd) => {
                    pd.set(sensorbox._id, new Map());
                    return new Map(pd)
                })
                setIsLoading(false)
            } else {
                toast.success(`${data.length} Records Found!`)
                prepareDataToPlotForSensorBox(sensorbox, data)
            }
        } catch (error) {
            setIsLoading(false)
            toast.error('Network error')
        }
    }
    const prepareDataToPlotForSensorBox = async (sensorbox: any, dataL: any) => {
        let y_data: any = {}, min: any = {}, max: any = {}, x_time: any = {}
        try {
            setIsLoading(true)
            const {sensors, category} = sensorboxes.get(sensorbox._id)
            sensors?.forEach((sensor: any, index: number) => {
                sensor.pin_with_representation = `${sensor.representation}_${sensor.sensor_name}`
                y_data[sensor.pin_with_representation] = []
                x_time[sensor.pin_with_representation] = []
                min[sensor.pin_with_representation] = Number.MAX_SAFE_INTEGER
                max[sensor.pin_with_representation] = Number.MIN_SAFE_INTEGER
            });
            dataL?.forEach((item: any) => {
                Object.keys(y_data).forEach((representation) => {
                        if (item[representation] !== null) {
                            min[representation] = min[representation] < item[representation] ? min[representation] : item[representation]
                            max[representation] = max[representation] > item[representation] ? max[representation] : item[representation]
                            y_data[representation].push(item[representation])
                            x_time[representation] = [...x_time[representation], item.added_time]
                        }
                    }
                )
            })
            const PlotLines = new Map()
            sensors?.forEach((sensor: any, index: number) => {
                const plotLine = {
                    x: x_time[sensor.pin_with_representation],
                    y: y_data[sensor.pin_with_representation],
                    min: min[sensor.pin_with_representation],
                    max: max[sensor.pin_with_representation],
                    mode: 'lines+markers',
                    type: 'scatter',
                    name: sensor.display_name,
                    sensor_type: sensor.pin_type,
                    category: category,
                    representation: sensor.representation
                }
                if (sensor.sensor_type.min) {
                    // @ts-ignore
                    plotLine["thresholds"] = [sensor.sensor_type.min, sensor.sensor_type.max]
                }
                PlotLines.set(sensor.pin_with_representation, plotLine)
            })
            setPlotData((pd) => {
                pd.set(sensorbox._id, PlotLines);
                setIsLoading(false)
                return new Map(pd)
            })
        } catch (e) {
            console.error(e)
            setIsLoading(false)
        }
    }
    const closeModal = () => {
        props.closeGraphModal();
    }
    const preparePlots = () => {
        let lines = []
        const line1 = plotData?.get(selectedSensorBox1._id)?.get(selectedParameter1?._id)
        if (line1) {
            line1.lineId = 0;
            line1.showThresholds = showThresholds1 && !!line1.thresholds
            lines.push(line1)
            setLine1NoData(null)
        } else {
            setLine1NoData('NO DATA')
        }
        const line2 = plotData?.get(selectedSensorBox2?._id)?.get(selectedParameter2?._id)
        if (line2) {
            line2.lineId = 1;
            line2.showThresholds = showThresholds2 && !!line2.thresholds
            lines.push(line2)
            setLine2NoData(null)
        } else {
            setLine2NoData('NO DATA')
        }
        return lines
    }
    const isThresholdsSet = (id: number) => {
        return !!plots.find((x: any) => x.lineId === id)?.thresholds
    }
    let graphClassName = ['multiple-graph w-100 pt-3 pb-3']
    return (
        <div className="modal fade bd-example-modal-sm show" id="graphmodal" role="dialog"
             aria-labelledby="exampleModalLabel">
            <div className="modal-dialog modal-sensor-data-graph" role="document">
                <div className="modal-content">
                    <div className="modal-header ">
                        <h5 className="modal-title" id="exampleModalLabel">{
                            <div>{sensorGroupName} {sensorGroup?.alias &&
                                <span className='pl-2'>( {sensorGroup?.alias} )</span>}</div>}</h5>
                        <span aria-hidden="true" className="close" onClick={() => {
                            closeModal()
                        }}>&times;</span>
                    </div>
                    <div className="row mt-50 mb-25 ml-4 ht-custom">
                        <RangePicker
                            id="dateRangePicker"
                            size={'large'}
                            format={'MMM-DD-YYYY'}
                            defaultValue={[moment().subtract(7, 'days'), moment()]}
                            onChange={handleDateChange}
                            disabledDate={disabledDate}
                            allowClear={false}
                            value={dateRange.map((x: any) => moment.unix(x))}
                            mode={['date', 'date']}
                        />
                        <div className="error-msg ml-4">{dateErrorState}</div>
                    </div>
                    {
                        <Fragment>
                            <div className="row mt-50 mb-25 ml-2 ht-custom">
                                <div className="col-xs-4 col-sm-4 col-md-3 col-lg-3 media-margin-tp">
                                    <div className="form-group mb-0">
                                        <Select name="sensorbox1"
                                                data={sensorboxOptions.filter(x => x._id !== selectedSensorBox2._id)}
                                                value={selectedSensorBox1}
                                                onChange={(e: any) => {
                                                    setSelectedSensorBox1(e)
                                                }}
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-3 col-lg-3 media-margin-tp">
                                    <div className="form-group mb-0">
                                        <Select name="type"
                                                data={configOptions1}
                                                value={selectedConfig1}
                                                onChange={handleConfig1Selection}
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-3 col-lg-3 media-margin-tp">
                                    <div className="form-group mb-0">
                                        <Select
                                            name="parameters"
                                            data={sensorOptions1}
                                            value={selectedParameter1}
                                            onChange={(e: any) => {
                                                const item = {...e, value: e._id, label: e.name, s: 1}
                                                setSelectedParameter1(item)
                                            }}

                                        />
                                        <Checkbox
                                            checked={showThresholds1}
                                            style={{display: isThresholdsSet(0) ? '' : 'none'}}
                                            onChange={(e) => setThresholds1(e.target.checked)}
                                        >show thresholds</Checkbox>
                                    </div>
                                </div>
                                {line1NoData && (<div className="col-xs-4 col-sm-4 col-md-3 col-lg-3 media-margin-tp">
                                    <div className="d-flex mb-0 align-items-center"
                                         style={{minHeight: '38px', color: 'red'}}>
                                        <span>{line1NoData}</span>
                                    </div>
                                </div>)}
                            </div>
                            <div className="row mt-50 mb-25 ml-2 ht-custom">
                                <div className="col-xs-4 col-sm-4 col-md-3 col-lg-3 media-margin-tp">
                                    <div className="form-group mb-0">
                                        <Select name="sensorbox1"
                                                data={sensorboxOptions.filter(x => x._id !== selectedSensorBox1._id)}
                                                value={selectedSensorBox2}
                                                onChange={(e: any) => {
                                                    setSelectedSensorBox2(e)
                                                }}
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-3 col-lg-3 media-margin-tp">
                                    <div className="form-group mb-0">
                                        <Select name="type"
                                                data={configOptions2}
                                                value={selectedConfig2}
                                                onChange={handleConfig2Selection}
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-3 col-lg-3 media-margin-tp">
                                    <div className="form-group mb-0">
                                        <Select
                                            name="parameters"
                                            data={sensorOptions2}
                                            value={selectedParameter2}
                                            onChange={(e: any) => {
                                                const item = {...e, value: e._id, label: e.name, s: 2}
                                                setSelectedParameter2(item)
                                            }}
                                        />
                                        <Checkbox
                                            name="showThresholds2"
                                            checked={showThresholds2}
                                            style={{display: isThresholdsSet(1) ? '' : 'none'}}
                                            onChange={(e) => setThresholds2(e.target.checked)}
                                        >show thresholds</Checkbox>
                                    </div>
                                </div>
                                {line2NoData && (<div className="col-xs-4 col-sm-4 col-md-3 col-lg-3 media-margin-tp">
                                    <div className="d-flex mb-0 align-items-center"
                                         style={{minHeight: '38px', color: 'red'}}>
                                        <span>{line2NoData}</span>
                                    </div>
                                </div>)}
                            </div>
                        </Fragment>
                    }
                    <div className="modal-body modal-height-graph px-3">
                        <div className="d-flex flex-wrap w-100 ml-0">
                            {
                                sensorGroup?.sensorboxes?.length > 0 ?
                                    <>
                                        <div className={graphClassName.join(' ')}>
                                            <div className='border p-3 h-100'>
                                                {
                                                    plots.length > 0 ?
                                                        <Graph
                                                            key={0}
                                                            fieldTestOfflineDuration={fieldTestOfflineDuration}
                                                            plots={plots}
                                                            showThresholds1={showThresholds1}
                                                            showThresholds2={showThresholds2}
                                                        />
                                                        :
                                                        <div className="no-data-message w-100 col-12">
                                                            No Data Available,Choose different configurations to plot
                                                            the graph. </div>
                                                }
                                            </div>
                                        </div>
                                    </>
                                    : <div className="no-data-message w-100 col-12">No Data Available</div>}
                        </div>
                    </div>
                    <div className="modal-footer modal-footer-custom-padding">
                    </div>
                </div>
            </div>
            {isLoading ?
                <LoadingWheel/>
                : null}
        </div>
    );
};
export default GraphModalGroup