/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, ChangeEvent } from 'react';
import { Input } from 'antd';
import { Button } from 'react-bootstrap';
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import Pagination from "react-js-pagination";
import Routes from '../../../pages/Routes'
import _ from 'lodash';
import authStore from '../../../store/authStore';
import EllipsisProps from '../../../components/textellipsis'
import SelectComponent from '../../../components/select';
import LoadingWheel from '../../../components/loadingwheel'
import inspectionChecklistService from '../../../services/InspectionChecklistServices';
import userService from '../../../services/userService';
import constants from '../../../config/constants';
import ConfirmModal from '../../../components/confirmmodal'
import Utility from '../../../helpers/Utility';
import toast from '../../../components/toast';
import organizationService from '../../../services/organizationService';
import EditCustomerInfoModal from '../../../components/inspection-user-edit/index'
import EditSystemInfoModal from '../../../components/inspection-systemedit-modal/index'
import './inspection-checklist.css';
import Permissions from '../../../config/permssions';
import moment from 'moment';

const { Search } = Input;

const {
  create_edit_users,
  create_edit_inspections,
} = Permissions

interface Props extends RouteComponentProps { }

const InspectionChecklist: React.FC<Props> = () => {

  let history = useHistory();
  const location = useLocation() as any;
  const page_number = location?.state?.page_number;

  const [inspectionList, setInspectionList] = useState<Array<any>>([]);
  const [customerModalVisible, setCustomerModalVisible] = useState(false);
  const [systemModalVisible, setSystemModalVisible] = useState(false);
  const [organizationFilterData, setOrganizationFilterData] = useState([])
  const [loading, setLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<any>({})
  const [selectedInspection, setSelectedInspection] = useState<any>({})
  const [selectedCustomerDropDown, setSelectedCustomerDropDown] = useState<any>()
  const [selectedSystem, setSelectedSystem] = useState<any>({})
  const [selectedOrgFilter, setSelectedOrgFilter] = useState<any>({ _id: '', name: 'Select Organization' })
  const [customerList, setCustomerList] = useState([])
  const [orgFilter, setOrgFilter] = useState('')
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    if (authStore?.currentUser?.active_role?.role_type === 0 || (authStore?.currentUser?.active_role?.role_type === 1 && authStore.currentUser.is_default)) {
      getOrganizations()
    } else {
      fetchInspectionChecklists(page_number ? page_number : page);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchInspectionChecklists = async (pageNumber: number = 0, search = '', orgid?: any, customerType?: any) => {
    try {
      pageNumber = pageNumber === 0 ? page : pageNumber;
      setLoading(true);
      let consumerList = await userService.getCustomers(orgid ? orgid : '')
      setCustomerList(consumerList)
      const limit = constants.maxLimit
      const resp = await inspectionChecklistService.getInspectionsList({ page: pageNumber, limit, customer: customerType, filter: JSON.stringify({ search }), orgid });
      if (resp) {
        const { inspections, count } = resp?.data;
        setInspectionList(inspections);
        setCount(count);
        setPage(pageNumber);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      let errMsg = error?.message;
      if (error?.response?.data?.message) {
        errMsg = error?.response?.data?.message
      }
      toast.error(errMsg);
    }
  }

  const handleOrganizationSelection = (option: any) => {
    setSelectedOrgFilter(option)
    setOrgFilter(option._id ? option._id : '')
    fetchInspectionChecklists(1, keyword, option._id);
  }

  const addNewInspection = () => {
    history.push({
      pathname: `${Routes.inspectionform}`,
      state: {
        org_id: selectedOrgFilter?._id, //org_id
        page_number: page // current page number
      },
    });
  }

  const handleCustomerSelection = (option: any) => {
    setSelectedCustomerDropDown(option ? option : '')
    fetchInspectionChecklists(1, keyword, selectedOrgFilter?._id, option?._id);
  }

  const getOrganizations = async () => {
    try {
      setLoading(true)
      const response = await organizationService.getOrganizations()
      setOrganizationFilterData(response.data.organizations);
      setSelectedOrgFilter(response.data.organizations[0])
      setOrgFilter(response.data.organizations[0]._id ? response.data.organizations[0]._id : '')
      fetchInspectionChecklists(page_number ? page_number : 1, '', response.data.organizations[0]._id);

    } catch (error) {
      toast.error(constants.message.error_role_organization)
      setLoading(false)
    }
  }

  const onEditCustomer = async (customerData: any) => {
    try {
      let users = await inspectionChecklistService.editUser(customerData, selectedOrgFilter?._id);
      const { message } = users.data;
      if (message === 'Success') {
        closeCustomerEditModal()
        fetchInspectionChecklists(page, keyword, selectedOrgFilter?._id);
        toast.success("Successfully Edited Customer Details")
      }
    } catch (error) {
      toast.error(error.response?.data?.message)
    }
  }

  const onEditSystem = async (systemData: any) => {
    try {

      let users = await inspectionChecklistService.updateInspection(systemData, selectedOrgFilter?._id);
      const { message } = users.data;
      if (message === 'Success') {
        closeSystemEditModal()
        fetchInspectionChecklists(page, keyword, selectedOrgFilter?._id);
        toast.success("Successfully Edited System Details")
      }
    } catch (error) {
      toast.error(error.response?.data?.message)
    }
  }

  const search = React.useCallback(_.debounce(fetchInspectionChecklists, 700), [])

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
    search(1, e.target.value, orgFilter);
  }

  const editUserHandler = (customerData: any) => {

    setSelectedCustomer(customerData)
    setCustomerModalVisible(true)
  }

  const editSystemHandler = (systemData: any) => {
    setSelectedSystem(systemData)
    setSystemModalVisible(true)
  }

  // const onDeleteHandler = (data : any) => {
  //   setSelectedInspection(data);
  //   Utility.showModal('confirmmodal')
  // }

  const onReinspection = (id: any) => {
    history.push({
      pathname: `${Routes.inspectionform}`,
      search: `?inspection_id=${id}`,
      state: {
        page_number: page
      },
    });
  }

  const closeConfirmModal = () => {
    setSelectedInspection({})
    Utility.closeModal('confirmmodal')
  }

  const deleteInspection = async () => {
    try {
      setLoading(true)
      let users = await inspectionChecklistService.deleteInspection(selectedInspection?._id);
      const { message } = users.data;
      if (message === 'Success') {
        setLoading(false)
        Utility.closeModal('confirmmodal')
        let pageNumber = Math.ceil((count - 1) / constants.maxLimit)
        fetchInspectionChecklists(pageNumber, keyword, selectedOrgFilter?._id);
      }
    } catch (error) {
      setLoading(false)
      toast.error(error.response?.data?.message)
    }
  }

  const editInspections = (id: any) => {
    history.push({
      pathname: `${Routes.inspectionform}`,
      search: `?checklist_id=${id}`,
      state: {
        page_number: page
      },
    });
  }

  const closeCustomerEditModal = async () => {
    setSelectedCustomer({})
    setCustomerModalVisible(false)
  }

  const closeSystemEditModal = async () => {
    setSelectedSystem({})
    setSystemModalVisible(false)
  }

  return (
    <div className="padding-b50" id="groups">
      <h5 className="table-title mb-3">Inspection</h5>
      <div className="d-lg-flex justify-content-between align-items-end">
        <div className="flex-fill">
          <div className="d-flex width-90">
            <div className="flex-auto filter-select">
              <div className="form-group mb-0 position-relative">
                <label className="custom-label">Customer</label>
                <SelectComponent
                  name="typeFilter"
                  data={customerList}
                  value={selectedCustomerDropDown}
                  onChange={(value: any) => handleCustomerSelection(value)}
                  showClose={true}
                  clearValue={() => handleCustomerSelection(null)}
                />
              </div>
            </div>
            {(authStore?.currentUser?.active_role?.role_type === 0 || (authStore?.currentUser?.active_role?.role_type === 1 && authStore.currentUser.is_default)) &&
              <div className="flex-auto filter-select ml-3">
                <label className="custom-label">Organization</label>
                <SelectComponent
                  name="typeFilter"
                  data={organizationFilterData}
                  value={selectedOrgFilter}
                  onChange={handleOrganizationSelection}
                />
              </div>
            }
          </div>

        </div>
        <div className="d-flex">
          {authStore?.currentUser?.access?.includes(create_edit_inspections) && 
            <Button onClick={addNewInspection} className="mr-3 h-38 py-0 fs-14 ins_bg_clr border-0"><i className="fa fa-plus mr-2"></i>Add new</Button>
           } 
          <div className="d-flex fs-14 flex-fill">
            <Search
              placeholder="Search"
              value={keyword || ""}
              size="large"
              onChange={onSearchChange}
              allowClear
              enterButton />
          </div>
        </div>
      </div>

      {inspectionList.length > 0 &&
        <div className="table-resp">
          <table className="table border mt-4 w-100">
            <thead className="table-header-bg">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Address</th>
                <th scope="col">System S/N</th>
                <th scope="col">Last Inspected by</th>
                <th scope="col">Last date Modified</th>
                {/* <th></th> */}
                <th />
              </tr>
            </thead>
            <tbody className="fnt-w-600">
              {
                inspectionList?.map((inspection: any, index: any) => {
                  const latestInspection = inspection?.inspection_datas?.reduce((prev: any, current: any) => (+prev?.inspection_version > +current?.inspection_version) ? prev : current, 0)
                  const address = (inspection?.consumer_data?.street && inspection?.consumer_data?.city && inspection?.consumer_data?.state) ? `${inspection?.consumer_data?.street}, ${inspection?.consumer_data?.city}, ${inspection?.consumer_data?.state} ` : '-'
                  const last_inspected_by = latestInspection?.inspected_by?.name ? latestInspection?.inspected_by?.name : '-'
                  const last_inspected_time = moment(latestInspection?.updatedAt).format('MM/DD/YYYY, HH:mm:ss') !== 'Invalid Date' ? moment(latestInspection?.updatedAt).format('MM/DD/YYYY, HH:mm:ss') : '-'
                  return (
                    <>
                      <tr data-toggle="collapse" data-target={`#${index}`} className="clickable pointer" aria-expanded="false" aria-controls="accordion">
                        <td >{(constants.maxLimit * (page - 1)) + index + 1}</td>
                        <td title={inspection?.consumer_data.name}><EllipsisProps length={20}>{inspection?.consumer_data.name}</EllipsisProps></td>
                        <td title={address}><EllipsisProps length={30}>{address}</EllipsisProps></td>
                        <td title={inspection?.system_name}><EllipsisProps length={20}>{inspection?.system_name}</EllipsisProps></td>
                        <td title={last_inspected_by}><EllipsisProps length={20}>{last_inspected_by}</EllipsisProps></td>
                        <td title={last_inspected_time}><EllipsisProps length={20}>{last_inspected_time}</EllipsisProps></td>
                        {/* <td> <i style={{ fontSize: 16 }} className={'fa fa-trash'} aria-hidden="true" onClick={(e : any) => { e.preventDefault(); onDeleteHandler(inspection); e.stopPropagation();}}></i> </td> */}
                        <td>
                          <i style={{ fontSize: 12 }} className={'fa fa-chevron-down'} aria-hidden="true"></i>
                        </td>
                      </tr>

                      <tr id={`${index}`} className="collapse out">
                        <td colSpan={7}>
                          <div className="row">
                            <div className="col-6 pr-lg-2 mb-3 mb-lg-0">
                              <div className="card">
                                <div className="card-body">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <h6 className="fnt-w-600">Customer Info</h6>
                                    {authStore?.currentUser?.access?.includes(create_edit_users) &&
                                      <a href="/" className="ins_edit_icn" onClick={(e: any) => { editUserHandler(inspection?.consumer_data); e.preventDefault(); }}>
                                        <span className="sr-only"></span>
                                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                      </a>
                                    }
                                  </div>
                                  <ul className="p-0 list_style_none mt-3">
                                    <li><label className="mr-1">Name:</label>{inspection?.consumer_data?.name}</li>
                                    <li><label className="mr-1">Address:</label>{address}</li>
                                    <li><label className="mr-1">City:</label>{(inspection?.consumer_data?.city) ? `${inspection?.consumer_data?.city} ` : '-'}</li>
                                    <li><label className="mr-1">Country:</label>{(inspection?.consumer_data?.country) ? `${inspection?.consumer_data?.country} ` : '-'}</li>
                                    <li><label className="mr-1">Phone:</label>{inspection?.consumer_data?.phonenumber ? `${inspection?.consumer_data?.phonenumber} ` : '-'}</li>
                                    <li><label className="mr-1">Email:</label>{inspection?.consumer_data?.email ? `${inspection?.consumer_data?.email} ` : '-'}</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 pl-lg-2">
                              <div className="card">
                                <div className="card-body">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <h6 className="fnt-w-600">System Info</h6>
                                    {authStore?.currentUser?.access?.includes(create_edit_inspections) &&
                                      <a href="/" className="ins_edit_icn" onClick={(e: any) => { editSystemHandler(inspection); e.preventDefault(); }}>

                                        <span className="sr-only"></span>
                                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                      </a>
                                    }
                                  </div>
                                  <div className="row">
                                    <div className="col-4">
                                      <ul className="p-0 list_style_none mt-3">
                                        <li><label className="mr-1">System:</label>{inspection?.system_name ? inspection?.system_name : 'None'}</li>
                                        <li><label className="mr-1">Building Info:</label>{inspection?.building_info ? inspection?.building_info : 'None'}</li>
                                        <li><label className="mr-1">Refrigent Type:</label>{inspection?.refrigerant_type ? inspection?.refrigerant_type : 'None'}</li>
                                        <li><label className="mr-1">Pkg Unit M #:</label>{inspection?.pkg_unit_m ? inspection?.pkg_unit_m : 'None'}</li>
                                        <li><label className="mr-1">S #:</label>{inspection?.pkg_unit_s ? inspection?.pkg_unit_s : 'None'}</li>
                                        <li><label className="mr-1">Coil M #:</label>{inspection?.coil_m ? inspection?.coil_m : 'None'}</li>
                                      </ul>
                                    </div>
                                    <div className="col-4">
                                      <ul className="p-0 list_style_none mt-3">
                                        <li><label className="mr-1">S #:</label>{inspection?.coil_s ? inspection?.coil_s : 'None'}</li>
                                        <li><label className="mr-1">Service area:</label>{inspection?.service_area ? inspection?.service_area : 'None'}</li>
                                        <li><label className="mr-1">Make:</label>{inspection?.make ? inspection?.make : 'None'}</li>
                                        <li><label className="mr-1">Condenser M #:</label>{inspection?.condenser_m ? inspection?.condenser_m : 'None'}</li>
                                        <li><label className="mr-1">S #:</label>{inspection?.condenser_s ? inspection?.condenser_s : 'None'}</li>
                                      </ul>
                                    </div>
                                    <div className="col-4">
                                      <ul className="p-0 list_style_none mt-3">
                                        <li><label className="mr-1">AH M #:</label>{inspection?.ah_m ? inspection?.ah_m : 'None'}</li>
                                        <li><label className="mr-1">S #:</label>{inspection?.ah_s ? inspection?.ah_s : 'None'}</li>
                                        <li><label className="mr-1">Age:</label>{inspection?.refrigerant_type ? inspection?.refrigerant_type : 'None'}</li>
                                        <li><label className="mr-1">Location:</label>{inspection?.refrigerant_type ? inspection?.refrigerant_type : 'None'}</li>
                                        <li><label className="mr-1">Location V H:</label>{inspection?.refrigerant_type ? inspection?.refrigerant_type : 'None'}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-3">
                            <div className="d-flex align-items-center justify-content-between">
                              <h6>Inspection Info</h6>
                              <Button className="h-30 py-0 fs-14  border-0  fnt-w-600"
                                onClick={(e: any) => {
                                  onReinspection(inspection?._id);
                                  e.preventDefault();
                                }}>
                                {inspection?.inspection_datas?.length > 0 ? 'Re-inspection' : 'Inspect'}
                              </Button>
                            </div>
                            {inspection?.inspection_datas.length > 0 &&
                              <div className="table-responsive">
                                <table className="table border mt-3">
                                  <thead className="gray_bg">
                                    <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">Inspected by</th>
                                      <th scope="col">Inspected Date</th>
                                      <th scope="col">Status</th>
                                      <th />
                                    </tr>
                                  </thead>
                                  {
                                    inspection?.inspection_datas?.sort((a: any, b: any) => (a?.order - b?.order))
                                      .map((item: any, index: number) => (
                                        <tbody className="" key={index}>
                                          <tr>
                                            <td>{index + 1}</td>
                                            <td>{item?.inspected_by?.name}</td>
                                            <td>{moment(item?.updatedAt).format('MM/DD/YYYY, HH:mm:ss')}</td>
                                            <td>{item?.is_completed === true ? 'Completed' : 'In Progress'}</td>
                                            <td>
                                              <div className="ins_edit_icn" onClick={(e: any) => { e.preventDefault(); editInspections(item?._id); }}>
                                                <span className="sr-only"></span>
                                                <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      ))
                                  }
                                </table>
                              </div>
                            }
                            {
                              inspection?.inspection_datas.length === 0 && (
                                <div className="no-result-container text-center">
                                  <p>{`No inspection Found for system ${inspection?.system_name}`}</p>
                                </div>
                              )
                            }
                          </div>
                        </td>
                      </tr>
                    </>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      }

      <EditCustomerInfoModal
        show={customerModalVisible}
        consumerDetails={selectedCustomer}
        onClose={closeCustomerEditModal}
        onEditCustomer={(data: any) => onEditCustomer(data)}
      />

      <EditSystemInfoModal
        show={systemModalVisible}
        systemDetails={selectedSystem}
        onClose={closeSystemEditModal}
        onEditSystem={(data: any) => onEditSystem(data)}
      />

      <ConfirmModal
        title="Delete inspection"
        message={`Are you sure want to delete this inspection `}
        closeConfirmModal={closeConfirmModal}
        delete={deleteInspection}
        isLoading={loading}
        isSuperAdmin={false}
      />

      {
        loading && <LoadingWheel />
      }

      {
        inspectionList?.length === 0 && !loading && (
          <div className="no-result-container text-center">
            <p>No Inspections Found</p>
          </div>
        )
      }
      <div className="px-2 mt-4">
        {
          Math.ceil(count / constants.maxLimit) > 1 &&
          <Pagination
            hideNavigation
            activePage={page}
            itemsCountPerPage={constants.maxLimit}
            totalItemsCount={count}
            itemClass={"item-class"}
            activeClass={"active-class"}
            pageRangeDisplayed={5}
            onChange={(pageNumber) => fetchInspectionChecklists(pageNumber, keyword, selectedOrgFilter?._id)}
          />
        }
      </div>
    </div>
  )
}

export default InspectionChecklist
