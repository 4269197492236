import React, { useState , useEffect } from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";
import logo from "../../assets/logo.png"
import './reminder-alert.css';
import toast from "../../components/toast";
import sensorService from '../../services/sensorService';
import constants from '../../config/constants';
import Routes  from '../Routes';
import authStore from '../../store/authStore';

export interface ReminderAlertProps extends RouteComponentProps {}

function ReminderAlert(props : ReminderAlertProps) {

    const [responseError, setResponseError] = useState('');
    const [formData ,setFormData]           = useState<any>({})
    const [errors, setErrors]               = useState<any>({})

    useEffect(() => {
        getSensorBoxDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getSensorBoxDetails() {  
      setResponseError('')
      try{
        let params : any   = props.match.params
        const response = await authStore.decodeJwt(params.token)
        setFormData((prevState :any )=> ({
          ...prevState,
          'sensor_box_name': response.sensor_box_name
        }));
      }
      catch(error){
        setResponseError('Invalid Token')}    
    }

    const updateFormValue = (e :any)=> {
      const { name, value} = e.target;
      setFormData((prevState :any )=> ({
          ...prevState,
          [name]: value
      }));
    };

    const validateField = () => {
      let error: any = {}

      if(!formData.offline_alert_interval) {
          error.offline_alert_interval = constants.message.offline_alert_interval_required
      }else if(formData.offline_alert_interval < 1){
          error.offline_alert_interval = constants.message.offline_alert_interval_notlessthan_one
      }else{
          error.offline_alert_interval = ''
      }

      setErrors(error)
      return !(error.offline_alert_interval)
  }

    async function setConfiguration() {
      try {
        if (validateField()){
          let params : any   = props.match.params
          await sensorService.setOfflineReminder({
            token : params.token, offline_alert_interval : formData?.offline_alert_interval
          })
          toast.success('Successfully configured interval');
          props.history.push(Routes.home);
        }
      } catch(error){
        setResponseError(error.response.data.message)
      }    
    }


    return (
      responseError ?

      <div className="error-wrap">
      <div className="error-center">{responseError}</div>
      </div> :

      <div className="d-flex align-items-center" style={{ minHeight: 'calc(100vh - 2.33rem)' }}>
        <div className="container col-12 col-lg-4 my-3">  
        <img src={logo} className="logo-img" alt="logo" />  
        <div className="container mt-3 reminder-card">   
          <div className="form-group row">
          <div className="col-md-12 text-center">
                <h4>Interval Configuration</h4>
              </div>
            <div className="col-sm-12 mt-4">
              <label className="custom-label">Offline alert  interval for {formData?.sensor_box_name} <span className="mand-star">*</span></label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter interval in hours"
                  value={formData.offline_alert_interval  ? formData.offline_alert_interval  : ''}
                  onChange={updateFormValue}
                  name="offline_alert_interval"
                  min={1}
                />
                { errors && 
                  <div className="error-msg">{errors && errors.offline_alert_interval ? errors.offline_alert_interval : ''}</div>
                }
            </div>
          </div>
            <button
              type="submit"
              onClick = {setConfiguration}
              className="btn btn-submit"
            > 
              <i className="fa fa-bell remindme-width" aria-hidden="true"></i>
              Remind Me
            </button>                     
        </div>
        </div>
      </div>
    );
  }
 
export default withRouter(ReminderAlert);
