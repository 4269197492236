import React, { useEffect, useState } from 'react';
import SelectComponent from '../select';
import toast from '../../components/toast';
import userService from '../../services/userService';
import CountryOptions from '../../config/country.json'
import { Button } from 'react-bootstrap';

interface Props { //props
  visibleUserFrom? : boolean;
  customerSelected? : boolean;
  isEditable? : boolean;
  consumerDetails? : any;
  errors? : any;
  clearFieldError : (name : any) => void
  toPrevPageFromCustomerForm : () => void
  createUser : (data : any) => void
}

const InspectionUserForm: React.FC<Props> = ({ isEditable ,clearFieldError , errors , createUser , visibleUserFrom , consumerDetails, toPrevPageFromCustomerForm}) => {

  const [costomerFormData ,setCustomerFormData] =useState<any>({})

  useEffect(() => {
    if(isEditable){
      setCustomerFormData(consumerDetails)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerDetails]);


  const updateFormValue = (e :any)=> {
    const { name, value} = e.target;
    clearFieldError(name)
    setCustomerFormData((prevState :any )=> ({
        ...prevState,
        [name]: value
    }));
    };

    const updateAddress = async (e: any) => {
        const { name, value } = e.target;
        clearFieldError(name)
        clearFieldError('address')
        setCustomerFormData((prevState :any )=> ({
            ...prevState,
            location : ''
        }));
        setCustomerFormData((prevState :any )=> ({
            ...prevState,
            [name]:value
        }));
  
        if(costomerFormData?.street && costomerFormData?.city && costomerFormData?.state && costomerFormData?.country && costomerFormData?.zip_code){
            try {
                let place = `${costomerFormData?.street} , ${costomerFormData?.state} , ${costomerFormData?.country} , ${costomerFormData?.zip_code}`
                const coordinates = await userService.getCoordinatesList(place)
                setCustomerFormData((prevState :any )=> ({
                    ...prevState,
                    location: { type: 'Point', coordinates }
                }));   
            } catch (error) {
                toast.error(error.response.data.message)
            }
        }
    }

  async function toNextTabFromCustomerInfo(e : any) {
    createUser(costomerFormData)
  }

  async function toPrevTabFromCustomerInfo(e : any) {
    toPrevPageFromCustomerForm()
  }

  const handleCountrySelection = (option: any) => {
      clearFieldError('country')
      clearFieldError('address')
      setCustomerFormData((prevState :any )=> ({
          ...prevState,
          'country': option._id,
          'state' : ''
      }));
  }

  const handleStateSelection = (option: any) => {
    clearFieldError('state')
    clearFieldError('address')
    setCustomerFormData((prevState :any )=> ({
        ...prevState,
        'state': option._id
    }));
  }

  const countryValue = CountryOptions.find((value:any)=>(value._id === costomerFormData?.country)) 
  const statesValue = CountryOptions.find((value:any)=>(value._id === costomerFormData?.country)) 
  const states = statesValue ? statesValue?.states : [];
  const stateValue = states.find((value:any)=>(value._id === costomerFormData?.state)) 

  return (
    <div className="row">
        { visibleUserFrom && 
        <>
              <div className="col-sm-6 mb-2 pr-sm-2">
                <label className="fs-12 ins_lable_clr">Enter Customer name <span className="mand-star">*</span></label>
                <input type="text"
                    className="form-control shadow-none"
                    name="name"
                    value={costomerFormData.name  ? costomerFormData.name  : ''}
                    onChange={updateFormValue}
                    placeholder="Enter name"
                    ></input>
                    <div className="error-msg">{errors && errors.name ? errors.name : ''}</div>
              </div>

              <div className="col-sm-6 mb-2 pl-sm-2">
                <label className="fs-12 ins_lable_clr">Email <span className="mand-star">*</span></label>
                <input type="email" 
                       className="form-control shadow-none"
                       name="email"
                       value={costomerFormData.email  ? costomerFormData.email  : ''}
                       onChange={updateFormValue}
                       placeholder="Enter email"
                       ></input>
                       <div className="error-msg">{errors && errors.email ? errors.email : ''}</div>
              </div>

              <div className="col-sm-6 mb-2 pr-sm-2">
                <label className="fs-12 ins_lable_clr">Phone <span className="mand-star">*</span></label>
                <input type="text"
                       className="form-control shadow-none"
                       name="phonenumber"
                       maxLength={15}
                       value={costomerFormData.phonenumber  ? costomerFormData.phonenumber  : ''}
                       onChange={updateFormValue}
                       placeholder="Enter phonenumber"
                       ></input>
                       <div className="error-msg">{errors && errors.phonenumber ? errors.phonenumber : ''}</div>
              </div>

              <div className="col-sm-6 mb-2 pl-sm-2">
                <label className="fs-12 ins_lable_clr">Address 1 <span className="mand-star">*</span></label>
                <input type="text"
                       className="form-control shadow-none"
                       name="street"
                       value={costomerFormData.street  ? costomerFormData.street  : ''}
                       onChange={updateAddress}
                       placeholder="Enter address 1"

                       ></input>
                       <div className="error-msg">{errors && errors.street ? errors.street : ''}</div>
              </div>

              <div className="col-sm-6 mb-2 pr-sm-2">
                <label className="fs-12 ins_lable_clr">Address 2 <span className="mand-star">*</span></label>
                <input type="text"
                       className="form-control shadow-none"
                       name="city"
                       value={costomerFormData.city  ? costomerFormData.city  : ''}
                       onChange={updateAddress}
                       placeholder="Enter address 2"
                       ></input>
                       <div className="error-msg">{errors && errors.city ? errors.city : ''}</div>
              </div>

              <div className="col-sm-6 mb-2 pl-sm-2">
                <label className="fs-12 ins_lable_clr">Country <span className="mand-star">*</span></label>
                <SelectComponent  name="country" 
                                  data = {CountryOptions} 
                                  value = {countryValue ? countryValue : ''}  
                                  onChange   = {handleCountrySelection}
                />
                <div className="error-msg">{errors && errors.country ? errors.country : ''}</div>
              </div>

              <div className="col-sm-6 mb-2 pr-sm-2">
                <label className="fs-12 ins_lable_clr">State <span className="mand-star">*</span></label>
                <SelectComponent  name="state" 
                                  data       = {states} 
                                  value      = {stateValue ? stateValue : ''}  
                                  onChange   = {handleStateSelection}
                />
                <div className="error-msg">{errors && errors.state ? errors.state : ''}</div>
              </div>

              <div className="col-sm-6 mb-2 pl-sm-2">
                <label className="fs-12 ins_lable_clr">Zip Code <span className="mand-star">*</span></label>
                <input type="text" 
                       className="form-control shadow-none"
                       name="zip_code"
                       maxLength={9}
                       value={costomerFormData.zip_code  ? costomerFormData.zip_code  : ''}
                       onChange={updateAddress}
                       placeholder="Enter zipcode"
                       > 
                </input>
              <div className="error-msg">{errors && errors.zip_code ? errors.zip_code : ''}</div>
              </div>

                {errors?.address &&
                        <div className="col-md-12">
                            <div className="form-group">
                                <div className="error-msg">{errors && errors.address ? errors.address : ''}</div>
                            </div>
                        </div>
                }

                </>
              }
              <div className="col-12 text-right mt-4">
                {visibleUserFrom && !isEditable &&
                <Button className="h-36 py-0 pl-4 pr-4 fs-14 ins_bg_clr border-0 font-weight-bold mr-3" 
                        onClick={(e : any)=> {toPrevTabFromCustomerInfo(e)}}
                        >Prev
                </Button>
                }

                <Button className="h-36 py-0 pl-4 pr-4 fs-14 ins_bg_clr border-0 font-weight-bold" 
                        onClick={(e : any)=> {toNextTabFromCustomerInfo(e)}}
                        >{ isEditable ? `Submit` : `Next`}
                </Button>
              </div>
    </div>
  )
}

export default InspectionUserForm;
