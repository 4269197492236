import React from 'react';
import validate from '../../helpers/validation';
import Api from '../../api';
import constants from '../../config/constants';
import LoadingWheel from '../loadingwheel';


export interface FeedbackProps {}
export interface FeedbackState {
    name    : string,
    email   : string,
    phone   : string,
    comment : string,
    isLoading: boolean,
    error?  : {
        email?         : string,
        phone?         : string,
        comment?       : string,
        name?          : string
    } 
}

export default class Feedback extends React.Component<FeedbackProps,FeedbackState> {

    error:any={};
    constructor(props: any){
        super(props);
        this.state = {
            name    : "",
            email   : "",
            phone   : "",
            comment : "" ,
            isLoading: false,
            error   : {
                email   : "",
                phone   : "",
                comment : "",
                name    : ""
            }
        }
    }

   
    validateEmail() {
     
        let errorEmail    =   '';
        let isValid  =   true
        if(validate.isEmpty(this.state.email)){
            errorEmail = constants.message.email_mandatory
            isValid     =  false
        }
        else{
            if(!validate.isEmail(this.state.email)){
                errorEmail = constants.message.email_valid
                isValid     =  false
            }
        }
        this.error.email=errorEmail;
        this.setState({error:this.error});
        return isValid
    }

    validatePhone() {
     
        let errorMessage    =   '';
        let isValid  =   true
        if(validate.isEmpty(this.state.phone)){
            errorMessage = constants.message.phoneNumber_mandatory
            isValid     =  false
        }
        else{
            if( !validate.isPhoneNumber( parseInt(this.state.phone) )){
                errorMessage = constants.message.phoneNumber_valid
                isValid     =  false
            }
        }
        this.error.phone=errorMessage;
        this.setState({error:this.error});
        return isValid
    }

    validateComment() {
        let errorMessage    =   '';
        let isValid  =   true
        if(validate.isEmpty(this.state.comment)){
            errorMessage = constants.message.comment_mandatory
            isValid     =  false
        }
        this.error.comment=errorMessage;
        this.setState({error:this.error});
        return isValid
    }

    validateName() {
        let errorMessage    =   '';
        let isValid  =   true
        if(validate.isEmpty(this.state.name) ){
            errorMessage = constants.message.name_mandatory
            isValid     =  false
        }
        else{
            if( !validate.isName(this.state.name) ){
                errorMessage = constants.message.name_valid
                isValid     =  false
            }
        }
        this.error.name=errorMessage;
        this.setState({error:this.error});
        return isValid
    }


    onNameChange( name : string ){
        this.setState( {name : name } )
    }

    onEmailChange( email : string ){
        this.setState( {email : email } )
    }

    onPhoneChange( phone : any ){
        if(validate.validatePhoneNumber(phone)) {
            this.setState( {phone : phone } )
        }
    }

    onCommentChange( comment : string ){
        this.setState( {comment : comment } )
    }

    async onSubmit(){
        let nameValid       = this.validateName()
        let phoneValid      = this.validatePhone()
        let emailValid      = this.validateEmail()
        let commentValid    = this.validateComment()
        if(phoneValid && emailValid && commentValid && nameValid ){
            this.setState( {isLoading:true} )
            try{
                await Api.post('/contactus',this.state)
                this.setState({isLoading:false})
            }catch{
                this.setState({isLoading:false})
            }
            this.setState( {name : "", email : "", phone : "", comment : "" } )
        }
    }

    render(){
        return(
            <div>
                <div className="row">
                <div className="col-md-12">
                <form className="form-wrapper">
                    <div className="row">
                    <div className="col-md-12">
                    <div className="error-msg contact-us"> {this.state.error && this.state.error.name ? this.state.error.name : ''} </div>
                    <input className="contact-ip"  type = "text"
                        placeholder = "Name"
                        maxLength={50}
                        value   = { this.state.name }
                        onBlur  = {()=>{this.validateName()}}
                        onChange = { (e) => this.onNameChange( e.target.value )  } 
                    />
                    </div>

                    <div className="col-md-12">
                    <div className="error-msg contact-us">{this.state.error && this.state.error.email ? this.state.error.email : ''}</div>
                    <input className="contact-ip" type = "text"
                        placeholder = "Email"
                        value       = { this.state.email }
                        maxLength={50}
                        onBlur      = { ()=>{this.validateEmail()} }
                        onChange    = { (e) => this.onEmailChange( e.target.value )  } 
                    />
                    </div>

                    <div className="col-md-12">
                        <div className="error-msg contact-us ">{this.state.error && this.state.error.phone ? this.state.error.phone : ''}</div>
                     <input className   ="contact-ip" type = "text"
                        placeholder     = "Phone"
                        value           = { this.state.phone }
                        maxLength       = {15}
                        onBlur          = { ()=>{this.validatePhone()} }
                        onChange        = { (e) => this.onPhoneChange( e.target.value )  } 
                    />
                    </div>

                    <div className="col-md-12">
                    <div className="error-msg contact-us"> {this.state.error && this.state.error.comment ? this.state.error.comment : ''} </div>
                    <textarea className ="contact-text-area"
                        placeholder     = "Comment"
                        value           = { this.state.comment }
                        maxLength       = {2000}
                        onBlur          = { () => { this.validateComment() } }
                        onChange        = { (e) => this.onCommentChange( e.target.value )  } 
                    ></textarea>
                    </div>
                    </div>
                </form>
                </div>

                <div className="col-md-12">
                <button className="submit-btn-contact"
                type = "button"
                onClick = { () => { this.onSubmit() } }
                >
                    SUBMIT
                </button>
                </div>
                </div>
                {this.state.isLoading ?
                    <LoadingWheel/>
                    :
                    null
                }
            </div>
        )
    }
}
