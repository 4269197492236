import React, { memo } from 'react';
import { Row, Col, Typography, Skeleton, Image } from 'antd';
import moment from 'moment';
import constants from '../../config/constants';
import authStore from '../../store/authStore'

const { Text } = Typography;

interface Props {
    issueDetails?: any,
    imageList: any[],
    skeletonOn: boolean,
    imageCreatedBy: any[],
    imageNotes: any[],
    userType: any[]
}

const IssueTrackerDiagnosticCard: React.FC<Props> = memo(({ issueDetails, imageList, skeletonOn, imageCreatedBy, imageNotes, userType }) => {

    return (
        <>
                <Row>
                    <Col span={24}>
                        <div className='W-100'>
                            <p style={{ fontSize: "14px", fontWeight: 5 }} className='mb-4'>
                                Diagnosis Details
                            </p>
                            <div className={userType?.includes(constants.role['Field Technician']) ? 'mb-4' : 'mb-2'}>
                            {!skeletonOn ?
                            (imageList?.length > 0 &&
                            imageList?.map((row, rowIndex) => (
                              ((userType[rowIndex] === constants.role['Field Technician']) &&
                                <div key={rowIndex} style={{ marginBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 5 }}>
                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                                    <div className="avatar" style={{ marginRight: 25, fontSize: 15 }}>
                                        {imageCreatedBy[rowIndex]?.createdBy ? (imageCreatedBy[rowIndex]?.createdBy?.charAt(0) + imageCreatedBy[rowIndex]?.createdBy?.slice(-1))?.toUpperCase() : 'U'}
                                    </div>
                                    <div>
                                        <p></p>
                                        <p style={{ fontSize: 12 }}>
                                        Uploaded on {moment(imageCreatedBy[rowIndex]?.createdAt).format("DD-MMMM-YYYY - HH:mm:ss") !==
                                            "Invalid Date"
                                            ?  moment(imageCreatedBy[rowIndex]?.createdAt).format("DD-MMMM-YYYY - HH:mm:ss")
                                            : "-"}  
                                            {imageCreatedBy[rowIndex]?.createdBy && (imageCreatedBy[rowIndex]?.createdBy !== authStore.currentUser?.name) ? ` by ${imageCreatedBy[rowIndex]?.createdBy} ` : ''}
                                        </p>
                                    </div>
                                    </div>
                                </div>
                                <div>
                                    <p style={{ maxWidth: '550px', overflowWrap: 'break-word', marginLeft: 64, fontSize: 11 }}>{imageNotes[rowIndex]}</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 70 }}>
                                    {row?.map((image: any, colIndex: any) => (
                                    <div key={colIndex} style={{ marginRight: 10 }}>
                                        <Image src={image} style={{ height: 120, padding: 20 }} />
                                    </div>
                                    ))}
                                </div>
                                </div>
                              )
                            ))
                            )
                           :
                            <Col>
                            <Skeleton.Avatar active className="mr-3" />
                            <Skeleton.Input active style={{ width: 250, maxWidth: 250, minWidth: 200 }} className="mr-1 mb-1" />
                            <Skeleton.Input active style={{ width: 250, maxWidth: 250, minWidth: 200 }} />
                            </Col>
                            }
                            </div>
                            <div className='ml-sm-5 p-3'>
                            <p className="paragraph">
                            <p style={{ fontSize: "13px", fontWeight: 5 }}>
                                Diagnosis
                            </p>
                                    <Text className="mr-1 mb-0">
                                        {issueDetails?.diagnosis
                                            ? issueDetails?.diagnosis
                                            : ``}
                                    </Text>
                            </p>
                            <p className="paragraph">
                            <p style={{ fontSize: "13px", fontWeight: 5 }}>
                                Overall Summary
                            </p>
                                    <Text className="mr-1 mb-0">
                                        {issueDetails?.overall_summary
                                            ? issueDetails?.overall_summary
                                            : ``}
                                    </Text>
                            </p>
                            </div>
                        </div>
                    </Col>
                </Row>

        </>
    );
})
export default IssueTrackerDiagnosticCard;
