/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Input, Pagination, Spin, Empty, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import Map from '../../pages/dashboard/map'
import Routes from '../../routing/routes'
import userService from '../../services/userService'
import authStore from '../../store/authStore';
import organizationService from '../../services/organizationService';
import SystemCards from '../../components_new/system-cards';
import OverviewCard from '../../components_new/overview-card'
import CountsCard from '../../components_new/counts-card'
import SnoozeModal from '../../components_new/snooze-modal';

import constants from '../../config/constants';
import {deviceExamplesForDemo} from "../../pages/sensorData";

const { Search } = Input;
const { Option } = Select;

const deploymentStatusType = [
  { _id: 'commissioned', name: 'Commissioned' },
  { _id: 'decommissioned', name: 'Decommissioned' },
  { _id: 'tested', name: 'Tested' },
  { _id: 'testing', name: 'Testing' },
  { _id: '', name: 'All' }
]

let timerId: any;

function Dashboard() {

  let history = useHistory();
  let dropDownCondition = authStore?.currentUser?.active_role?.role_type === 0 || (authStore?.currentUser?.active_role?.role_type === 1 && authStore.currentUser.is_default);
  const [alertList, setAlertList] = useState<any>([]);
  const [systemOverview, setSystemOverview] = useState<any>([]);
  const [centerCoord, setCenterCoord] = useState<any>([]);
  const [mapDatas, setMapDatas] = useState<any>([]);
  const alertRef = useRef(alertList);
  alertRef.current = alertList;
  const [loading, setLoading] = useState(true);
  const [searchFilter, setSearchFilter] = useState('')
  const [selectedFilter, setSelectedFilter] = useState('') //org
  const [selectedDeploymentStatus, setSelectedDeploymentStatus] = useState((dropDownCondition) ? 'commissioned' : null) //org
  const [organizationFilterData, setOrganizationFilterData] = useState([]) //org
  const [totalCount, setTotalCount] = useState(0)
  const [snoozeModalVisible, setSnoozeModalVisible] = useState(false)
  const [page, setPage] = useState(1);

  useEffect(() => {
    // note : temp solution for the org admin access to all org
    if (dropDownCondition) {
      getOrganizations()
    } else {
      fetchDashboard()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const renameForDemoAccount = (list:any)=>{
    list.forEach((device:any,index:number)=>{
      // @ts-ignore
      for (const [key, value] of deviceExamplesForDemo.entries()) {
        if(device.group){
          if(device.name.toLowerCase().includes('rtu'))
            device.name = 'Group with RTU';
          else if(device.name.toLowerCase().includes('chiller'))
            device.name = 'Group with Chiller';
          else if(device.name.toLowerCase().includes('heater'))
            device.name = 'Group with Heater';
          else if(device.name.toLowerCase().includes('indoor'))
            device.name = 'Group Indoor';
          else if(device.name.toLowerCase().includes('outdoor'))
            device.name = 'Group Outdoor';
          else if(device.name.toLowerCase().includes('mri'))
            device.name = 'Group with MRI';
          else if(device.name.toLowerCase().includes('cooler'))
            device.name = 'Group with Cooler';
          else if(device.name.toLowerCase().includes('freezer'))
            device.name = 'Group with Freezer';
          else if(device.name.toLowerCase().includes('oxygen'))
            device.name = 'Group with O2 Generator';
          else device.name = 'remove'
        }else{
          device['masked_name'] = 'remove';
          if(device.alias?.toLowerCase().includes('rtu'))
            device.masked_name = 'RTU';
          else if(device.alias?.toLowerCase().includes('chiller'))
            device.masked_name = 'Chiller';
          else if(device.alias?.toLowerCase().includes('heater'))
            device.masked_name = 'Heater';
          else if(device.alias?.toLowerCase().includes('indoor'))
            device.masked_name = 'Indoor';
          else if(device.alias?.toLowerCase().includes('outdoor'))
            device.masked_name = 'Outdoor';
          else if(device.alias?.toLowerCase().includes('mri'))
            device.masked_name = 'MRI';
          else if(device.alias?.toLowerCase().includes('cooler'))
            device.masked_name = 'Cooler';
          else if(device.alias?.toLowerCase().includes('freezer'))
            device.masked_name = 'Freezer';
          else if(device.alias?.toLowerCase().includes('oxygen'))
            device.masked_name = 'O2 Generator';

        }
        if(value.includes(device._id)) {
          if(device.group){
            device.name = key;
          }else {
            device.masked_name = key;
          }
          break;
        }
      }
    })
    return list
  }
  const fetchDashboard = async (pageNumber: number = 0, searchKeyword: string = '', orgid?: any, deployment_status? : any) => {

    pageNumber = pageNumber === 0 ? page : pageNumber;

    try {
      setLoading(true);
      const promises = [
        userService.getSensorBoxesForMaps(orgid, deployment_status).then((mapDatas) => {
          setMapDatas(mapDatas?.result);
        }),
        userService.getSensorBoxes(pageNumber,15,searchKeyword,"group","desc", orgid,true,deployment_status)
          .then((alertList) => {
            const demoCheck = (authStore?.currentUser?.active_role?.role_type === -1);
            if(demoCheck){
              alertList.result = renameForDemoAccount(alertList?.result).filter((x:any)=>x.name!=='remove').filter((x:any)=>x.masked_name!=='remove')
            }
            setAlertList(alertList?.result);
            setTotalCount(alertList?.count);
          }),
        userService.getSystemOverview(orgid, deployment_status).then((systemOverview) => {
          setSystemOverview(systemOverview);
        }),
      ];
      Promise.all(promises).then(() => {
        setLoading(false);
        setPage(pageNumber);
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const getOrganizations = async () => {

    try {
      const response = await organizationService.getOrganizations()
      setSelectedFilter(response.data.organizations[0]._id)
      fetchDashboard(page, searchFilter, response.data.organizations[0]._id, selectedDeploymentStatus)
      setOrganizationFilterData(response.data.organizations);
    } catch (error) {
      setLoading(false)
    }
  }

  const searchDebounce = React.useCallback(_.debounce(fetchDashboard, 700), [])

  const handleSearchFilter = (e: any) => {
    const { value } = e.target;
    setSearchFilter(value);
    searchDebounce(1, value, selectedFilter, selectedDeploymentStatus)
  };

  const onPageChange = (page: any) => {
    fetchDashboard(page, searchFilter, selectedFilter, selectedDeploymentStatus)
  };

  const resetCoords = () => {
    // setCenterCoord([undefined, undefined])
  }

  const setCenterCoordOnMouseHover = (item: any) => {

    if (timerId) {
      clearTimeout(timerId)
    }

    let coords: Array<any> = []

    if (item.group && item.sensorboxes[0]) {
      coords = item.sensorboxes[0].location?.coordinates
    } else {
      coords = item.location?.coordinates
    }

    timerId = setTimeout(() => {
      setCenterCoord(coords)
      clearTimeout(timerId)
    }, 1000);

  }

  const onClickCard = (item: any) => {
    let params = `${Routes.systems}?system_id=${item?._id}`
    if (dropDownCondition) {
      params += `&org_id=${selectedFilter}`
    }
    history.push(params)
  }

  const onClickSnooze = () => {
    setSnoozeModalVisible(true)
  }

  const onClickStatus = (data: any) => {
    let params = `${Routes.systems}?status=${data?.statusNumber}`
    if (dropDownCondition) {
      params += `&org_id=${selectedFilter}`
    }
    
    if(selectedDeploymentStatus){
      params += `&deployment_status=${selectedDeploymentStatus}`
    }

    history.push(params)
  }

  const onClose = () => {
    setSnoozeModalVisible(false)
  }

  const systemCounts = systemOverview?.systemCounts?.map((system: any) => {
    const systems = {
      status: constants.statusColor.get(String(system?.status)),
      count: system.count
    }
    return systems
  })

  const systemCountsForOverview = systemOverview?.systemCounts?.map((system: any) => {
    const systems = {
      statusNumber: system?.status,
      status: constants.statusColorForOverview.get(String(system?.status)),
      count: system.count
    }
    return systems
  })

  const colorLabelsForDougnut = systemCounts?.map((obj: any) => {
    return obj?.status
  })

  const colorCountForDoughnut = systemCounts?.map((obj: any) => {
    return obj?.count
  })

  const handleFilterSelection = (option: any) => {
    setSelectedFilter(option)
    fetchDashboard(1, searchFilter, option, selectedDeploymentStatus)
  }

  const handleDeploymentStatusSelection = (option: any) => {
    setSelectedDeploymentStatus(option)
    fetchDashboard(1, searchFilter, selectedFilter, option)
  }

  const greenStatusCount = systemCounts?.find((count: any) => count?.status === 'green')
  const redStatusCount = systemCounts?.find((count: any) => count?.status === 'red')
  const yelowStatusCount = systemCounts?.find((count: any) => count?.status === 'yellow')

  return (
    <div className="p-0">
      <Spin spinning={loading}>
        <div className="d-flex flex-column h-100 overflow-hidden p-0">
          <div className="flex-fill h-100 overflow-hidden dashboard-padding">
            <div className="d-flex">
              <div className="flex-fill">
                <p className="page-title text-left">Dashboard</p>
              </div>
              {
                (dropDownCondition) &&
                <div className="d-flex pb-3 justify-content-end mr-4">
                  <Select value={selectedFilter} defaultValue={selectedFilter} onChange={handleFilterSelection} style={{ minWidth: "220px" }}>
                    {
                      organizationFilterData &&
                      organizationFilterData.map((data: any) => {
                        return (
                          <Option value={data._id}>{data.name}</Option>
                        );
                      })
                    }
                  </Select>
                </div>
                
              }
              {(dropDownCondition) &&
              <div className="d-flex pb-3 ">
                  <Select value={selectedDeploymentStatus} defaultValue={selectedDeploymentStatus} onChange={handleDeploymentStatusSelection} style={{ minWidth: "220px" }}>
                    {
                      deploymentStatusType &&
                      deploymentStatusType.map((data: any) => {
                        return (
                          <Option value={data._id}>{data.name}</Option>
                        );
                      })
                    }
                  </Select>
                </div>
                }
            </div>
            <div className="d-lg-flex">
              <div className="flex-custom-fill mr-md-2">
                <div className="d-flex flex-column h-100">
                  <div className="flex-fill mb-3">
                    <OverviewCard
                      colorLabelsForDougnut={colorLabelsForDougnut}
                      colorCountForDoughnut={colorCountForDoughnut}
                      redStatusCount={redStatusCount}
                      yelowStatusCount={yelowStatusCount}
                      greenStatusCount={greenStatusCount}
                      systemCountsForOverview={systemCountsForOverview}
                      systemOverview={systemOverview}
                      onClickStatus={(data: any) => onClickStatus(data)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-custom-fill ml-md-2 mb-3">
                <Map sensorBoxList={mapDatas} centerCoord={centerCoord} resetCoords={resetCoords} />
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row align-items-sm-center mt-3">
              <div className="flex-fill">
                <h6 className="page-title text-left">Alerts({totalCount})</h6>
              </div>
              <div className="flex-auto mb-3">
                <Search
                  placeholder="Search"
                  value={searchFilter || ""}
                  size="large"
                  onChange={handleSearchFilter}
                  allowClear
                  enterButton />
              </div>
            </div>

            <Row gutter={{ xs: 8, sm: 16 }}>
              {alertList?.length > 0 ?
                alertList?.map((alert: any, index: number) => {
                  return (
                    <Col className="gutter-row mb-3" xs={24} lg={12} xl={12} xxl={8}>
                      <SystemCards systemData={alert}
                        index={index}
                        setCenterCoordOnMouseHover={(data: any) => setCenterCoordOnMouseHover(data)}
                        onClickCard={(data: any) => onClickCard(data)}
                        onClickSnooze={() => onClickSnooze()}
                      />
                    </Col>
                  )
                })
                :
                <Empty />
              }
            </Row>
            {Math.ceil(totalCount / 15) > 1 &&
              <Pagination
                defaultCurrent={1}
                current={page}
                total={totalCount}
                pageSize={15}
                responsive={true}
                className="float-right"
                showSizeChanger={false}
                onChange={onPageChange} />
            }
          </div>
          {/* {loading ? <Spin /> : null} */}

        </div>
      </Spin>

      <SnoozeModal
        title="Snooze Notification"
        isVisible={snoozeModalVisible}
        onClose={onClose}
      />
    </div>
  );
}
export default Dashboard;
