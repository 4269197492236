import React from 'react';
import TroubleshootItems from '../../components/troubleshoot-items/index'

interface Props { //props
  groupList: any,
  loading : boolean
}

const TroubleshootDashboard: React.FC<Props> = ({ groupList , loading }) => {

    return (
        
        <div className="padding-b50" id="groups">
        {!loading &&
            (groupList.length > 0) ?
                groupList?.map((groups :any) => {
                    return( 
                        <TroubleshootItems
                            groups = {groups}
                        />
                    )
                })
                :
                <div className="text-center w-100 no-card-ph">
                    <div className="no-result-container">
                        <p>No Data</p>
                    </div>
                </div>
        }
            </div>
    )
}

export default TroubleshootDashboard;
