import React from "react";
import { Checkbox, Table} from 'antd';
const columns = [

  {
    title: 'Cooling/Heating Pump',
    dataIndex: 'name',
    key: 'name',
    render: (status : any) => {
        return(
          <Checkbox>Duct Cleaning</Checkbox>
        )
      },
  },
  {
    title: 'Technician',
    dataIndex: 'technician',
    key: 'technician',
  },
  {
    title: 'Service done date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Comment',
    key: 'comment',
    dataIndex: 'comment',
    
  },
];

const data = [
  {
    key: '1',
    name: '	Duct Cleaning',
    technician: 'William John',
    date: '24th Jan 2022',
    comment: 'Issue fixed',
  },
  {
    key: '2',
    name: 'Duct Cleaning',
    technician: 'William John',
    date: '24th Jan 2022',
    comment: 'Issue fixed',
  },
];
function Maintenance() {
  return (
    <>
      <Table className="system-table" columns={columns} dataSource={data} pagination={false} />
    </>
  );
}
export default Maintenance;
