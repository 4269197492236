import {User} from '../interface'

class UserStore {
     profileDetials? : User 

     constructor(){
         this.profileDetials = undefined
     }

}

const userStore = new UserStore()
export default userStore