import React from 'react';
import { Table } from 'antd';

interface Props {
    data?: any,
    columns?: any,
    onSelectRow: (record: any, rowIndex: any, event: any) => void
}

const table: React.FC<Props> = ({ data, columns, onSelectRow }) => {

    const onRow = (record: any, rowIndex: any) => {
        return {
            onClick: (event: any) => {
                onSelectRow(record, rowIndex, event)
            },
        };
    };

    return (
        <>
            <Table
                onRow={onRow}
                columns={columns}
                dataSource={data}
                pagination={false}
            />
        </>
    );
}
export default table;
