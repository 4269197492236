import React, { useEffect, useState } from 'react';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import moment from 'moment';

import maintenaceService from '../../services/maintenaceService';

interface Props {
  show: boolean;
  onClose: () => void;
  sensorBoxName?: string;
  sensorBox: any
}

const MaintenanceModal: React.FC<Props> = ({ show, sensorBoxName, sensorBox, onClose }) => {

const [history, setHistory] = useState<Array<any>>([]);
const [maintenanceList, setMaintenanceList] = useState<Array<any>>([])

  const getMaintenaceHistory = async () => {
    try {
      const response = await maintenaceService.getMaintenanceHistory(sensorBox?._id);
      const { history } = response.data;
      setHistory(history);
    } catch (error) {
      
    }
  }
  const getMaintenanceList = async () => {
    try {
      const response = await maintenaceService.getSensorboxMaintenanceList(sensorBox?._id);
      const { checklists } = response.data;
      setMaintenanceList(checklists);
    } catch (error) {
      
    }
  }
  useEffect(()=> {
    if(sensorBox?._id) {
      getMaintenaceHistory();
      getMaintenanceList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorBox]);


  return (
    <Modal
      show={show}
      onHide={onClose}
      size="lg"
    >
      <Modal.Header closeButton>
      <div>{sensorBoxName} {sensorBox.alias && <span className='pl-2'>( {sensorBox.alias} )</span> }</div>
      </Modal.Header>
      <Modal.Body>
        <Tabs variant="pills" className="checklist-tab mb-3">
          <Tab eventKey="history" title="History">
            {
              history?.length === 0 ?
              <p className="my-5 font-weight-bold text-secondary text-center">No history</p> :
              <table className="table sensor-list" style={{ tableLayout: 'fixed' }}>
                <thead className="table-header-bg">
                    <tr>
                      <th style={{ width: '5%' }} className="text-custom-size" scope="col">#</th>
                      <th style={{ width: '15%' }} className="text-custom-size custom-width no-wrap" scope="col">Category</th>
                      <th style={{ width: '22%' }} className="text-custom-size" scope="col">Maintenance</th>
                      <th style={{ width: '14%' }} className="text-custom-size" scope="col">Technician</th>
                      <th style={{ width: '18%' }} className="text-custom-size" scope="col">Service Done Date</th>
                      <th style={{ width: '26%' }} className="text-custom-size" scope="col">Comment</th>
                    </tr>
                </thead>
                <tbody>
                  {
                    history?.map((h:any, index: number) => (
                      <tr className={index%2 !== 0 ? "background-color" : ""}>
                        <td className="text-custom-size">{index+1}</td>
                        <td className="text-custom-size text-truncate">{h?.check_list_id?.check_list_category}</td>
                        <td className="text-custom-size text-truncate ">{h?.check_list_id?.check_list_item}</td>
                        <td className="text-custom-size text-truncate" title={h?.action_by?.name}>{h?.action_by?.name}</td>
                        <td className="text-custom-size">{moment(new Date(h?.last_service_done_date)).format('MM/DD/YYYY')}</td>
                        <td className="text-custom-size text-truncate" title={h?.comment}>{h?.comment}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            }
          </Tab>
          <Tab eventKey="checklist" title="Checklist">
            {
              (maintenanceList?.length === 0) ?
              <p className="my-5 font-weight-bold text-secondary text-center">No maintenance activities</p> :
              <table className="table sensor-list" style={{ tableLayout: 'fixed' }}>
                <thead className="table-header-bg">
                  <tr>
                    <th style={{ width: '3%' }} className="text-custom-size" scope="col">#</th>
                    <th style={{ width: '15%' }} className="text-custom-size custom-width no-wrap" scope="col">Category</th>
                    <th style={{ width: '15%' }} className="text-custom-size" scope="col">Maintenance</th>
                    <th style={{ width: '15%' }} className="text-custom-size" scope="col">Service Done Date</th>
                    <th style={{ width: '18%' }} className="text-custom-size" scope="col">Next Maintenance Date</th>
                    <th style={{ width: '10%' }} className="text-custom-size" scope="col">Due Status</th>
                    <th style={{ width: '24%' }} className="text-custom-size" scope="col">comment</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    maintenanceList?.map((cheklist: any, index: number) => {
                      let diff = moment(cheklist?.next_maintenance_date).diff(moment(), 'days')
                      return (
                        <tr className={[index%2 !== 0 ? "background-color" : "", diff < 0 ? 'text-danger': ''].join(' ')}>
                          <td className="text-custom-size">{index+1}</td>
                          <td className="text-custom-size text-truncate">{cheklist?.check_list_id?.check_list_category}</td>
                          <td className="text-custom-size text-truncate">{cheklist?.check_list_id?.check_list_item}</td>
                          <td className="text-custom-size">
                            { 
                              cheklist?.last_service_done_date ?
                              moment(cheklist?.last_service_done_date).format('MM/DD/YYYY') :
                              '-'
                            }
                          </td>
                          <td className="text-custom-size">{moment(cheklist?.next_maintenance_date).format('MM/DD/YYYY')}</td>
                          <td className="text-truncate text-custom-size">
                            {
                              diff === 0 ? <span>Due Today</span> : diff > 0 ?
                              <span>Due in {diff} days</span> :
                              <span>{Math.abs(diff)} Days Overdue</span>
                            }
                          </td>
                          <td className="text-custom-size text-truncate" title={cheklist?.comment}>{cheklist?.comment}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            }
          </Tab>
        </Tabs>
       
      </Modal.Body>
    </Modal>
  )
}

export default MaintenanceModal;
