import React, { memo, useState } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  Select,
  message,
  Spin,
  Divider,
  Tag,
  Image,
  Skeleton,
} from "antd";
import { EditOutlined, UserOutlined, CaretDownFilled } from "@ant-design/icons";
import Utils from "../../helpers/Utility";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Routes from "../../routing/routes";
import issueTrackerServices from "../../services/issueTrackerServices";
import authStore from "../../store/authStore";
import constants from "../../config/constants";
import Permissions from "../../config/permssions";
import ConfirmationModal from "../confirmation-modal";
import IssueTrackerDiagnosticCard from "../issue-tracker-diagnostic-card";
import IssueTrackerDiagnosticDetails from "../issue-tracker-diagnostic-details";

const { Text } = Typography;

interface Props {
  issueDetails?: any;
  onEditTicket: () => void;
  contractorList: any[];
  fetchIssueDetailsWithId: () => void
  imageList: any[],
  skeletonOn: boolean
  imageNotes: any[],
  imageCreatedBy: any[],
  ticket_id: any,
  onClickCancelTicket: () => void,
  onIgnoreTicket: () => void,
  onResolveTicket: (values: any, action: any) => void
  onCreateSubTicket: () => void,
  technicianUnderContractor: any[],
  userType: any[]
}

const IssueTrackerDetailsCard: React.FC<Props> = memo(({
  issueDetails,
  onEditTicket,
  contractorList,
  fetchIssueDetailsWithId,
  imageList,
  skeletonOn,
  imageNotes,
  imageCreatedBy,
  ticket_id,
  onClickCancelTicket,
  onIgnoreTicket,
  onResolveTicket,
  onCreateSubTicket,
  technicianUnderContractor,
  userType
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [selectedTechnician, setSelectedTechnician] = useState(
    issueDetails?.assigned_to
  );
  const [selectedContractor, setSelectedContractor] = useState(
    issueDetails?.contractor
  );
  let created_on =
    moment(issueDetails?.created_on).format("MMM-DD-YYYY - HH:mm:ss") !==
      "Invalid Date"
      ? moment(issueDetails?.created_on).format("MMM-DD-YYYY - HH:mm:ss")
      : "-";


  const handleEditTicket = () => {
    onEditTicket();
  };

  const onSelectAssignee = async (selectedValue: any) => {
    if (selectedValue === constants.addAssigneeField || selectedValue === constants.addNewContractor) {
      history.push({
        pathname: `${Routes.users}`,
        state: {
          shouldBeVisible: true,
        },
      });
    }
    if (
      authStore?.currentUser?.active_role?.role_type === constants.role.Admin
    ) {
      setSelectedContractor(selectedValue);
      if (selectedValue !== issueDetails.contractor?._id) {
       setIsVisible(true)
      }
    } else {
      setSelectedTechnician(selectedValue);
      if (selectedValue !== issueDetails.assigned_to) {
       setIsVisible(true)
      }
    }
  };

  const onCancelAssign = () => {
    setSelectedContractor(issueDetails?.contractor);
    setSelectedTechnician(issueDetails?.assigned_to);
  }

  const handleAssignTicket = async () => {
    try {
      if (selectedTechnician && authStore?.currentUser?.active_role?.role_type === constants.role.Contractor) {
        setIsLoading(true);
        issueDetails.issue_assigned_to = selectedTechnician;
        const assignedToAlreadyExist =
          issueDetails?.assigned_to &&
          Utils.getObjectById(
            issueDetails?.assigned_to,
            technicianUnderContractor
          )?._id === issueDetails?.assigned_to;
        await issueTrackerServices.updateIssueTrackerDetails(
          issueDetails?._id,
          issueDetails
        );
        const newIssueDetails =
          await issueTrackerServices.IssueTrackerDetailsById(issueDetails?._id);
        issueDetails.assigned_to = newIssueDetails?.issue?.assigned_to;
        message.success(
          `Successfully ${assignedToAlreadyExist ? "re" : ""
          } assigned the ticket ${assignedToAlreadyExist ? "to" : ""} ${assignedToAlreadyExist
            ? Utils.getObjectById(
              issueDetails?.assigned_to,
              technicianUnderContractor
            )?.name
            : ""
          }`
        );
        setIsLoading(false);
      } else {
        setIsLoading(true);
        issueDetails.contractor = selectedContractor
        await issueTrackerServices.updateIssueTrackerDetails(
          issueDetails?._id,
          issueDetails
        );
        const newIssueDetails =
          await issueTrackerServices.IssueTrackerDetailsById(issueDetails?._id);
        issueDetails.contractor = newIssueDetails?.issue?.contractor?._id;
        message.success(
          `Successfully assigned the ticket`
        );
      }
      fetchIssueDetailsWithId()
      setIsLoading(false);
    } catch (error: any) {
      const errorData = error?.response?.data
        const isUnauthorizedIssue = Utils.issueTrackerErrorHandler(errorData, constants.responseCode.unAuthorized, constants.message.issue_allocation_changed, constants.message.issue_doesnot_exist)
        if (isUnauthorizedIssue) {
            message.destroy()
            await message.error(error?.response?.data?.message)
            history.push({pathname : Routes.issuetracker});
            setIsLoading(false)
        } else {
            message.destroy()
            message.error(error?.response?.data?.message || constants.message.internalServerError)
            setIsLoading(false)
        }
    }
  };

  const system_name = issueDetails.group_id?.name
    ? `${issueDetails?.group_id?.name} / ${issueDetails?.sensor_box?.alias
      ? issueDetails?.sensor_box?.alias
      : issueDetails?.sensor_box?.sensor_box_name
    }`
    : issueDetails?.sensor_box?.alias
      ? issueDetails?.sensor_box?.alias
      : issueDetails?.sensor_box?.sensor_box_name;

  const AssigneeField = (
    assigned_to: any,
    placeholder: string,
    selectedValue: string,
    subordinates: any[],
    assigneeAddNew: string
  ) => {
    return (
      <Select
        style={{ width: "100%", maxWidth: "100%" }}
        className="asgnFieldSlct mt-1"
        placeholder={placeholder}
        value={selectedValue ? selectedValue : subordinates?.find(element => element?._id === assigned_to) ? assigned_to : selectedValue}
        onSelect={(e) => onSelectAssignee(e)}
        suffixIcon={<CaretDownFilled />}
      >
        {subordinates?.length &&
          subordinates?.map((elements: any) => (
            <Select.Option value={elements?._id} key={elements?._id}>
              {elements?.name}
            </Select.Option>
          ))}
        <Select.Option value={constants.addAssigneeField}>
          <UserOutlined /> {assigneeAddNew}
        </Select.Option>
      </Select>
    );
  };


  return (
    <>
      <Col span={18}>
        <Spin spinning={isLoading}>
          <Card
            className="custom-card online-device border-0"
          >
            <div className="d-flex justify-content-between font-w-600 mr-1">
                <p className="m-0" style={{ fontSize: "17px" }}>
                  {issueDetails?.issue_id}  -  {issueDetails?.issue_title}
                </p>
              </div>
              <Divider type="horizontal" className="mt-4 mb-3"></Divider>
            <Row gutter={[5, 5]}>
            <div className="mt-1">
              <p style={{ fontSize: "14px", fontWeight: 5 }}>
                System
              </p>
              <div className="paragraph">
                <div className="d-flex align-items-center">
                  <Text className="mr-1 mb-0" style={{fontSize: '14px', fontWeight: 500}}>{system_name}</Text>
                  <div
                    className={`small-circle-alert ml-1 ${Utils.getStatus(
                      issueDetails?.alert_status
                    )}`}
                  ></div>
                </div>
              </div>
            </div>
              <Divider type="vertical" style={{ height: 75 }} className="ml-4 mr-4 mt-1" ></Divider>
              <div className="mt-1 ml-2">
                <p style={{ fontSize: "14px", fontWeight: 5 }}>
                  Diagnostic Code
                </p>
                <p className="paragraph">
                    <Text className="mr-1 mb-0">
                      {issueDetails?.alert_code
                        ? issueDetails?.alert_code
                        : "N/A"}
                    </Text>
                </p>
              </div>
              <Divider type="horizontal" className="mt-1 mb-3"></Divider>
              <div>
                <p style={{ fontSize: "14px", fontWeight: 5 }} className={issueDetails?.issue_description ? "mb-3" : "mb-0"}>
                  Description
                </p>
                <p className="paragraph">
                    <Text className="mr-1 mb-0">
                      {issueDetails?.issue_description
                        ? issueDetails?.issue_description
                        : ``}
                    </Text>
                </p>
              </div>
              <Divider type="horizontal" className="mt-1 mb-3"></Divider>
              <div className="mr-5">
                <p style={{ fontSize: "14px", fontWeight: 5 }} className={imageList?.length > 0 ? "mb-4" : "mb-5"}>
                  Attachments ({(userType?.filter((userRoles) => userRoles !== constants.role['Field Technician'] && userRoles !== -1))?.length})
                </p>

                {!skeletonOn ?
                (imageList?.length > 0 &&
                  imageList?.map((row, rowIndex) => (
                    ((userType[rowIndex] === constants.role.Admin || userType[rowIndex] === constants.role.Contractor) &&
                    <div key={rowIndex} style={{ marginBottom: 10 }}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 5 }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                          <div className="avatar" style={{ marginRight: 25, fontSize: 15 }}>
                            {imageCreatedBy[rowIndex]?.createdBy ? (imageCreatedBy[rowIndex]?.createdBy?.charAt(0) + imageCreatedBy[rowIndex]?.createdBy?.slice(-1))?.toUpperCase() : 'U'}
                          </div>
                          <div>
                            <p></p>
                            <p style={{ fontSize: 12 }}>
                              Uploaded on {moment(imageCreatedBy[rowIndex]?.createdAt).format("DD-MMMM-YYYY - HH:mm:ss") !==
                                "Invalid Date"
                                ?  moment(imageCreatedBy[rowIndex]?.createdAt).format("DD-MMMM-YYYY - HH:mm:ss")
                                : "-"}  
                                {imageCreatedBy[rowIndex]?.createdBy ? ` by ${imageCreatedBy[rowIndex]?.createdBy} ` : ''}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <p style={{ maxWidth: '550px', overflowWrap: 'break-word', marginLeft: 64, fontSize: 11 }}>{imageNotes[rowIndex]}</p>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 70 }}>
                        {row?.map((image: any, colIndex: any) => (
                          <div key={colIndex} style={{ marginRight: 10 }}>
                            <Image src={image} style={{ height: 120, padding: 20 }} />
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                  ))
                )
                  :
                    <Col>
                      <Skeleton.Avatar active className="mr-3" />
                      <Skeleton.Input active style={{ width: 250, maxWidth: 250, minWidth: 200 }} className="mr-1 mb-1" />
                      <Skeleton.Input active style={{ width: 250, maxWidth: 250, minWidth: 200 }} />
                    </Col>
                }
              </div>

              {authStore?.currentUser?.access?.includes(
                Permissions.create_edit_issue_tracker
              ) && (
                  <>
                    {issueDetails?.status === 'OPEN' &&
                      <Divider type="horizontal" className="mt-auto mb-3"></Divider>
                    }
                    {issueDetails?.status === 'OPEN' &&
                      <Button
                        type="primary"
                        className="font-weight-bold mx-xl-0"
                        onClick={handleEditTicket}
                        style={{
                          width: "20%",
                          maxWidth: "20%",
                          height: "36px",
                          position: "relative",
                          left: '79%'
                        }}
                      >
                        <EditOutlined />
                        Edit Ticket {`>`}
                      </Button>
                    }

                  </>
                )}

            </Row>

                    {(ticket_id && issueDetails &&
                  ((issueDetails.status === 'OPEN' && authStore?.currentUser?.active_role?.role_type === constants.role['Field Technician']) ||
                  (issueDetails.status === 'PENDING REVIEW' && authStore?.currentUser?.active_role?.role_type === constants.role.Admin))) &&
                    <>
                      <Divider type="horizontal" className="mt-4 mb-4"></Divider>
                      <IssueTrackerDiagnosticDetails
                        issueDetails={issueDetails}
                        onResolveTicket={(values: any, action: any) => onResolveTicket(values, action)}
                        onIgnoreTicket={onIgnoreTicket}
                        onCancel={onClickCancelTicket} 
                        imageList={imageList}
                        imageNotes={imageNotes}
                        imageCreatedBy={imageCreatedBy}
                        skeletonOn={skeletonOn}
                        userType={userType}
                        fetchIssueDetailsWithId={fetchIssueDetailsWithId}
                        />
                      </>
                      }

                  {(ticket_id && (issueDetails?.status === 'INCOMPLETE')) && (authStore?.currentUser?.active_role?.role_type === constants.role.Admin) &&
                      <>
                      <Divider type="horizontal" className="mt-4 mb-4"></Divider>
                      <IssueTrackerDiagnosticDetails
                          issueDetails={issueDetails}
                          onResolveTicket={() => onCreateSubTicket()}
                          onIgnoreTicket={onIgnoreTicket}
                          onCancel={onClickCancelTicket} 
                          imageList={imageList}
                          imageNotes={imageNotes}
                          imageCreatedBy={imageCreatedBy}
                          skeletonOn={skeletonOn}
                          userType={userType}
                          fetchIssueDetailsWithId={fetchIssueDetailsWithId}
                          />
                        </>
                  }


                  {ticket_id && issueDetails && (
                    ((issueDetails.status === 'PENDING REVIEW' || issueDetails.status === 'INCOMPLETE') &&
                      (authStore?.currentUser?.active_role?.role_type === constants.role.Contractor ||
                        authStore?.currentUser?.active_role?.role_type === constants.role['Field Technician'])) ||
                    (issueDetails.status === 'RESOLVED' || issueDetails.status === 'CLOSED')
                  ) && (
                    <>
                      <Divider type="horizontal" className="mt-4 mb-4"></Divider>
                      <IssueTrackerDiagnosticCard issueDetails={issueDetails}                           
                          imageList={imageList}
                          imageNotes={imageNotes}
                          imageCreatedBy={imageCreatedBy}
                          skeletonOn={skeletonOn}
                          userType={userType}
                          />
                    </>
                  )}
          </Card>

        </Spin>
      </Col>
      <Col span={6}>
        <Card style={{ marginBottom: '4px' }}>
          <div>
            <p style={{ fontSize: "12px", fontWeight: 5 }}>
              Status
            </p>
            <p className="paragraph">
                <Tag color={Utils.getIssueTrackerStatus(issueDetails?.status)} >
                  {issueDetails?.status}
                </Tag>
            </p>
          </div>
          <div>
            <p style={{ fontSize: "12px", fontWeight: 5 }}>
              Priority
            </p>
            <p className="paragraph">
                <Text className="mr-1 mb-0 font-w-500">
                  {issueDetails?.issue_priority
                    ? issueDetails?.issue_priority
                    : ``}
                </Text>
            </p>
          </div>
          <div>
            <p style={{ fontSize: "12px", fontWeight: 5 }}>
              Created
            </p>
            <p className="paragraph">
                <Text className="mr-1 mb-0 font-w-500">
                  {created_on}
                </Text>
            </p>
          </div>
        {issueDetails?.status === 'OPEN' && (authStore?.currentUser?.active_role?.role_type === constants.role.Admin || authStore?.currentUser?.active_role?.role_type === constants.role.Contractor) &&
          <div>
            <p style={{ fontSize: "12px", fontWeight: 5 }}>
              Assign {authStore?.currentUser?.active_role?.role_type === constants.role.Admin ? 'Contractor' : 'Technician'}
            </p>
            <div className="d-flex align-items-center">
              <UserOutlined style={{ color: "cornflowerblue", fontSize: 13 }} />
              {authStore?.currentUser?.active_role?.role_type ===
                constants.role.Contractor
                ? AssigneeField(
                  issueDetails?.assigned_to,
                  constants.assigneeFieldPlaceholder,
                  selectedTechnician,
                  technicianUnderContractor,
                  constants.addNewTechnician
                )
                : authStore?.currentUser?.active_role?.role_type ===
                  constants.role.Admin
                  ? AssigneeField(
                    issueDetails?.contractor?._id,
                    constants.contractorFieldPlaceholder,
                    selectedContractor,
                    contractorList,
                    constants.addNewContractor
                  )
                  : ""}
            </div>
          </div>
          }
        {((issueDetails?.status !== 'OPEN' && (authStore?.currentUser?.active_role?.role_type === constants.role.Admin 
        || authStore?.currentUser?.active_role?.role_type === constants.role.Contractor)) 
        || (authStore?.currentUser?.active_role?.role_type === constants.role['Field Technician']))  &&
          <div>
            <p style={{ fontSize: "12px", fontWeight: 5 }}>
              {authStore?.currentUser?.active_role?.role_type === constants.role.Contractor ? 'Technician' : 'Contractor'}
            </p>
            <p className="paragraph">
                <Text className="mr-1 font-w-500">
                  {authStore?.currentUser?.active_role?.role_type === constants.role.Contractor ? (Utils.getObjectById(issueDetails?.assigned_to, technicianUnderContractor) || {})?.name || '-' : issueDetails?.contractor?.name}
                </Text>
            </p>
          </div>

        }
        </Card>
        <Card>
          <div>
            <p style={{ fontSize: "12px", fontWeight: 5 }}>
              Customer
            </p>
            <p className="paragraph">
                <Text className="mr-1 mb-0 font-w-500">
                  {issueDetails?.consumer && issueDetails?.consumer[0] ? issueDetails?.consumer[0]?.name
                    : issueDetails?.consumer ? issueDetails?.consumer?.name
                      : `None`}
                </Text>
            </p>
          </div>
          <div>
            <p style={{ fontSize: "12px", fontWeight: 5 }}>
              Address
            </p>
            <p className="paragraph">
                <Text className="mr-1 mb-0 font-w-500">
                  {issueDetails?.address ? issueDetails?.address : `None`}
                </Text>
            </p>
          </div>

          <div>
            <p style={{ fontSize: "12px", fontWeight: 5 }}>
              Phone
            </p>
            <p className="paragraph">
                <Text className="mr-1 mb-0 font-w-500">
                  {issueDetails?.phone ? issueDetails?.phone : `None`}
                </Text>
            </p>
          </div>
          <div>
            <p style={{ fontSize: "12px", fontWeight: 5 }}>
              Email
            </p>
            <p className="paragraph">
                <Text className="mr-1 mb-0 font-w-500">
                  {issueDetails?.email ? issueDetails?.email : `None`}
                </Text>
            </p>
          </div>
        </Card>
      </Col>
      <ConfirmationModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        onConfirmFunction={handleAssignTicket}
        confirmationMessage={"Are you sure you want to proceed ?"}
        confirmationTitle={selectedContractor ? "Assigning Contractor" : "Assigning Technician"}
        onCancelAssign={onCancelAssign}
      />
    </>
  );
});
export default IssueTrackerDetailsCard;
