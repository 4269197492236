/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {Row, Col, Pagination, Spin, Tag, Button, Tooltip, Modal, Divider, message, Select, Input, Space} from 'antd';

import {
  FilterOutlined
} from '@ant-design/icons';
import Table from '../../components_new/table'
import Utils from '../../helpers/Utility';
import {IssueQueryParams, buildSingleUserIssueQueryParams} from '../../helpers/IssueQueryHelper';
import issueTrackerService from '../../services/issueTrackerServices'
import authStore from '../../store/authStore';
import organizationService from '../../services/organizationService';
import IssuetrackerOverviewCard from '../../components_new/issue-tracker-overview-card';
import IssueTrackerFilterDrawer from '../../components_new/issue-tracker-filterdrawer';
import CreateNote from './CreateNote.jsx';
import ViewNote from './ViewNote.jsx';
import OnDemandSettings from './OnDemandSettings';
import { useForm } from 'antd/lib/form/Form';

import constants from '../../config/constants';
import _ from 'lodash';
import api from '../../api';

const { Option } = Select;
const { Search } = Input;

const userTypes = new Map([
  ['consumer', 'Customer'],
  ['contractor', 'Contractor'],
  ['service_resolved_by', 'Resolved By'],
  ['OPEN', 'Open'],
  ['CLOSED', 'Closed'],
  ['IGNORED', 'Ignored'],
  ['RESOLVED', 'Resolved'],
  ['PENDING REVIEW', 'Pending Review'],
  ['dateRange', 'Date Range'],
  ['INCOMPLETE', 'Incomplete']
])

function ReportGenerator() {

  const [issues, setIssues] = useState<any>([]);
  const [issuesOverview, setIssuesOverview] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [organizationFilterData, setOrganizationFilterData] = useState([]) //org
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(1);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [filterData, setFilterData] = useState<any>({});
  const [createNoteForm] = useForm(); 
  const [uploadLoading, setUploadLoading] = useState(false);

  /// report gen state ////////////
  const [notes, setNotes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('Issue Notes');
  const [modalText, setModalText] = useState('Where Notes Go');

  // This controls if the modal is currently displaying just text, the notes
  // view, or the note creation view
  const [modalState, setModalState] = useState('text');
  const [issueID, setIssueID] = useState('');
  /////////////////////////////////
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [errorMessage, setMessage] = useState('');
  const [issueStatus, setIssueStatus] = useState('');

  const organizationDropDownCondition = authStore?.currentUser?.active_role?.role_type === 0 || (authStore?.currentUser?.active_role?.role_type === 1 && authStore.currentUser.is_default)

  useEffect(() => {
    if (organizationDropDownCondition) {
      getIssuesWithOrganizationDetails()
    } else {
      fetchIssueTracker(buildSingleUserIssueQueryParams(filterData))
    }
  }, [])

  const fetchIssueTracker = async (params: IssueQueryParams) => {
    let pageNumber = (params.page === 0 ? page : params.page) as number;

    try {
      setLoading(true)
      let issuesTrackerDatas = await issueTrackerService.getIssueTrackerDetails2(params)
      setTotalCount(issuesTrackerDatas?.count)
      let issuesOverview = await issueTrackerService.getIssueReportOverview(params)
      setIssuesOverview(issuesOverview?.issuesCounts)
      setIssues(issuesTrackerDatas?.issues)
      setLoading(false)
      setPage(pageNumber)
    } catch (error) {
      setLoading(false)
      message.error(error.response.data.message)
    }
  }

  const getIssuesWithOrganizationDetails = async () => {

    try {
      const response = await organizationService.getOrganizations()

      const data = { ...filterData };
      data.orgid = response.data.organizations[0]._id
      setFilterData(data);

      let params : IssueQueryParams = {
        page: 1,
        searchKeyword: data?.searchKeyword ? data?.searchKeyword : '',
        orgid: response.data.organizations[0]._id,
      }

      fetchIssueTracker(params)
      setOrganizationFilterData(response.data.organizations);
    } catch (error) {
      setLoading(false)
      message.error(error.response.data.message)
    }
  }

  const searchDebounce = React.useCallback(_.debounce(fetchIssueTracker, 700), [])

  const handleSearchFilter = (e: any) => {
    const statusdata = { ...filterData};
    statusdata.searchKeyword = e.target.value;
    setFilterData(statusdata)

    let params = buildSingleUserIssueQueryParams(statusdata);
    searchDebounce(params)
  };

  const onPageChange = (page: any) => {
    let params = buildSingleUserIssueQueryParams(filterData);
    params.page = page;
    fetchIssueTracker(params)
  };

  const columns = [
    {
      title: 'Ticket ID',
      dataIndex: 'issue_id',
      key: 'issue_id',
      ellipsis: {
        showTitle: false,
      },
      render: (name: string | null | undefined) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
      width: '5%',
      align: 'left' as const,
    }//10
    ,
    {
      title: 'Customer',
      dataIndex: 'consumer',
      key: 'consumer',
      ellipsis: {
        showTitle: false,
      },
      render: (name: string | null | undefined) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
      width: '5%',
      align: 'left' as const,
    }//10
    ,
    {
      title: 'Issue Title',
      dataIndex: 'issue_title',
      key: 'issue_title',
      ellipsis: {
        showTitle: false,
      },
      render: (name: string | null | undefined) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
      width: '15%',
      align: 'left' as const,
    }//10
    ,
    {
      title: 'System',
      key: 'system',
      dataIndex: 'system',
      ellipsis: {
        showTitle: false,
      },
      render: (name: string | null | undefined) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
      width: '15%',
      align: 'left' as const,
    }//10
    ,
    {
      title: 'Days Open',
      key: 'daysopen',
      dataIndex: 'daysopen',
      ellipsis: {
        showTitle: false,
      },
      render: (name: string | null | undefined) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
      width: '5%',
      align: 'left' as const,
    }
    ,
    {
      title: 'Priority',
      key: 'priority',
      dataIndex: 'priority',
      render: (data: any) => (
        <>
          <Tag color={Utils.getIssuePriorityColor(data)} key={data}>
            {data.toUpperCase()}
          </Tag>
        </>
      ),
      width: '5%',
      align: 'left' as const,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (data: any) => (
        <>
          <Tag color={Utils.getIssueTrackerStatus(data)} key={data}>
            {data.toUpperCase()}
          </Tag>
        </>
      ),
      width: '5%',
      align: 'left' as const,
    }//10
  ];

  const tableDatas = issues?.map((issue: any, index: number) => {

    const system_name = issue.group_id?.name ? (`${issue?.group_id?.name} / ${issue?.sensor_box?.alias ? issue?.sensor_box?.alias : issue?.sensor_box?.sensor_box_name}`)
      : (issue?.sensor_box?.alias ? issue?.sensor_box?.alias : issue?.sensor_box?.sensor_box_name);

    let daysOpen = (issue.status === 'OPEN') ? Utils.getMomentFromNow(issue?.created_on) : ''

    let result: any = {
      _id: issue?._id,
      key: index + 1,
      issue_id: issue?.issue_id,
      consumer: issue?.consumer ? issue?.consumer?.name : '',
      issue_title: issue?.issue_title,
      system: system_name,
      daysopen: daysOpen,
      status: issue?.status,
      priority: issue?.issue_priority
    };
    return result
  })

  /////// from report gen ////////////////////////////
  const getNotes = async (currentID: string) => {
    try {
      let response = await issueTrackerService.getIssueNoteById(currentID)
      const notes = response?.data;
      setNotes(notes);

      setModalState('notes');
      // Knowing the issueID is valid and the notes were fetched, it will
      // then check if the notes array is empty. If it is, it will display an
      // update to the user informing them there are no notes for the issue
      if (notes.length === 0) {
        setModalText("No notes found for this issue");
        setLoading(false);
        return;
      }
      // We have notes to display! We set the modal state to notes, so the
      // modal will display the notes instead of the text
      setLoading(false);
      return;
    } catch (err) {
      setModalState('text')
      setModalText("Error fetching notes");
      setLoading(false);
      return;
    }
  }

  const onSelectRow = async (record: any) => {
    setIssueStatus(record.status)
    // Then it sets the state to loading, so the model will display a loading
    // spinner while the notes are fetched
    setLoading(true);
    setModalState('text');
    setModalText("Loading...");

    let currentID = record.issue_id;
    // Then it checks if the issueID passed to the function is valid
    // If it is not, it will display an error message for the modal
    if (issueID === undefined) {
      setModalState('text')
      setModalTitle("Issue Notes Invalid ID");
      showModal();
      setModalText("No ID passed to handleNotesModal");
      setLoading(false)
    } else {
      // If the ID is valid, it will set the modal title to the issue ID
      // and then show the modal (it is currently loading)
      setModalTitle("Issue Notes for " + currentID);
      setIssueID(currentID)
      showModal();

      // Then it checks if the issueID passed to the function is the same as
      // the issueID that is currently stored in state. If it is not, it will
      // set the issueID state to the new ID and then fetch the notes for the
      // new issue
      setIssueID(currentID);
      await getNotes(currentID);
    }

    // let params = `?ticket_id=${record?._id}`
    // if (organizationDropDownCondition) {
    //   params += `&org_id=${selectedFilter}`
    // }

    // history.push({
    //   pathname: `${Routes.issuetrackerdetails}`,
    //   search: params
    // });
  }

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setLoading(false);
  };
  const handleCancel = () => {
    if (modalState === 'create') {
      createNoteForm.resetFields();
      setModalState('notes');
      setIsErrorVisible(false)
    } else {
      setIsModalOpen(false);
      setLoading(false);
    }
  };
  const handleCreateNote = () => {
    createNoteForm.resetFields();
    setModalState('create');
  };

    const onFinish = async () => {
    setUploadLoading(true)
    const values = createNoteForm.getFieldsValue();
    if (values?.text || (values.attachments && values.attachments?.file?.status !== 'removed')) {
    // Create a new FormData object to hold the form data and files
    const formData = new FormData();
  
    // Append the text input value to the FormData object
    formData.append('text', values.text||' ');

    if (values?.attachments) {
      // Append the uploaded files to the FormData object
      const fileData = values?.attachments?.fileList?.map((file: { name: any; type: any; thumbUrl: any; url: any; originFileObj: any; }) => {
        return {
          name: file.name,
          type: file.type,
          uri: file.thumbUrl || file.url,
          file: file.originFileObj,
        };
      });
      
      fileData.forEach((file: { file: string | Blob; }) => {
        formData.append('attachments', file.file);
      });
    }

    // Make the API request to submit the form data and files
    try {
      const response: any = await api.post(`/issue-tracker/note?issue_id=${issueID}`, formData)
      if (response.status === 200) {
        setIsErrorVisible(false);
        setIsModalOpen(false);
        setUploadLoading(false)
        createNoteForm.resetFields();
      }  else {
        // Handle form submission error
        createNoteForm.resetFields();
        setUploadLoading(false)
        const errMsg = response.date?.message;
        message.destroy()
        message.error(errMsg)
        // setMessage(errMsg);
        // setIsErrorVisible(true);
      }
    } catch (error: any) {
      createNoteForm.resetFields();
      // setMessage("Network error. Try again in a moment.");
      // setIsErrorVisible(true);
      const errorData = error?.response?.data
        const isUnauthorizedIssue = Utils.issueTrackerErrorHandler(errorData, constants.responseCode.unAuthorized, constants.message.issue_allocation_changed, constants.message.issue_doesnot_exist)
        if (isUnauthorizedIssue) {
            handleCancel()
            message.destroy()
            await message.error(error?.response?.data?.message)
            setUploadLoading(false)
        } else {
            message.destroy()
            message.error(error?.response?.data?.message)
            setUploadLoading(false)
        }
    }
  } else {
        message.destroy()
        message.error(constants.message.noImageOrTextError)
        setUploadLoading(false)
    }
  };

  const modalContent = () => {
    if (modalState === 'text') {
      return (
        <div>{modalText}</div>
      )
    } else if (modalState === 'notes') {
      return (
        <ViewNote notes={notes} />
      )
    } else if (modalState === 'create') {
      return (
        <CreateNote issueID={issueID} 
        setIsModalOpen={setIsModalOpen} 
        isErrorVisible={isErrorVisible}
        errorMessage={errorMessage} 
        createNoteForm={createNoteForm}
        setMessage={setMessage}
        setIsErrorVisible={setIsErrorVisible}
        setUploadLoading={setUploadLoading}
        uploadLoading={uploadLoading}
        />
      )
    }
  };
  //////////////////////////////////////////////////

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);

    let params = buildSingleUserIssueQueryParams(filterData);
    params.page = page;
    fetchIssueTracker(params)
  };

  const onHandleFilterOnCard = (status: any) => {

    const data = { ...filterData };
    delete data?.consumer
    delete data?.contractor
    delete data?.service_resolved_by
    data[`status`] = [status]
    setFilterData(data);

    let param = buildSingleUserIssueQueryParams(data);
    fetchIssueTracker(param)
  }

  const onApplyFilter = (values: any) => {

    const statusdata = { ...filterData, ...values};
    setFilterData(statusdata)
    let param = buildSingleUserIssueQueryParams(statusdata);
    fetchIssueTracker(param);
    setDrawerVisible(false);
  }

  const handleClearAll = () => {
    const clearStatus = {'orgid': filterData?.orgid}
    setFilterData(clearStatus);
    let param = buildSingleUserIssueQueryParams(clearStatus);
    fetchIssueTracker(param)
    setDrawerVisible(false)
  }

  const onCloseTags = (key: any, tag: any) => {
    const statusData = { ...filterData };
    if (key === 'status') {
      statusData['status'] = statusData?.status?.filter((status: any) => status !== tag)
      if (!(statusData?.status?.length)) {
        delete statusData[key];
      }
    } else {
      delete statusData[key];
    }

    setFilterData(statusData);

    let param = buildSingleUserIssueQueryParams(statusData);
    fetchIssueTracker(param)
  }

  const handleFilterSelection = (option: any) => {// for org_id
    const statusData = { ...filterData };
    statusData['orgid'] = option
    setFilterData(statusData);

    let param = buildSingleUserIssueQueryParams(statusData);
    fetchIssueTracker(param);
  }

  const handleDateSelection = (dates: any, dateString: Array<string>) => {
    const statusData = { ...filterData };
    if (dates) {
      statusData.dateRange = dateString[0] === '' ? null : dateString;
    } else {
      delete statusData['dateRange'];
    }
    setFilterData(statusData);
    
    let param = buildSingleUserIssueQueryParams(statusData);
    fetchIssueTracker(param);
  }

  const openIssuesCount = issuesOverview?.find((issue: any) => issue?.status === 'OPEN')
  const closedIssuesCount = issuesOverview?.find((issue: any) => issue?.status === 'CLOSED')
  const ignoredIssuesCount = issuesOverview?.find((issue: any) => issue?.status === 'IGNORED')
  const resolvedIssuesCount = issuesOverview?.find((issue: any) => issue?.status === 'RESOLVED')
  const pendingReviewsCount = issuesOverview?.find((issue: any) => issue?.status === 'PENDING REVIEW')

  const genTags = (data: any) => {
    const excludeKeys = ['orgid', 'searchKeyword']

    return Object.keys(data).filter((key: any)=> !excludeKeys.includes(key)).length > 0 &&
    <>
      <Divider
        type="horizontal"
        style={{ backgroundColor: "#000", margin: "auto" }}
      />
      <div className=''>
        <Row gutter={[16, 16]}>
          <Col span={22}>
            {
              Object.keys(data).filter((key: any)=> !excludeKeys.includes(key)).map((key: any) => {
                return data[key]?.map((tag: any) => (
                  <Tag className='mt-3 h6' color="grey" closable onClose={(e: any) => { onCloseTags(key, tag); e.preventDefault(); }}>
                    {key === 'status' ? userTypes.get(tag) : (key === 'dateRange' ? tag : tag.name)} {key !== 'status' && <span className='ml-2 text-uppercase font-weight-bold'>
                      {userTypes.get(key)}
                    </span>}
                  </Tag>
                ))
              })
            }

          </Col>
          <Col span={2}>
            <p className='mt-3 float-right'> <a href="/" onClick={(e: any) => { handleClearAll(); e.preventDefault(); }}>
              CLEAR ALL
            </a></p>
          </Col>
        </Row>
      </div>
      <Divider
        type="horizontal"
        style={{ backgroundColor: "#000", margin: "auto" }}
      />
    </>
  }


  return (
    <div className="p-0">
      <Spin spinning={loading}>
        <div className="d-flex flex-column h-100 overflow-hidden p-0">
          <div className="flex-fill h-100 overflow-hidden issue-tracker-padding">
            <div className="d-flex mb-2 align-content-center">
              <div className="flex-fill">
                <p className="page-title text-left">Report Generator</p>
              </div>
              <Space size={0}>

                <OnDemandSettings data={filterData} onRangeChange={handleDateSelection}/>
                {
                  (organizationDropDownCondition) &&
                  <div className="d-flex justify-content-end mr-4">
                    <Select value={filterData?.orgid} defaultValue={filterData?.orgid} onChange={handleFilterSelection} size="large" style={{ minWidth: "165px" }}>
                      {
                        organizationFilterData &&
                        organizationFilterData.map((data: any) => {
                          return (
                            <Option value={data._id} key={data._id}>{data.name}</Option>
                          );
                        })
                      }
                    </Select>
                  </div>

                }
                <Search
                  className='mr-4'
                  style={{ minWidth: "100px" }}
                  placeholder="Search"
                  value={filterData?.searchKeyword || ""}
                  size="large"
                  onChange={handleSearchFilter}
                  allowClear
                  enterButton />

                <Button type="text" icon={<FilterOutlined />} size="large" onClick={showDrawer}>Filter</Button>
                <IssueTrackerFilterDrawer
                  data={filterData}
                  orgid={filterData?.orgid}
                  visible={drawerVisible}
                  onCloseDrawer={onCloseDrawer}
                  onApplyFilter={onApplyFilter}
                  onClearAll={handleClearAll}
                />
              </Space>
            </div>

            {genTags(filterData)}

            <div className="d-lg-flex mb-3">
              <div className="flex-custom-fill mr-md-2">
                <div className="d-flex flex-column h-100">
                  <div className="flex-fill mb-3">
                  </div>
                  <div className="flex-auto">
                    <Row gutter={[16, 16]}>
                      <Col span={4} >
                        <IssuetrackerOverviewCard
                          count={openIssuesCount ? openIssuesCount?.count : 0}
                          description={<Tag color={Utils.getIssueTrackerStatus('OPEN')} key={'OPEN'}>{'OPEN'}</Tag>}
                          onHandleFilterOnCard={() => onHandleFilterOnCard('OPEN')}
                        />

                      </Col>
                      <Col span={4} >
                        <IssuetrackerOverviewCard
                          count={pendingReviewsCount ? pendingReviewsCount?.count : 0}
                          description={<Tag color={Utils.getIssueTrackerStatus('PENDING REVIEW')} key={'PENDING REVIEW'}>{'PENDING REVIEW'}</Tag>}
                          onHandleFilterOnCard={() => onHandleFilterOnCard('PENDING REVIEW')}
                        />
                      </Col>
                      <Col span={4} >
                        <IssuetrackerOverviewCard
                          count={resolvedIssuesCount ? resolvedIssuesCount?.count : 0}
                          description={<Tag color={Utils.getIssueTrackerStatus('RESOLVED')} key={'RESOLVED'}>{'RESOLVED'}</Tag>}
                          onHandleFilterOnCard={() => onHandleFilterOnCard('RESOLVED')}
                        />

                      </Col>
                      <Col span={4} >
                        <IssuetrackerOverviewCard
                          count={ignoredIssuesCount ? ignoredIssuesCount?.count : 0}
                          description={<Tag color={Utils.getIssueTrackerStatus('IGNORED')} key={'IGNORED'}>{'IGNORED'}</Tag>}
                          onHandleFilterOnCard={() => onHandleFilterOnCard('IGNORED')}
                        />
                      </Col>
                    <Col span={4} >
                        <IssuetrackerOverviewCard
                          count={pendingReviewsCount ? pendingReviewsCount?.count : 0}
                          description={<Tag color={Utils.getIssueTrackerStatus('INCOMPLETE')} key={'INCOMPLETE'}>{'INCOMPLETE'}</Tag>}
                          onHandleFilterOnCard={() => onHandleFilterOnCard('INCOMPLETE')}
                        />
                    </Col>
                      <Col span={4} >
                        <IssuetrackerOverviewCard
                          count={closedIssuesCount ? closedIssuesCount?.count : 0}
                          description={<Tag color={Utils.getIssueTrackerStatus('CLOSED')} key={'CLOSED'}>{'CLOSED'}</Tag>}
                          onHandleFilterOnCard={() => onHandleFilterOnCard('CLOSED')}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <div className='style-table'>
              <Table
                data={tableDatas}
                columns={columns}
                onSelectRow={(record: any) => onSelectRow(record)}
              />
            </div>

            <div className='py-4'>
              {Math.ceil(totalCount / constants.maxLimit) > 1 &&
                <Pagination
                  defaultCurrent={1}
                  current={page}
                  total={totalCount}
                  pageSize={constants.maxLimit}
                  responsive={true}
                  className="float-right"
                  showSizeChanger={false}
                  onChange={onPageChange} />
              }
            </div>


            <Modal
              className="reportGenModal"
              title={modalTitle}
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              closable={false}
              centered={true}
              footer={
                issueStatus === 'OPEN' ?
                (modalState !== 'create' && issueStatus === 'OPEN') ? (
                  <div>
                    <Button
                      type="primary"
                      onClick={handleCreateNote}
                      disabled={modalState === 'notes' ? false : true}>
                      New Note
                    </Button>
                    <Button type="primary" onClick={handleOk}> Ok </Button>
                  </div>
                ) : (
                  <><Button type='primary' htmlType="submit" disabled={uploadLoading} onClick={onFinish}>Submit</Button>
                    <Button danger onClick={handleCancel} disabled={uploadLoading}> Cancel </Button></>
                )
                : 
                <Button danger onClick={handleCancel}> Ok </Button>
              }
              maskClosable={true}
            >
              <Spin spinning={loading} tip="Loading">
                {modalContent()}
              </Spin>
            </Modal>

          </div>
        </div>
      </Spin>
    </div>
  );
}
export default ReportGenerator;
