import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import constants from '../../config/constants';
import sensorService from '../../services/sensorService';
import moment from 'moment'

interface Props {
  show: boolean;
  onClose: () => void;
  sensorbox?: any;
}

const DeviceConfigLogModal: React.FC<Props> = ({ show, onClose, sensorbox }) => {


  const [logs, setLogs] = useState([]);

  const getLogs = async () => {
    try {
      const resp = await sensorService.getDeviceConfigLog(sensorbox?.sensor_box_name, sensorbox?.latest_config_req);
      setLogs(resp?.data.logs)
    } catch (error) {}
  }

  useEffect(() => {
    if(show){
      getLogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return (
    <Modal
      className="logs-modal-body"
      show={show}
      onHide={onClose}
    >
      <Modal.Header  closeButton>
        {sensorbox?.sensor_box_name} {sensorbox?.alias && `(${sensorbox?.alias})`}
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <p className="h6">S.No:- {sensorbox?.latest_config_req}</p>
          <table className="table sensor-list mb-0" style={{ tableLayout: 'fixed' }}>
            <thead className="table-header-bg">
              <tr>
                <th style={{ width: '5%' }} className="text-custom-size" scope="col">#</th>
                <th style={{ width: '55%' }} className="text-custom-size custom-width no-wrap" scope="col">Status</th>
                <th style={{ width: '40%' }} className="text-custom-size" scope="col">Time</th>
              </tr>
            </thead>
            <tbody>
              {
                logs?.map((log:any, index: number) => (
                  <tr key={log?._id} className={index%2 !== 0 ? "background-color" : ""}>
                    <td className="text-custom-size">{index+1}</td>
                    <td className="text-custom-size text-truncate">{constants.deviceStatus.get(log?.status)?.desc}</td>
                    <td className="text-custom-size text-truncate ">{moment(log?.timestamp).format('MM/DD/YYYY, HH:mm:ss')}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </Modal.Body> 
    </Modal>
  )
}

export default DeviceConfigLogModal;

