/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Input } from 'antd';
import _ from 'lodash';
import toast from '../../../components/toast';
import Pagination from "react-js-pagination";
import userService from '../../../services/userService';
import LoadingWheel from '../../../components/loadingwheel';
import { AlgorithmConfig } from '../../../interface'
import EllipsisProps from '../../../components/textellipsis'
import constants from '../../../config/constants';
import AddAlgorithmModal from '../../../components/add-algorithmconfigmodal'
import TestAlgorithmConfigModal from '../../../components/testalgorithmconfig-modal'
import ConfirmModal from '../../../components/confirmmodal';
import algorithmConfigService from '../../../services/algorithmConfigServices';
import Utility from '../../../helpers/Utility';
import '../settings.css';

const { Search } = Input;

const gateTypes = new Map([
    ['OR', '||'],
    ['AND','&&'],
])

const conditionTypes = new Map([
    [1, '>'],
    [2, '<'],
    [3, '>='],
    [4, '<='],
    [5, '==='],
    [6, '!==']
])

function AlgorithmConfigs() {

    const [loading, setLoading] = useState(true)
    const [showTestAlgorithmConfigModal ,setShowTestAlgorithmConfigModal] = useState(false);
    const [refrigerentList ,setRefrigerentList] = useState<any>([{}]);
    const [pinRepresentationList ,setPinRepresentationList] = useState<any>([{}]);
    const [algorithmConfig, setAlgorithmConfig] = useState([])
    const [visible, setVisible] = useState(false)
    const [algorithmTotal, setAlgorithmTotal] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedAlgorithm, setSelectedAlgorithm] = useState<any>({})
    const [searchFilter, setSearchFilter] = useState('')

    useEffect(() => {
        getAlgorithmConfig(currentPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAlgorithmConfig = async (pageNumber: number = 0 , typeFilter: string = '',searchKeyword: string = '') => {

        pageNumber = pageNumber === 0 ? currentPage : pageNumber;
        const limit= constants.maxLimit
        try {
            await  getRefrigerentList()
            await  getPinRepresentations()
            const response = await algorithmConfigService.getAlgorithmConfig(pageNumber, limit, typeFilter, searchKeyword)
            setAlgorithmConfig(response.data.algorithms);
            setCurrentPage(pageNumber)
            setAlgorithmTotal(response.data.count)
            setLoading(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    const searchDebounce = React.useCallback(_.debounce(getAlgorithmConfig, 700), [])   

    const addAlgorithmConfig = async (data: AlgorithmConfig) => {
        setLoading(true)
        try {
            const response:any = await algorithmConfigService.addAlgorithmConfig(data)
            if(response.status === 200)
            toast.success('Configuration Added');
            closeForm()
            let pageNumber = Math.ceil((algorithmTotal + 1)/constants.maxLimit)
            getAlgorithmConfig(pageNumber)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    const updateAlgorithmCofig = async (data: AlgorithmConfig) => {
        setLoading(true)
        try {
            const response:any= await algorithmConfigService.updateAlgorithmConfig(data , selectedAlgorithm._id)
            if(response.status === 200)
            toast.success('Configuration Updated');
            closeForm()
            getAlgorithmConfig(currentPage)

        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    const deleteAlgorithmCode = async (id: string) => {

        try {
            setLoading(true)
            setVisible(false)
            const response: any = await algorithmConfigService.deleteAlgorithmConfig(id)
            if(response.status === 200)
                toast.success('Configuration Removed');
            closeConfirmModal()
            closeForm()
            let pageNumber = Math.ceil((algorithmTotal - 1)/constants.maxLimit)
            getAlgorithmConfig(pageNumber)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    const getPinRepresentations = async () => {
        try {
            const resp = await algorithmConfigService.pinRepresentations();
            setPinRepresentationList(resp.data.pin_representation)
          } catch (error) {
          }

    }

    const getRefrigerentList = async () => {
        try {
          const resp = await userService.getRefrigerentList();
          setRefrigerentList(resp)
        } catch (error) {}
    }

    const handleSelectingTable = (item :any)=> {
        showAlgorithmCodeModal(item)
    };

    const handleSearchFilter = (e :any)=> {
        const { value } = e.target;
        setSearchFilter(value); 
        searchDebounce(1,'',value)
    };

    const showAlgorithmCodeModal = (algorithm?: AlgorithmConfig) => {
        if (algorithm) {
            setSelectedAlgorithm(algorithm)
        }else{
            setSelectedAlgorithm({})
        }
        setVisible(true)
    }

    const closeForm = () => {
        setVisible(false)
    }

    const closeConfirmModal = () => {
        Utility.closeModal('confirmmodal')
    }

    const showsTestAlgorithmConfigModal = async () => {
        setShowTestAlgorithmConfigModal(true)
      }
    
      const closeTestAlgorithmConfigModal = async () => {
        setShowTestAlgorithmConfigModal(false)
      }

    const expressionValue = (item : any) => {
        let expression = '';
        if(item.rule_type !== 1){ //mutiple
                let ruleExpression = item?.rules.map((rule : any) => {
                    let equation = ` ${rule.representation} ${conditionTypes.get(rule.condition)} ${rule.threshold} `
                    return equation
                })
                expression = ruleExpression.join (gateTypes.get(item?.gate))
        }else{ //all should have values or all zeros 
            let multipleExpression = item?.combination_rule.map((rule : any) => {
                    let firstValue = rule + ' === 0';
                    let secondValue = rule + ' > 0';
                    return {firstValue ,  secondValue}
            })
            let firstCondtion = multipleExpression.map((elem : any)=> elem.firstValue ).join(" && ");
            let secondCondition = multipleExpression.map((elem : any) => elem.secondValue ).join(" && ");
            expression = `!(${firstCondtion} || ${secondCondition})`
        }
        return expression
    }

    return (
       <div className="">            
            <div>
                <div className="padding-b50">
                {
                loading &&
                <LoadingWheel />
                }
                    <div className="row mb-4 settings-space-between">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <p className="table-title media-padding">Large Chiller Alerts</p>
                        </div>
                        <div className="ml-auto">
                            <button type="button" 
                                disabled={false} 
                                className="btn btn-primary"
                                onClick={() => showsTestAlgorithmConfigModal()}
                            >Test Alerts
                            </button>
                        </div>

                        <div className="col-xs-6 col-sm-6 col-md-3 col-lg-3 media-margin-tp mt-auto">
                            <Search
                                placeholder="Search"
                                value={searchFilter || ""}
                                size="large"
                                onChange={handleSearchFilter}
                                allowClear
                                enterButton />
                        </div>
                    </div>
                    {algorithmConfig.length > 0 ?
                    <div className="row no-gutters table-wrap">
                        <table className="table custom-table table-style">
                            <thead className="table-header-bg">
                                <tr>
                                    <th className="text-left" scope="col">#</th>
                                    <th scope="text-left">Diagnostic Code</th>
                                    <th className="text-left" scope="col">Status</th>
                                    <th className="text-left" scope="col">Refrigerant Type</th>
                                    <th className="text-left" scope="col">Description</th>
                                    <th className="text-left" scope="col">Expression</th>
                                </tr>
                            </thead>
                            <tbody>
                                {algorithmConfig?.length > 0 &&
                                    algorithmConfig?.map((item: any, index) => {
                                        let expression = expressionValue(item)
                                        return (
                                            <tr className="click-icon" key={index} onClick={() => handleSelectingTable(item)}>
                                                <td className="text-left">{(constants.maxLimit * (currentPage - 1)) + index + 1}</td>
                                                <td><EllipsisProps length={6}>{item?.code}</EllipsisProps></td>
                                                <td className="text-left">{<i className={`fa fa-circle ${Utility.getSensorStatus(item?.status)}`} aria-hidden="true"></i>}</td>
                                                <td className="text-left"><EllipsisProps length={20}>{item?.refrigerent_type ? item?.refrigerent_type : ''}</EllipsisProps></td>
                                                <td className="text-left"><EllipsisProps length={50}>{item?.description ? item?.description : ''}</EllipsisProps></td>
                                                <td className="text-left"><EllipsisProps length={30}>{expression}</EllipsisProps></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    :
                      <div className="text-center w-100 no-card-ph">
                          <div className="no-result-container">
                            <p>No Alerts found</p>
                          </div>
                      </div>
                    }
                    <div className="pagination-container">
                        {Math.ceil(algorithmTotal / constants.maxLimit) > 1 &&

                            <Pagination
                                hideNavigation={true}
                                activePage={currentPage}
                                itemsCountPerPage={constants.maxLimit}
                                totalItemsCount={algorithmTotal}
                                itemClass={"item-class"}
                                activeClass={"active-class"}
                                pageRangeDisplayed={5}
                                onChange={(pageNumber) => getAlgorithmConfig(pageNumber, '', searchFilter)}
                            />
                        }
                    </div>
                        <AddAlgorithmModal
                            visible={visible}
                            setVisibleClose={closeForm}
                            selectedAlgorithm={selectedAlgorithm}
                            addAlgorithmConfig={(data: AlgorithmConfig) => addAlgorithmConfig(data)}
                            updateAlgorithmConfig={(data: AlgorithmConfig) => updateAlgorithmCofig(data)}
                            refrigerentList = {refrigerentList}
                            pinRepresentationList = {pinRepresentationList}

                        />

                        <TestAlgorithmConfigModal
                            show={showTestAlgorithmConfigModal}
                            onClose={closeTestAlgorithmConfigModal}
                        />

                        <ConfirmModal
                            title={"Delete Algorthm Code"}
                            message={"Are you sure you want to delete this algorithm code"}
                            closeConfirmModal={closeConfirmModal}
                            delete={() => deleteAlgorithmCode(selectedAlgorithm._id)}
                            isLoading={false}
                        />
                    <div className="add-icons" onClick={() => { showAlgorithmCodeModal() }}>
                        <i className="fa fa-plus" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlgorithmConfigs