import React from 'react';
import { Table, Tooltip } from 'antd';
import Icon from '../icon';

import Utility from '../../helpers/Utility';

interface Props {
  subSystem?: any,
  onClickSnooze: () => void;
  setCenterCoordOnMouseHover: (data: any) => void;
}

const AlertTable: React.FC<Props> = ({ subSystem, onClickSnooze, setCenterCoordOnMouseHover }) => {

  const mouseHoverHandler = (data: any) => {
    if (setCenterCoordOnMouseHover) {
      setCenterCoordOnMouseHover(data)
    }
  }
  
  const columns = [
    {
      title: "",
      dataIndex: "is_online",
      key: "is_online",
      render: (isOnline: any) => {
        return (
          <Icon className={`icon-14 ${isOnline ? "online-icon" : "offline-icon"}`} iconRef="#range" />
        )
      },
      width: '10%',
      align: 'left' as const,
    },
    {
      title: "System",
      dataIndex: "name",
      key: "name",
      ellipsis: {
        showTitle: false,
      },
      render: (name: {} | null | undefined) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
      width: '35%',
      align: 'left' as const,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: any) => {
        let color = Utility.getStatus(status)
        return (
          <div className={`small-circle-alert ${color}`}></div>
        )
      },
      width: '20%',
      align: 'center' as const,
    },
    {
      title: "Hours",
      dataIndex: "hours",
      key: "hours",
      width: '35%',
      align: 'left' as const,
    },


    // {
    //   title: "Snooze",
    //   dataIndex: "snooze",
    //   key: "snooze",
    // render: () => (
    //   <a href="#">
    //     <Button className="btn snooze-btn" onClick={(e) => {
    //         e.stopPropagation();
    //         onClickSnooze()
    //       }}>
    //     <Icon className="icon-14" iconRef="#snooze"  />
    //     </Button>
    //   </a>
    // ),
    //   width: 30,
    // },
  ];

  const tableDatas = subSystem?.map((sensor: any, index: number) => {
    let hoursOutstanding = sensor?.alert_outstanding_time ? Utility.getMomentFromNow(sensor?.alert_outstanding_time) : 'N/A'
    let result: any = { key: index + 1, is_online: sensor?.is_online, name: sensor?.alias ? sensor?.alias : sensor?.sensor_box_name, status: sensor.status, hours: hoursOutstanding, snooze: 1, location: sensor?.location };
    return result
  })

  return (
    <>
      <Table
        onRow={(record, rowIndex) => {
          return {
            onMouseOver: (event: any) => { mouseHoverHandler(record) }, // click row
          };
        }}
        columns={columns}
        dataSource={tableDatas}
        pagination={false}
        scroll={{  y: 250 }}
      />
    </>
  );
}
export default AlertTable;
