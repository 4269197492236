import React from 'react';
import Utility from '../../helpers/Utility';
import "./rawdatamodal.css"

export interface userFormProps{
    rawData: string;
}

export interface userFormState { }

class SensorRawData extends React.Component<userFormProps, userFormState>{


    closeModal = () =>{
        Utility.closeModal('rawData')
    }



    render(){
        return (
        <div>
            <div className="modal fade show" id="rawData" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    
                    <div className="modal-content create-user-modal">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle" >Raw Data</h5>
                        <span aria-hidden="true" className="close" aria-label="Close" onClick={()=>this.closeModal()}>&times;</span>
                    </div>
                    <div className="modal-body">
                    <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                        <label className="custom-label" >Sensor Data</label>
                                        <textarea 
                                            className="form-control resize-none text-area-input" 
                                            id="address" 
                                            aria-describedby="emailHelp" 
                                            value={this.props.rawData}
                                            disabled={true}
                                        />
                                </div>
                            </div>
                    </div>
                    </div>

                    <div className="modal-footer">
    
                    </div>
                    </div>
                </div>
                </div>
               
        </div>

        );
    }
  }
 
export default SensorRawData
