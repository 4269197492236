import Api from '../api';
import qs from 'query-string'
class GroupService {
// note : temp solution for the org admin access to all org
  getSensorboxesForGroup = async (orgid? : any) => {
    let url = `/sensorboxes-for-group`
    if(orgid){
      url += `?orgid=${orgid}`
    }
    return Api.get(url)
  }

  getGroups = async (params: any) => {
    const query = qs.stringify(params);
    // note : temp solution for the org admin access to all org
    let url = query ? `/sensorbox-group?${query}` : `/sensorbox-group`
    return Api.get(url)
  }

  createGroups = async (group: any ,orgid? : any) => {
    // note : temp solution for the org admin access to all org
    let url = `/sensorbox-group`
    if(orgid){
      url += `?orgid=${orgid}`
    }
    return Api.post(url, group)
  }

  editGroup = async (groupId: string, group: any , orgid? : any) => {
    // note : temp solution for the org admin access to all org
    let url = `/sensorbox-group/${groupId}`
    if(orgid){
      url += `?orgid=${orgid}`
    }
    return Api.put(url, group)
  }

  deleteGroup = async (groupId: string) => {
    return Api.delete(`/sensorbox-group/${groupId}`, )
  }

  isGroupNameExist = async (groupName: string, groupId?: string , orgid? : string) => {
    // note : temp solution for the org admin access to all org
    let url = `/sensorbox-group_exist/${groupName}?gid=${groupId || ''}`
    if(orgid){
      url += `&orgid=${orgid}`
    }
    return Api.get(url)
  }



}

export default new GroupService()