import React , { useState } from 'react';
import { Menu, Dropdown, Avatar, Button } from 'antd';
import { RouteComponentProps, withRouter ,useHistory} from "react-router-dom";
import {
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  UserSwitchOutlined,
  KeyOutlined,
} from '@ant-design/icons';

import authStore from '../../store/authStore';
import ChangePassword from '../../components/passwordchangemodal'
import SwitchRoleModal     from '../../components/switch-role-modal'
import Routes from '../../routing/routes'

import Utility from '../../helpers/Utility'
import Permissions from '../../config/permssions';

const {
  super_admin_access

} = Permissions


export interface Props extends RouteComponentProps {
  collapseToggle: any;
  collapsed: boolean;
}


const TopMenu = (props: Props) => {

  let history = useHistory();

  const menu = (
    <Menu>
      <Menu.Item className="header-menu">
        <Button type="link" icon={<KeyOutlined />} onClick={()=>showChangePasswordModal()}>
          Change password
        </Button>
      </Menu.Item>
      {!authStore?.currentUser?.access?.includes(super_admin_access) && checkMultipleRole() &&
      <Menu.Item className="header-menu">
        <Button type="link" icon={<UserSwitchOutlined />} onClick={()=>showSwitchRoleModal()}>
          Switch role
        </Button>
      </Menu.Item>
      }
      <Menu.Item>
        <Button danger type="link" icon={<LogoutOutlined />}  onClick={signOut}>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );

  const [confirmSwitchRoleModal, setConfirmSwitchRoleModal] = useState(false)

  function signOut(){
    authStore.signOut()
    window.location.href="/";
  }

  async function showSwitchRoleModal() {
    setConfirmSwitchRoleModal(true)
  }

  function switchRole(data :any) {
    setConfirmSwitchRoleModal(false)
    authStore.signOut()
    history.push(`${Routes.login}?role_id=${data._id}`);
  }

  function closeSwitchRoleModal(){
      setConfirmSwitchRoleModal(false)
  }

  function checkMultipleRole(){
    return authStore?.currentUser?.roles.length>1 ? true : false
  }

  function closeModal(){
    Utility.closeModal('changePassword')
  }

  async function showChangePasswordModal() {
    Utility.showModal('changePassword')
  }

  const { collapseToggle, collapsed } = props;
  return (
    <div className="d-flex hvac-header pt-3 pr-3">
      <div className="flex-fill justify-content-right">
        <Button className="trigger collapse-btn" onClick={collapseToggle}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
      </div>
      <div className="flex-auto">
        <Dropdown overlay={menu}>
          <button
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            <div className="d-flex">
              <div className="flex-auto pr-3">
                <Avatar size={36} style={{
                  color: '#f56a00',
                  backgroundColor: '#fde3cf',
                }} >
                {authStore?.currentUser?.name.charAt(0).toUpperCase()}
                </Avatar>
              </div>
              <div className="flex-fill user-info">
                <p className="mb-0 mr-1">{authStore?.currentUser?.name}</p>
                <p className="small mb-0">{authStore?.currentUser?.active_role?.name}</p>
              </div>
              <div className="flex-auto">
                <DownOutlined />
              </div>
            </div>
          </button>
        </Dropdown>
        <SwitchRoleModal 
                title={"Switch Role"} 
                roles={authStore?.currentUser?.roles}   
                switch={(data: any) => switchRole(data)} 
                visible={confirmSwitchRoleModal} 
                toggleVisibility={closeSwitchRoleModal}/>
        <ChangePassword closeModal={()=>closeModal()}/>
      </div>
    </div>
  );
};

export default withRouter(TopMenu);
