import React from 'react';
import { Card } from 'antd';

import Icon from '../icon';

interface Props {
    count?: any,
    descriptionOne?: any,
    descriptionTwo?: any,
    iconClassName? : any,
    iconRef? : any
}

const CountsCard: React.FC<Props> = ({ count, descriptionOne, descriptionTwo, iconClassName, iconRef  }) => {

    return (
        <>
            <Card className="custom-card online-device border-0">
                <div className="d-flex">
                    <div className="flex-fill">
                        <h2 className="mb-1 mt-3">{count}</h2>
                    </div>
                    <div className="flex-auto">
                        <div className="circle-icon">
                            <Icon className={iconClassName} iconRef={iconRef} />
                        </div>
                    </div>
                </div>
                <p className="mb-1">{descriptionOne}</p>
                <p className="status mb-0">{descriptionTwo}</p>
            </Card>
        </>
    );
}
export default CountsCard;
