/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Input } from 'antd';
import Pagination from "react-js-pagination";
import _ from 'lodash';
import toast from '../../../components/toast';
import LoadingWheel from '../../../components/loadingwheel';
import settingsService from '../../../services/settingsService';
import { PinConfiguration } from '../../../interface'
import EllipsisProps from '../../../components/textellipsis'
import constants from '../../../config/constants';
import PinConfigForm from '../../../components/addPinConfig'
import SelectComponent from '../../../components/select';
import ConfirmModal from '../../../components/confirmmodal';
import Utility from '../../../helpers/Utility';
import '../settings.css';

const { Search } = Input;

const pinTypesMap = new Map([
    [1, "I2C Pin"],
    [2, "SPI Pin"],
    [3, "TH Pin"],
    [4, "SPI and TH Pin"],
    [5, "GPIO Common Pin"],
    [6, "GPIO Pin 1"],
    [7, "GPIO Pin 2"],
    [8, "GPIO Pin 3"],
    [9, "GPIO Pin 4"],
    [10, "GPIO Pin 5"],
    [11, "GPIO Pin 6"],
    [12, "GPIO Pin 7"],
    [13, "Switch Common Pin"],
    [14, "Switch 1"],
    [15, "Switch 2"],
    [16, "Switch and GPIO Pin"],
    [17, "Return Humidity" ],
    [18, "Supply Humidity" ],
    [19, "I2C, SPI and TH Pin"]
])

const filterOptions = [
    { _id: '', name: 'All Pins' },
    { _id: 'current', name: 'Current' },
    { _id: 'pressure', name: 'Pressure' },
    { _id: 'temperature', name: 'Temperature' },
    { _id: 'voc', name: 'Voc' },
    { _id: 'vibration', name: 'Vibration' },
    { _id: 'flow', name: 'Flow' },
    { _id: 'switch', name: 'Switch' },
    { _id: 'voltage', name: 'Voltage' },
    { _id: 'humidity', name: 'Humidity' },
    { _id: 'light', name: 'Light' },
    { _id: 'sound', name: 'Sound' }
]

function PinConfig() {

    const [loading, setLoading] = useState(true)
    const [pinConfigList, setPinConfigList] = useState([])
    const [visible, setVisible] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedConfig, setSelectedConfig] = useState<any>({})
    const [configTotal, setConfigTotal] = useState(0)
    const [selectedFilter, setSelectedFilter] = useState<any>(filterOptions[0])
    const [typeFilter, setTypeFilter] = useState('')
    const [searchFilter, setSearchFilter] = useState('')
    const [pinRepresentationList ,setPinRepresentationList] = useState<any>([{}]);

    useEffect(() => {
        setSelectedConfig({})
        getPinConfigurations(currentPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPinConfigurations = async (pageNumber: number = 0, typeFilter: string = '',queryFilter: string = '') => {

        pageNumber = pageNumber === 0 ? currentPage : pageNumber;
        const limit= constants.maxLimit
        try {
            const response = await settingsService.getPinConfigurations(pageNumber, limit, typeFilter, queryFilter)
            await getPinRepresentations()
            setPinConfigList(response.data.pin_configuration);
            setCurrentPage(pageNumber)
            setConfigTotal(response.data.total)
            setLoading(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    const setSearchString = React.useCallback(_.debounce(getPinConfigurations, 700), [])   

    const handleFilterSelection = (option: any) => {
        setSelectedFilter(option)
        setTypeFilter(option._id??'')
        getPinConfigurations(1, option._id, searchFilter)
    }


    const addPinConfiguration = async (data: PinConfiguration) => {
        setLoading(true)
        try {
            await settingsService.addPinConfiguration(data)
            closeForm()
            let pageNumber = Math.ceil((configTotal + 1)/constants.maxLimit)
            setSelectedFilter(filterOptions[0])
            setTypeFilter('')
            getPinConfigurations(pageNumber, typeFilter, searchFilter)
            setLoading(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    const updatePinConfiguration = async (data: PinConfiguration) => {
        setLoading(true)
        try {
            await settingsService.updatePinConfiguration(data)
            closeForm()
            setSelectedFilter(filterOptions[0])
            setTypeFilter('')
            getPinConfigurations(currentPage, typeFilter, searchFilter)
            setLoading(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    const deletePinConfiguration = async (id: string) => {

        try {
            setLoading(true)
            setVisible(false)
            await settingsService.deletePinConfiguration(id)
            closeConfirmModal()
            closeForm()
            let pageNumber = Math.ceil((configTotal - 1)/constants.maxLimit)
            getPinConfigurations(pageNumber, typeFilter, searchFilter)
            setLoading(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    const showPinForm = (pinConfig?: PinConfiguration) => {
        
        if (pinConfig) {
            setSelectedConfig(pinConfig)
        }else{
            setSelectedConfig({})
        }
        setVisible(true)
    }

    const closeForm = () => {
        setVisible(false)
    }


    const closeConfirmModal = () => {
        Utility.closeModal('confirmmodal')
    }

    const getPinRepresentations = async () => {
        try {
            const resp = await settingsService.pinRepresentations();
            setPinRepresentationList(resp.data.pin_representation)
          } catch (error) {
          }

    }

    return (
       <div className="padding-b50">            
            <div> 
                <div className="">
                {
                loading &&
                <LoadingWheel />
                }
                    <div className="row mb-25">
                        <div className="col-lg">
                            <p className="table-title media-padding">Pin Configurations</p>
                        </div>
                        <div className="filter-select ml-auto">
                            <SelectComponent name="typeFilter"
                                data={filterOptions}
                                value={selectedFilter}
                                onChange={handleFilterSelection}
                            />
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 media-margin-tp mt-auto">
                            <Search
                                placeholder="Search"
                                value={searchFilter || ""}
                                size="large"
                                onChange={(e) => { setSearchFilter(e.target.value); setSearchString(1,typeFilter, e.target.value) }}
                                allowClear
                                enterButton />
                        </div>
                    </div>
                    {pinConfigList.length > 0 ?
                    <div className="row no-gutters table-wrap">
                        <table className="table custom-table table-style">
                            <thead className="table-header-bg">
                                <tr>
                                    <th className="text-center" scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Representation</th>
                                    <th scope="col">Type</th>
                                    <th className="text-center" scope="col">Pin Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pinConfigList.length > 0 &&
                                    pinConfigList.map((item: any, index) => {

                                        return (
                                            <tr className='click-icon' key={index} onClick={() => showPinForm(item)}>
                                                <td className="text-center">{(constants.maxLimit * (currentPage - 1)) + index + 1}</td>
                                                <td><EllipsisProps length={30}>{item.display_name}</EllipsisProps></td>
                                                <td><EllipsisProps length={30}>{item.representation}</EllipsisProps></td>
                                                <td className="text-capitalize" ><EllipsisProps length={16}>{item.type}</EllipsisProps></td>
                                                <td className="text-capitalize text-center" ><EllipsisProps length={16}>{pinTypesMap.get(item.pin_type)}</EllipsisProps></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                     :
                     <div className="text-center w-100 no-card-ph">
                         <div className="no-result-container">
                           <p>No pin configuration found</p>
                         </div>
                     </div>
                    }
                    <div className="pagination-container">
                        {Math.ceil(configTotal / constants.maxLimit) > 1 ?

                            <Pagination
                                hideNavigation={true}
                                activePage={currentPage}
                                itemsCountPerPage={constants.maxLimit}
                                totalItemsCount={configTotal}
                                itemClass={"item-class"}
                                activeClass={"active-class"}
                                pageRangeDisplayed={5}
                                onChange={(pageNumber) => getPinConfigurations(pageNumber, typeFilter, searchFilter)}
                            />
                            : null
                        }
                    </div>
                    {visible ?

                        <PinConfigForm
                            visible={visible}
                            setVisibleClose={closeForm}
                            selectedConfig={selectedConfig}
                            addPinConfiguration={(data: PinConfiguration) => addPinConfiguration(data)}
                            updatePinConfiguration={(data: PinConfiguration) => updatePinConfiguration(data)}
                            pinTypesMap={pinTypesMap}
                            pinRepresentationList = {pinRepresentationList}
                        />
                        : null}
                    <ConfirmModal
                        title={"Delete Pin Configuration"}
                        message={"Are you sure you want to delete this pin configuration"}
                        closeConfirmModal={closeConfirmModal}
                        delete={() => deletePinConfiguration(selectedConfig._id)}
                        isLoading={false}
                    />

                    <div className="add-icons" onClick={() => { showPinForm() }}>
                        <i className="fa fa-plus" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PinConfig
