import React, { useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { Input, Form, Upload, message, Modal, Spin } from "antd";
import uploadImageLogo from "../../assets/images/image-upload.png";
import issueTrackerServices from "../../services/issueTrackerServices";
import ConfirmationModal from "../confirmation-modal";
import constants from "../../config/constants";
import Utils from "../../helpers/Utility";
import { useHistory } from "react-router-dom";
import Routes from "../../routing/routes";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  setLoading: (loading: boolean) => void;
  fetchIssueDetailsWithId: () => void;
  ticketId: string | null;
  loading: boolean;
}

const AttachmentUpload: React.FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  setLoading,
  fetchIssueDetailsWithId,
  ticketId,
  loading
}) => {
  const { Dragger } = Upload;
  const [createNoteForm] = useForm();
  const [fileList, setFileList] = useState<any>([]);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false)
  const [note, setNote] = useState('')
  const history = useHistory();

  const handleUpload = (info: any) => {
    const file = info?.fileList[0]?.originFileObj;
    if (file) {
      try {
        setFileList(info?.fileList);
      } catch (error) {
      }
    }
  };

  const handleOk = async () => {
    try {
      setLoading(true);
      const createAttachmentsValues = createNoteForm.getFieldsValue();
      const formData = new FormData();
      formData.append("text", createAttachmentsValues.attachmentNote || " ");

      if (createAttachmentsValues?.attachmentImages) {
        const fileData =
          createAttachmentsValues?.attachmentImages?.fileList.map(
            (file: {
              name: string;
              type: string;
              thumbUrl: string;
              url: string;
              originFileObj: any;
            }) => {
              return {
                name: file.name,
                type: file.type,
                uri: file.thumbUrl || file.url,
                file: file.originFileObj,
              };
            }
          );

        if (fileData?.length) {
        fileData?.forEach((file: { file: string | Blob }) => {
          formData.append("attachments", file?.file);
        });
      }
    }
          createNoteForm.resetFields();
          setFileList([]);
        const addAttachment =
          await issueTrackerServices.addIssueTrackerAttachments(
            ticketId,
            formData
          );
        if (addAttachment?.status === 200) {
          message.destroy();
          message.success(constants.message.attachmentUploadSuccess);
          fetchIssueDetailsWithId();
          setLoading(false);
          setIsModalOpen(false);
        }
    } catch (error: any) {
      createNoteForm.resetFields();
      setFileList([]);
      const errorData = error?.response?.data
        const isUnauthorizedIssue = Utils.issueTrackerErrorHandler(errorData, constants.responseCode.unAuthorized, constants.message.issue_allocation_changed, constants.message.issue_doesnot_exist)
        if (isUnauthorizedIssue) {
            message.destroy()
            await message.error(error?.response?.data?.message)
            history.push({pathname : Routes.issuetracker});
            setLoading(false)
        } else {
            message.destroy()
            message.error(error?.response?.data?.message || constants.message.internalServerError)
            setLoading(false)
        }
    }
  };
  const beforeUploadValidation = (file: any) => {
    const isDuplicate = fileList?.some((existingFile: any) => 
    existingFile?.name === file?.name && existingFile?.size === file?.size && existingFile?.type === file?.type
    );
    if (isDuplicate) {
      message.destroy()
      message.error(constants.message.imageAlreadyExist);
      return Upload.LIST_IGNORE;
    }
      if ((fileList?.length + 1) > 5) {
        message.destroy()
        message.error(constants.message.imageMaxLimitReached);
        return Upload.LIST_IGNORE;
      }
    const isImage = file?.type?.startsWith('image/');
    if (!isImage) {
      message.destroy()
      message.error(constants.message.fileTypeError);
      return Upload.LIST_IGNORE;
    }
    if (
      !(file.type === "image/png") &&
      !(file.type === "image/jpg") &&
      !(file.type === "image/jpeg") &&
      !(file.type === "image/webp")
    ) {
      message.destroy()
      message.error(constants.message.unsupportedImageFormat);
      return Upload.LIST_IGNORE;
    }
    if (file.size / 1024 / 1024 > 10) {
      message.destroy()
      message.error(constants.message.imageSizeExceeded);
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  const removeImage = (file: any) => {
    const index = fileList?.indexOf(file);
    const newFileList = fileList?.slice();
    newFileList?.splice(index, 1);
    setFileList(newFileList);
  };

  return (
    <>
      <Modal
        onCancel={() => {
          setIsModalOpen(false);
          createNoteForm.resetFields();
          setFileList([]);
        }}
        onOk={() => {
          setIsConfirmationVisible(true)
        }}
        title="Add Attachments & Notes"
        open={isModalOpen}
        closable={false}
        width={700}
        className="p-5"
        okText="Upload Attachment"
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: (fileList?.length === 0 && note === '') || loading }}
      >
      <Spin spinning={loading}>
        <Form form={createNoteForm}>
          <Form.Item name="attachmentImages">
            <Dragger
              showUploadList
              onChange={handleUpload}
              beforeUpload={beforeUploadValidation}
              accept=".jpg,.png,.jpeg,.webp"
              onRemove={(file) => removeImage(file)}
              listType="picture"
              onPreview={() => {}}
            >
              <p className="ant-upload-drag-icon">
                <img
                  src={uploadImageLogo}
                  alt={"file1"}
                  style={{ width: "120px", height: "120px" }}
                />
              </p>
              <p></p>
              <p className="ant-upload-text alert-link">
                Click or drag file to this area to upload image(s)
              </p>
              <p className="ant-upload-hint">
                 Maximum of 5 images allowed for a description
              </p>
              <p className="ant-upload-hint settings-tr">
                Accepted formats - .jpeg .png .jpg .webp
              </p>
            </Dragger>
          </Form.Item>
          <br />
          <Form.Item name="attachmentNote" normalize={Utils.trimSpacesInValues}>
            <Input.TextArea
              placeholder="Add Description..."
              maxLength={constants.attachmentNotesLength}
              showCount
              onChange={(e) => setNote(e.target.value)}
            ></Input.TextArea>
          </Form.Item>
        </Form>
      </Spin>
      </Modal>
      <ConfirmationModal 
        isVisible={isConfirmationVisible} 
        setIsVisible={setIsConfirmationVisible}
        onConfirmFunction={() => handleOk()} 
        confirmationMessage={constants.message.uploadConfirmation} 
        confirmationTitle={"Upload Attachments"}
      >
      </ConfirmationModal>
    </>
  );
};
export default AttachmentUpload;
