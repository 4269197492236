import {
    DownloadOutlined,
} from '@ant-design/icons';

import {Segmented, Button, Tooltip, DatePicker, Space} from 'antd';
// import DateRange from './DateRange';
import { useState, useEffect } from "react";
import issueTrackerService from '../../services/issueTrackerServices'
import { buildSingleUserIssueQueryParams } from '../../helpers/IssueQueryHelper';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;


const rangePresets = [
    {
        label: 'Last 7 Days',
        value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
        label: 'Last 14 Days',
        value: [dayjs().add(-14, 'd'), dayjs()],
    },
    {
        label: 'Last 30 Days',
        value: [dayjs().add(-30, 'd'), dayjs()],
    },
    {
        label: 'Last 90 Days',
        value: [dayjs().add(-90, 'd'), dayjs()],
    },
];

const OnDemandSettings = (props) => {
    const { data, onRangeChange } = props;
    const [detail, setDetail] = useState("Detailed");
    const [onDemandReady, setOnDemandReady] = useState(false);
    const [userUpdate, setUserUpdate] = useState('');

    // Mange the demandGeneration button enabled/disabled state
    useEffect(() => {
        // To avoid using the sate onDemandReady directly and implying that it
        // is a dependency of this effect, we use a local variable to check
        // the state of the settings
        let onDemandReady = false;
        if (data?.dateRange?.[0] && detail) {
            onDemandReady = true;
        }
        setOnDemandReady(onDemandReady);
        // console.log("Ready? ", onDemandReady)
    }, [data, detail]);

    // This function is called when the user clicks the generate report button.
    // It then builds the URI and calls the doFetchDownload function.
    const handleOnClick = () => {
        doFetchDownload();
    };

    // This function is called when the user selects a detail level from the
    // segment selector. It then updates the state of the detail level.
    const handleDetailChange = (value) => {
        setDetail(value);
    };

    // This function fetches the report from the backend and downloads it.
    const doFetchDownload = async (url) => {

        try {
            let params = buildSingleUserIssueQueryParams(data)
            const resp = await issueTrackerService.fetchIssueReport(params, detail)

            const url = URL.createObjectURL(resp.data);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = "report";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);

        } catch (e) {
            alert("No issues found, please try other filter again.");
        }
    };

    return (
        <>
            <div className="p-0">{userUpdate}</div>
            <Space className="mr-4">
            <Segmented className='form-control-button' size='large'
                options={['Detailed', 'Overview']}
                onChange={e => handleDetailChange(e.toString())}
            />

            {/* TODO: presets range does not work */}
            <RangePicker className='form-control-button'
                         format="MM-DD-YYYY" onChange={onRangeChange} style={{ minWidth: "220px" ,color:'black'}} presets={rangePresets} />
                <div>
                    <Tooltip position="top" title="Generate Report">
                        <Button  icon={<DownloadOutlined />}
                                 type="primary"
                                 size="large"
                                 disabled={!onDemandReady}
                                 onClick={handleOnClick}
                                />
                    </Tooltip>
                </div>

            </Space>
        </>

    );
}

export default OnDemandSettings;