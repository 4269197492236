import Api from '../api';

class MaintenaceService {
  getMaintenaceChecklistItems = async () => {
    return Api.get('/maintenance-checklist')
  }

  saveMaintenanceCheckList = async (params: any) => {
    return Api.post('/maintenance-checklist', params)
  }

  removeMaintenanceCheckListItem = async (params: any) => {
    return Api.delete('/maintenance-checklist', { data: params })
  }

  getMaintenanceHistory = async (sensorBoxId: string) => {
    return Api.get(`/maintenance-checklist/history/${sensorBoxId}`)
  }

  getSensorboxMaintenanceList = async (sensorBoxId: string) => {
    return Api.get(`/maintenance-checklist/${sensorBoxId}`)
  }

}

export default new MaintenaceService()