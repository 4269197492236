import React from "react";
import { Card, Typography } from "antd";
import Icon from "../icon";

import authStore from '../../store/authStore'

import Utility from '../../helpers/Utility';

interface Props {
  subSystem?: any;
  setCenterCoordOnMouseHover: (data: any) => void;
  onClickCard: (data: any) => void;
  onClickSnooze: () => void;
  index?: any
}

const { Text } = Typography;

const AlertCard: React.FC<Props> = ({ subSystem, setCenterCoordOnMouseHover, onClickCard, onClickSnooze, index }) => {

  const hoursOutstanding = subSystem?.alert_outstanding_time ? Utility.getMomentFromNow(subSystem?.alert_outstanding_time) : 0

  const demoCheck = (authStore?.currentUser?.active_role?.role_type === -1);

  const consumerName = demoCheck ? `Consumer ${index + 1}` : subSystem?.consumer?.[0]?.name ? subSystem?.consumer?.[0]?.name : ''

  const location = demoCheck ? `-` : subSystem?.place ? subSystem?.place : ''

  const systemName = demoCheck ? subSystem?.masked_name??subSystem?.sensor_box_name : subSystem?.alias ? subSystem?.alias : subSystem?.sensor_box_name

  return (
    <>
      <Card className="custom-card card-padding border-0" onMouseOver={() => { setCenterCoordOnMouseHover(subSystem) }} onClick={() => { onClickCard(subSystem) }}>
        <div className="d-flex w-100 alert-collapse-container h-100 flex-column min-w-0 pr-4">
          <div className="flex-fill">
            <div className="d-flex align-items-center">
              <Text 
                ellipsis={{ tooltip: systemName }}
                className="mr-1 mb-0 system-name"
              >{systemName}
              </Text>
              <div className={`small-circle-alert ml-1 ${Utility.getStatus(subSystem?.status)}`}></div>
            </div>
            {authStore?.currentUser?.active_role?.role_type !== 4 &&
            <Text 
              ellipsis={{ tooltip: consumerName }}
              className="d-block text-gray">
              <Icon className="icon-14 mr-2" iconRef="#user_icon" />
              {consumerName}
            </Text>
            }
            <Text 
              ellipsis={{ tooltip: location }}
              className="d-block text-gray">
              <Icon className="icon-14 mr-2" iconRef="#location" />
              {location}
            </Text>
            <div className="d-flex system-button my-2">
              <div className="alert-hours mr-3">
                <Icon className="icon-14 mr-1" iconRef="#last_updated" />
                {hoursOutstanding ? hoursOutstanding : 'N/A'}
              </div>
            </div>
          </div>
          <div className="flex-auto">
            {/* <Button className="btn snooze-btn" onClick={(e) => {
              e.stopPropagation();
              onClickSnooze()
            }}>
              <Icon className="icon-14 mr-1" iconRef="#snooze" />
              Snozze
            </Button> */}
          </div>

        </div>
      </Card>
    </>
  );
}
export default AlertCard;
