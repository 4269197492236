const Permissions = {

      super_admin_access : 1,
      org_admin_access : 2.0,
      
      view_all_sensorboxes_assets: 3.0,
      view_own_sensorboxes_assets: 3.1,
      view_contractor_sensorboxes_assets: 3.2,
      view_assigned_users_sensorboxes_assets: 3.3,
      create_or_update_sensorboxes_assets: 3.4,
      delete_sensorboxes_assets: 3.5,
      view_only_partial_asset_sensorbox_details: 3.6,

      view_all_users: 4.0,
      view_assigned_users: 4.1,
      view_parents_users: 4.2,
      create_edit_users: 4.3,
      delete_users: 4.4,

      view_raw_data: 6.0,

      view_roles: 7.0,
      create_edit_delete_roles: 7.1,

      view_system_status: 8.0,
      
      view_all_inspections: 9.0,
      view_created_inspections: 9.1,
      view_team_created_inspections: 9.2,
      create_edit_inspections: 9.3,

      view_all_issue_tracker: 10.0,
      create_edit_issue_tracker: 10.1,
      create_edit_attachment_issue_tracker: 10.2,

      details_view_all_sensorboxes_assets: 5.0,
      details_view_own_sensorboxes_assets: 5.1,
      details_view_contractor_sensorboxes_assets: 5.2,
      details_view_assigned_users_sensorboxes_assets: 5.3,

}

export default Permissions