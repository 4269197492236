/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Modal, Row } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import IssueTicketDetails from '../issue-tracker-ticket-form';

const { confirm } = Modal;

interface Props {
    visible?: any,
    issueDetails?: any,
    onClickCancel: () => void;
    onDelete: () => void;
    onClickEdit: (values: any) => void;
    contractorList?: any[]
}

const IssueTrackerEditTicket: React.FC<Props> = ({ visible, issueDetails, onClickEdit, onDelete, onClickCancel, contractorList }) => {

    const onCreateTicket = async (values: any) => {
        onClickEdit(values)
    }

    const onClickCancelTicket = () => {
        onClickCancel()
    }

    const handleDelete = () => {
        showDeleteConfirm()
    }

    const showDeleteConfirm = () => {
        confirm({
            title: `Are you sure delete this ticket ${issueDetails?.issue_id}?`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',

            onOk() {
                onDelete()
            },
        });
    };

    return (
        <div className="">
            <Modal title="Edit Ticket" visible={visible} onCancel={onClickCancelTicket} width={1700} footer={null}>
                <Row gutter={[16, 16]}>
                <IssueTicketDetails
                    issueDetails={issueDetails}
                    onCreateTicket={(values: any) => onCreateTicket(values)}
                    onClickEdit={(values: any) => onClickEdit(values)}
                    onClickCancel={onClickCancelTicket}
                    onDelete={handleDelete} isLoading={false}
                    issueId={issueDetails?.id}
                    org_id={issueDetails?.org_id}  
                    isEditReq={true} 
                    contractorList={contractorList}        
                    />
                    </Row>
            </Modal>
        </div>
    );
}

export default IssueTrackerEditTicket;
