import React from 'react';
import './switch.css'

interface Props { //props
  checked : boolean,
  label : String,
  onChange: (checkFlag: any) => void;
}

const Switch: React.FC<Props> = ({ checked, label ,onChange  }) => {

  return (
        <div className="mb-5  hide-checkbox">
            <div className="form-inline mr-4">
                <label className="label-switch switch-primary">
                <input type="checkbox" 
                    className="switch switch-bootstrap status" 
                    name="status" 
                    id="status" 
                    value="1" 
                    checked={checked}
                    onChange={(e:any) => { onChange(e) }}
                    />
                <span className="lable"></span>
                </label>
                <label className="custom-label font-weight-bold ml-2" style={{ fontSize: '1rem' }}>
                    {label}
                </label>
            </div>
        </div>
  )
}

export default Switch;
