import React, { useState, useEffect } from 'react'
import toast from '../../../components/toast';
import LoadingWheel from '../../../components/loadingwheel';
import roleService from '../../../services/roleService';
import EllipsisProps from '../../../components/textellipsis'
import { Role } from '../../../interface'
import constants from '../../../config/constants';
import AddRoleModal from '../../../components/add-role'
import ConfirmModal from '../../../components/confirmmodal';
import Utility from '../../../helpers/Utility';
import authStore from '../../../store/authStore';
import organizationService from '../../../services/organizationService';
import SelectComponent from '../../../components/select';
import '../settings.css';
import Permissions from '../../../config/permssions';
import moment from 'moment';


const {
    super_admin_access,
    create_edit_delete_roles,

  } = Permissions

function Roles() {

    const [loading, setLoading] = useState(true)
    const [roleList, setRoleList] = useState([])
    const [visible, setVisible] = useState(false)
    const [organizationFilterData, setOrganizationFilterData] = useState([])
    const [selectedFilter, setSelectedFilter] = useState<any>({_id : '',name : 'Select Organization'})
    const [selectedRole, setSelectedRole] = useState<any>({})

    useEffect(() => {
        if(authStore?.currentUser?.access?.includes(super_admin_access)){
            getOrganizations()
        }else{
            getRoles();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRoles = async (id? :any) => {
        try {
            if(id){
                const response = await roleService.getRoles(id)
                setRoleList(response.data.roles);
                setLoading(false)
            }
            else{
                const response = await roleService.getRoles()
                setRoleList(response.data.roles);
                setLoading(false)
            }
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    const getOrganizations = async () => {

        try {
            const response = await organizationService.getOrganizations()
            setSelectedFilter(response.data.organizations[0])
            getRoles(response.data.organizations[0]._id)
            setOrganizationFilterData(response.data.organizations);
            setLoading(false)
        } catch (error) {
            toast.error(constants.message.error_role_organization)
            setLoading(false)
        }
    }

    const addRole = async (data: Role) => {
        setLoading(true)
        try {
            await roleService.addRole(data)
            closeForm()
            getRoles()
            setLoading(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    const updateRole = async (data: Role) => {
        setLoading(true)
        try {
            await roleService.updateRole(data)
            closeForm()
            getRoles()
            setLoading(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    const handleFilterSelection = (option: any) => {
        setSelectedFilter(option)
        getRoles(option._id)
    }

    const deleteRole = async (id: string) => {

        try {
            setLoading(true)
            setVisible(false)
            await roleService.deleteRole(id)
            closeConfirmModal()
            closeForm()
            getRoles()
            setLoading(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    const handleSelectingTable = (item :any)=> {
                showRoleForm(item)
    };

    const showRoleForm = (role?: Role) => {
        
        if (role) {
            setSelectedRole(role)
        }else{
            setSelectedRole({})
        }
        setVisible(true)
    }

    const closeForm = () => {
        setVisible(false)
    }

    const closeConfirmModal = () => {
        Utility.closeModal('confirmmodal')
    }

    return (
       <div className="">            
            <div>
                <div className="padding-b50">
                {
                loading &&
                <LoadingWheel />
                }
                    <div className="row mb-4 settings-space-between">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-9 col-xl-9">
                            <p className="table-title media-padding">Roles</p>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                        {authStore?.currentUser?.access?.includes(super_admin_access) &&
                        <div className="filter-select">
                            <label className="custom-label">Organization</label>
                                <SelectComponent name="typeFilter"
                                    data={organizationFilterData}
                                    value={selectedFilter}
                                    onChange={handleFilterSelection}
                                />
                        </div>
                        }
                        </div>
                        
                    </div>
                    {(roleList.length > 0) ?
                    <div className="row no-gutters table-wrap">
                        <table className="table custom-table table-style">
                            <thead className="table-header-bg">
                                <tr>
                                    <th scope="col">Role Name</th>
                                    <th scope="col">Role Heirarchy</th>
                                    <th scope="col">Created Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(roleList.length > 0) &&
                                    roleList.map((item: any, index) => {
                                        return (
                                            <tr className='click-icon' key={index} onClick={() => handleSelectingTable(item)}>
                                                <td className="text-truncate"><EllipsisProps length={20}>{item.name}</EllipsisProps></td>
                                                <td className="text-truncate">{item.role_hierarchy}</td>
                                                <td className="text-truncate">{item.created_at ? moment(item.created_at).format('MM/DD/YYYY, HH:mm:ss') : ''}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    :
                        <div className="text-center w-100 no-card-ph">
                            <div className="no-result-container">
                                <p>No roles found</p>
                            </div>
                        </div>
                    }

                        <AddRoleModal
                            visible={visible}
                            setVisibleClose={closeForm}
                            selectedRole={selectedRole}
                            addRole={(data: Role) => addRole(data)}
                            updateRole={(data: Role) => updateRole(data)}
                            rolePositionReferenceType={roleList}
                        />

                    <ConfirmModal
                        title={"Delete Role"}
                        message={"Are you sure you want to delete this role"}
                        closeConfirmModal={closeConfirmModal}
                        delete={() => deleteRole(selectedRole._id)}
                        isLoading={false}
                    />
                    {(!authStore?.currentUser?.access?.includes(super_admin_access) || authStore?.currentUser?.access?.includes(create_edit_delete_roles)) &&
                    <div className="add-icons" onClick={() => { showRoleForm() }}>
                        <i className="fa fa-plus" aria-hidden="true"></i>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Roles