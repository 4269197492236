import React from 'react';
import Selectdrop from 'react-select'
import './select.css'

export interface selectProps {
  data        : Array<object>
  onChange    : any
  name        : string
  value       : any
  isDisabled? : boolean
  clearValue? : any
  showClose?  : any,
  onBlur?     : any,
  isMulti?    : boolean,
  isGroup?    : boolean,
  customStyles? : any
  userAccess? : boolean
  maxOptions? : number
}

export interface selectState {
}

class Select extends React.Component<selectProps, selectState>{

  constructor(props:selectProps){
    super(props);
    this.state = {
    }
  }

  handleChange = (value:any) => {  
    if(this.props.isMulti){
      this.props.onChange(value)
    }
    else if(this.props.isGroup){
      this.props.onChange(value)
    }
    else{
      this.props.data.forEach((user : any) => {
        if(user._id === value.value){
          this.props.onChange(user)
        }
      });
    }
  };

  getValueObject = (value:any)=> {
    return {
      value: value._id,
      label: value.name
    }
  }


  optionarray = ()=> {
    let dataArray = [] as any;
    this.props.data?.forEach((item:any)=>{
      dataArray.push({value:item._id,label:item.name})
    })  
    return dataArray;
  }



  render() { 
    return (
      <>
        <Selectdrop class="form-control custom-select"
        classNamePrefix="custom-scroll-select"
        isDisabled  = {this.props.isDisabled}
        name        = {this.props.name} 
        value       = {!this.props.isMulti ? this.props.value ? this.getValueObject(this.props.value) :{value:'', label:'Select'}:this.props.value } 
        onChange    = {this.handleChange} 
        noOptionsMessage={() => {
          return ( this.props.maxOptions ? (this.props.value?.length >= this.props.maxOptions ? 'You have reached the maximum limit' : 'No options available') : 'No options available') ;
        }}
        options     = {this.props.maxOptions ? (this.props.value?.length >= this.props.maxOptions ? [] : this.props.isGroup ? this.props.data : this.optionarray()) : this.props.isGroup ? this.props.data : this.optionarray()}  
        onBlur      = {this.props.onBlur} 
        isMulti     = {this.props.isMulti ? this.props.isMulti :false}
        styles      = {this.props.customStyles ? this.props.customStyles : {}}
      />
      {this.props.value && !this.props.isDisabled && this.props.showClose?
      <i className="fa fa-close close-btn-select" onClick={()=>this.props.clearValue()}></i>
      : null}
    </>
    );
  }

}
 
export default Select;
