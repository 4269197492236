import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import algorithmConfigService from '../../services/algorithmConfigServices';
import constants from '../../config/constants';
import validate from '../../helpers/validation';
import Utility from '../../helpers/Utility';

interface Props {
  show: boolean;
  onClose: () => void;
}

const TestAlgorithmConfigModal: React.FC<Props> = ({ show, onClose}) => {

  const [formData ,setFormData] = useState<any>({})
  const [algorithmResult ,setAlgorithmResult] = useState<any>({})
  const [errors, setErrors] = useState<any>({})

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const updateFormValue = (e :any)=> {
    const { name, value} = e.target;
    setFormData((prevState :any )=> ({
        ...prevState,
        [name]: value
    }));
  };

  const validateField = () => {
    let error: any = {}

    if (formData?.sensor_values) {
      error.sensor_values = ''
    } else {
      error.sensor_values = constants.message.test_algorithm_sensor_values_required
    }

    if (!(validate.isJson(formData?.sensor_values))) {
      error.sensor_values = constants.message.test_algorithm_Valid_json_required
    } else {
      if(JSON.parse(JSON.stringify(JSON.parse(formData?.sensor_values)))?.sensor_name){
        error.sensor_values = ''
      }else{
        error.sensor_values = constants.message.sensor_name_mandatory
      }
    }

    setErrors(error)
    return !(error.sensor_values)
  }

  const runTestAlgorithmConfig = async () => {
    try {
      if (validateField()){
        let resp : any = await algorithmConfigService.testAlgorithmConfig(formData)
        setAlgorithmResult(resp?.data.algorithmResult)
      }
    } catch (error) {

    }
  }

  function closeModal() {
    onClose()
    setErrors({})
    setAlgorithmResult({})
    setFormData({})
  }

  return (
        <Modal
        show={show}
        onHide={closeModal}
        >
        <Modal.Header closeButton>
        <div>Test Algorithm Config</div>
        </Modal.Header>
        <Modal.Body>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="custom-label" >Sensor values <span className="mand-star">*</span></label>
                      <textarea 
                        className="form-control resize-none text-area-input" 
                        id="sensor_values" 
                        name="sensor_values"
                        placeholder="Enter sensor values in JSON"
                        aria-describedby="emailHelp" 
                        onChange={updateFormValue}
                        disabled={false}
                      />
                      <div className="error-msg">{errors && errors.sensor_values ? errors.sensor_values : ''}</div>
                  </div>
                </div>

                <div className="col-md-12"> 
                  <label className="font-weight-bold ml-lg-2">{`Diagnostic Code - ${algorithmResult?.code ? algorithmResult?.code : `None`}`}</label>
                </div>
                <div className="col-md-12">
                  <label className="font-weight-bold ml-lg-2 ">Color Status - </label>  <i className={`fa fa-circle ml-2 mt-1 ${Utility.getSensorStatus(algorithmResult?.status)}`}  aria-hidden="true"></i>
                </div>

                <div className="modal-footer pb-0">
                  <button type="button" 
                    className="btn btn-primary default-btn"
                    onClick={() => 
                      runTestAlgorithmConfig()
                    }
                  >RUN
                  </button>
            </div>
        </Modal.Body>
    </Modal>
  )
}

export default TestAlgorithmConfigModal;
