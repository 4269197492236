import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './datepicker.css'

export interface selectProps {
  onChange          : any
  value             : any
  isClearable?      : boolean
  readOnly?         : boolean
  dateFormat?       : string
  minDate?          : Date
  maxDate?          : Date
  placeholderText?  : string
  disabled?         : boolean
}

export interface selectState {

}

class Select extends React.Component<selectProps, selectState>{

  constructor(props:selectProps){
    super(props);
    this.state = {

    }
  }


  render(){
    return (
    
            <div className="input-group bulleted date-pick-wrap">

                <DatePicker
                    className="date-picker-custom"
                    selected={this.props.value}
                    onChange={(e:any) => this.props.onChange(e)}
                    isClearable={(this.props.value && !this.props.disabled) ? this.props.isClearable : false}
                    readOnly={this.props.readOnly}
                    dateFormat={this.props.dateFormat ? this.props.dateFormat :  "MMMM d, yyyy"}
                    minDate={this.props.minDate}
                    maxDate={this.props.maxDate}
                    disabled={this.props.disabled}
                    placeholderText={this.props.placeholderText ? this.props.placeholderText : "Select Date"}
                />
                <div className="input-group-prepend test-date-ic">
                    <span className="input-group-text pl-2">
                    <i className="fa fa-calendar" aria-hidden="true"></i>
                    </span>
                </div>
            </div>
        
    )
  }

}
 
export default Select;
