import React, { useEffect, useState } from 'react';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import authStore from '../../store/authStore';
import Utils from '../../helpers/Utility';
import LoadingWheel from '../loadingwheel';
import SensorService from '../../services/sensorService';
import "./alertLogsModal.css"
import constants from '../../config/constants';
import moment from 'moment'
import InvalidRangeAlertModal from "../InvalidRangeAlertModal/InvaliRangeAlertModal";
import Permissions from '../../config/permssions';

const maxLimit = 16
const {
    view_only_partial_asset_sensorbox_details
} = Permissions

interface Props {
  show: boolean;
  onClose: () => void;
  sensorBoxName?: string;
  sensorBox: any,
  showLogDataModal: (logData: any) => void;
  showInvalidRangeAlertModal:(logData: any) => void;
  lastTab:string;
}

const AlertLogsModal: React.FC<Props> = ({ show, sensorBoxName, sensorBox, onClose,
  showLogDataModal,showInvalidRangeAlertModal ,lastTab}) => {

  const [loading, setLoading] = useState(true)
  const [alertLogs, setAlertLogs] = useState<Array<any>>([])
  const [deviceLogs, setDeviceLogs] = useState<Array<any>>([])
  const [validRangeLogs, setValidRangeLogs] = useState<Array<any>>([])
  const [currentAlertLogPage, setCurrentAlertLogPage] = useState(1)
  const [currentDeviceLogPage, setCurrentDeviceLogPage] = useState(1)
  const [currentValidRangeLogPage, setCurrentValidRangeLogPage] = useState(1)
  const [alertLogsTotal, setAlertlogsTotal] = useState(0)
  const [deviceLogsTotal, setDeviceLogsTotal] = useState(0)
  const [validRangeLogsTotal, setValidRangeLogsTotal] = useState(0)

  useEffect(() => {
    getAlertLogs()
    getDeviceAlertLogs()
    getValidRangeLogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorBox, sensorBoxName]);

  const getAlertLogs = async (pageNumber: number = 0) => {

    if (sensorBoxName) {
      setLoading(true)
      pageNumber = pageNumber === 0 ? currentAlertLogPage : pageNumber;
      const response = await SensorService.getAlertLogs(sensorBoxName, pageNumber, maxLimit)
      setAlertLogs(response.data.logs)
      setCurrentAlertLogPage(pageNumber)
      setAlertlogsTotal(response.data.total)
      setLoading(false)
    }
    
  }

    const getDeviceAlertLogs = async (pageNumber: number = 0) => {

    if (sensorBoxName) {
      setLoading(true)
      pageNumber = pageNumber === 0 ? currentDeviceLogPage : pageNumber;
      const response = await SensorService.getDeviceOfflineLogs(sensorBoxName, pageNumber, maxLimit)
      setDeviceLogs(response.data.logs)
      setCurrentDeviceLogPage(pageNumber)
      setDeviceLogsTotal(response.data.total)
      setLoading(false)
    }
    
  }

  const getValidRangeLogs = async (pageNumber: number = 0) => {

    if (sensorBoxName) {
      setLoading(true)
      pageNumber = pageNumber === 0 ? currentValidRangeLogPage : pageNumber;
      const response = await SensorService.getValidRangeLogs(sensorBoxName, pageNumber, maxLimit)
      setValidRangeLogs(response.data.logs)
      setCurrentValidRangeLogPage(pageNumber)
      setValidRangeLogsTotal(response.data.total)
      setLoading(false)
    }
  }

  const demoCheck = (authStore?.currentUser?.active_role?.role_type === -1);
  const algorithmDescriptionCheck = (item: any) => {
    return item?.description
  }

  let failureModeStyleWidth = '54%'
  if(sensorBox.device_type === 'lockrouter') failureModeStyleWidth = '68%'

  return (
    <Modal
      show={show}
      onHide={onClose}
      size="lg"
    >
      <Modal.Header closeButton>
      <div>{sensorBoxName} {sensorBox.alias && <span className='pl-2'>( {sensorBox.alias} )</span> }</div>
      </Modal.Header>
      <Modal.Body>
        <Tabs variant="pills" className="checklist-tab mb-3" defaultActiveKey={lastTab??'status'}>
          <Tab eventKey="status" title="Health Status">
            {
            <div>
            <div className="table-wrap">
              {loading ?
                <LoadingWheel />
                :
                <table className={alertLogs.length > 0 ? "table custom-table table-style-logs text-custom-size" : "table custom-table table-style-no-logs "} style={{ tableLayout: 'fixed' }}>
                  {alertLogs.length > 0 ? 
                    <>
                      <thead className="table-header-bg">
                        <tr>
                          <th style={{ width: '5%' }} scope="col">#</th>
                          <th style={{ width: '5%' }} className="text-center" scope="col">Status</th>
                          {(sensorBox.device_type === 'general' || sensorBox.device_type === 'cyclictest') && <th style={{ width: '14%' }} className="text-center" scope="col">Diagnostics Code</th> }
                          <th style={{ width: failureModeStyleWidth }} className="text-center" scope="col">Failure Mode</th>
                          <th style={{ width: '22%' }} scope="col">Alert Mail Sent On</th>
                        </tr>
                      </thead>
                      <tbody>
                        {alertLogs.map((item: any, index) => {
                          return (
                            <tr  className={index%2 !== 0 ? "background-color click-icon" : "click-icon"} key={index} onClick={() => { sensorBox.device_type === 'general' && showLogDataModal(item) }}>
                              <td>{(maxLimit * (currentAlertLogPage - 1)) + index + 1}</td>
                              <td className="text-center"><i className={`fa fa-circle ${Utils.getSensorStatus(item.status)}`} aria-hidden="true"></i></td>
                              {(sensorBox.device_type === 'general' || sensorBox.device_type === 'cyclictest') && <td className="text-truncate text-center" title={item?.diagnostics_code}>{item?.diagnostics_code}</td> }
                              <td className="text-center" title={ algorithmDescriptionCheck(item)}>{algorithmDescriptionCheck(item)}</td>
                              <td className="text-truncate" title={moment(item?.timestamp).format('MM/DD/YYYY, HH:mm:ss')}>{moment(item?.timestamp).format('MM/DD/YYYY, HH:mm:ss')}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </>
                    :
                    <div>
                      <p className='no-logs-text'>No logs found</p>
                    </div>}
                </table>
              }
            </div>
            {alertLogs.length > 0 && !loading &&
            <div className={Math.ceil(alertLogsTotal / maxLimit) > 1 ? "pagination-container-logs" : "pagination-container-no-logs"}>
              {Math.ceil(alertLogsTotal / maxLimit) > 1 ?
                <Pagination
                  hideNavigation={true}
                  activePage={currentAlertLogPage}
                  itemsCountPerPage={maxLimit}
                  totalItemsCount={alertLogsTotal}
                  itemClass={"item-class"}
                  activeClass={"active-class"}
                  pageRangeDisplayed={5}
                  onChange={(pageNumber) => getAlertLogs(pageNumber)}
                />
                : null
              }
            </div>
          }
          </div>
          }
          </Tab>
          <Tab eventKey="device" title="Offline Status">
          {
            <div>
            <div className="table-wrap">
              {loading ?
                <LoadingWheel />
                :
                <table className={deviceLogs.length > 0 ? "table custom-table table-style-logs text-custom-size" : "table custom-table table-style-no-logs "} style={{ tableLayout: 'fixed' }}>
                  {deviceLogs.length > 0 ? 
                    <>
                      <thead className="table-header-bg">
                       {authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) ?
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Alert Mail Sent On</th>
                        </tr>
                          :
                        <tr>
                          <th style={{ width: '10%' }} scope="col">#</th>
                          <th style={{ width: '50%' }} scope="col">Recipients</th>
                          <th style={{ width: '40%' }} scope="col">Alert Mail Sent On</th>
                        </tr>
                        }
                      </thead>
                      <tbody>
                        {deviceLogs.map((item: any, index) => {
                          return (
                            <tr  className={index%2 !== 0 ? "background-color click-icon" : "click-icon"} key={index}>
                              <td>{(maxLimit * (currentDeviceLogPage - 1)) + index + 1}</td>
                              <td className="text-truncate" title={demoCheck ? `example@gmail.com` : item.emails.toString().replace(/,/g, ', ')}>{demoCheck ? `example@gmail.com` : item.emails.toString().replace(/,/g, ', ')}</td>
                              <td className="text-truncate" title={moment(item?.timestamp).format('MM/DD/YYYY, HH:mm:ss')} >{moment(item?.timestamp).format('MM/DD/YYYY, HH:mm:ss')}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </>
                    :
                    <div>
                      <p className='no-logs-text'>No logs found</p>
                    </div>}
                </table>
              }
            </div>
            {deviceLogs.length > 0 && !loading &&
            <div className={Math.ceil(deviceLogsTotal / maxLimit) > 1 ? "pagination-container-logs" : "pagination-container-no-logs"}>
              {Math.ceil(deviceLogsTotal / maxLimit) > 1 ?
                <Pagination
                  hideNavigation={true}
                  activePage={currentDeviceLogPage}
                  itemsCountPerPage={maxLimit}
                  totalItemsCount={deviceLogsTotal}
                  itemClass={"item-class"}
                  activeClass={"active-class"}
                  pageRangeDisplayed={5}
                  onChange={(pageNumber) => getDeviceAlertLogs(pageNumber)}
                />
                : null
              }
            </div>
          }
          </div>
          }
          </Tab>
          <Tab eventKey="invalid-range" title="Invalid Range">
          {
            <div>
            <div className="table-wrap">
              {loading ?
                <LoadingWheel />
                :
                <table className={validRangeLogs.length > 0 ? "table custom-table table-style-logs text-custom-size" : "table custom-table table-style-no-logs "} style={{ tableLayout: 'fixed' }}>
                  {validRangeLogs.length > 0 ? 
                    <>
                      <thead className="table-header-bg">
                        {authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) ?
                        <tr>
                          <th scope="col">#</th>
                          <th className="text-center" scope="col">Status</th>
                          <th scope="col">Representations</th>
                          <th scope="col">Alert Mail Sent On</th>
                        </tr>
                        :
                        <tr>
                          <th style={{ width: '5%' }} scope="col">#</th>
                          <th style={{ width: '10%' }} className="text-center" scope="col">Status</th>
                          <th style={{ width: '35%' }} scope="col">Representations</th>
                          <th style={{ width: '35%' }} scope="col">Recipients</th>
                          <th style={{ width: '15%' }} scope="col">Alert Mail Sent On</th>
                        </tr>
                        }
                      </thead>
                      <tbody>
                        {validRangeLogs.map((item: any, index) => {
                          return (
                            <tr  className={index%2 !== 0 ? "background-color click-icon" : "click-icon"} key={index} onClick={() => { sensorBox.device_type === 'general' && showInvalidRangeAlertModal(item) }}>
                              <td>{(maxLimit * (currentValidRangeLogPage - 1)) + index + 1}</td>
                              <td className="text-center"><i className={`fa fa-circle ${Utils.getSensorStatus(item.status)}`} aria-hidden="true"></i></td>
                              <td className="text-truncate" title={item?.representation.join(', ')}>{item?.representation.join(', ')}</td>
                              <td className="text-truncate" title={demoCheck ? `example@gmail.com` : item.emails.toString().replace(/,/g, ', ')}>{demoCheck ? `example@gmail.com` : item.emails.toString().replace(/,/g, ', ')}</td>
                              <td className="text-truncate" title={moment(item?.timestamp).format('MM/DD/YYYY, HH:mm:ss')}>{moment(item?.timestamp).format('MM/DD/YYYY, HH:mm:ss')}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </>
                    :
                    <tr>
                      <p className='no-logs-text'>No logs found</p>
                    </tr>}
                </table>
              }
            </div>
            {validRangeLogs.length > 0 && !loading &&
            <div className={Math.ceil(validRangeLogsTotal / maxLimit) > 1 ? "pagination-container-logs" : "pagination-container-no-logs"}>
              {Math.ceil(validRangeLogsTotal / maxLimit) > 1 ?
                <Pagination
                  hideNavigation={true}
                  activePage={currentValidRangeLogPage}
                  itemsCountPerPage={maxLimit}
                  totalItemsCount={validRangeLogsTotal}
                  itemClass={"item-class"}
                  activeClass={"active-class"}
                  pageRangeDisplayed={5}
                  onChange={(pageNumber) => getValidRangeLogs(pageNumber)}
                />
                : null
              }
            </div>
          }
          </div>
          }
          </Tab>
        </Tabs>

      </Modal.Body>
    </Modal>
  )
}

export default AlertLogsModal;
