import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import constants from '../../config/constants';
import Utility from '../../helpers/Utility';
import DeviceParameterDisplay from '../device-parameter-display/index'
import "./group-items.css"
import moment from 'moment'

interface Props { //props
    groups: any,
    hideSensorBox : boolean,
    hideSensorValues : boolean
}

const GroupItems: React.FC<Props> = ({ groups , hideSensorBox , hideSensorValues  }) => {

    const chartData = (sensor :any) => { //datas on chart
        let labelArray = groups.sensorboxes?.map((sensor: any) => {
                return constants.statusColor.get(String(sensor.status))
        })
        const counts = {} as any;
        labelArray?.forEach((value: any) => {
            counts[value.toUpperCase()] = (counts[value.toUpperCase()] || 0) + 1; 
        })
        const colorLabels = Object.keys(counts);
        const colorLabelValues = Object.values(counts);
        const data = {
            datasets: [{
                            data: colorLabelValues,
                            backgroundColor: colorLabels,
                            hoverBackgroundColor: colorLabels,
                            borderRadius : 0,
                            radius: '50px',
            }],
            labels : colorLabelValues
        }
        return data
    };

    const faultDevice = groups?.sensorboxes?.filter((sensor: any) => sensor.status !== 0 && sensor.status !== -1)
    const groupOptions = groups?.sensorboxes?.map((item:any)=>{
        let temp = [] as any;
        if(item.sensors?.length > 0){
            item.sensors?.forEach((pin:any)=>{
                let object = {
                    label : pin?.display_name,
                    value : `${item?.sensor_box_name}-${pin?._id}` ,
                }
                temp.push(object)
            })
        }
        const mainObject = {
            label : item.alias ? item?.alias : item?.sensor_box_name,
            options : temp
        }
        return mainObject
    })


    const chartClassName = ['col-12']
    const sensorBoxClassName = ['col-12 pr-lg-1']
    const sensorValuesClassName = ['col-12 pl-lg-0']
    const rowClassName =['row m-0']
    const statusClassName = ['mt-16 text-center']

    if(hideSensorValues && hideSensorValues){
        rowClassName.push('hidesensorvaluestv-view mb-5 align-content-start')
        statusClassName.push('w-100')
    }
    
    if(!hideSensorBox && !hideSensorValues){
        chartClassName.push('col-lg-3')
        sensorBoxClassName.push('col-lg-6')
        sensorValuesClassName.push('col-lg-3')
    }

    if(hideSensorBox && !hideSensorValues){
        chartClassName.push('col-lg-6')
        sensorValuesClassName.push('col-lg-6')
        rowClassName.push('hidesensorboxtv-view mb-5 align-content-start')
        statusClassName.push('w-50')
    }

    if(hideSensorValues && !hideSensorBox){
        rowClassName.push('hidesensorvaluestv-view mb-5 align-content-start')
        statusClassName.push('w-50')
    }

    return (
        <div className={rowClassName.join(' ')}>
        <div className = {chartClassName.join(' ')}>
            <div className="chartContainer">
                <Doughnut 
                    data={chartData(groups)} options={{
                    responsive: true,
                    cornerRadius: 8,
                    aspectRatio: 1,
                    legend: {
                        display: false,
                        position: "bottom",
                        align:'center',
                        reverse:true,
                        labels :{
                          padding:10,
                          boxWidth:12,
                          fontSize:13
                        }
                    },
                    tooltips: { enabled: false },
                    cutoutPercentage: 80,
                    }}  
                    height={200} width={200} />
                <div className="chartInner">
                    <div className="chartStatus">{groups.name}</div>
                    <div className="chartStatus"><i className={`fa fa-circle ${Utility.getSensorStatus(groups.status)}`} aria-hidden="true"></i></div>
                    <span className="mb-0 text-secondary small chartStatus">{groups?.last_updated_time ? moment(groups?.last_updated_time).format('MM/DD/YYYY, HH:mm:ss') : '-'}</span>
                </div>
            </div>
        </div>
        {!hideSensorBox && !hideSensorValues &&
        <div className =  {sensorBoxClassName.join(' ')}>
        <div className ="row m-0">
        {
            groups?.sensorboxes?.map((value : any) => {
                return (
                <div key={value?._id} className="tv-view px-1 mb-2">
                    <div
                    className="group-item pl-3 pr-5 py-2 dashboard-border position-relative"
                    >
                    <i className={`fa fa-circle ${Utility.getSensorStatus(value.status)}`} style={{ position: 'absolute', right: '1rem', top: '.5rem' }} aria-hidden="true"></i>
                    <p className="mb-0 text-truncate font-w-600 d_tab_txt_clr" title = {value.sensor_box_name}>
                        {
                        value.alias ? value.alias : value.sensor_box_name
                        }
                    </p>
                    <i title={value?.is_online ? 'Online' : 'Offline'} className={ value?.is_online ? 'fa fa-signal online-status' : 'fa fa-ban offline-status'} style={{ position: 'absolute', right: '1rem', top: '2.5rem' }} aria-hidden="true"></i>
                    <span className="mb-0 text-secondary small">{value?.last_updated_time ? moment(value?.last_updated_time).format('MM/DD/YYYY, HH:mm:ss') : '-'}</span>
                    </div>
                </div>
                )
            })
        } 
        </div>
        <label className="font-w-600">Status : <span style={{ color : `${faultDevice?.length > 0 ? '#FF0000' : '#088001'}` }}> {faultDevice?.length > 0 ?
            'Needs Attention'
            :'No Issues'} </span></label>
        </div>
        }
        {!hideSensorValues &&
        <div className = {sensorValuesClassName.join(' ')}>
            <DeviceParameterDisplay
                groupOptions = {groupOptions}
                sensorBoxes    = {groups.sensorboxes}
            />
        </div>
        }
        {(hideSensorBox || hideSensorValues) && 
        <div className={statusClassName.join(' ')}>
            <p className="mb-2 font-weight-bold"> Status : <span style={{ color : `${faultDevice?.length > 0 ? '#FF0000' : '#088001'}` }}> {faultDevice?.length > 0 ?
            'Needs Attention'
            :'No Issues'} </span> </p>
        </div>
        }
    {(!hideSensorBox && !hideSensorValues) && <span className="divider"></span>}
    </div>
    )
}

export default GroupItems;
