import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import SelectComponent from '../select';
import { Role } from '../../interface'
import constants from '../../config/constants';
import roleService from '../../services/roleService';
import Utility from '../../helpers/Utility';
import validate from '../../helpers/validation';
import authStore from '../../store/authStore';
import  Permissions from '../../config/permssions'

const { 
    super_admin_access,
    create_edit_delete_roles,
    org_admin_access
} = Permissions

export interface roleFormProps {
    setVisibleClose?: () => void;
    selectedRole?: Role;
    visible: boolean;
    addRole: (role: Role) => void;
    updateRole: (role: Role) => void;
    rolePositionReferenceType: any;
}

function AddRole(props: roleFormProps) {

    const [isUpdate, setIsUpdate] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [formData ,setFormData] =useState<any>({})
    const [needToSave, setNeedToSave] = useState(false)
    const [errors, setErrors] = useState<any>({})

    useEffect(() => {
        if(props.visible){
        initialSetup();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.visible]);

    const initialSetup = () => {
        if (props.selectedRole && props.selectedRole.hasOwnProperty('_id')) {
            setIsUpdate(true)
            setIsEdit(false)
            setFormData(props.selectedRole)

            let propsValue=[...props.rolePositionReferenceType];
            const filteredValue=propsValue.find((val :any)=>{
                return  val.role_hierarchy === Number(props.selectedRole?.role_hierarchy)-1
            })
            
            let selectedValue={
                _id : filteredValue?._id,
                name : filteredValue?.name,
            }

            setFormData((prevState :any )=> ({
                ...prevState,
                role_hierarchy: selectedValue
            }));

        }
    }


    const saveRole = async () => {
        if (validateField()) {

            const isNameExist = await isRoleExist();
            if(isNameExist) return false;

            if (isUpdate) {
                if (!needToSave) {
                    closeForm();
                    return
                }
                    let data ={
                        _id  : formData._id,
                        name :  formData.name,
                        role_hierarchy : formData.role_hierarchy._id,
                        access_permissions : formData.access_permissions
                    }
                    props.updateRole(data)
                    closeForm()
            } else {
                let data = {
                    name :  formData.name,
                    role_hierarchy : formData.role_hierarchy._id,
                    access_permissions : formData.access_permissions
                }
                props.addRole(data)
                closeForm()
            }
        }
    }

    const closeForm = () => {
        setFormData({})
        setIsEdit(true);
        setIsUpdate(false);
        if (props?.setVisibleClose) {
            setFormData({})
            setErrors({})
            props.setVisibleClose()
        }
    }

    const updateFormValue = (e : any)=> {
        const { name, value} = e.target;
        clearFieldError(name)
        setNeedToSave(true)
        setFormData((prevState :any )=> ({
            ...prevState,
            [name]: value
        }));
    };

    const isDisabled = () => !isEdit

    const handleCheckedItem = (event : any) => {        
        setNeedToSave(true)    
        let selectedValue=event.target.value;
        if(event.target.checked) {
                setFormData((prevState :any )=> ({
                    ...prevState,
                    access_permissions: prevState.access_permissions ? [...prevState.access_permissions, Number(selectedValue)] : [Number(selectedValue)]
                }));
            }else{
                setFormData((prevState :any )=> ({
                    ...prevState,
                    access_permissions: prevState.access_permissions ? formData.access_permissions.filter((checkedItem :any) => checkedItem !== Number(selectedValue)) : [Number(selectedValue)]
                }));
            }    
    }

    const handleParentChecked = (check : Array<any>,event : any) => {    
        setNeedToSave(true)  
        let defaultValue=check.filter(item=> item.default === true)
            if(event.target.checked) {
                setFormData((prevState :any)=> ({
                    ...prevState,
                    access_permissions: prevState.access_permissions ? [...prevState.access_permissions, defaultValue[0].permission] : [defaultValue[0].permission]
                }));
            }else{
                const Values = check.map(val => val.permission);
                setFormData((prevState :any)=> ({
                    ...prevState,
                    access_permissions: prevState.access_permissions ? formData.access_permissions.filter((checkedItem :any) => !Values.includes(checkedItem)) : [defaultValue[0].permission]
                }));
            }        
    }

    const handleCheckedFlag = (flag :Array<any>) => { 
        const value=flag.filter(value => {return formData?.access_permissions?.includes(value.permission)})
                if (value?.length)  
                   return true
    }

    const validateField = () => {
        let error: any = {}

        if (validate.isEmpty(formData.name)) {
            error.name = constants.message.role_name_required
        } else {
            error.name = ''
        }

        if (!formData?.role_hierarchy) {
            error.role_hierarchy = constants.message.role_position_reference_required
        } else {
            error.role_hierarchy = ''
        }

        if (!formData?.access_permissions) {
            error.role_access_permissions = constants.message.role_access_permission_required
        } else {
            if(formData?.access_permissions.length >0){
            error.role_access_permissions = ''
            } else{
            error.role_access_permissions = constants.message.role_access_permission_required
            }
        }

        setErrors(error)
        return !(error.name || error.role_hierarchy || error.role_access_permissions)
    }

    const clearFieldError = (field: string) => {
        let prevErrors = errors
        prevErrors[field] = ''
        setErrors(prevErrors)
    }

    const getSaveButtonDisabledCondition = () => {
        if (!isUpdate) {
            return false
        } else {
            return !(isEdit)
        }
    }

    const handleRoleSelection = (option: any) => {

        let  optionValue={
            _id  : option._id,
            name : option.name
        }
        setNeedToSave(true)
        clearFieldError('role_hierarchy')
        setFormData((prevState :any )=> ({
            ...prevState,
            'role_hierarchy': optionValue
        }));
    }

    const isRoleExist = async () => {
        if(!formData.name?.trim()) return;
        try {
          const resp = await roleService.isRoleExist(formData.name,formData?._id);
          const { role_exist_status } = resp.data;
          let errorMsg = role_exist_status ? constants.message.role_exists : '';  
          setErrors((prev: any) => ({...prev, name: errorMsg }));
          return role_exist_status;
        } catch (error) {}
    }

    const handleDataHierarchy = () => {
        let hierarchyValue=[...props.rolePositionReferenceType];
            if(isUpdate){
                const filteredValue=hierarchyValue.filter((val :any)=>{
                    return  val.name !== formData.name
                  })
                  if(typeof filteredValue === 'undefined'){
                    return props.rolePositionReferenceType
                  }else{
                    return filteredValue
                }
                }else{
                    return props.rolePositionReferenceType
                }
    }

    const toggleEdit = () => {
        if (isEdit) {
            initialSetup()
        }
        setIsEdit(!isEdit)
    }

    const showConfirmModal = () => {
        closeForm()
        Utility.showModal('confirmmodal')
    }

    const demoCheck = (authStore?.currentUser?.active_role?.role_type === -1);

    return (

        <Modal show={props.visible} onHide={closeForm} size="lg">
            <Modal.Header className="py-3 px-3 font-weight-bold" closeButton>
                {isUpdate ? props.selectedRole?.is_default || authStore?.currentUser?.access?.includes(super_admin_access) ? formData?.name : "Edit Role"  : "Add Role"}
            </Modal.Header>
            <Modal.Body >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {isUpdate && authStore?.currentUser?.access?.includes(org_admin_access) &&
                             !authStore?.currentUser?.access?.includes(super_admin_access) && 
                             !props?.selectedRole?.access_permissions?.includes(org_admin_access)  &&
                                <button type="button"
                                    className="btn btn-edit"
                                    onClick={toggleEdit}>
                                    {isEdit ? "Cancel Edit" : "Edit"}
                                </button>
                                }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-text-field">
                                <div className="form-group">
                                    <label className="custom-label">Role Name <span className="mand-star">*</span></label>
                                    <input type="text"
                                        className="form-control"
                                        name="name"
                                        value={formData?.name}
                                        onChange={updateFormValue}
                                        onBlur={isRoleExist}
                                        maxLength={50}
                                        disabled={isDisabled()}
                                        placeholder="Enter Role Name" />
                                    <div className="error-msg">{errors && errors.name ? errors.name : ''}</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="input-text-field">
                                <div className="form-group">
                                    <label className="custom-label">Role Hierarchy Level <span className="mand-star">*</span></label>
                                    <SelectComponent 
                                        name="role_hierarchy"
                                        isDisabled={isDisabled()}
                                        data={handleDataHierarchy()}
                                        value={formData.role_hierarchy}
                                        onChange={handleRoleSelection}
                                    />
                                    <div className="error-msg">{errors && errors.role_hierarchy ? errors.role_hierarchy : ''}</div>
                                </div>
                            </div>
                        </div>
                        {
                            Object.keys(constants.Permissions).map((key: any)=> {
                                return (
                                    <div className="col-md-6">
                                        <div className="input-text-field"> 
                                            <div className="form-group">
                                                {
                                            <input
                                            type="checkbox"   
                                            checked={handleCheckedFlag(constants.Permissions[key]) || false}
                                            disabled={isDisabled()}
                                            onChange={(e)=>{handleParentChecked(constants.Permissions[key],e)}}/>
                                                }
                                            <label className="custom-label"  style={{ fontSize: '1rem' ,marginLeft: '5px' }}>
                                                    {
                                                        key
                                                    }                      
                                            </label>
                                            </div>
                                        </div>
                                        <ul className="settings-roleul">
                                        {
                                        constants.Permissions[key].map((value :any) => {
                                            return (
                                                <li >
                                                    <input
                                                    key={key}
                                                    onChange={handleCheckedItem}
                                                    type="checkbox" 
                                                    name={value.desc}
                                                    checked={formData?.access_permissions?.includes(value.permission)}
                                                    value={value.permission} 
                                                    disabled={isDisabled()}/> 
                                                    <label className="custom-label" style={{ fontSize: '1rem' ,marginLeft: '5px' }}>
                                                    {value.desc}                           
                                                    </label>   
                                                </li>
                                                )
                                        })
                                        }
                                        </ul>
                                    </div>
                                )
                            })
                        }        
                    <div className="error-msg">{errors && errors.role_access_permissions ? errors.role_access_permissions : ''}</div>             
                    </div>
                </div>
            </Modal.Body>
            {authStore?.currentUser?.access?.includes(org_admin_access) && 
            !props?.selectedRole?.access_permissions?.includes(org_admin_access) && 
            !authStore?.currentUser?.access?.includes(super_admin_access) &&
            <div className={props.selectedRole?._id && isEdit ? "modal-footer modal-footer-custom" : "modal-footer"}>
                {props.selectedRole?._id && isEdit &&
                    <button
                        type="button"
                        disabled={demoCheck}
                        className="btn btn-inverse-info btn-rounded btn-icon delete-user-icon"
                        onClick={showConfirmModal}
                    >
                        <i className="fa fa-trash-o fa-lg" style={{color: "red"}} />
                    </button>
                    }

                <button type="button" disabled={getSaveButtonDisabledCondition() || demoCheck} className="btn btn-primary default-btn"
                    onClick={() => saveRole()}
                >{isUpdate ? "Update" : "Save"}
                </button>
            </div>
            }
        </Modal>
    );
}

export default AddRole
